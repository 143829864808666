import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Sphere } from '~/glxp/ogl/extras/Sphere.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Color } from '~/glxp/ogl/math/Color'

import { map } from '~/glxp/utils/math'

import DebugController from '~/glxp/debug/debugController'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class ExperienceBackground {
  constructor(
    scene,
    {
      parent = null,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = true,
      depthTest = false,
      depthWrite = false,
      renderOrder = -2,
      forceRenderOrder = true,
      name = 'Experience Background',
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.forceRenderOrder = forceRenderOrder
    this.name = name

    this.shader = new Shader(ShaderManifest['experienceBackground'], 1)

    this.textureBlobs = new Texture(this.gl)
    this.textureStars = new Texture(this.gl)
    this.textureBlue = new Texture(this.gl)

    this.progress = 0
    this.mask = 0
    this.starsMask = 0

    this.config = {
      ColorBackground: { value: "#100819", params: {} },
      StarsRes: {
        value: { x: 12, y: 8 },
        params: { x: { min: 0, max: 20, step: 0.01 }, y: { min: 0, max: 20, step: 0.01 } },
      },
      NoiseRes: {
        value: { x: 10, y: 10 },
        params: { x: { min: 0, max: 20, step: 0.01 }, y: { min: 0, max: 20, step: 0.01 } },
      },
      StarsRampStart: { value: 0.3, params: { min: 0, max: 1, step: 0.01 } },
      StarsRampEnd: { value: 0.7, params: { min: 0, max: 1, step: 0.01 } },
      NoiseSpeed: { value: -0.01, params: { min: -0.5, max: 0.5, step: 0.001 } },
    }

    this.init()
  }

  init() {
    // const VERTICES  = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0]
    // const INDICES   = [0, 1, 2, 0, 2, 3]
    // const UVS       = [ 0, 1, 0, 0, 1, 0, 1, 1 ]
    // const attribs = {
    //   position: { size: 3, data: new Float32Array(VERTICES) },
    //   uv: { size: 2, data: new Float32Array(UVS) },
    //   index: { data: new Uint16Array(INDICES) }
    // }

    this.geometry = new Sphere(this.gl, { radius: 500 })

    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      uniforms: {
        uRez: { value: [this.scene.width, this.scene.height] },
        uTime: { value: this.scene.time },
        uProgress: { value: this.progress },
        uCamera: { value: this.scene.camera.worldPosition },
        uTextureBlobs: { value: this.textureBlobs },
        uTextureStars: { value: this.textureStars },
        uTextureBlue: { value: this.textureBlue },
        uStarsRes: { value: [this.config.StarsRes.value.x, this.config.StarsRes.value.y]},
        uNoiseRes: { value: [this.config.NoiseRes.value.x, this.config.NoiseRes.value.y]},
        uStarsRampStart: this.config.StarsRampStart,
        uStarsRampEnd: this.config.StarsRampEnd,
        uNoiseSpeed: this.config.NoiseSpeed,
        uColorBackground: { value: new Color(this.config.ColorBackground.value) },
      },
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      cullFace: this.gl.FRONT,
    })

    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
      forceRenderOrder: this.forceRenderOrder,
    })

    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  initGui() {
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
  }

  setProgress(progress) {
    this.progress = progress
    this.mask = map(this.progress, 0.5, 1, 0, 1)
    this.starsMask = map(this.progress, 0, 0.5, 0, 1)
  }

  onLoaded() {
    this.initGui()
    
    this.textureBlobs = this.scene.textureLoader.getTexture('perlin_1')
    this.textureBlobs.needsUpdate = true
    this.program.uniforms['uTextureBlobs'].value = this.textureBlobs

    this.textureStars = this.scene.textureLoader.getTexture('stars')
    this.textureStars.needsUpdate = true
    this.program.uniforms['uTextureStars'].value = this.textureStars

    this.textureBlue = this.scene.textureLoader.getTexture('blue_1')
    this.textureBlue.needsUpdate = true
    this.program.uniforms['uTextureBlue'].value = this.textureBlue

    setTimeout(() => {
      this.program.uniforms["uStarsRes"].value = [this.config.StarsRes.value.x, this.config.StarsRes.value.y]
      this.program.uniforms["uNoiseRes"].value = [this.config.NoiseRes.value.x, this.config.NoiseRes.value.y]
      this.program.uniforms['uColorBackground'].value = new Color(this.config.ColorBackground.value)
    }, 1);
  }

  preRender() {
    this.program.uniforms['uTime'].value = this.scene.time
    this.program.uniforms['uProgress'].value = this.progress

    if (DebugController.active) {
      this.program.uniforms["uStarsRes"].value = [this.config.StarsRes.value.x, this.config.StarsRes.value.y]
      this.program.uniforms["uNoiseRes"].value = [this.config.NoiseRes.value.x, this.config.NoiseRes.value.y]
      this.program.uniforms['uColorBackground'].value = new Color(this.config.ColorBackground.value)
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default ExperienceBackground
