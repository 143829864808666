import gsap from 'gsap'
import throttle from 'lodash.throttle'

// Structural
import SceneAbs from '~/glxp/abstract/scene'
import Manifest from '~/glxp/manifest'

// Entities
import ExperienceBackground from '~/glxp/entities/experience/ExperienceBackground'
import ExperienceEarth from '~/glxp/entities/experience/ExperienceEarth'
import ExperienceEarthGlow from '~/glxp/entities/experience/ExperienceEarthGlow'
import ExperienceEarthGlowTop from '~/glxp/entities/experience/ExperienceEarthGlowTop'
import ExperienceEarthClouds from '~/glxp/entities/experience/ExperienceEarthClouds'
import ExperienceSun from '~/glxp/entities/experience/ExperienceSun'
import ExperienceLines from '~/glxp/entities/experience/ExperienceLines'
import LensFlares, { getJJAbramsFlares } from '~/glxp/entities/LensFlares'
import LensFlarePostEntity from '~/glxp/postProcess/LensFlarePost'

// Post
import Bloom from '~/glxp/postProcess/bloomPass'

// Utils
import RAF from '~/glxp/utils/raf'
import DebugController from '~/glxp/debug/debugController'
import GlobalEmitter from '~/glxp/utils/emitter'
import Mouse from '~/glxp/utils/mouse'
import { clamp, lerp } from '~/glxp/utils/math'
import { isMobile } from '~/glxp/utils/device'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'

// Managers
import CameraManager from '~/glxp/managers/CameraManager'
import DebugManager from '~/glxp/managers/DebugManager'
import HotspotManager from '~/glxp/managers/HotspotManager'

// Data

// Paths

class Scene extends SceneAbs {
  constructor(container, manager = null, shouldFirstDraw = false) {
    super(container, manager)

    this.name = 'Flight Experience'

    this.manager = manager
    this.renderer = manager.renderer

    this.time = 0
    this.dt = 0
    this.drawcalls = 0
    this.progress = 0
    this.progressDelta = 0
    this.progressTarget = 0.00001
    this.timescale = 1
    this.forceAllDraw = true
    this.lastWheel = 0
    this.wheelProgress = 0

    this.debugManager = new DebugManager(this)

    this.clearColor = this.manager.clearColor
    this.loaded = false
    this.active = false

    this.textureLoader = this.manager.textureLoader

    // Meshes
    this.meshes = []
    this.root = new Transform()
    this.cameraWrapper = new Transform()
    this.cameraWrapper.setParent(this.root)

    // First Draw
    this.shouldFirstDraw = shouldFirstDraw
    this.didFirstDraw = false
    this.pendingFDMeshes = []
    this.pendingFDMeshesPassed = []

    this.root.scale.set(1, 1, 1)

    this.config = {
      Pause: { value: false, type: "bool" },
      TimeScale: { value:  1, params: {min: 0, max: 3, step: 0.01} },
      ScrollDamping: { value: 0.05, params: { min: 0, max: 1, step: 0.01 } },
      SunPosition: { value: { x: -10.5, y: 0, z: -10.15 }, params: {} },
      // DEV
      OverrideScroll: {value: false, params: {}},
      Progress: { value: 1, params: {min: 0, max: 1, step: 0.01} },
      DevScrollSpeed: { value: 30, params: { min: 0, max: 60 } },
    }

    DebugController.addBlade(
      this.config,
      `Global Settings - ${this.name || 'Unknown'}`
    )

    // Animations
    this.animCameraPos = { x: 0, y: 0.5, z: 1.5 }
    if(isMobile) {
      this.animCameraLookat = { x: 0, y: 2.4, z: 0 }
    } else {
      this.animCameraLookat = { x: 0, y: 2.7, z: 0 }
    }
    this.animCameraWrapperRot = { x: 0, y: 0, z: Math.PI * 0.5 }
    this.animEarthGlowRot = { x: 0, y: Math.PI * 0.25, z: 0 }
    this.animEarthGlowTopRot = { x: 0, y: Math.PI * 0.25, z: 0 }
    this.animEarthSunRot = { x: 0, y: 0, z: -Math.PI * 0.5 }
    this.animEarthPos = { x: 0, y: 0, z: 0 }
    this.animEarthRot = { x: 0, y: -Math.PI * 1.25, z: 0 }
    this.animSunPos = { ...this.config.SunPosition.value }

    // Inits
    this.initCameras()
    this.initBackground()
    this.initEarth()
    this.initEarthClouds()
    this.initEarthGlow()
    this.initLines()
    this.initSun()

    if (!DebugController.queryDebug('notextures')) {
      this.initFlares()
    }

    // Should be created after Lines, hotspots are linked to them
    this.initHotspots()

    // Binds
    this.boundOnProgressRequest = this.onProgressRequest.bind(this)
  }

  // Inits
  initHotspots () {
    this.hotspotManager = new HotspotManager(this.gl, {
      scene: this,
      debug: DebugController.queryDebug('dev'),
      hotspots: this.lines.hotspots,
    })

    GlobalEmitter.on('webgl_flight_experience_hotspots_request', this.emitHotspots.bind(this))
  }

  initCameras() {
    this.cameraManager = new CameraManager(this.gl, {
      width: this.width,
      height: this.height,
      scene: this,
      debug: false,
      hasMousePan: false,
      // mousePanOffsets: [0.02, -0.01]
    })
    this.camera = this.cameraManager.camera
    this.camera.setParent(this.cameraWrapper)

    this.cameraManager.toGo.set(this.animCameraPos.x, this.animCameraPos.y, this.animCameraPos.z)
    this.cameraManager.toLook.set(this.animCameraLookat.x, this.animCameraLookat.y, this.animCameraLookat.z)
    this.cameraWrapper.rotation.set(this.animCameraWrapperRot.x, this.animCameraWrapperRot.y, this.animCameraWrapperRot.z)
  }

  initPost() {
    this.post = new LensFlarePostEntity(this, this.postOptions)

    this.post.config.DirtDistanceFactor.value = 5.5
    this.post.config.DirtResolutionScale.value.x = 2.03 // Needed to prevent erratic shader max() behavior
    this.post.config.DirtResolutionScale.value.y = 2
    this.post.config.DirtResolutionOffset.value.x = .03
    this.post.config.DirtResolutionOffset.value.y = .32
  }

  initBackground(){
    this.background = new ExperienceBackground(this)
    
    this.meshes.push(this.background)
  }

  initEarth(){
    this.earth = new ExperienceEarth(this)
    this.earth.earthMesh.position.set(this.animEarthPos.x, this.animEarthPos.y, this.animEarthPos.z)
    this.earth.earthMesh.rotation.set(this.animEarthRot.x, this.animEarthRot.y, this.animEarthRot.z)
    
    this.meshes.push(this.earth)
  }

  initEarthClouds(){
    this.earthClouds = new ExperienceEarthClouds(this, { parent: this.earth.earthMesh })
    
    this.meshes.push(this.earthClouds)
  }

  initEarthGlow() {
    this.glow = new ExperienceEarthGlow(this)
    this.glow.meshWrapper.rotation.set(this.animEarthGlowRot.x, this.animEarthGlowRot.y, this.animEarthGlowRot.z)
    this.meshes.push(this.glow)

    this.glowTop = new ExperienceEarthGlowTop(this)
    this.glowTop.meshWrapper.rotation.set(this.animEarthGlowTopRot.x, this.animEarthGlowTopRot.y, this.animEarthGlowTopRot.z)
    this.meshes.push(this.glowTop)
  }

  initLines() {
    this.lines = new ExperienceLines(this, { parent: this.earth.earthMesh })

    this.meshes.push(this.lines)
  }
  
  initSun(){
    this.sun = new Transform()
    // this.sun.visible = false
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    this.sun.setParent(this.root)

    this.sunGlow = new ExperienceSun(this, { parent: this.sun })
    this.meshes.push(this.sunGlow)
  }

  initFlares(){
    this.lensFlares = new LensFlares(this, {
      lightSource: this.sun,
      getFlares: (spacing) => getJJAbramsFlares(this, spacing),
      spacing: 0.11
    })
    this.meshes.push(this.lensFlares)
  }

  // Lifecycle
  setProgress(p, shouldForce = false) {
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  activate() {
    return new Promise((resolve) => {
      this.active = true
      this.activationResolve = resolve
      this.postFirstDraw()

      this.addEvents()
    })
  }

  disable() {
    this.active = false

    this.removeEvents()
  }

  async load() {
    let loadables = []

    // Post
    this.initPost()

    // Textures
    const addTextureToLoadables = (groupKey) => {
      for (const key in Manifest[groupKey]) {
        if (Manifest[groupKey].hasOwnProperty(key)) {
          const element = Manifest[groupKey][key]
          loadables.push(this.textureLoader.load(element.url, key, element.options))
        }
      }
    }

    // Textures - Common
    addTextureToLoadables('main')
    addTextureToLoadables('JJAbramsLensFlares')
    addTextureToLoadables('spaceUnlit')
    addTextureToLoadables('flightExperienceUnlit')

    // Load
    Promise.all(loadables).then(() => {
      this.onLoaded()
    })

    // Loading progression
    let percent = 0
    for (let i = 0; i < loadables.length; i++) {
      loadables[i].then(() => {
        percent++
        const formatedVal = Math.round(
          (percent / loadables.length) * 100
        )
        this._emitter.emit('progress', formatedVal)
        GlobalEmitter.emit('webgl_loading_progress', { progress: formatedVal })
      })
    }
  }

  // Animations
  initTimelineScroll () {
    this.tlScroll = gsap.timeline({ paused: true, duration: 100 })

    // CAMERA
    if(isMobile) {
      this.tlScroll.to(this.animCameraPos, { x: 0, y: 2, z: 9, duration: 90, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.animCameraLookat, { x: 0, y: -1, z: 0, duration: 70, ease: 'power2.inOut' }, 0)
    }else{
      this.tlScroll.to(this.animCameraPos, { x: 0, y: 2, z: 4.5, duration: 90, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.animCameraLookat, { x: 0, y: 0, z: 0, duration: 70, ease: 'power2.inOut' }, 0)
    }
    this.tlScroll.to(this.animCameraWrapperRot, { x: 0, y: 0, z: 0, duration: 70, ease: 'power2.inOut' }, 0)
    
    // POST
    if (!DebugController.queryDebug('notextures')) {
      this.tlScroll.to(this.animSunPos, { x: 0, y: -4, z: -10, duration: 60, ease: 'power2.inOut' }, 0)
      this.tlScroll.to(this.post.config.DirtOpacityFactor, { value: 0, duration: 70, ease: 'power2.inOut' }, 0)
      this.tlScroll.to(this.lensFlares.config.GlobalOpacity, { value: 0, duration: 30, ease: 'power2.inOut' }, 0)
    }

    // SUN
    // this.tlScroll.to(this.animEarthSunRot, { x: 0, y: 0, z: 0, duration: 60, ease: 'power2.inOut' }, 0)
    this.tlScroll.to(this.sunGlow.config.Alpha, { value: 0, duration: 35, ease: 'power2.inOut' }, 0)

    // EARTH GLOW
    this.tlScroll.to(this.animEarthGlowRot, { x: 0, y: 0, z: 0, duration: 70, ease: 'power2.inOut' }, 0)
    this.tlScroll.to(this.animEarthGlowTopRot, { x: 0, y: 0, z: 0, duration: 70, ease: 'power2.inOut' }, 0)
    this.tlScroll.fromTo(this.glow.config.GlowRotationFactor, { value: 0 }, { value: 0.8, duration: 90, ease: 'power3.inOut' }, 0)
    this.tlScroll.fromTo(this.glowTop.config.RimAlpha, { value: 1 }, { value: 0, duration: 35, ease: 'power2.inOut' }, 0)

    // EARTH CLOUDS
    this.tlScroll.to(this.earthClouds.config.Progress, { value: 1, duration: 40, ease: 'power2.inOut' }, 10)

    // EARTH
    this.tlScroll.to(this.animEarthRot, { x: 0, y: -Math.PI * 0.75, z: 0, duration: 100, ease: 'power3.inOut' }, 0)
    this.tlScroll.to(this.earth.config.CloseupFactor, { value: 0, duration: 30, ease: 'power2.inOut' }, 10)
    this.tlScroll.to(this.earth.config.CycleProgress, { value: 0.55, duration: 70, ease: 'power2.inOut' }, 10)
    this.tlScroll.to(this.earth.config.FresnelGlowIntensity, { value: 1, duration: 70, ease: 'power2.inOut' }, 20)

    // LINES
    this.lines.lines.forEach((line, i) => {
      this.tlScroll.to(line.config.DottedLineProgress, { value: 1, duration: 40, ease: 'power2.out' }, 40 + i * 5)
      this.tlScroll.to(line.config.FullLineProgress, { value: 1, duration: 35, ease: 'power2.out' }, 50 + i * 5)
    })
  }

  // Utils
  applyDefaultState() {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  reset() {
    // Progress
    this.setProgress(0, true)
  }

  getFirstDrawPromises() {
    let promises = []

    this.meshes.forEach((mesh) => {
      if (mesh.getFirstDrawPromise) {
        promises.push(mesh.getFirstDrawPromise())
        this.pendingFDMeshes.push(mesh)
      }
    })

    return promises
  }

  drawNextFDMesh() {
    if (this.pendingFDMeshes.length > 0) {
      this.pendingFDMeshes[0].firstDraw()
      this.pendingFDMeshesPassed.push(this.pendingFDMeshes.shift())
    }
  }
  
  emitHotspots() {
    GlobalEmitter.emit('webgl_flight_experience_hotspots', { hotspots: this.lines.hotspots })
  }


  // Events
  addEvents() {
    GlobalEmitter.on('webgl_flight_experience_progress', this.boundOnProgressRequest)
  }

  removeEvents() {
    GlobalEmitter.off('webgl_flight_experience_progress', this.boundOnProgressRequest)
  }

  onLoaded() {
    this.active = true
    this.loaded = true

    this._emitter.emit('loaded')
    GlobalEmitter.emit('webgl_loaded')

    // First Draw
    if (this.shouldFirstDraw) {
      const firstDrawPromises = this.getFirstDrawPromises()

      this.firstDrawAllPromise = Promise.all(firstDrawPromises).then(() => {
        this.didFirstDraw = true
        // console.log(`Scene ${this.name} finished first draw`)
      })
    }

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].onLoaded()
    }

    // Post
    if(this.hasBloom){
      this.bloomPass = new Bloom(this, this.post)
    }
    this.post.onLoaded()

    // Debug
    DebugController.onLoaded()
    this.debugManager.onLoaded()
    
    // TODO: Refactor
    setTimeout(() => {
      this.initTimelineScroll()
    }, 200);
  }

  postFirstDraw() {}

  onProgressRequest({ progress }) {
    this.setProgress(progress)
  }

  resize() {
    this.cameraManager.resize(this.width, this.height)
    this.hotspotManager.resize(this.width, this.height)
  }

  // Rendering
  preRender() {
    // Progress
    if (this.hasWheel) {
      // Listen to mouse wheel for progress on Sandbox

      if (!this.isAnimatingIntro) {
        this.wheelProgress += Mouse.normalizeWheel - this.lastWheel
        this.wheelProgress = clamp(this.wheelProgress, 0, 0.037)
      }

      // Can override it through the config value if needed
      this.progressTarget = this.config.OverrideScroll.value ? this.config.Progress.value : this.wheelProgress * this.config.DevScrollSpeed.value
      this.progressTarget = clamp(this.progressTarget, 0, 1)
      this.progress = lerp(this.progress, this.progressTarget, 0.075)
      this.lastWheel = Mouse.normalizeWheel
    } else {
      // Listen to FE events for progress
      let tmp = this.progressTarget - this.progress
      this.progressDelta = tmp
      tmp *= this.config.ScrollDamping.value

      this.progress += tmp
    }

    // TODO: move this to a .on() tweakpane event
    this.sun.position.set(this.animSunPos.x, this.animSunPos.y, this.animSunPos.z)

    // Animations
    if (this.tlScroll) {
      if(isMobile) this.tlScroll.progress(this.progress * 1.2)
      else this.tlScroll.progress(this.progress)
    }

    this.earth.earthMesh.position.set(this.animEarthPos.x, this.animEarthPos.y, this.animEarthPos.z)
    this.earth.earthMesh.rotation.set(this.animEarthRot.x, this.animEarthRot.y, this.animEarthRot.z)

    this.cameraManager.toGo.set(this.animCameraPos.x, this.animCameraPos.y, this.animCameraPos.z)
    this.cameraManager.toLook.set(this.animCameraLookat.x, this.animCameraLookat.y, this.animCameraLookat.z)
    this.cameraWrapper.rotation.set(this.animCameraWrapperRot.x, this.animCameraWrapperRot.y, this.animCameraWrapperRot.z)
    
    this.glow.meshWrapper.rotation.set(this.animEarthGlowRot.x, this.animEarthGlowRot.y, this.animEarthGlowRot.z)
    this.glowTop.meshWrapper.rotation.set(this.animEarthGlowTopRot.x, this.animEarthGlowTopRot.y, this.animEarthGlowTopRot.z)
    this.sunGlow.meshWrapper.rotation.set(this.animEarthSunRot.x, this.animEarthSunRot.y, this.animEarthSunRot.z)
    
    // Rendering
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].preRender()
    }

    this.cameraManager.preRender()
  }

  render() {
    // First Draw
    if (this.shouldFirstDraw && !this.didFirstDraw) {
      this.drawNextFDMesh()
      return
    }

    if (!this.active) {
      return
    }

    // Timings
    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    // Pre render
    this.preRender()

    // Aspect
    this.gl.viewport(0, 0, this.width, this.height)
    this.camera.perspective({ aspect: this.width / this.height })
    this.renderer.setViewport(this.width, this.height)

    // Render Time
    this.renderer.render({
      scene: this.root,
      camera: this.camera,
      clear: true,
      frustumCull: true,
      sort: true,
      post: this.post,
    })

    // Post
    this.bloomPass && this.bloomPass.render()
    this.post.render()

    this.postRender()
  }

  throttledHotspotUpdate = throttle(() => {
    this.hotspotManager.update()
  }, 10)

  postRender() {
    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }
    
    this.throttledHotspotUpdate()
  }
}

export default Scene
