// Dependencies
import UAParser from 'ua-parser-js'

// Server vs Browser
// See: https://github.com/vercel/next.js/issues/5354#issuecomment-520305040
export const IS_SERVER = process.server
export const IS_BROWSER = process.client

// Environment Constants
export const DEV = process.env.NODE_ENV === 'development'
export const PROD = process.env.NODE_ENV !== 'development'

// Responsive Constants
export const BREAKPOINTS = {
  XXXS: 0,
  S: 375,
  M: 768,
  L: 1200,
  XL: 1440,
  XXL: 1600,
  XXXL: 1920,
}

// UA Constants
// UA Parser
const PARSER = new UAParser()

// Set UA Parser
if (IS_BROWSER) {
  PARSER.setUA(navigator.userAgent)
}

export const OS = PARSER.getOS()
export const DEVICE = PARSER.getDevice()
export const BROWSER = PARSER.getBrowser()

export const IS_MOBILE = DEVICE.type === 'mobile'
export const IS_TABLET = DEVICE.type === 'tablet'

export const SPACEPORT_ALTITUDE = 4500 // feet
export const MAX_ALTITUDE = 297000 // feet
