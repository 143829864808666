import GlobalEmitter from '~~/glxp/utils/emitter'

function onRouteChange({ name }) {
  // console.log('route changed:', name)
  window.trackingVars.page = name
  window.trackingVars.slug = null
  setTimeout(() => {
    window.Evergage?.reinit()
  }, 0)
}

function onSectionChange(slug) {
  // console.log('section changed:', slug)
  window.trackingVars.slug = slug
  setTimeout(() => {
    window.Evergage?.reinit()
  }, 0)
}

export default defineNuxtPlugin(() => {
  // initialize global variable
  window.trackingVars = {
    page: null,
    slug: null,
  }

  // watch route and section changes
  const route = useRoute()
  watch(route, onRouteChange)
  GlobalEmitter.on('reveal', onSectionChange)
})
