import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Sphere } from '~/glxp/ogl/extras/Sphere.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Color } from '~/glxp/ogl/math/Color'
import { Texture } from '~/glxp/ogl/core/Texture.js'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class ExperienceEarthEntity {
  constructor(
    scene,
    {
      id = 0,
      parent = null,
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = false,
      depthTest = true,
      depthWrite = true,
      renderOrder = 0,
      name = 'Experience Earth'
    } = {}
  ) {
    this.groupId = id
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name

    // Shader
    this.shaderEarth = new Shader(ShaderManifest['experiencePlanet'])

    // Textures
    this.texture = new Texture(this.gl)
    this.textureClouds = new Texture(this.gl)
    this.textureNight = new Texture(this.gl)

    this.config = {
      TexturesOffset: {
        value: { x: -0.375, y: 0 },
        params: { x: { min: -1, max: 1, step: 0.01 }, y: { min: -1, max: 1, step: 0.01 } },
      },

      CycleStart: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      CycleProgress: { value: 0.15, params: { min: 0, max: 1, step: 0.01 } },
      CycleLength: { value: 0.1, params: { min: 0, max: 1, step: 0.01 } },

      CloudsColorVariance: { value: 0.2, params: { min: 0.01, max: 1, step: 0.01 } },
      CloudsSpeedFactor: { value: 0.001, params: { min: 0, max: 0.1, step: 0.0001 } },
      CloudsShadowOffset: {
        value: { x: -0.002, y: 0.002 },
        params: { x: { min: -0.1, max: 0.1, step: 0.001 }, y: { min: -0.1, max: 0.1, step: 0.001 } },
      },
      CloudsShadowIntensity: { value: 0.20, params: { min: 0.01, max: 1, step: 0.01 } },

      FresnelShadowColor: { value: "#08062b", params: {} },
      FresnelGlowColor: { value: "#2c65df", params: {} },
      FresnelGlowRimColor: { value: "#659af8", params: {} },
      FresnelGlowIntensity: { value: 1, params: { min: 0, max: 1 }  },

      GlowRotation: { value: 2, params: {  min: 0, max: Math.PI * 2, step: 0.01  }  },
      GlowRotationFactor: { value: 0.5, params: {  min: 0, max: 1, step: 0.01  }  },
    }

    this.initEarth()
  }

  initGui(){
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
  }

  initEarth() {
    this.earthGeometry = new Sphere(this.gl, { radius: 1.35, widthSegments: 128 })

    this.earthProgram = new Program(this.gl, {
      vertex: this.shaderEarth.vert,
      fragment: this.shaderEarth.frag,
      uniforms: {
        uTime: { value: this.scene.time }, 
        uAlpha: { value: 1 },
        uCamera: { value: this.scene.camera.worldPosition },

        uTexture: { value: this.texture },
        uTextureClouds: this.textureClouds,
        uTextureNight: this.textureNight,
        uTexturesOffset: { value: [this.config.TexturesOffset.value.x, this.config.TexturesOffset.value.y]},
        
        uCycleStart: this.config.CycleStart,
        uCycleProgress: this.config.CycleProgress,
        uCycleLength: this.config.CycleLength,

        uCloudsColorVariance: this.config.CloudsColorVariance,
        uCloudsSpeedFactor: this.config.CloudsSpeedFactor,
        uCloudsShadowIntensity: this.config.CloudsShadowIntensity,
        uCloudsShadowOffset: { value: [this.config.CloudsShadowOffset.value.x, this.config.CloudsShadowOffset.value.y]},

        uFresnelShadowColor: { value: new Color(this.config.FresnelShadowColor.value) },
        uFresnelGlowColor: { value: new Color(this.config.FresnelGlowColor.value) },
        uFresnelGlowRimColor: { value: new Color(this.config.FresnelGlowRimColor.value) },
        uFresnelGlowIntensity: this.config.FresnelGlowIntensity,

        uGlowRotation: this.config.GlowRotation,
        uGlowRotationFactor: this.config.GlowRotationFactor,
      },
      transparent: this.transparent,
      depthTest: true,
      depthWrite: true,
    })

    this.earthProgram.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.earthMesh = new Mesh(this.gl, {
      geometry: this.earthGeometry,
      program: this.earthProgram,
      renderOrder: this.renderOrder,
    })

    this.earthMesh.scale.set(this.scale, this.scale, this.scale)
    this.earthMesh.name = this.name
    this.earthMesh.setParent(this.parent)
  }

  onLoaded() {
    if (this.texture) {
      this.texture = this.scene.textureLoader.getTexture('flightExperienceEarthDay')
      this.texture.needsUpdate = true
      this.earthProgram.uniforms['uTexture'].value = this.texture
    }

    if (this.textureClouds) {
      this.textureClouds = this.scene.textureLoader.getTexture('flightExperienceEarthClouds')
      this.textureClouds.needsUpdate = true
      this.earthProgram.uniforms['uTextureClouds'].value = this.textureClouds
    }

    if (this.textureNight) {
      this.textureNight = this.scene.textureLoader.getTexture('flightExperienceEarthNight')
      this.textureNight.needsUpdate = true
      this.earthProgram.uniforms['uTextureNight'].value = this.textureNight
    }

    this.initGui()

    setTimeout(() => {
      this.earthProgram.uniforms["uTexturesOffset"].value = [this.config.TexturesOffset.value.x, this.config.TexturesOffset.value.y]
      this.earthProgram.uniforms["uCloudsShadowOffset"].value = [this.config.CloudsShadowOffset.value.x, this.config.CloudsShadowOffset.value.y]

      this.earthProgram.uniforms['uFresnelShadowColor'].value = new Color(this.config.FresnelShadowColor.value)
      this.earthProgram.uniforms['uFresnelGlowColor'].value = new Color(this.config.FresnelGlowColor.value)
      this.earthProgram.uniforms['uFresnelGlowRimColor'].value = new Color(this.config.FresnelGlowRimColor.value)
    }, 1);
  }

  preRender() {
    this.earthProgram.uniforms["uTime"].value = this.scene.time

    if (DebugController.active) {
      this.earthProgram.uniforms["uTexturesOffset"].value = [this.config.TexturesOffset.value.x, this.config.TexturesOffset.value.y]
      this.earthProgram.uniforms["uCloudsShadowOffset"].value = [this.config.CloudsShadowOffset.value.x, this.config.CloudsShadowOffset.value.y]

      this.earthProgram.uniforms['uFresnelShadowColor'].value = new Color(this.config.FresnelShadowColor.value)
      this.earthProgram.uniforms['uFresnelGlowColor'].value = new Color(this.config.FresnelGlowColor.value)
      this.earthProgram.uniforms['uFresnelGlowRimColor'].value = new Color(this.config.FresnelGlowRimColor.value)
    }
  }

  dispose() {
    this.earthMesh.setParent(null)
    this.earthGeometry.remove()
    this.earthProgram.remove()
  }
}

export default ExperienceEarthEntity
