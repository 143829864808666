import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Color } from '~/glxp/ogl/math/Color'
import { Vec2 } from '~/glxp/ogl/math/Vec2'

import { map } from '~/glxp/utils/math'
import { isMobile } from '@/glxp/utils/device'


class SpaceportSky {
  constructor(
    scene,
    {
      parent = null,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = true,
      depthTest = false,
      depthWrite = false,
      renderOrder = 200,
      forceRenderOrder = true,
      name = 'Spaceport Sky',
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.forceRenderOrder = forceRenderOrder
    this.name = name

    this.shader = new Shader(ShaderManifest['spaceportSky'], 1)

    this.textureBlobs = new Texture(this.gl)
    this.textureStars = new Texture(this.gl)
    this.textureBlue = new Texture(this.gl)

    this.progress = 0
    this.mask = 0
    this.starsMask = 0

    this.config = {
      ColorTop: { value: '#00000c', params: {} },
      ColorBottom: { value: '#2a3e93', params: {} },
      StarsRes: { value: 1.5, params: { min: 0.1, max: 10, step: 0.01 } },
      StarsMaskOffset: { value: 0.75, params: { min: 0, max: 1, step: 0.01 } },
      StarsOpacity: { value: 0.8, params: { min: 0, max: 1, step: 0.01 } },
      BlobsColor1: { value: '#43125d', params: {} },
      BlobsColor2: { value: '#1c1e7c', params: {} },
      BlobsOpacity: { value: 0.35, params: { min: 0, max: 1, step: 0.01 } },
      BlobsSpeed: { value: 0.05, params: { min: 0, max: 1, step: 0.001 } },
      MaskLength: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
      DitheringFactor: { value: 0.1, params: { min: 0, max: 1, step: 0.01 } },
    }

    this.init()
  }

  init() {
    const VERTICES  = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0]
    const INDICES   = [0, 1, 2, 0, 2, 3]
    const UVS       = [ 0, 1, 0, 0, 1, 0, 1, 1 ]
    const attribs = {
      position: { size: 3, data: new Float32Array(VERTICES) },
      uv: { size: 2, data: new Float32Array(UVS) },
      index: { data: new Uint16Array(INDICES) }
    }

    this.geometry = new Geometry(this.gl, attribs)

    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      uniforms: {
        uRez: { value: [this.scene.width, this.scene.height] },
        uTime: { value: this.scene.time },
        uProgress: { value: this.progress },
        uDitheringFactor: this.config.DitheringFactor,
        uTextureBlobs: { value: this.textureBlobs },
        uTextureStars: { value: this.textureStars },
        uTextureBlue: { value: this.textureBlue },
        uColorTop: { value: new Color(this.config.ColorTop.value) },
        uColorBottom: { value: new Color(this.config.ColorBottom.value) },
        uStarsRes: this.config.StarsRes,
        uStarsMaskOffset: this.config.StarsMaskOffset,
        uStarsOpacity: this.config.StarsOpacity,
        uBlobsColor1: { value: new Color(this.config.BlobsColor1.value) },
        uBlobsColor2: { value: new Color(this.config.BlobsColor2.value) },
        uBlobsOpacity: this.config.BlobsOpacity,
        uBlobsSpeed: this.config.BlobsSpeed,
        uStarsMask: { value: this.starsMask },
        uMask: { value: this.mask },
        uMaskScale: { value: new Vec2(isMobile ? 0.25 : 1, 1) },
        uMaskLength: this.config.MaskLength,
      },
    })

    this.program.cullFace = false

    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
      forceRenderOrder: this.forceRenderOrder,
    })

    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  initGui() {
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - Night Sky`, 1)
  }

  setProgress(progress) {
    this.progress = progress
    this.mask = map(this.progress, 0.5, 1, 0, 1)
    this.starsMask = map(this.progress, 0, 0.5, 0, 1)
  }

  onLoaded() {
    this.initGui()
    
    this.textureBlobs = this.scene.textureLoader.getTexture('perlin_1')
    this.textureBlobs.needsUpdate = true
    this.program.uniforms['uTextureBlobs'].value = this.textureBlobs

    this.textureStars = this.scene.textureLoader.getTexture('stars')
    this.textureStars.needsUpdate = true
    this.program.uniforms['uTextureStars'].value = this.textureStars

    this.textureBlue = this.scene.textureLoader.getTexture('blue_1')
    this.textureBlue.needsUpdate = true
    this.program.uniforms['uTextureBlue'].value = this.textureBlue

    setTimeout(() => {
      this.program.uniforms['uColorTop'].value = new Color(this.config.ColorTop.value)
      this.program.uniforms['uColorBottom'].value = new Color(this.config.ColorBottom.value)
      this.program.uniforms['uBlobsColor1'].value = new Color(this.config.BlobsColor1.value)
      this.program.uniforms['uBlobsColor2'].value = new Color(this.config.BlobsColor2.value)
    }, 1);
  }

  preRender() {
    this.program.uniforms['uTime'].value = this.scene.time
    this.program.uniforms['uProgress'].value = this.progress
    this.program.uniforms['uMask'].value = this.mask
    this.program.uniforms['uStarsMask'].value = this.starsMask
    this.program.uniforms['uRez'].value = [this.scene.width, this.scene.height]

    if (DebugController.active) {
      this.program.uniforms['uColorTop'].value = new Color(this.config.ColorTop.value)
      this.program.uniforms['uColorBottom'].value = new Color(this.config.ColorBottom.value)
      this.program.uniforms['uBlobsColor1'].value = new Color(this.config.BlobsColor1.value)
      this.program.uniforms['uBlobsColor2'].value = new Color(this.config.BlobsColor2.value)
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default SpaceportSky
