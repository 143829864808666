import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const GTM_ID = runtimeConfig.public.GTM_ID
  if (!GTM_ID) return
  nuxtApp.vueApp.use(
    createGtm({
      id: GTM_ID,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: true,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
    })
  )
})
