import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Plane } from '~/glxp/ogl/extras/Plane.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Color } from '~/glxp/ogl/math/Color'
import { Texture } from '~/glxp/ogl/core/Texture.js'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class ExperienceSunEntity {
  constructor(
    scene,
    {
      id = 0,
      parent = null,
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = false,
      depthTest = false,
      depthWrite = false,
      renderOrder = 0,
      name = 'Experience Sun'
    } = {}
  ) {
    this.groupId = id
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name

    // Shader
    this.shaderGlow = new Shader(ShaderManifest['experienceSun'])

    this.config = {
      Alpha: { value: 1, params: {  min: 0, max: 1, step: 0.01  }  },
      SunSize: { value: 0.3, params: {  min: 0, max: 1, step: 0.01  }  },
      SunScaling: { value: {x: 1, y: 1}, params: {  x: { min: 0, max: 5, step: 0.01 }, y: { min: 0, max: 4, step: 0.001 }  }  },
      SunColorTaper: { value: 0.75, params: {  min: 0, max: 1, step: 0.01  }  },
      ColorSunCenter: { value: '#ffffff', params: { }  },
      ColorSunOutside: { value: '#e84e82', params: { }  },
      ColorFresnelCenter: { value: '#ffffff', params: { }  },
      ColorFresnelOutside: { value: '#562bff', params: { }  },
      ColorMinimumFactor: { value: 0.7, params: {  min: 0, max: 1, step: 0.01  }  },
    }

    this.init()
  }

  initGui(){
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
  }

  init() {
    this.geometry = new Plane(this.gl, { width: 20, height: 20 })

    this.program = new Program(this.gl, {
      vertex: this.shaderGlow.vert,
      fragment: this.shaderGlow.frag,
      uniforms: {
        uTime: { value: this.scene.time }, 
        uCamera: { value: this.scene.camera.worldPosition },
        uAlpha: this.config.Alpha,
        uSunSize: this.config.SunSize,
        uSunScaling: { value: [this.config.SunScaling.value.x, this.config.SunScaling.value.y] },
        uSunColorTaper: this.config.SunColorTaper,
        uColorSunCenter: { value: new Color(this.config.ColorSunCenter.value) },
        uColorSunOutside: { value: new Color(this.config.ColorSunOutside.value) },
        uColorFresnelCenter: { value: new Color(this.config.ColorFresnelCenter.value) },
        uColorFresnelOutside: { value: new Color(this.config.ColorFresnelOutside.value) },
        uColorMinimumFactor: this.config.ColorMinimumFactor,
      },
      transparent: true,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
    })

    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
    })

    this.mesh.scale.set(this.scale, this.scale, this.scale)
    this.mesh.lookAt(this.scene.camera.position)
    this.mesh.name = this.name

    this.meshWrapper = new Transform(this.gl)
    this.mesh.setParent(this.meshWrapper)
    this.meshWrapper.setParent(this.parent)
  }

  onLoaded() {
    this.initGui()

    setTimeout(() => {
      this.program.uniforms['uColorSunCenter'].value = new Color(this.config.ColorSunCenter.value)
      this.program.uniforms['uColorSunOutside'].value = new Color(this.config.ColorSunOutside.value)
      this.program.uniforms['uColorFresnelCenter'].value = new Color(this.config.ColorFresnelCenter.value)
      this.program.uniforms['uColorFresnelOutside'].value = new Color(this.config.ColorFresnelOutside.value)
      this.program.uniforms["uSunScaling"].value[0] = this.config.SunScaling.value.x
      this.program.uniforms["uSunScaling"].value[1] = this.config.SunScaling.value.y
    }, 1);
  }

  preRender() {
    this.program.uniforms["uTime"].value = this.scene.time

    this.mesh.lookAt(this.scene.camera.position)

    if (DebugController.active) {
      this.program.uniforms['uColorSunCenter'].value = new Color(this.config.ColorSunCenter.value)
      this.program.uniforms['uColorSunOutside'].value = new Color(this.config.ColorSunOutside.value)
      this.program.uniforms['uColorFresnelCenter'].value = new Color(this.config.ColorFresnelCenter.value)
      this.program.uniforms['uColorFresnelOutside'].value = new Color(this.config.ColorFresnelOutside.value)
      this.program.uniforms["uSunScaling"].value[0] = this.config.SunScaling.value.x
      this.program.uniforms["uSunScaling"].value[1] = this.config.SunScaling.value.y
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default ExperienceSunEntity
