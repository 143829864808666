import throttle from 'lodash.throttle'

// Structural
import SubsceneAbs from '~/glxp/abstract/subscene'
import Manifest from '~/glxp/manifest'

// Entities
import BackgroundBezierEntity from '~/glxp/entities/BackgroundBezier'
import MeshDecalVSSUnity from '~/glxp/entities/MeshVSSDecalUnity'
import LensFlares, { getSpacecraftFleetUnityFlares } from '~/glxp/entities/LensFlares'
import Occluder from '~/glxp/entities/Occluder'
import LensFlarePostEntity from '~/glxp/postProcess/LensFlarePost'

// Post
import Bloom from '~/glxp/postProcess/bloomPass'

// Utils
import Mouse from '@/glxp/utils/mouse'
import RAF from '~/glxp/utils/raf'
import DebugController from '~/glxp/debug/debugController'
import PBRConfigs from '~/glxp/debug/pbrConfigs'
import { clamp, lerp, map } from '~/glxp/utils/math'
import easings from "~/glxp/utils/easing"
import CatmullCurve from '~/glxp/utils/catmull'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { RenderTarget } from '~/glxp/ogl/core/RenderTarget.js'
import { Raycast } from '~/glxp/ogl/extras/Raycast'

// Managers
import CameraManager from '~/glxp/managers/CameraManager'
import DebugManager from '~/glxp/managers/DebugManager'
import HotspotManager from '~/glxp/managers/HotspotManager'

// Constants
import { FLEET_UNITY_CAMERA_POSITION_POINTS } from '~~/glxp/camera/paths'
import { HOTSPOTS_FLEET_UNITY } from '~/glxp/data/dataHotspots'

// import gsap, {Power1} from 'gsap'

// TODO: make it slower, add momentum, tilt camera so gradient is visible always
class SceneSubSpacecraftFleetUnity extends SubsceneAbs {
  constructor(
    container,
    manager = null,
    textureLoader,
    shouldFirstDraw = false
  ) {
    super(container, manager, textureLoader, shouldFirstDraw)

    this.name = 'Spacecraft Fleet - Unity'

    this.manager = manager
    this.renderer = manager.renderer
    this.textureLoader = textureLoader

    this.time = 0
    this.dt = 0
    this.drawcalls = 0
    this.progress = 0
    this.progressDelta = 0
    this.progressTarget = 0.00001
    this.timescale = 1
    this.forceAllDraw = true
    this.wheelProgress = 0
    this.lastWheel = 0

    this.clearColor = this.manager.clearColor
    this.loaded = false
    this.active = false

    // Meshes & Entities
    this.root = new Transform()
    this.root.scale.set(1, 1, 1)
    this.meshes = []
    this.entities = {
      vssUnity: null
    }

    this.hasProgressedPastThreshold = false

    // Debug
    this.debugManager = new DebugManager(this)

    // Config
    this.config = {
      InitialCameraRotation: { value: { x: -.19, y: .33, z: -.12 }, params: {} },
      InitialUnityPosition: { value: { x: 0, y: 0, z: this.mobile ? .79 : .48 }, params: {} },

      InitialIBLSpecularFactor: { value: PBRConfigs.CONFIG_SPACECRAFT_FLEET_UNITY.IBLSpecularFactor.value, params: { min: 0, max: 4, step: 0.01 } },
      InitialLightPower: { value: 1, params: { min: 0, max: 20, step: 0.01 } },
      IBLSpecularFactorAttenuation: { value: 2, params: { min: 0, max: 3, step: 0.01 } },
      LightPowerAttenuation: { value: 2, params: { min: 0, max: 10, step: 0.01 } },
      IBLSpecularFactorMinimum: { value: .6, params: { min: 0, max: 1, step: 0.01 } },
      LightPowerAttenuationMinimum: { value: .5, params: { min: 0, max: 1, step: 0.01 } },

      ScrollSpeed: { value: 5, params: { min: 0, max: 60 } },
      SunPosition: { value: { x: -1000, y: -515, z: -2300 }, params: {} },
      InitialFov: { value: this.mobile ? 40 : 27, params: { min: 1, max: 50 } },
      FovOffset: { value: this.mobile ? 14 : 0, params: { min: 1, max: 50 } },

      TransitionThreshold: { value: 0.0002, params: { min: 0, max: .001 } },
      HotspotOffset1: { value: { x: 0, y: 0, z: 0 }, params: { min: 0, max: .001 } },
      HotspotOffset2: { value: { x: 0, y: 0, z: 0 }, params: { min: 0, max: .001 } },
      HotspotOffset3: { value: { x: 0, y: 0, z: 0 }, params: { min: 0, max: .001 } },
    }

    this.PBRConfigs = PBRConfigs['CONFIG_0']

    // Inits
    this.initCameras()
    this.initCameraTracks()
    this.initBackground()
    this.initRT()
    this.initHotspots()
    this.initSun()
    this.initFlares()
  }

  // Inits
  initRT() {
    this.rt = new RenderTarget(this.gl)
  }

  initCameras() {
    this.cameraManager = new CameraManager(this.gl, {
      width: this.width,
      height: this.height,
      scene: this,
      debug: false,
      hasMousePan: false,
      mousePanOffsets: [0.01, -0.01]
    })

    this.camera = this.cameraManager.camera
  }

  initCameraTracks() {
    const positionTrack = new CatmullCurve(
      this.cameraManager.parseCameraPoints(
        FLEET_UNITY_CAMERA_POSITION_POINTS,
        this.mobile ? 1.2 : 1,
        false
      )
    )
    positionTrack.generate()
    this.cameraManager.addSpline(positionTrack, 'camera')
  }

  initBackground() {
    const background = new BackgroundBezierEntity(this, "backgroundSpacecraftFleetUnity")
    this.meshes.push(background)
    background.mesh.setParent(this.root)
  }

  initHotspots() {
    this.hotspotManager = new HotspotManager(this.gl, {
      scene: this,
      debug: false,
      hotspots: HOTSPOTS_FLEET_UNITY,
      debugRadius: .04
    })
  }

  initSun() {
    this.sun = new Transform()
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    this.sun.visible = false

    this.sun.setParent(this.root)
  }

  initFlares() {
    this.raycaster = new Raycast(this.gl)

    this.occluder = new Occluder(this, { scaleFactor: .7, position: { x: 0.2, y: .16, z: .52 }, visible: false })
    this.occluder.mesh.setParent(this.root)
    this.meshes.push(this.occluder)

    this.lensFlares = new LensFlares(this, {
      lightSource: this.sun,
      getFlares: (spacing) => getSpacecraftFleetUnityFlares(this, spacing),
      spacing: 0.08,
      raycaster: this.raycaster,
      occluder: this.occluder,
      fadePower: 10
    })
    this.meshes.push(this.lensFlares)
  }

  setProgress(p, shouldForce = false) {
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  // Lifecycle
  // Check subscene abstract for lifecycle events. They should start with super().
  fetchLoadableTextures() {
    let loadables = []

    this.post = new LensFlarePostEntity(this, this.postOptions)
    this.post.config.Exposure.value = .22
    this.post.config.Contrast.value = .1
    this.post.config.NoiseOpacity.value = .02
    this.post.config.Vignette.value = .0
    this.post.config.VignetteStrength.value = .1
    this.post.config.DirtDistanceFactor.value = 1.1

    const addTextureToLoadables = (groupKey) => {
      for (const key in Manifest[groupKey]) {
        if (Manifest[groupKey].hasOwnProperty(key)) {
          const element = Manifest[groupKey][key].url
          loadables.push({
            groupKey,
            element,
            key,
            options: Manifest[groupKey][key].options,
          })
        }
      }
    }

    addTextureToLoadables('main')
    addTextureToLoadables('defaultLensFlares')
    addTextureToLoadables('JJAbramsLensFlares')
    addTextureToLoadables('simonDhaenensLensFlares')
    addTextureToLoadables('unityDecalPBR')
    addTextureToLoadables('spacecraftFleetImagine')

    return loadables
  }

  loadModels() {
    const loadables = []
    const meshesExcludeList = ['SpaceShip_Unity_LandingGear']

    this.PBRConfig = PBRConfigs.CONFIG_SPACECRAFT_FLEET_UNITY

    // Spaceship
    this.entities.vssUnity = new MeshDecalVSSUnity(this, { meshesExcludeList, PBRConfig: this.PBRConfig })
    loadables.push(this.entities.vssUnity.load().then(() => {
      this.entities.vssUnity.setFeatherTimeline()
      this.meshes.push(...this.entities.vssUnity.meshes)
      this.entities.vssUnity.rootParent.setParent(this.root)
    }))

    return loadables
  }

  setHotspotFrom(hotspot, unity) {
    switch (hotspot.name) {
      case "unity-exterior-hotspot-1":
        // Pilots
        if(!unity.hotspots[0]) return
        hotspot.worldPosition.x = unity.hotspots[0].mesh.worldPosition.x + this.config.HotspotOffset1.value.x
        hotspot.worldPosition.y = unity.hotspots[0].mesh.worldPosition.y + this.config.HotspotOffset1.value.y
        hotspot.worldPosition.z = unity.hotspots[0].mesh.worldPosition.z + this.config.HotspotOffset1.value.z
        break;

      case "unity-exterior-hotspot-3":
        // Outside
        if(!unity.hotspots[1]) return
        hotspot.worldPosition.x = unity.hotspots[1].mesh.worldPosition.x + this.config.HotspotOffset2.value.x
        hotspot.worldPosition.y = unity.hotspots[1].mesh.worldPosition.y + this.config.HotspotOffset2.value.y
        hotspot.worldPosition.z = unity.hotspots[1].mesh.worldPosition.z + this.config.HotspotOffset2.value.z
        break;

      case "unity-exterior-hotspot-2":
        // Windows
        if(!unity.hotspots[2]) return
        hotspot.worldPosition.x = unity.hotspots[2].mesh.worldPosition.x + this.config.HotspotOffset3.value.x
        hotspot.worldPosition.y = unity.hotspots[2].mesh.worldPosition.y + this.config.HotspotOffset3.value.y
        hotspot.worldPosition.z = unity.hotspots[2].mesh.worldPosition.z + this.config.HotspotOffset3.value.z
        break;

      default:
        break;
    }
  }

  // Utils
  applyDefaultState() {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  setShipTransforms() {
    this.ship.position.set(0, -1, 0)
    this.ship.rotation.set(Math.PI * 0.4, 0, 0)
    this.ship.scale.set(0.02, 0.02, 0.02)
  }

  // Events
  onLoaded() {
    // this.active = true
    this._emitter.emit('loaded')

    // First Draw
    if (this.shouldFirstDraw) {
      const firstDrawPromises = this.getFirstDrawPromises()

      this.firstDrawAllPromise = Promise.all(firstDrawPromises).then(() => {
        this.didFirstDraw = true
        // GlobalEmitter.emit('webgl_loaded')
        // console.log(`Subscene ${this.name} finished first draw`)
      })
    }

    // Meshes
    for (const mesh of this.meshes) {
      mesh.onLoaded()
    }

    // Post
    if (this.hasBloom) {
      this.bloomPass = new Bloom(this, this.post)
      this.bloomPass.config.Threshold.value = .87
      this.bloomPass.config.Blur.value = 1
      this.bloomPass.config.Overdrive.value = 1
      this.bloomPass.config.Similarity.value = .13
    }
    this.post.onLoaded()

    // Debug
    this.gui = DebugController.addBlade(
      this.config,
      `Global Settings - ${this.name || 'Unknown'}`
    )
    DebugController.addBlade(this.cameraManager.config, `Camera - ${this.name}`, 0)

    DebugController.onLoaded()
    this.debugManager.onLoaded()

    // For recording purposes
    // gsap.to(this, {
    // progress: .3,
    // ease: Power1.easeInOut,
    // duration: 3,
    // delay: 2
    // })
    // 
    // gsap.to(this, {
    // progress: 1,
    // ease: Power1.easeInOut,
    // duration: 6,
    // delay: 5.5
    // })
  }

  onFirstActivate() {
    this.hasAlreadyActivated = true

    // TODO: find out why one of these breaks on onLoaded
    if (DebugController.active) {
      this.entities.vssUnity.initGui()
      this.occluder.initGui()
    }
  }

  postFirstDraw() { }

  resize() {
    this.cameraManager.resize(this.width, this.height)
    this.rt.setSize(this.width, this.height)
    this.hotspotManager.resize(this.width, this.height)

    if(!this.active){
      this.active = true

      requestAnimationFrame(() => {
        this.active = false
      })
    }

    if(this.active){
      this.hasProgressedPastThreshold = false
    }

    this.render(true)
  }

  // Rendering
  preRender() {
    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].preRender()
    }

    this.cameraManager.preRender()
  }

  render(force = false) {
    if(force){
      this.renderThings()
      return

    }
    // First Draw
    if (this.shouldFirstDraw && !this.didFirstDraw) {
      this.drawNextFDMesh()
      return
    }

    // For subscene transition to eve and back
    if (this.progress > this.config.TransitionThreshold.value) this.hasProgressedPastThreshold = true
    if (this.hasProgressedPastThreshold && this.progressTarget < this.config.TransitionThreshold.value && this.progress < this.config.TransitionThreshold.value) {
      return
    }

    if (!this.active) {
      return
    }

    this.renderThings()
  }

  renderThings() {
    // Timings
    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    // Wheel progress
    if (this.hasWheel) {
      this.wheelProgress += Mouse.normalizeWheel - this.lastWheel
      this.wheelProgress = clamp(this.wheelProgress, 0, 0.19)
      this.progressTarget = (this.wheelProgress * this.config.ScrollSpeed.value)
      this.lastWheel = Mouse.normalizeWheel
    }

    this.progress = lerp(this.progress, this.progressTarget, 0.075)
    this.progress = clamp(this.progress, 0, 1)

    /**
     * FIRST STAGE
     */
    const firstStageProgress = clamp(map(this.progress, 0., .5, 0, 1), 0, 1)
    // Offset Unity Position
    if (this.entities.vssUnity) {
      this.entities.vssUnity.config.positionOffset.value.z = this.config.InitialUnityPosition.value.z + firstStageProgress * -this.config.InitialUnityPosition.value.z
      this.entities.vssUnity.root.rotation.x = -easings.easeInOutCubic(firstStageProgress) * Math.PI * .15
      this.entities.vssUnity.root.position.set(this.entities.vssUnity.animations.root.position.x + this.entities.vssUnity.config.positionOffset.value.x, this.entities.vssUnity.animations.root.position.y + this.entities.vssUnity.config.positionOffset.value.y, this.entities.vssUnity.animations.root.position.z + this.entities.vssUnity.config.positionOffset.value.z)
    }

    // Camera animation
    this.cameraManager.config.rotationOffset.value.x = this.config.InitialCameraRotation.value.x + easings.easeOutCubic(firstStageProgress) * -this.config.InitialCameraRotation.value.x
    this.cameraManager.config.rotationOffset.value.y = this.config.InitialCameraRotation.value.y + easings.easeOutCubic(firstStageProgress) * -this.config.InitialCameraRotation.value.y
    this.cameraManager.config.rotationOffset.value.z = this.config.InitialCameraRotation.value.z + easings.easeOutCubic(firstStageProgress) * -this.config.InitialCameraRotation.value.z

    /**
     * SECOND STAGE
     */
    const secondStageProgress = clamp(map(this.progress, 0.25, 1, 0, 1), 0, 1)

    // Feather animation
    if (this.entities.vssUnity) {
      this.entities.vssUnity.rootParent.rotation.x = -easings.easeInOutQuad(secondStageProgress) * Math.PI * 0.97
      const featherProgress = clamp(map(this.progress, 0.05, .75, 0, 1), 0, 1)
      this.entities.vssUnity.updateFeather(featherProgress)
    }

    // Animate hotspots
    for (const hotspot of this.hotspotManager.hotspots) {
      this.setHotspotFrom(hotspot, this.entities.vssUnity)
      hotspot.visibility = this.progress > 0 ? 1 : 0
    }

    // Avoid blowing out the highlights when ship flips
    let attenuated
    attenuated = this.config.InitialIBLSpecularFactor.value - this.progress * this.config.IBLSpecularFactorAttenuation.value
    this.PBRConfig.IBLSpecularFactor.value = Math.max(attenuated, this.config.IBLSpecularFactorMinimum.value)

    attenuated = this.config.InitialLightPower.value - (this.progress * this.config.InitialLightPower.value * this.config.LightPowerAttenuation.value)
    this.PBRConfig.lightPower.value = Math.max(attenuated, this.config.LightPowerAttenuationMinimum.value)

    // Tilt sun to make it move less on camera movement
    this.config.SunPosition.value.x = -1000 - this.progress * 1000
    this.sun && this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)

    // Widen field of view on mobile
    const fovProgress = clamp(map(this.progress, 0, .2, 0, 1), 0, 1)
    this.camera.fov = this.config.InitialFov.value + fovProgress * this.config.FovOffset.value

    this.cameraManager.setProgress(this.progress)

    // Pre render
    this.preRender()

    // Aspect
    this.gl.viewport(0, 0, this.width, this.height)
    this.camera.perspective({ aspect: this.width / this.height })
    this.renderer.setViewport(this.width, this.height)

    // Render Time
    this.renderer.render({
      scene: this.root,
      camera: this.camera,
      clear: true,
      frustumCull: true,
      sort: true,
      post: this.post,
    })

    // Post
    this.bloomPass && this.bloomPass.render()
    // Last link of post processing pipeline should render in this.rt, which is fetched from main scene wrapper
    this.post.render({ target: this.rt })

    this.postRender()
  }

  postRender() {
    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }

    this.hotspotManager.update()
  }
}

export default SceneSubSpacecraftFleetUnity
