import { Texture } from '~/glxp/ogl/core/Texture.js'
import { BasisManager } from '~/glxp/ogl/extras/BasisManager'
import DebugController from '~/glxp/debug/debugController'

class TextureLoaderManager {
  constructor(gl) {
    this.gl = gl
    this.textures = {}
    this.currentUnit = 0

    this.isCompressedSupport = false
    const EXT = {
      astcSupported: !!gl.renderer.getExtension(
        'WEBGL_compressed_texture_astc'
      ),
      etc1Supported: !!gl.renderer.getExtension(
        'WEBGL_compressed_texture_etc1'
      ),
      etc2Supported: !!gl.renderer.getExtension('WEBGL_compressed_texture_etc'),
      dxtSupported: !!gl.renderer.getExtension('WEBGL_compressed_texture_s3tc'),
      bptcSupported: !!gl.renderer.getExtension('EXT_texture_compression_bptc'),
      pvrtcSupported:
        !!gl.renderer.getExtension('WEBGL_compressed_texture_pvrtc') ||
        !!gl.renderer.getExtension('WEBKIT_WEBGL_compressed_texture_pvrtc'),
    }

    for (const [key, extension] of Object.entries(EXT)) {
      if (extension) this.isCompressedSupport = true
    }

    this.basisManager = new BasisManager(`/vendors/basis/BasisWorker.js`)
  }

  load(url, id, options) {
    let isCompressed = options.indexOf('compressed') > -1
    let needTransparence = options.indexOf('transparent') > -1

    let lodsTarget = options.filter((element) => {
      if (element.indexOf('lodTarget') !== -1) {
        return true
      }
    })[0]
    lodsTarget =
      lodsTarget !== undefined ? lodsTarget.replace('lodTarget=', '') : null
    let isLods = lodsTarget !== null

    let lodsPath = ''
    let imageFileName = url.replace(/^.*[\\\/]/, '')
    let pathRoot = url.replace(imageFileName, '')
    let imageUrl = url

    if (isLods) {
      imageUrl = `${pathRoot}${lodsTarget}/${imageFileName}${
        needTransparence ? '.png' : '.jpg'
      }`
    }

    if (isCompressed && this.isCompressedSupport) {
      if (isLods) {
        imageUrl = `${pathRoot}${lodsTarget}/${imageFileName}.ktx2`
      } else {
        imageUrl = `${pathRoot}${imageFileName.replace(/\.[^/.]+$/, '')}.ktx2`
      }

      this.textures[id] = {
        options,
      }

      const promise = new Promise((resolve) => {
        fetch(imageUrl).then((test) => {
          test.arrayBuffer().then((buffer) => {
            this.basisManager.parseTexture(buffer).then((text) => {
              const texture = this.initCompressedTexture(text, options)

              this.textures[id].texture = texture
              this.textures[id].unit = this.currentUnit
              texture.update()
              this.currentUnit++
              resolve(texture)
            })
          })
        })
      })

      return promise
    } else {
      let image = new Image()
      image.crossOrigin = 'anonymous'
      const promise = new Promise((resolve) => {
        image.onload = () => {
          let texture = this.initTexture(image, options)
          this.textures[id].texture = texture
          this.textures[id].unit = this.currentUnit
          this.currentUnit++
          resolve(texture)
        }
      })
      image.src = imageUrl
      this.textures[id] = {
        img: image,
        options,
      }
      return promise
    }
  }

  initCompressedTexture(text, options) {
    const gl = this.gl
    const texture = new Texture(gl, {
      generateMipmaps: options.indexOf('mipmap') > -1,
      internalFormat: text.internalFormat,
    })
    texture.image = text

    texture.flipY = options.indexOf('flipY') > -1
    texture.wrapS =
      options.indexOf('mirror') > -1 ? gl.MIRRORED_REPEAT : gl.CLAMP_TO_EDGE
    texture.wrapT =
      options.indexOf('mirror') > -1 ? gl.MIRRORED_REPEAT : gl.CLAMP_TO_EDGE
    texture.wrapS = options.indexOf('repeat') > -1 ? gl.REPEAT : texture.wrapS
    texture.wrapT = options.indexOf('repeat') > -1 ? gl.REPEAT : texture.wrapT
    texture.wrapS =
      options.indexOf('clamp') > -1 ? gl.CLAMP_TO_EDGE : texture.wrapS
    texture.wrapT =
      options.indexOf('clamp') > -1 ? gl.CLAMP_TO_EDGE : texture.wrapT
    texture.minFilter =
      options.indexOf('nearest') > -1 ? gl.NEAREST : texture.minFilter
    texture.magFilter =
      options.indexOf('nearest') > -1 ? gl.NEAREST : texture.magFilter
    texture.needsUpdate = true

    return texture
  }

  initTexture(image, options) {
    const gl = this.gl
    const texture = new Texture(gl, {
      generateMipmaps: options.indexOf('mipmap') > -1,
      flipY: options.indexOf('flipY') > -1,
    })
    texture.image = image
    texture.flipY = options.indexOf('flipY') > -1
    texture.wrapS =
      options.indexOf('mirror') > -1 ? gl.MIRRORED_REPEAT : gl.CLAMP_TO_EDGE
    texture.wrapT =
      options.indexOf('mirror') > -1 ? gl.MIRRORED_REPEAT : gl.CLAMP_TO_EDGE
    texture.wrapS = options.indexOf('repeat') > -1 ? gl.REPEAT : texture.wrapS
    texture.wrapT = options.indexOf('repeat') > -1 ? gl.REPEAT : texture.wrapT
    texture.wrapS =
      options.indexOf('clamp') > -1 ? gl.CLAMP_TO_EDGE : texture.wrapS
    texture.wrapT =
      options.indexOf('clamp') > -1 ? gl.CLAMP_TO_EDGE : texture.wrapT
    texture.minFilter =
      options.indexOf('nearest') > -1 ? gl.NEAREST : texture.minFilter
    texture.magFilter =
      options.indexOf('nearest') > -1 ? gl.NEAREST : texture.magFilter

    return texture
  }

  get(id) {
    if (this.textures[id]) {
      return this.textures[id]
    }
  }

  getTexture(id) {
    if (DebugController.queryDebug('notextures')) {
      return this.textures['black'].texture
    }

    if (this.textures[id]) {
      return this.textures[id].texture
    }
  }

  getImage(id) {
    if (this.textures[id]) {
      return this.textures[id].img
    }
    return null
  }

  isTextureRegistered(id) {
    if (this.textures[id]) {
      return true
    }
    return false
  }
}

export default TextureLoaderManager
