import Shader from '~/glxp/utils/shader'
import DebugController from '~/glxp/debug/debugController'
import ShaderManifest from '~/glxp/shaderManifest'
import GLTFUtils from '~/glxp/utils/GLTFUtils'

import { Program } from '~/glxp/ogl/core/Program.js'
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Color } from '~/glxp/ogl/math/Color'

import { vec3 } from 'gl-matrix'

import GUIMaterialManifest from '~/glxp/guiMaterialManifest'

class MeshEntity {
  constructor(
    scene,
    data,
    texture,
    {
      parent = null,
      id = 0,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      gltf = null,
      node = null,
      name = '',
      material = null,
      materialName = '',
      transparent = false,
      depthTest = true,
      depthWrite = true,
      renderOrder = 0,
      globalConfig = null,
      fog = true,
      alpha = 1,
      shaderId = "unlit",
      visible = true
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.gltf = gltf
    this.data = data
    this.node = node
    this.name = name
    this.parent = parent ? parent : scene.root
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.videoTexture = null
    this.video = null
    this.fog = fog
    this.renderOrder = renderOrder
    this.groupId = id
    this.geom = {}
    this.materialInfos = material
    this.materialName = materialName
    this.globalConfig = globalConfig
    this.alpha = alpha
    this.textureId = texture
    this.defines = {}
    this.seed = Math.random()
    this.transform = new Transform()
    this.shaderId = shaderId
    this.visible = visible

    if (node) {
      if (node.rotation) {
        this.transform.quaternion.x = node.rotation[0]
        this.transform.quaternion.y = node.rotation[1]
        this.transform.quaternion.z = node.rotation[2]
        this.transform.quaternion.w = node.rotation[3]
      }

      if (node.scale) {
        vec3.copy(this.transform.scale, node.scale)
      }

      if (node.translation) {
        vec3.copy(this.transform.position, node.translation)
      }
    }

    this.geom = this.gltf
      ? GLTFUtils.getGeometryDataFromGltf(this.data, this.defines, this.gltf)
      : this.data

    this.guiName = `${this.scene.name || '?'} - ${this.materialName}`

    this.initConfig()
    this.init()
    this.cleanConfig()
  }

  initConfig() {
    this.config = GUIMaterialManifest.createUnlitMaterial(this.guiName)

    if (DebugController.guiIsReady) {
      this.initGUI()
    } else {
      DebugController.on('gui-lazyloaded', this.initGUI.bind(this))
    }
  }

  initGUI() {
    GUIMaterialManifest.addUnlitMaterialToGUI(this.guiName, this.groupId)
    this.gui = GUIMaterialManifest.getGui(this.guiName)

    // TODO: Refactor texture update to avoid duplicate for each Mesh
    // DebugController.addTextureUpload(this, { maps: ['texture'] })

    this.cleanConfig()
  }

  cleanConfig() {
    if (!this.gui || this.gui === {}) return

    if (this.defines['HAS_SHEEN'] == undefined) {
      this.gui.params['SheenColor'].hidden = true
      this.gui.params['SheenOpacity'].hidden = true
      this.gui.params['SheenDepth'].hidden = true
    }
  }

  initVideoTexture(url) {
    this.videoTexture = new Texture(this.gl, {
      generateMipmaps: false,
      width: 1024,
      height: 512,
    })
    let video = document.createElement('video')
    video.src = url
    video.loop = true
    video.muted = true
    this.video = video
  }

  init() {
    const attribs = {
      position: { size: 3, data: this.geom.vertices },
      uv: { size: 2, data: this.geom.uvs },
      normal: { size: 3, data: this.geom.normals },
      index: { data: this.geom.indices },
    }
    this.geometry = new Geometry(this.gl, attribs)

    let uniforms = {
      uTime: { value: this.scene.time },
      uAlpha: { value: this.alpha },
      uSeed: { value: this.seed },
      uTint: { value: new Color(this.config.Tint.value) },
      uCamera: { value: this.scene.camera.position },
    }

    // Color Correction
    this.defines['HAS_COLOR_CORRECTION'] = 1
    uniforms = Object.assign(uniforms, {
      uTint: { value: new Color(this.config.Tint.value) },
      uTintOpacity: this.config.TintOpacity,
      uExposure: this.config.Exposure,
      uContrast: this.config.Contrast,
      uSaturation: this.config.Saturation,
    })

    if (this.scene.textureLoader.isTextureRegistered(`${this.textureId}`)) {
      this.defines['HAS_COLORMAP'] = 1
      this.texture = new Texture(this.gl)

      if (this.materialInfos && this.materialInfos['textureScale'] !== undefined) {
        this.config.TextureScale.value = this.materialInfos.textureScale
      }

      uniforms = Object.assign(uniforms, {
        uTexture: { value: this.texture },
        uTextureScale: this.config.TextureScale,
      })
    } else {
      uniforms = Object.assign(uniforms, {
        uColor: { value: new Color(this.materialInfos ? this.materialInfos.color : "#ffffff") },
      })
    }
    if (
      this.scene.textureLoader.isTextureRegistered(`${this.textureId}_Opacity`)
    ) {
      this.defines['HAS_ALPHAMAP'] = 1
      this.alphaTexture = new Texture(this.gl)
      this.transparent = true
      this.renderOrder = 1

      if (this.materialInfos) {
        if (this.materialInfos['renderOrder'] !== undefined) {
          this.renderOrder = this.materialInfos.renderOrder
        }

        if (this.materialInfos['depthTest'] !== undefined) {
          this.depthTest = this.materialInfos.depthTest
        }

        if (this.materialInfos['depthWrite'] !== undefined) {
          this.depthWrite = this.materialInfos.depthWrite
        }
      }

      uniforms = Object.assign(uniforms, {
        uAlphaTexture: { value: this.alphaTexture },
      })
    }

    if (this.materialInfos && this.materialInfos['sheen'] !== undefined) {
      this.defines['HAS_SHEEN'] = 1
      uniforms = Object.assign(uniforms, {
        uSheenOpacity: this.config.SheenOpacity,
        uSheenDepth: this.config.SheenDepth,
        uSheenColor: { value: new Color(this.config.SheenColor.value) },
      })
    }

    if (this.globalConfig && this.globalConfig.FogColor && this.fog) {
      this.defines['HAS_FOG'] = 1
      uniforms = Object.assign(uniforms, {
        uFogColor: { value: new Color(this.globalConfig.FogColor.value) },
        // uFogDensity: { value: this.globalConfig.FogDensity.value },
        uFogNear: { value: this.globalConfig.FogNear.value },
        uFogFar: { value: this.globalConfig.FogFar.value },
      })
    }

    this.shader = new Shader(ShaderManifest[this.shaderId], 1, this.defines)

    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      uniforms,
    })

    if (this.materialInfos && this.materialInfos.cullFace !== undefined) {
      if (this.materialInfos.cullFace == false) {
        this.program.cullFace = false
      } else {
        this.program.cullFace = this.gl[this.materialInfos.cullFace]
      }
    }

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
      transform: this.transform,
    })
    this.mesh.name = this.name
    this.mesh.visible = this.visible
    this.mesh.setParent(this.parent)
    // this.mesh.updateMatrixWorld(true)
    // this.parent.updateMatrixWorld(true)
  }

  onLoaded() {
    if (this.texture) {
      this.texture = this.scene.textureLoader.getTexture(this.textureId)
      this.texture.needsUpdate = true
      this.program.uniforms['uTexture'].value = this.texture
    }

    if (this.alphaTexture) {
      this.alphaTexture = this.scene.textureLoader.getTexture(
        `${this.textureId}_Opacity`
      )
      this.alphaTexture.needsUpdate = true
      this.program.uniforms['uAlphaTexture'].value = this.alphaTexture
    }
  }

  // Render
  getFirstDrawPromise() {
    this.didFirstDraw = false
    return new Promise((resolve) => {
      this.firstDrawResolve = resolve
    })
  }

  firstDraw() {
    if (this.didFirstDraw) return

    // this.gl.finish()

    const storedVisibility = this.mesh.visible
    this.mesh.visible = true

    this.scene.renderer.render({
      scene: this.mesh,
      camera: this.scene.camera,
      clear: false,
      frustumCull: true,
      sort: false,
    })

    this.mesh.visible = storedVisibility

    // this.gl.finish()

    if (!this.didFirstDraw) {
      this.didFirstDraw = true
      requestAnimationFrame(() => {
        this.firstDrawResolve(this)
      })
    }
  }

  // TODO: remove new Color() on each frame by using this.gui.on()
  preRender() {
    this.program.uniforms['uTime'].value = this.scene.time
    this.program.uniforms['uAlpha'].value = this.alpha
    this.program.uniforms['uTint'].value = new Color(this.config.Tint.value)

    if (this.defines['HAS_FOG'] !== undefined) {
      // this.program.uniforms["uFogDensity"].value = this.globalConfig.FogDensity.value
      this.program.uniforms['uFogColor'].value = new Color(
        this.globalConfig.FogColor.value
      )
      this.program.uniforms['uFogNear'].value = this.globalConfig.FogNear.value
      this.program.uniforms['uFogFar'].value = this.globalConfig.FogFar.value
    }
    this.program.uniforms['uCamera'].value = this.scene.camera.position

    if (
      this.video &&
      this.video.currentTime > 0 &&
      this.video.readyState >= this.video.HAVE_ENOUGH_DATA
    ) {
      if (!this.videoTexture.image) this.videoTexture.image = this.video
      this.videoTexture.needsUpdate = true
      this.program.uniforms['uTexture'].value = this.videoTexture
    }

    if (this.defines['HAS_SHEEN'] !== undefined) {
      this.program.uniforms['uSheenColor'].value = new Color(
        this.config.SheenColor.value
      )
    }
  }
}

export default MeshEntity
