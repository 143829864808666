import DebugController from '~/glxp/debug/debugController'

const CONFIGS = {
  // Example
  CONFIG_0: {
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_clouds',
      params: { options: { nebula: 'env_clouds' } },
    },
    EnvironmentDiffuse: {
      value: 'env_clouds',
      params: { options: { nebula: 'env_clouds' } },
    },
    EnvRotationOffset: { value: 0.75, params: { min: 0, max: 1, step: 0.001 } },
    lightColor: { value: '#ffffff', params: {} },
    lightPosition: {
      // value: { x: 0, y: -100, z: -10 },
      value: { x: 0, y: 1, z: 0 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#ffffff', params: {} },
    FogNear: { value: 15, params: { min: 0, max: 50, step: 0.01 } },
    FogFar: { value: 25, params: { min: 0, max: 50, step: 0.01 } },
    lightPower: { value: 5, params: { min: 0, max: 10, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },

  // Header
  CONFIG_HEADER: {
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_clouds',
      params: { options: { env_clouds: 'env_clouds' } },
    },
    EnvironmentDiffuse: {
      value: 'env_clouds_blur',
      params: { options: { env_clouds_blur: 'env_clouds_blur' } },
    },
    EnvRotationOffset: {
      value: 0.175,
      params: { min: 0, max: 1, step: 0.001 },
    },
    lightColor: { value: '#ffffff', params: {} },
    lightPosition: {
      value: { x: 0, y: -100, z: -10 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#ffffff', params: {} },
    FogNear: { value: 15, params: { min: 0, max: 50, step: 0.01 } },
    FogFar: { value: 25, params: { min: 0, max: 50, step: 0.01 } },
    lightPower: { value: 1, params: { min: 0, max: 10, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },

  // Spaceport
  CONFIG_SPACEPORT: {
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_spaceport',
      params: {
        options: {
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
        },
      },
    },
    EnvironmentDiffuse: {
      value: 'env_spaceport_blur',
      params: {
        options: {
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
        },
      },
    },
    EnvRotationOffset: { value: 0.75, params: { min: 0, max: 1, step: 0.001 } },
    lightColor: { value: '#ffffff', params: {} },
    lightPosition: {
      value: { x: 1, y: 50, z: -1000 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#4c4a82', params: {} },
    FogNear: { value: 0, params: { min: 0, max: 1000, step: 0.1 } },
    FogFar: { value: 450, params: { min: 0, max: 1500, step: 1 } },
    lightPower: { value: 1, params: { min: 0, max: 10, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },
  CONFIG_SPACEPORT_GLASS_SHIPS: {
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_spaceport_glass_ships',
      params: {
        options: {
          spaceportGlassShips: 'env_spaceport_glass_ships',
          spaceportGlassShipsBlur: 'env_spaceport_glass_ships_blur',
          spaceportGlassNoShips: 'env_spaceport_glass_noships',
          spaceportGlassNoShipsBlur: 'env_spaceport_glass_noships_blur',
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
        },
      },
    },
    EnvironmentDiffuse: {
      value: 'env_spaceport_glass_ships_blur',
      params: {
        options: {
          spaceportGlassShips: 'env_spaceport_glass_ships',
          spaceportGlassShipsBlur: 'env_spaceport_glass_ships_blur',
          spaceportGlassNoShips: 'env_spaceport_glass_noships',
          spaceportGlassNoShipsBlur: 'env_spaceport_glass_noships_blur',
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
        },
      },
    },
    EnvRotationOffset: { value: 0.75, params: { min: 0, max: 1, step: 0.001 } },
    lightColor: { value: '#ffffff', params: {} },
    lightPosition: {
      value: { x: 1, y: 50, z: -1000 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#4c4a82', params: {} },
    FogNear: { value: 0, params: { min: 0, max: 1000, step: 0.1 } },
    FogFar: { value: 450, params: { min: 0, max: 1500, step: 1 } },
    lightPower: { value: 1, params: { min: 0, max: 10, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },
  CONFIG_SPACEPORT_GLASS_NOSHIPS: {
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_spaceport_glass_noships',
      params: {
        options: {
          spaceportGlassShips: 'env_spaceport_glass_ships',
          spaceportGlassShipsBlur: 'env_spaceport_glass_ships_blur',
          spaceportGlassNoShips: 'env_spaceport_glass_noships',
          spaceportGlassNoShipsBlur: 'env_spaceport_glass_noships_blur',
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
        },
      },
    },
    EnvironmentDiffuse: {
      value: 'env_spaceport_glass_noships_blur',
      params: {
        options: {
          spaceportGlassShips: 'env_spaceport_glass_ships',
          spaceportGlassShipsBlur: 'env_spaceport_glass_ships_blur',
          spaceportGlassNoShips: 'env_spaceport_glass_noships',
          spaceportGlassNoShipsBlur: 'env_spaceport_glass_noships_blur',
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
        },
      },
    },
    EnvRotationOffset: { value: 0.75, params: { min: 0, max: 1, step: 0.001 } },
    lightColor: { value: '#ffffff', params: {} },
    lightPosition: {
      value: { x: 1, y: 50, z: -1000 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#4c4a82', params: {} },
    FogNear: { value: 0, params: { min: 0, max: 1000, step: 0.1 } },
    FogFar: { value: 450, params: { min: 0, max: 1500, step: 1 } },
    lightPower: { value: 1, params: { min: 0, max: 10, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },

  // Spacecraft Fleet
  CONFIG_SPACECRAFT_FLEET_EVE: {
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_spacecraft_fleet_eve',
      params: {
        options: {
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
          spacecraft_fleet_eve: 'env_spacecraft_fleet_eve',
          spacecraft_fleet_eve_blur: 'env_spacecraft_fleet_eve_blur'
        },
      },
    },
    EnvironmentDiffuse: {
      value: 'env_spacecraft_fleet_eve_blur',
      params: {
        options: {
          spaceport: 'env_spaceport',
          spaceport_blur: 'env_spaceport_blur',
          spacecraft_fleet_eve: 'env_spacecraft_fleet_eve',
          spacecraft_fleet_eve_blur: 'env_spacecraft_fleet_eve_blur'
        },
      },
    },
    EnvRotationOffset: { value: 0.75, params: { min: 0, max: 1, step: 0.001 } },
    lightColor: { value: '#ffffff', params: {} },
    lightPosition: {
      value: { x: 0, y: -4, z: 0 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#4c4a82', params: {} },
    FogNear: { value: 0, params: { min: 0, max: 1000, step: 0.1 } },
    FogFar: { value: 450, params: { min: 0, max: 1500, step: 1 } },
    lightPower: { value: 1, params: { min: 0, max: 10, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },

  CONFIG_SPACECRAFT_FLEET_UNITY: {
    // IBLDiffuseFactor: { value: .015, params: { min: 0, max: 2, step: 0.001 } },
    // IBLSpecularFactor: { value: 2, params: { min: 0, max: 2, step: 0.01 } },
    IBLDiffuseFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    IBLSpecularFactor: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
    Environment: {
      value: 'env_clouds_bottom_blur_x1',
      params: { options: {                
        "env_spacecraft_fleet_unity": "env_spacecraft_fleet_unity",
        "env_spacecraft_fleet_unity2": "env_spacecraft_fleet_unity2",
        "env_spacecraft_fleet_unity3": "env_spacecraft_fleet_unity3",
        "env_spacecraft_fleet_unity4": "env_spacecraft_fleet_unity4",
        "env_clouds_blur": "env_clouds_blur",
        "env_clouds": "env_clouds",
        "env_clouds_flipped": "env_clouds_flipped",
        "env_clouds_bottom_blur_x1": "env_clouds_bottom_blur_x1",
        }
      },
    },
    EnvironmentDiffuse: {
      value: 'env_clouds_blur',
      params: { options: {                
        "env_spacecraft_fleet_unity": "env_spacecraft_fleet_unity",
        "env_spacecraft_fleet_unity2": "env_spacecraft_fleet_unity2",
        "env_spacecraft_fleet_unity3": "env_spacecraft_fleet_unity3",
        "env_spacecraft_fleet_unity4": "env_spacecraft_fleet_unity4",
        "env_clouds_blur": "env_clouds_blur",
        "env_clouds": "env_clouds",
        "env_clouds_flipped": "env_clouds_flipped",
        "env_clouds_bottom_blur_x1": "env_clouds_bottom_blur_x1",
        }
      },
    },
    EnvRotationOffset: {
      value: 0.175,
      params: { min: 0, max: 1, step: 0.001 },
    },
    lightColor: { value: '#5689cf', params: {} },
    lightPosition: {
      // value: { x: 0, y: -100, z: 0 },
      value: { x: -1206.83, y: -515.00, z: -2300.00 },
      params: { x: { step: 1 }, y: { step: 1 }, z: { step: 1 } },
    },
    lightPower: { value: 1, params: { min: 0, max: 10, step: 0.01 } },
    emissiveColor: { value: '#000000', params: {} },
    FogColor: { value: '#ffffff', params: {} },
    FogNear: { value: 15, params: { min: 0, max: 50, step: 0.01 } },
    FogFar: { value: 25, params: { min: 0, max: 50, step: 0.01 } },
    Alpha: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
    Debug: {
      value: 'disabled',
      params: {
        options: {
          disabled: 'disabled',
          baseColor: 'baseColor',
          metallic: 'metallic',
          roughness: 'roughness',
          specRef: 'specRef',
          geomOcc: 'geomOcc',
          mcrfctDist: 'mcrfctDist',
          spec: 'spec',
          mathDiff: 'mathDiff',
        },
      },
    },
    OverwriteEnvIBL: { value: false, type: 'bool' },
    EnvIBL: { value: 'placeholder', type: 'input-image', input: null },
  },
}

// DebugController.addBlade(CONFIGS.CONFIG_0, 'PBR_CONFIG', 0)
DebugController.addBlade(CONFIGS.CONFIG_HEADER, 'PBR_CONFIG_HEADER', 0)
DebugController.addBlade(CONFIGS.CONFIG_SPACEPORT, 'PBR_CONFIG_SPACEPORT', 0)
DebugController.addBlade(CONFIGS.CONFIG_SPACEPORT_GLASS_SHIPS, 'PBR_CONFIG_SPACEPORT_GLASS_SHIPS', 0)
DebugController.addBlade(CONFIGS.CONFIG_SPACEPORT_GLASS_NOSHIPS, 'PBR_CONFIG_SPACEPORT_GLASS_NOSHIPS', 0)
DebugController.addBlade(CONFIGS.CONFIG_SPACECRAFT_FLEET_EVE, 'PBR_CONFIG_SPACECRAFT_FLEET_EVE', 0)
DebugController.addBlade(CONFIGS.CONFIG_SPACECRAFT_FLEET_UNITY, 'PBR_CONFIG_SPACECRAFT_FLEET_UNITY', 0)

export default CONFIGS
