import { Drawable } from '~/glxp/abstract/Drawable';
import { DrawableMaterial } from '~/glxp/abstract/DrawableMaterial';
import { DrawableGeometry } from '~/glxp/abstract/DrawableGeometry';
import DebugController from '~/glxp/debug/debugController'
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'
import Shader from '../utils/shader';
import ShaderManifest from '../shaderManifest';
import { Color } from '../ogl/math/Color';

let overrideMaterialTemp = null

export default class Flare extends Drawable {
    texture

    step = 0
    baseScale = { x: 1, y: 1 }
    textureId = ""
    textureRatio = 1
    direction = 0
    directionOffset = 0
    name = "Flare"
    forceRenderOrder = true
    renderOrder = 100
    globalOpacity = 1
    lookAtCenter = true
    hasColorCorrection = true
    program = { transparent: true } // For OGL renderer
    shouldDraw = false

    config = {
        Opacity: { value: 1, params: { min: 0, max: 30, label: "Opacity" } },
        Scale: { value: { x: 1, y: 1 }, params: { min: 0, max: 2, label: "Scale" } },
        Offset: { value: { x: 0, y: 0 }, params: { x: { min: -10, max: 10 }, y: { min: -10, max: 10 }, label: "Offset" } },
    }

    /**
     * Lens flare element in native WebGL, through Drawable
     * @param {SceneAbs} scene 
     * @param {{ step, offset, scale, textureId, opacity, lookAtCenter, hasColorCorrection, rotationOffset, overrideMaterial }} options 
     * @param {number} options.step - Step of the flare (0 = center, 1 = first ring, 2 = second ring, etc.)
     * @param {{ x, y }} options.offset - Offset added to the step flare step
     * @param {{ x, y }} options.scale - Scale of the flare plane
     * @param {string} options.textureId - Texture ID of the flare to be fetched
     * @param {number} options.opacity - Opacity of the flare
     * @param {boolean} options.lookAtCenter - If true, the flare will look at the center of the screen
     * @param {boolean} options.hasColorCorrection - If true, the flare will have color correction defines, config and uniforms
     * @param {number} options.rotationOffset - Rotation offset of the flare
     * @param {DrawableMaterial} options.overrideMaterial - Override the default material
     */
    constructor(scene, {
        step = 1,
        offset = { x: 0, y: 0 },
        scale = { x: 1, y: 1 },
        textureId = "Hexa_00",
        opacity = 1,
        lookAtCenter = true,
        hasColorCorrection = true,
        rotationOffset = 0,
        overrideMaterial = null,
        tint = "#ffffff",
    }) {
        // Shader
        const defines = {}
        if (hasColorCorrection) { defines["HAS_COLOR_CORRECTION"] = 1 }
        const { vert, frag } = new Shader(ShaderManifest['flare'], 1, defines)

        new DrawableMaterial(scene.gl, {
          vertex: vert,
          fragment: frag,
          attributes: ['position', 'uv'], // Attributes need to be active within the program for location fetching to work!
          uniforms: ['uWorldMatrix', 'uDirection', 'uTexture', 'uOpacity', 'uTint', 'uTintOpacity', 'uExposure', 'uSaturation']
        })

        if(overrideMaterialTemp === null) {
          overrideMaterialTemp = new DrawableMaterial(scene.gl, {
              vertex: vert,
              fragment: frag,
              attributes: ['position', 'uv'], // Attributes need to be active within the program for location fetching to work!
              uniforms: ['uWorldMatrix', 'uDirection', 'uTexture', 'uOpacity', 'uTint', 'uTintOpacity', 'uExposure', 'uSaturation']
          })
        }

        // Commenting this to speed up init
        // let material
        // if (overrideMaterial === null) material = overrideMaterial
        // else material = new DrawableMaterial(scene.gl, {
        //     vertex: vert,
        //     fragment: frag,
        //     attributes: ['position', 'uv'], // Attributes need to be active within the program for location fetching to work!
        //     uniforms: ['uWorldMatrix', 'uDirection', 'uTexture', 'uOpacity', 'uTint', 'uTintOpacity', 'uExposure', 'uSaturation']
        // })

        // Plane
        const geometry = new DrawableGeometry(scene.gl, overrideMaterialTemp.locations, {
            position: {
                value: new Float32Array([
                    -1.0, -1.0, 0.0,
                    1.0, -1.0, 0.0,
                    1.0, 1.0, 0.0,
                    -1.0, 1.0, 0.0,
                ]),
                size: 3
            },
            uv: {
                value: new Float32Array([
                    0.0, 0.0,
                    1.0, 0.0,
                    1.0, 1.0,
                    0.0, 1.0,
                ]),
                size: 2
            },
            index: {
                value: new Uint16Array([
                    0, 1, 2, 0, 2, 3,
                ]),
            },
        })

        super(scene, scene.gl, {
            material: overrideMaterialTemp,
            geometry
        })

        this.step = step
        this.textureId = textureId
        this.lookAtCenter = lookAtCenter
        this.hasColorCorrection = hasColorCorrection
        this.directionOffset = rotationOffset

        // Color Correction
        this.config = Object.assign(this.config, {
            Tint: { value: tint, params: { label: "Tint" } },
            TintOpacity: { value: 1, params: { min: 0, max: 1, step: 0.01, label: "TintOpacity" } },
            Exposure: { value: 0, params: { min: -2, max: 3, step: 0.01, label: "Exposure" } },
            Saturation: { value: 1, params: { min: 0, max: 2, step: 0.01, label: "Saturation" } },
        })
        this.config.Offset.value = offset
        this.config.Scale.value.x = scale.x
        this.config.Scale.value.y = scale.y
        this.config.Opacity.value = opacity

        if (this.lookAtCenter) {
            // Add rotation offset if looking at center
            this.config["RotationOffset"] = { value: 0, params: { min: -Math.PI, max: Math.PI, label: "Rotation Offset" } }
        } else {
            // Add rotation config if not looking at center
            this.config["Rotation"] = { value: 0, params: { min: -Math.PI, max: Math.PI, label: "Rotation" } }
        }

        this.name = `${scene.name} - Flare - ${textureId}`
        this.texture = this.gl.createTexture();

        this.prefillTexture()
    }

    initGui = (parentFolder) => {
        this.gui = DebugController.addBlade(this.config, this.name, GUI_PANEL_CUSTOM, parentFolder, false)

        // const folder = parentFolder.addFolder({ title: this.name, expanded: false })
        // let inputs = {}
        // for (const key of Object.keys(this.config)) {
        //     inputs[key] = folder.addInput(this.config[key], "value", this.config[key].params)
        // }

        // if (this.lookAtCenter) {
        //     // Add rotation offset if looking at center
        //     this.config["RotationOffset"] = { rotationValue: 0, params: { min: -Math.PI, max: Math.PI, label: "Rotation Offset" } }
        //     folder.addInput(this.config.RotationOffset, "rotationValue", this.config.RotationOffset.params)
        //     folder.on("change", (e) => {
        //         if (e.presetKey !== "rotationValue") return
        //         this.directionOffset = e.value
        //     })
        // } else {
        //     // Add rotation config if not looking at center
        //     this.config["Rotation"] = { rotationValue: 0, params: { min: -Math.PI, max: Math.PI, label: "Rotation" } }
        //     folder.addInput(this.config.Rotation, "rotationValue", this.config.Rotation.params)
        //     folder.on("change", (e) => {
        //         if (e.presetKey !== "rotationValue") return
        //         this.direction = e.value
        //     })
        // }
    }

    setColorCorrectionUniforms = () => {
        const tint = new Color(this.config.Tint.value)
        this.gl.uniform3f(this.material.locations.uniforms.uTint, tint.r, tint.g, tint.b);

        this.gl.uniform1f(this.material.locations.uniforms.uTintOpacity, this.config.TintOpacity.value);
        this.gl.uniform1f(this.material.locations.uniforms.uExposure, this.config.Exposure.value);
        this.gl.uniform1f(this.material.locations.uniforms.uSaturation, this.config.Saturation.value);
    }

    // Fill texture with a 1x1 blue pixel.  
    prefillTexture = () => {
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, new Uint8Array([0, 0, 255, 255]));
    }

    load = () => {
        const image = this.scene.textureLoader.getTexture(this.textureId).image

        this.textureRatio = (image.width / image.height)

        const previousState = {
            premultiply: this.gl.getParameter(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL),
            flip: this.gl.getParameter(this.gl.UNPACK_FLIP_Y_WEBGL)
        }

        this.gl.activeTexture(this.gl.TEXTURE0);

        // Set the texture's target
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);

        // Premultiply alpha (enable texture transparency)
        this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);

        // Flip the image's y axis
        this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, 1);

        // Mipmaps
        this.gl.generateMipmap(this.gl.TEXTURE_2D);

        // Stretch/wrap options
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);

        // Bind image to texture 0
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, image);

        // Need to reset these to default values, otherwise will affect next texture
        this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, previousState.flip);
        this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, previousState.premultiply);
    }

    preDraw = () => {
        if (!this.shouldDraw) {
            this.shouldDraw = true
        }
    }

    onDraw = ({ camera }) => {
        this.scene.applyDefaultState()

        // Uniforms
        this.gl.uniform1f(this.material.locations.uniforms.uOpacity, this.globalOpacity * this.config.Opacity.value);

        if (this.lookAtCenter) this.gl.uniform1f(this.material.locations.uniforms.uDirection, this.direction + this.config["RotationOffset"].value)
        else this.gl.uniform1f(this.material.locations.uniforms.uDirection, this.config["Rotation"].value)

        if (this.hasColorCorrection) this.setColorCorrectionUniforms()

        // Texture
        this.gl.activeTexture(this.gl.TEXTURE0)
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture)
        this.gl.uniform1i(this.material.locations.uniforms.uTexture, 0);

        // Ratio scale
        this.scale.x = this.baseScale.x * 1 / (this.scene.width / this.scene.height) * this.config.Scale.value.x
        this.scale.y = this.baseScale.y * this.config.Scale.value.y

        // World Matrix
        this.updateMatrix()
        this.gl.uniformMatrix4fv(this.material.locations.uniforms.uWorldMatrix, false, this.matrix)

        // Transparent, so needs to disable depth testing
        this.gl.disable(this.gl.DEPTH_TEST)

        // Screen blending, preserves color
        this.gl.blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_COLOR)
        // Regular alpha blend
        // this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA)
    }

    postDraw = () => {
        // Default scene state
        this.scene.applyDefaultState()
    }
}