import { Transform } from '~/glxp/ogl/core/Transform'

export class Drawable extends Transform {
    gl
    mode                // WebGLRenderingContext["TRIANGLES"]
    material            // DrawableMaterial
    geometry            // DrawableGeometry
    name                // string
    shouldDraw = true

    /**
     * A barebones WebGL entity that also works with OGL's renderer and Scene Graph
     * @param {WebGLRenderingContext} gl 
     * @param {{ geometry, material, name }} options 
     */
    constructor(scene, gl, { material, geometry, name = "Drawable" }) {
        super()

        this.scene = scene
        this.gl = gl
        this.mode = gl.TRIANGLES
        this.material = material
        this.geometry = geometry

        this.name = name
    }

    // Exists to be overriden
    preDraw = ({ camera } = {}) => { }

    // Exists to be overriden
    onDraw = ({ camera } = {}) => { }

    // Exists to be overriden
    postDraw = ({ camera } = {}) => { }

    // TODO: if drawing this BEFORE an ogl mesh, update this.renderer.currentProgram and this.gl.renderer.currentGeometry
    draw = ({ camera } = {}) => {
        this.preDraw({ camera })

        if (!this.shouldDraw) return

        this.gl.useProgram(this.material.program);

        this.gl.bindVertexArray(this.geometry.vao)

        this.onDraw({ camera })

        if (this.geometry.attributes.index) {
            const vertexCount = this.geometry.attributes.index.value.length
            const type = this.gl.UNSIGNED_SHORT;
            const offset = 0;
            this.gl.drawElements(this.mode, vertexCount, type, offset);
        }

        else this.gl.drawArrays(this.mode, 0, 6);

        this.gl.bindVertexArray(null)

        this.postDraw({ camera })
    }
}