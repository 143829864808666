
import Shader from '~/glxp/utils/shader'
import DebugController from '~/glxp/debug/debugController'
import ShaderManifest from '~/glxp/shaderManifest'
import GLTFUtils from '~/glxp/utils/GLTFUtils';

import { Program } from '~/glxp/ogl/core/Program.js'
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Color } from '~/glxp/ogl/math/Color'

import { vec3 } from "gl-matrix"

// TODO: Check why first draw doesn't work in this one
// TODO: Remove unused code from UnlitMesh
class MeshEntity {
  constructor(scene, data, texture, {
    parent = null,
    id = 0,
    blendFunc = {
      src: scene.gl.SRC_ALPHA,
      dst: scene.gl.ONE_MINUS_SRC_ALPHA
    },
    gltf = null,
    node = null,
    name = '',
    materialName = '',
    transparent = false,
    depthTest = true,
    depthWrite = true,
    renderOrder = 0,
    globalConfig = null,
    fog = true,
    alpha = 1,
    forceRenderOrder = true
  } = {}) {
    this.gl               = scene.gl
    this.scene            = scene
    this.gltf             = gltf
    this.data             = data
    this.node             = node
    this.name             = name
    this.parent           = parent ? parent : scene.root
    this.transparent      = transparent
    this.blendFunc        = blendFunc
    this.depthTest        = depthTest
    this.depthWrite       = depthWrite
    this.videoTexture     = null
    this.video            = null
    this.fog              = fog
    this.renderOrder      = renderOrder
    this.groupId          = id
    this.geom             = {}
    this.materialInfos    = materialName
    this.globalConfig     = globalConfig
    this.alpha            = alpha
    this.textureId        = texture
    this.defines          = {}
    this.seed             = Math.random()
    this.transform        = new Transform()
    this.forceRenderOrder = forceRenderOrder

    if (node) {
      if (node.rotation) {
        this.transform.quaternion.x = node.rotation[0]
        this.transform.quaternion.y = node.rotation[1]
        this.transform.quaternion.z = node.rotation[2]
        this.transform.quaternion.w = node.rotation[3]
      }

      if (node.scale) {
        vec3.copy(this.transform.scale, node.scale)
      }

      if (node.translation) {
        vec3.copy(this.transform.position, node.translation)
      }
    }

    this.geom = this.gltf ? GLTFUtils.getGeometryDataFromGltf(this.data, this.defines, this.gltf) : this.data

    this.initConfig()
    this.init()
    this.cleanConfig()
  }

  initConfig() {
    this.config = {
      GroundResolution: {
        value: { x: 10, y: 7 },
        params: { x: { min: 0.1, max: 20, step: 0.1 }, y: { min: 0.1, max: 20, step: 0.1 } },
      },
      GroundSpeedFactor: { value: 0.0005, params: { min: -0.01, max: 0.01, step: 0.0001 } },

      CloudsResolution: {
        value: { x: 1.75, y: 2 },
        params: { x: { min: 0.1, max: 10, step: 0.1 }, y: { min: 0.1, max: 10, step: 0.1 } },
      },
      CloudsDensityMin: { value: 0.15, params: { min: 0.01, max: 1, step: 0.01 } },
      CloudsDensityMax: { value: 0.85, params: { min: 0.01, max: 1, step: 0.01 } },
      CloudsColorVariance: { value: 0.4, params: { min: 0.01, max: 1, step: 0.01 } },
      CloudsSpeedFactor: { value: 0.0005, params: { min: -0.01, max: 0.01, step: 0.0001 } },
      CloudsShadowOffset: {
        value: { x: 0, y: 0.004 },
        params: { x: { min: 0.001, max: 0.1, step: 0.001 }, y: { min: 0.001, max: 0.1, step: 0.001 } },
      },
      CloudsShadowIntensity: { value: 0.5, params: { min: 0.01, max: 1, step: 0.01 } },

      FresnelColor: { value: "#5e8cef", params: {} },
      FresnelRimColor: { value: "#a2daff", params: {} },
      FresnelIntensity: { value: .97, params: { min: 0, max: 1.4 }  }
    }

    if (DebugController.guiIsReady) {
      this.initGUI()
    } else {
      DebugController.on('gui-lazyloaded', this.initGUI.bind(this))
    }
  }

  initGUI() {
    this.guiName = `${this.scene.name} - ${this.name}`
    this.gui = DebugController.addBlade(this.config, this.guiName, this.groupId)
    if(this.gui){
      DebugController.addTextureUpload(this, {
        maps: ['texture']
      })
    }
    this.cleanConfig()
  }

  cleanConfig() {
    if (!this.gui || this.gui === {}) return
  }

  // initVideoTexture(url) {
  //   this.videoTexture = new Texture(this.gl, {
  //     generateMipmaps: false,
  //     width: 1024,
  //     height: 512,
  //   });
  //   let video = document.createElement('video')
  //   video.src = url
  //   video.loop = true
  //   video.muted = true
  //   this.video = video
  // }

  init() {
    const attribs = {
      position: { size: 3, data: this.geom.vertices },
      uv: { size: 2, data: this.geom.uvs },
      normal: { size: 3, data: this.geom.normals },
      index: { data: this.geom.indices }
    }
    this.geometry = new Geometry(this.gl, attribs)


    let uniforms = {
      uTime: { value: this.scene.time }, uAlpha: { value: this.alpha },
      uSeed: { value: this.seed },
      // uTint: { value: new Color(this.config.Tint.value) },
      uCamera: { value: this.scene.camera.position },
    }

    // ================== UNLIT MESH CODE START =========================
    if (this.scene.textureLoader.isTextureRegistered(`${this.textureId}`)) {
      this.defines['HAS_COLORMAP'] = 1
      this.texture = new Texture(this.gl)
      uniforms = Object.assign(uniforms, {
        uTexture: { value: this.texture },
      })
    }
    // ================== UNLIT MESH CODE END =========================

    // Config uniforms
    uniforms = Object.assign(uniforms, {
      uGroundRes: { value: [this.config.GroundResolution.value.x, this.config.GroundResolution.value.y]},
      uGroundSpeedFactor: this.config.GroundSpeedFactor,

      uCloudsRes: { value: [this.config.CloudsResolution.value.x, this.config.CloudsResolution.value.y]},
      uCloudsDensityMin: this.config.CloudsDensityMin,
      uCloudsDensityMax: this.config.CloudsDensityMax,
      uCloudsColorVariance: this.config.CloudsColorVariance,
      uCloudsSpeedFactor: this.config.CloudsSpeedFactor,
      uCloudsShadowIntensity: this.config.CloudsShadowIntensity,
      uCloudsShadowOffset: { value: [this.config.CloudsShadowOffset.value.x, this.config.CloudsShadowOffset.value.y]},

      uFresnelColor: { value: new Color(this.config.FresnelColor.value) },
      uFresnelRimColor: { value: new Color(this.config.FresnelRimColor.value) },
      uFresnelIntensity: this.config.FresnelIntensity
    })

    // Noise texture
    this.cloudsTexture = new Texture(this.gl)
    uniforms = Object.assign(uniforms, {
      uCloudsTexture: this.cloudsTexture,
    })

    this.shader = new Shader(ShaderManifest["homePlanet"], 1, this.defines)

    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      uniforms
    })

    if (this.materialInfos && this.materialInfos.cullface !== undefined) {
      if (this.materialInfos.cullface == false) {
        this.program.cullFace = false
      } else {
        this.program.cullFace = this.gl[this.materialInfos.cullface]
      }
    }

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
      transform: this.transform,
      forceRenderOrder: this.forceRenderOrder
    })
    this.mesh.name = this.name


    this.mesh.setParent(this.parent)
    // this.mesh.updateMatrixWorld(true)
    // this.parent.updateMatrixWorld(true)
  }

  onLoaded() {
    if (this.texture) {
      this.texture = this.scene.textureLoader.getTexture(this.textureId)
      this.texture.needsUpdate = true
      this.program.uniforms['uTexture'].value = this.texture
    }

    if (this.alphaTexture) {
      this.alphaTexture = this.scene.textureLoader.getTexture(`${this.textureId}_Opacity`)
      this.alphaTexture.needsUpdate = true
      this.program.uniforms['uAlphaTexture'].value = this.alphaTexture
    }

    if (this.cloudsTexture) {
      this.cloudsTexture = this.scene.textureLoader.getTexture('fbm_1')
      this.cloudsTexture.needsUpdate = true
      this.program.uniforms['uCloudsTexture'].value = this.cloudsTexture
    }
  }

  // Render
  getFirstDrawPromise() {
    this.didFirstDraw = false
    return new Promise((resolve) => { this.firstDrawResolve = resolve })
  }

  firstDraw() {
    if (this.didFirstDraw) return

    // this.gl.finish()

    const storedVisibility = this.mesh.visible
    this.mesh.visible = true

    this.scene.renderer.render({
      scene: this.mesh,
      camera: this.scene.camera,
      clear: false,
      frustumCull: true,
      sort: false,
    })

    this.mesh.visible = storedVisibility

    // this.gl.finish()

    if (!this.didFirstDraw) {
      this.didFirstDraw = true
      requestAnimationFrame(() => { this.firstDrawResolve(this) })
    }
  }

  // TODO: remove new Color() on each frame by using this.gui.on()
  preRender() {
    this.program.uniforms["uTime"].value = this.scene.time

    if (DebugController.active) {
      this.program.uniforms["uGroundRes"].value = [this.config.GroundResolution.value.x, this.config.GroundResolution.value.y]
      this.program.uniforms["uCloudsRes"].value = [this.config.CloudsResolution.value.x, this.config.CloudsResolution.value.y]
      this.program.uniforms["uCloudsShadowOffset"].value = [this.config.CloudsShadowOffset.value.x, this.config.CloudsShadowOffset.value.y]

      this.program.uniforms['uFresnelColor'].value = new Color(this.config.FresnelColor.value)
      this.program.uniforms['uFresnelRimColor'].value = new Color(this.config.FresnelRimColor.value)
    }
  }
}

export default MeshEntity
