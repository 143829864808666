import { GUI_PANEL_UNLIT } from '../data/dataGUIPanels'
import DebugController from '~/glxp/debug/debugController'
import MeshUnlit from '~/glxp/entities/MeshUnlit'
import GUIMaterialManifest from '~/glxp/guiMaterialManifest'
import { Color } from '~/glxp/ogl/math/Color'
import { Texture } from '@/glxp/ogl/core/Texture.js'
import { Mat4 } from '../ogl/math/Mat4'

class MeshPole extends MeshUnlit {
  constructor(scene, data, texture, opts = {}) {
    super(scene, data, texture, opts)

    this.program.uniforms['uTime'] = { value: 0 }

    this.program.uniforms['uColor'] = { value: new Color("#FF0000") }
    this.program.uniforms['uAlpha'] = { value: 1 }
    this.program.uniforms['uEye'] = { value: scene.camera.position }
    this.program.uniforms['uPMatrix'] = { value: scene.camera.projectionMatrix }
    this.program.uniforms['uMMatrix'] = { value: new Mat4() }
    this.program.uniforms['uVMatrix'] = { value: scene.camera.viewMatrix }
    this.program.uniforms['uNMatrix'] = { value: new Mat4() }
    // this.program.uniforms['uMatcap'] = { value: new Texture(scene.gl, {}) }
  }

  initConfig() {
    const guiName = `${this.scene.name} - Pole`

    this.config = {
      ...GUIMaterialManifest.createUnlitMaterial(guiName),
    }

    this.config['Exposure'] = { value: -.1, params: {max: 2, min: -2} }
    this.config['Tint'] = {value: "#828282", params: {}}

    delete this.config['TextureScale']
    delete this.config['Contrast']

    this.gui = DebugController.addBlade(this.config, guiName, GUI_PANEL_UNLIT)

    this.gui && this.gui.on('change', () => {
      this.program.uniforms['uTint'].value = new Color(this.config.Tint.value)
    })
  }

  preRender() {
    // console.log(this.scene.time);
    // this.program.uniforms['uTime'].value = this.scene.time
    this.program.uniforms['uMMatrix'].value = this.mesh.worldMatrix
    this.program.uniforms['uNMatrix'].value = this.mesh.normalMatrix
  }
}

export default MeshPole
