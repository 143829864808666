// Structural
import SubsceneAbs from '~/glxp/abstract/subscene'
import Manifest from '~/glxp/manifest'
import GLBLoader from '~/glxp/loader/glbLoader'

// Entities
import MeshPBR from '~/glxp/entities/MeshPBR'
import MeshUnlit from '~/glxp/entities/MeshUnlit'
import LensFlares, { getHomeSpaceportFlares } from '~/glxp/entities/LensFlares'
import MeshFlag from '~/glxp/entities/MeshFlag'
import MeshPole from '~/glxp/entities/MeshPole'

// Post
import Bloom from '~/glxp/postProcess/bloomPass'
import LensFlarePost from '~/glxp/postProcess/LensFlarePost'

// Utils
import RAF from '~/glxp/utils/raf'
import DebugController from '~/glxp/debug/debugController'
import PBRConfigs from '~/glxp/debug/pbrConfigs'
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import GlobalEmitter from '~/glxp/utils/emitter'
import Catmull from '~/glxp/utils/catmull'
import Mouse from '~/glxp/utils/mouse'
import { clamp, deg2rad, lerp } from '~/glxp/utils/math'
import { isMobile } from '@/glxp/utils/device'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { RenderTarget } from '~/glxp/ogl/core/RenderTarget.js'

// Managers
import CameraManager from '~/glxp/managers/CameraManager'
import DebugManager from '~/glxp/managers/DebugManager'

// Paths
import { HOME_SPACEPORT_CAMERA_POSITION_POINTS_DESKTOP, HOME_SPACEPORT_CAMERA_POSITION_POINTS_MOBILE } from '~/glxp/camera/paths'
import { GUI_PANEL_PBR, GUI_PANEL_UNLIT } from '~/glxp/data/dataGUIPanels'

class SceneHomeSpaceport extends SubsceneAbs {
  constructor(container, manager = null, textureLoader, shouldFirstDraw = false) {
    super(container, manager, textureLoader, shouldFirstDraw)

    this.name = 'Home Spaceport'

    this.manager = manager
    this.renderer = manager.renderer
    this.textureLoader = textureLoader

    this.time = 0
    this.dt = 0
    this.drawcalls = 0

    this.progress = 0
    this.progressTarget = 0.00001
    this.lastWheel = 0
    this.wheelProgress = 0

    this.forceAllDraw = true

    this.clearColor = this.manager.clearColor
    this.loaded = false
    this.active = false

    // Meshes & Entities
    this.root = new Transform()
    this.root.scale.set(1, 1, 1)
    this.meshes = []

    // Debug
    this.debugManager = new DebugManager(this)

    // Config
    this.config = {
      ScrollDamping: { value: 0.05, params: { min: 0, max: 1, step: 0.01 } },
      SunPosition: { value: { x: -1, y: 27, z: -225 }, params: {} },
      // DEV
      OverrideScroll: {value: false, params: {}},
      Progress: { value: 0, params: {min: 0, max: 1, step: 0.01} },
      DevScrollSpeed: { value: 30, params: { min: 0, max: 60 } },
    }

    // Inits
    this.initCameras()
    this.initCameraTracks()
    this.initRT()
    this.initSun()

    if (!DebugController.queryDebug('notextures')) {
      this.initFlares()
    }
  }

  // Inits
  initRT() {
    this.rt = new RenderTarget(this.gl);
  }

  initPost() {
    this.post = new LensFlarePost(this, this.postOptions)

    // TODO: Check if there would be better way to set that before network config is enabled.
    this.post.config.Gamma.value = 1.2
    this.post.config.Exposure.value = 0.15
    this.post.config.Contrast.value = 0.2
  }

  initCameras() {
    this.cameraManager = new CameraManager(this.gl, {
      width: this.width,
      height: this.height,
      scene: this,
      debug: false,
      hasMousePan: true,
      mousePanOffsets: [0.05, -0.025]
    })
    this.camera = this.cameraManager.camera

    this.cameraManager.toGo.set(0, 1, 5)

    if (isMobile) {
      this.cameraManager.toLook.set(0, 0.1, 0.35)
    } else {
      this.cameraManager.toLook.set(0, 0.1, 0)
    }
  }

  initCameraTracks() {
    const cameraPositionTrack = new Catmull(
      this.cameraManager.parseCameraPoints(
        isMobile ? HOME_SPACEPORT_CAMERA_POSITION_POINTS_MOBILE : HOME_SPACEPORT_CAMERA_POSITION_POINTS_DESKTOP,
        1.5,
        false
      )
    )
    cameraPositionTrack.generate()

    this.cameraManager.addSpline(cameraPositionTrack, 'camera')
  }

  initSun(){
    this.sun = new Transform()
    this.sun.visible = false
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    this.sun.setParent(this.root)
  }

  initFlares(){
    this.lensFlares = new LensFlares(this, {
      lightSource: this.sun,
      getFlares: (spacing) => getHomeSpaceportFlares(this, spacing),
      spacing: 0.13,
    })
    
    this.meshes.push(this.lensFlares)
  }

  setProgress(p, shouldForce = false) {
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  // Lifecycle
  // Check subscene abstract for lifecycle events. They should start with super().
  fetchLoadableTextures() {
    let loadables = []

    this.initPost()

    const addTextureToLoadables = (groupKey) => {
      for (const key in Manifest[groupKey]) {
        if (Manifest[groupKey].hasOwnProperty(key)) {
          const element = Manifest[groupKey][key].url
          loadables.push({
            groupKey,
            element,
            key,
            options: Manifest[groupKey][key].options,
          })
        }
      }
    }

    // Common
    addTextureToLoadables('main')
    addTextureToLoadables('defaultLensFlares')

    // Spaceport scene
    addTextureToLoadables('spaceportPBR')
    addTextureToLoadables('spaceportUnlit')
    addTextureToLoadables('spaceportEnv')

    // Eve
    addTextureToLoadables('evePBR')
    addTextureToLoadables('eveUnlit')

    // Unity
    addTextureToLoadables('unityPBR')
    addTextureToLoadables('unityUnlit')

    return loadables
  }

  loadModels() {
    const loadables = []
    const unlitMaterialNames = Object.keys(Manifest.spaceportUnlit) || []
    const shipsMaterialNames = ['M_SpaceShip_Unity', 'M_SpaceShip_Eve']

    // SPACEPORT LANDSCAPE + BUILDINGS + SHIPS
    let url = `${"/"}glxp/models/spaceport_7_draco`;
    loadables.push(new GLBLoader(url + '.glb', true).then((glb) => {
      // console.log(glb);

      // Root for PBR model
      let modelRoot = new Transform()
      modelRoot.scale.set(.015, .015, .015)
      modelRoot.setParent(this.root)

      // Build node Tree
      let modelTree = GLTFUtils.buildNodeTree(glb, modelRoot)

      // Mesh List
      let meshList = GLTFUtils.buildMeshList(glb, modelTree)

      // Mesh Instanciation
      let entity

      for (const mesh of meshList) {
        const isSkybox = mesh.materialName.includes("Skybox")
        const isMountain = mesh.materialName.includes("Mountains")
        const isPole = mesh.node.name.includes("_Pole")
        const isFlag = mesh.node.name.includes("Tapestry")
        if(isPole || isFlag) mesh.materialName = "Pole"
        
        if (unlitMaterialNames.includes(mesh.materialName)) {
          entity = new MeshUnlit(this,
            mesh.meshData,
            Manifest.spaceportUnlit[mesh.materialName] !== undefined ? mesh.materialName : 'test',
            {
              id: GUI_PANEL_UNLIT,
              parent: mesh.parent || modelRoot,
              gltf: glb,
              name: mesh.meshName,
              node: mesh.node,
              fog: !isSkybox ? true : false,
              transparent: false,
              materialName: mesh.materialName,
              material: Manifest.spaceportUnlit[mesh.materialName]?.material,
              globalConfig: this.getPBRConfig(mesh.materialName),
            }
          )

          if (isMountain) entity.mesh.rotation.y = -deg2rad(10)
          if (isSkybox) entity.mesh.rotation.y = -deg2rad(5)
          
        } else if(isFlag) {
          // console.log(mesh.node.name);
          // const flags = ["M_UsaFlag", "M_FranceFlag", "M_BelgiumFlag"]
          // const flag = flags[Math.floor(Math.random() * flags.length)]
          const flag = "M_VgFlag"
          entity = new MeshFlag(this,
            mesh.meshData,
            flag,
            {
              id: 2,
              parent: mesh.parent || modelRoot,
              gltf: glb,
              name: mesh.meshName,
              node: mesh.node,
              fog: !isSkybox,
              transparent: false,
              materialName: "M_Props",
              material: Manifest.spaceportUnlit["M_Props"]?.material,
              globalConfig: this.getPBRConfig("M_Props"),
              shaderId: "flag",
              directionFactor: -1
            }
          )
        } else if(isPole) {
          entity = new MeshPole(this,
            mesh.meshData,
            "M_MetalMatcap",
          {
            id: 1,
            parent: mesh.parent || modelRoot,
            gltf: glb,
            data: mesh.meshData,
            name: mesh.meshName,
            node: mesh.node,
            fog: false,
            transparent: false,
            materialName: "M_Props",
            material: Manifest.spaceportUnlit["M_Props"]?.material,
            globalConfig: this.getPBRConfig("M_Props"),
            shaderId: "matcap",
          })
        } else {
          entity = new MeshPBR(this, {
            id: GUI_PANEL_PBR,
            parent: mesh.parent || modelRoot,
            gltf: glb,
            data: mesh.meshData,
            name: mesh.meshName,
            node: mesh.node,
            fog: false,
            transparent: false,
            materialName: mesh.materialName,
            material: mesh.material,
            globalConfig: this.getPBRConfig(mesh.materialName),
          })

          // TODO: Move somewhere else?
          if (shipsMaterialNames.includes(mesh.materialName)) {
            entity.config.IBL.value = 0.6
          }
        }
        this.meshes.push(entity)
      }
    }))

    return loadables
  }

  // Utils
  getPBRConfig(materialName) {
    switch(materialName) {
      case 'M_Glass_PBR':
        return PBRConfigs["CONFIG_SPACEPORT_GLASS_SHIPS"]

      default:
        return PBRConfigs["CONFIG_SPACEPORT"]
    }
  }

  applyDefaultState() {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  // Events
  addEvents() {
    GlobalEmitter.on('webgl_home_spaceport_progress', this.onProgressRequest.bind(this))
  }

  removeEvents() {
    GlobalEmitter.off('webgl_home_spaceport_progress', this.onProgressRequest.bind(this))
  }

  onActivate() {
    this.addEvents()
  }

  onDisable() {
    this.removeEvents()
  }

  onLoaded() {
    // this.active = true
    this._emitter.emit('loaded')

    // First Draw
    if (this.shouldFirstDraw) {
      const firstDrawPromises = this.getFirstDrawPromises()

      this.firstDrawAllPromise = Promise.all(firstDrawPromises).then(() => {
        this.didFirstDraw = true
        // console.log(`Subscene ${this.name} finished first draw`)
      })
    }

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].onLoaded()
    }

    // Post
    if(this.hasBloom){
      this.bloomPass = new Bloom(this, this.post)
    }
    this.post.onLoaded()

    // Debug
    this.gui = DebugController.addBlade(
      this.config,
      `Global Settings - ${this.name || 'Unknown'}`
    )
    DebugController.onLoaded()
    this.debugManager.onLoaded()
  }

  postFirstDraw() { }

  onProgressRequest({ progress }) {
    this.setProgress(progress)
  }

  resize() {
    this.cameraManager.resize(this.width, this.height)
    this.rt.setSize(this.width, this.height)
  }

  // Rendering
  preRender() {
    // Progress
    if (this.hasWheel) {
      // Listen to mouse wheel for progress on Sandbox

      if (!this.isAnimatingIntro) {
        this.wheelProgress += Mouse.normalizeWheel - this.lastWheel
        this.wheelProgress = clamp(this.wheelProgress, 0, 0.037)
      }

      // Can override it through the config value if needed
      this.progressTarget = this.config.OverrideScroll.value ? this.config.Progress.value : this.wheelProgress * this.config.DevScrollSpeed.value
      this.progress = lerp(this.progress, this.progressTarget, 0.075)
      this.progress = clamp(this.progress, 0, 1)
      this.lastWheel = Mouse.normalizeWheel
    } else if(!this.overrideProgress) {
      // Listen to FE events for progress
      let tmp = this.progressTarget - this.progress
      this.progressDelta = tmp
      tmp *= this.config.ScrollDamping.value

      this.progress += tmp
    }

    // TODO: move this to a .on() tweakpane event
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].preRender()
    }

    this.cameraManager.setProgress(this.progress)
    this.cameraManager.preRender()
  }

  render() {
    // First Draw
    if (this.shouldFirstDraw && !this.didFirstDraw) {
      this.drawNextFDMesh()
      return
    }

    if (!this.active) {
      return
    }

    // Timings
    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    // Pre render
    this.preRender()

    // Aspect
    this.gl.viewport(0, 0, this.width, this.height)
    this.camera.perspective({ aspect: this.width / this.height })
    this.renderer.setViewport(this.width, this.height)

    // Render Time
    this.renderer.render({
      scene: this.root,
      camera: this.camera,
      clear: true,
      frustumCull: true,
      sort: true,
      post: this.post,
    })

    // Post
    this.bloomPass && this.bloomPass.render()
    // Last link of post processing pipeline should render in this.rt, which is fetched from main scene wrapper
    this.post.render({ target: this.rt })

    

    this.postRender()
  }

  postRender() {
    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }
  }
}

export default SceneHomeSpaceport
