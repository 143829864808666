import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxtjs_i18n_dist_runtime_plugin_mjs_vyNBGOI7EC from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugin.mjs";
import plugins_debouncedResize_js_orR01eA9qI from "/opt/buildhome/repo/plugins/debouncedResize.js";
import plugins_gtm_client_js_KEcT5dOoKs from "/opt/buildhome/repo/plugins/gtm.client.js";
import plugins_preventOrphans_js_TMzBKQNjLg from "/opt/buildhome/repo/plugins/preventOrphans.js";
import plugins_real_height_client_js_OHT4ERUDX4 from "/opt/buildhome/repo/plugins/real-height.client.js";
import plugins_say_hello_client_js_Be4LX9d2Mn from "/opt/buildhome/repo/plugins/say-hello.client.js";
import plugins_sentry_client_js_GoGQuZo4Et from "/opt/buildhome/repo/plugins/sentry.client.js";
import plugins_tracking_client_js_fEUopUxxSj from "/opt/buildhome/repo/plugins/tracking.client.js";
import plugins_vue_tel_input_js_GukcJ5wu6r from "/opt/buildhome/repo/plugins/vue-tel-input.js";
import plugins_webglManager_client_js_pFkd223UVR from "/opt/buildhome/repo/plugins/webglManager.client.js";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxtjs_i18n_dist_runtime_plugin_mjs_vyNBGOI7EC,
  plugins_debouncedResize_js_orR01eA9qI,
  plugins_gtm_client_js_KEcT5dOoKs,
  plugins_preventOrphans_js_TMzBKQNjLg,
  plugins_real_height_client_js_OHT4ERUDX4,
  plugins_say_hello_client_js_Be4LX9d2Mn,
  plugins_sentry_client_js_GoGQuZo4Et,
  plugins_tracking_client_js_fEUopUxxSj,
  plugins_vue_tel_input_js_GukcJ5wu6r,
  plugins_webglManager_client_js_pFkd223UVR
]