import { getAverage } from '../utils/math'

/**
 * Calculates and averages framerates
 */
export default class FramerateManager {
  active = false
  
  averageFPS = 0
  fps = 0
  arrayFPS = []
  
  rafId = null
  previousTime = null
  deltaTime = null
  
  count = 100  // each n frames, calculate the average FPS

  constructor(){
    this.init()
  }

  init(){
    this.onRaf(0)
  }

  // When average FPS is calculated
  setOnAverageCallback = (onAverageCallback) => {
    this.onAverageCallback = onAverageCallback
  }

  // Needs to be called outside any other raf loop
  onRaf = (timestamp) => {
    this.deltaTime = timestamp - this.previousTime

    this.fps = 1000 / this.deltaTime

    this.previousTime = timestamp

    this.rafId = requestAnimationFrame(this.onRaf)
  }

  // Call this every rendered frame
  update = () => {
    if (!this.active) return

    if (this.arrayFPS.length < this.count) {
      this.arrayFPS.push(this.fps)
    } else {
      this.averageFPS = getAverage(this.arrayFPS)
      this.arrayFPS = [] // Clear array to prep for next round of averaging
      this.onAverageCallback && this.onAverageCallback(this.averageFPS)
    }
  }

  setActive = (toggle) => {
    this.active = toggle
  }
}
