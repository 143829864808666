import { GUI_PANEL_UNLIT } from '../data/dataGUIPanels'
import DebugController from '~/glxp/debug/debugController'
import { Color } from '../ogl/math/Color'
import { Texture } from '../ogl/core/Texture'
import PlaneEntity from './Plane'

class MeshGround extends PlaneEntity {
    constructor(
        scene,
        shader,
        texture,
        {
            parent = null,
            scale = 1,
            blendFunc = {
                src: scene.gl.SRC_ALPHA,
                dst: scene.gl.ONE_MINUS_SRC_ALPHA,
            },
            transparent = false,
            depthTest = true,
            depthWrite = true,
            renderOrder = 0,
            alpha = 1,
            hasShadow = false,
            name = 'MeshGround',
        } = {}
    ) {
        super(scene, shader, texture, {
            parent,
            scale: 1,
            blendFunc,
            transparent,
            depthTest,
            depthWrite,
            renderOrder,
            alpha,
            hasShadow,
            name,
            planeOptions: {
                width: scale,
                height: scale,
                widthSegments: 64,
                heightSegments: 64,
            }
        })

        this.config = {
            lightPosition : {value: { x: -.3, y: -.1, z: 1 }, params: {}},
            color1 : {value: "#7a6269", params: {}},
            color2 : {value: "#e59777", params: {}},
        }

        delete this.program.uniforms.uTexture
        delete this.program.uniforms.isCameraFliped
        delete this.program.uniforms.uColor
        delete this.program.uniforms.uAlpha

        this.program.uniforms['uFbmTexture'] = { value: new Texture(this.gl) }
        this.program.uniforms['uRiversTexture'] = { value: new Texture(this.gl) }
        this.program.uniforms['uPerlinTexture'] = { value: new Texture(this.gl) }
        this.program.uniforms['uAbsFbmTexture'] = { value: new Texture(this.gl) }

        this.program.uniforms['uTime'] = { value: 0 }
        this.program.uniforms['uColor1'] = { value: new Color("#7a6269") }
        this.program.uniforms['uColor2'] = { value: new Color("#e59777") }
        this.program.uniforms['uLightPosition'] = { value: [this.config.lightPosition.x, this.config.lightPosition.y, this.config.lightPosition.z] }
        this.program.uniforms['uFbmScale'] = { value: 1 }
        this.program.uniforms['uRiversScale'] = { value: 1 }
        this.program.uniforms['uNormalOffset'] = { value: scale / 64 }
    }

    onLoaded() {
        if (this.textureId) {
            this.texture = this.scene.textureLoader.getTexture(this.textureId)
            this.texture.needsUpdate = true
            this.program.uniforms['uFbmTexture'].value = this.texture
        }

        const guiName = `${this.scene.name} - Ground`
        this.gui = DebugController.addBlade(this.config, guiName, GUI_PANEL_UNLIT)

        {
            let texture = this.scene.textureLoader.getTexture("lightning_1")
            texture.needsUpdate = true
            this.program.uniforms['uRiversTexture'].value = texture
        }

        {
            let texture = this.scene.textureLoader.getTexture("absFbm_1")
            texture.needsUpdate = true
            this.program.uniforms['uAbsFbmTexture'].value = texture
        }

        {
            let texture = this.scene.textureLoader.getTexture("perlin_1")
            texture.needsUpdate = true
            this.program.uniforms['uPerlinTexture'].value = texture
        }
    }

    preRender() {
        // console.log(this.name);
        this.program.uniforms['uRez'].value = [this.scene.width, this.scene.height]
        this.program.uniforms['uTime'].value = this.scene.time

        this.program.uniforms['uColor1'].value = new Color(this.config.color1.value)
        this.program.uniforms['uColor2'].value = new Color(this.config.color2.value)
        this.program.uniforms['uLightPosition'].value = [this.config.lightPosition.value.x, this.config.lightPosition.value.y, this.config.lightPosition.value.z]
    }
}

export default MeshGround
