import { vec3 } from 'gl-matrix'

import DebugController from '~/glxp/debug/debugController'

import OrbitController from '~/glxp/camera/orbit'
import { Camera } from '~/glxp/ogl/core/Camera.js'
import { Vec3 } from '~/glxp/ogl/math/Vec3.js'

import Catmull from '~/glxp/utils/catmull'
import Mouse from '~/glxp/utils/mouse'
// import { FloatArrayToGlMatrixVec3Array } from '~/glxp/utils/math'

// Mouse pan saved
const _VEC3 = vec3.create()
const _UP = vec3.fromValues(0, 1, 0)

export default class CameraManager {
  constructor(gl, { width, height, fov, scene, debug, hasMousePan, mousePanOffsets, frontFacing}) {
    this.width = width
    this.height = height
    this.fov = fov || 40
    this.scene = scene
    this.debug = debug
    this.hasMousePan = hasMousePan || false
    this.mousePanOffsets = mousePanOffsets || [0.1, -0.1]

    this.gl = gl
    this.toGo = new Vec3(0, 0, 10)
    this.toLook = new Vec3(0, 0, 0)
    this.progress = 0
    this.lastWheel = 0
    this.catmulls = {}

    this.mouse = [0, 0]

    this.panMouse = vec3.create()
    this.pan = {
      mouse: vec3.create(),
      current: vec3.create(),
      target: vec3.create(),
      targetBis: vec3.create(),
    }

    this.camera = new Camera(this.gl, {
      near: 0.01,
      far: 100000,
      fov: this.fov,
      aspect: this.width / this.height,
    })

    this.config = {
      rotationOffset: { value: { x: 0, y: 0, z: 0 }, params: { x: {min: -3.1415, max: 3.1415}, y: {min: -3.1415, max: 3.1415}, z: {min: -3.1415, max: 3.1415} } }
    }

    if (this.debug || DebugController.active && DebugController.queryDebug('orbit')) {
      this.debugMode = true
      this.orbit = new OrbitController(this.scene, this.camera, {
        startAngleX: frontFacing ? Math.PI : 2.5,
        startAngleY: frontFacing ? -Math.PI / 2 : -1,
        startDistance: 25,
        target: frontFacing ? [0, 0, 0] : [0, 1, 0],
      })

      this.initDebugEvents()
    }

    window.__cameraManager = this
  }
  resize(width, height) {
    this.camera.perspective({
      aspect: width / height,
    })
  }

  initDebugEvents() {
    if (DebugController.active && DebugController.pane) {
      DebugController.pane.containerElem_.addEventListener('mouseover', () => {
        this.preventUpdateOrbit = true
      })
      DebugController.pane.containerElem_.addEventListener('mouseleave', () => {
        this.preventUpdateOrbit = false
      })
    }
  }


  addSplineFromCurve(curve, id) {
    const catmull = new Catmull(
      FloatArrayToGlMatrixVec3Array(curve._data.vertices)
    )
    catmull.node.position = vec3.clone(curve._data.translate)
    catmull.node.rotation = vec3.clone(curve._data.rotation)
    catmull.node.scale = vec3.clone(curve._data.scale)
    catmull.generate()
    this.catmulls[id] = catmull
  }

  addSpline(catmull, id) {
    this.catmulls[id] = catmull
  }

  parseCameraPoints(points, scale, isLoop = false) {
    const formatedPoints = []

    points.forEach((pos) => {
      formatedPoints.push([pos[0] * scale, pos[2] * scale, -pos[1] * scale])
    })

    if (isLoop) {
      formatedPoints.push(formatedPoints[0])
    }

    return formatedPoints
  }

  setProgress(progress) {
    this.progress = progress
  }

  preRender() {
    this.update()
    if (this.orbit) {
      this.orbit.enabled = !this.preventUpdateOrbit
      this.orbit.update()
    }
  }

  update() {
    if (this.debugMode) {
      this.scene.progressTarget = (this.scene.time * 0.01) % 1
      this.scene.progress = this.scene.progressTarget
    } else {
      if (this.catmulls['camera']) {
        vec3.copy(this.toGo, this.catmulls['camera'].getPointProgress(this.progress))
      }

      if (this.catmulls['lookat']) {
        vec3.copy(this.toLook, this.catmulls['lookat'].getPointProgress(this.progress))
      }
    }

    // Mouse pan
    vec3.copy(_VEC3, this.toGo)

    if (this.hasMousePan) {
      let cameraDir = vec3.create()
      let cameraUp = vec3.create()
      let cameraRight = vec3.create()

      this.pan.mouse[0] = Mouse.cursor[0] * this.mousePanOffsets[0]
      this.pan.mouse[1] = Mouse.cursor[1] * (this.scene.width / this.scene.height) * this.mousePanOffsets[1]

      vec3.sub(cameraDir, this.toGo, this.toLook)
      vec3.normalize(cameraDir, cameraDir)

      vec3.cross(cameraRight, _UP, cameraDir)
      vec3.normalize(cameraRight, cameraRight)
      vec3.cross(cameraUp, cameraDir, cameraRight)

      vec3.scale(this.pan.target, cameraRight, this.pan.mouse[0])
      vec3.scale(this.pan.targetBis, cameraUp, this.pan.mouse[1])
      vec3.add(this.pan.target, this.pan.target, this.pan.targetBis)

      // Apply to position vec
      vec3.lerp(this.pan.current, this.pan.current, this.pan.target, 0.1)
      vec3.add(_VEC3, _VEC3, this.pan.current)
    }

    // Camera position is in World position
    vec3.mul(this.camera.position, _VEC3, this.scene.root.scale)
    vec3.mul(this.toLook, this.toLook, this.scene.root.scale)

    this.camera.lookAt(this.toLook)

    this.camera.rotation.x += this.config.rotationOffset.value.x
    this.camera.rotation.y += this.config.rotationOffset.value.y
    this.camera.rotation.z += this.config.rotationOffset.value.z
  }
}
