import Emitter from 'event-emitter'
// import 'https://greggman.github.io/webgl-memory/webgl-memory.js';
import initWebglMemory from './utils/webgl-memory.js'

import HomeScene from '~/glxp/scenes/homeParent'
import SpaceportAmericaScene from '~/glxp/scenes/spaceportAmerica'
import SpacecraftFleetScene from '~/glxp/scenes/spacecraftFleetParent'
import FlightExperience from '~/glxp/scenes/flightExperience'
// import SpacecraftFleetSandboxScene from '~/glxp/scenes/spacecraftFleetSandboxParent'
import SandboxParent from '~~/glxp/scenes/sandboxParent'
import BenchmarkScene from '~~/glxp/scenes/benchmarkParent'

import performanceConfig from '~/glxp/debug/performanceConfig'

import { Renderer } from '~/glxp/ogl/core/Renderer.js'

import { getGPUTier } from "~/glxp/utils/detect-gpu/dist/lib/src"
import TextureLoader from '~/glxp/utils/textureLoaderObject'
import GlobalEmitter from '~/glxp/utils/emitter'

import DebugController from '~/glxp/debug/debugController'

import FramerateManager from './managers/FramerateManager'
import PerformanceManager from './managers/PerformanceManager'
import { isMobile, isIOS } from './utils/device.js'
import debounce from '~/glxp/utils/debounce'

class Manager {
  constructor() {
    this.scenes = {}
    this.initialized = false

    this.clearColor = [1, 1, 1, 1]

    this._emitter = {}
    Emitter(this._emitter)
    this.on = this._emitter.on.bind(this._emitter)

    this.fpsAverages = []

    this.framerateManager = new FramerateManager()
    this.performanceManager = new PerformanceManager(this, isMobile ? { minimal: 40, low: 50 } : { minimal: 50, low: 54 })

    window.GLXP = this
  }

  init(container) {
    this.dpr = Math.max(Math.min(window.devicePixelRatio, 2), 1)
    if (DebugController.queryDebug('low-dpr')) this.dpr = 1

    if(isMobile && isIOS) {
      this.width = window.innerWidth * this.dpr
      this.height = window.outerHeight * this.dpr
    }else{
      this.width = container.offsetWidth * this.dpr
      this.height = container.offsetHeight * this.dpr
    }
    this.container = container
    this.catchContext()
    this.textureLoader = new TextureLoader(this.gl)
    this.initialized = true

    this.initScenes()

    this.debouncedResize = debounce(this.resize.bind(this), 60)
    if(!isMobile) window.addEventListener('resize', this.debouncedResize)
    else{
      window.addEventListener('orientationchange', this.resize.bind(this))
    }


    // Events
    // this.resizeObserver = new ResizeObserver(this.resize.bind(this))
    // this.resizeObserver.observe(this.container)
    GlobalEmitter.on('webgl_loaded', () => {
      this.resize()
      this._emitter.emit('resize')
      DebugController.active && DebugController.pane.refresh()
    })

    this.getTier().then((tier) => {
      const contentElement = document.querySelector("#benchmark-results .content")
      if (contentElement) contentElement.innerHTML = JSON.stringify(tier, null, 2)
      this.adaptToTier(tier)
      this.tier = tier
      window.tier = tier
    })

    if (location.pathname.includes("/benchmark")) {
      GlobalEmitter.on('webgl_loaded', () => {
        setTimeout(() => {
          this.getMemoryInfo()
        }, 100);
      })
    }

    return this
  }

  initScenes() {
    // console.time("initScenes")

    this.populateScene('home')
    this.populateScene('spaceportAmerica')
    this.populateScene('spacecraftFleet')
    this.populateScene('flightExperience')

    // if (DebugController.queryDebug("dev")) this.addScene('sandbox', SandboxParent)
    if (location.pathname.includes("/benchmark")) this.addScene('benchmark', BenchmarkScene)
  }

  populateScene(id) {
    this.scenes[id] = {
      scene: null,
      active: false,
      callbackCanvas: null,
    }
  }

  addScene(id, Scene) {
    this.scenes[id] = {
      scene: new Scene(this.container, this),
      active: false,
      callbackCanvas: null,
    }
  }

  registerCallbackCanvas(id, canvas) {
    this.scenes[id].callbackCanvas = canvas
  }

  getScene(id) {
    if (id === "") return null

    if (this.scenes[id]) {
      // Initialise scene
      switch (id) {
        case "home":
          this.addScene(id, HomeScene)
          break;

        case "spaceportAmerica":
          this.addScene(id, SpaceportAmericaScene)
          break;

        case "spacecraftFleet":
          this.addScene(id, SpacecraftFleetScene)
          break;

        case "flightExperience":
          this.addScene(id, FlightExperience)
          break;

        default:
          break;
      }
      // TODO: DebugController.setCurrentScene shouldn't be here as getting a scene doesn't mean we'll get to it all the time.
      // Works for ou current use though
      DebugController.setCurrentScene(id)

      return this.scenes[id].scene
    }

    console.error(`Scene ${id} not found`)
    return null
  }

  loadScene(id) {
    if (this.scenes[id].scene.loaded === false) {
      let promise = new Promise((resolve, reject) => {
        this.scenes[id].scene.load()

        this.scenes[id].scene.on('loaded', () => {
          setTimeout(() => {
            // Apply flares debug config
            // WARNING: This is a hack to make the debug controller work with the lens flares config
            // It's not a good solution but it works for now
            const scene = this.scenes[id].scene
            // Subscenes
            if (scene.subscenes) {
              for (const subscene of scene.subscenes) {
                if (DebugController.sceneConfig && subscene.lensFlares) {
                  const key = Object.keys(DebugController.sceneConfig).filter(key => key.includes('Lens Flares') && key.includes(subscene.name))
                  if (key && DebugController.sceneConfig[key]) {
                    // DO NOT DO THIS: subscene.lensFlares.config[flareKey] =  DebugController.sceneConfig[key] or you'll override the whole object which will break the GUI
                    for (const flareKey in subscene.lensFlares.config) {
                      if (Object.hasOwnProperty.call(subscene.lensFlares.config, flareKey)) {
                        subscene.lensFlares.config[flareKey].value = DebugController.sceneConfig[key][flareKey].value
                      }
                    }

                    for (const flare of subscene.lensFlares.flares) {
                      flare.step = DebugController.sceneConfig[key].Spacing.value
                    }
                  }
                }
              }
              // Scene
            } else if (DebugController.sceneConfig && scene.lensFlares) {
              const key = Object.keys(DebugController.sceneConfig).filter(key => key.includes('Lens Flares') && key.includes(scene.name))
              if (key && DebugController.sceneConfig[key]) {
                // DO NOT DO THIS: subscene.lensFlares.config[flareKey] =  DebugController.sceneConfig[key] or you'll override the whole object which will break the GUI
                for (const flareKey in scene.lensFlares.config) {
                  if (Object.hasOwnProperty.call(scene.lensFlares.config, flareKey)) {
                    scene.lensFlares.config[flareKey].value = DebugController.sceneConfig[key][flareKey].value
                  }
                }

                for (const flare of scene.lensFlares.flares) {
                  flare.step = DebugController.sceneConfig[key].Spacing.value
                }
              }
            }
          }, 100);

          this.scenes[id].scene.onResize()
          resolve()
        })
      })

      return promise
    }
  }

  activate(id) {
    this.scenes[id].active = true

    const activationPromise = this.scenes[id].scene.activate()

    activationPromise.then(() => {      
      this.framerateManager.setActive(true)

      if (!location.pathname.includes("/benchmark")) {
        this.framerateManager.setOnAverageCallback((fps) => {
          // console.log('calc fps callback, value:', fps);
          const cappedFPS = Math.min(fps, 60) // Cap FPS due to higher refresh rate screens
          this.performanceManager.onPerfCheck({ fps: cappedFPS })
        })
      }

      if (DebugController.active) {
        this.performanceManager.setDebugEvents()
      }
    })

    return activationPromise
  }

  resize = () => {
    setTimeout(() => {
      if(isMobile && isIOS) {
        if(this.hasSetIOSHeight) return
        this.width = window.innerWidth * this.dpr
        this.height = window.outerHeight * this.dpr
        this.canvas.style.minWidth = `${window.innerWidth}px`
        this.canvas.style.minHeight = `${window.outerHeight}px`
        this.hasSetIOSHeight = true
      }else{
        this.width = this.container.offsetWidth * this.dpr
        this.height = this.container.offsetHeight * this.dpr
        this.canvas.style.minWidth = `${this.container.offsetWidth}px`
        this.canvas.style.minHeight = `${this.container.offsetHeight}px`
      }

      this.renderer.setSize(this.width, this.height)
  
      // resize scenes after changes
      for (const scene of Object.values(this.scenes)) {
        if(!scene.scene) continue
  
        if(scene.scene.post) scene.scene.post.rt.onResize()
        scene.scene.onResize()
  
        // resize subscenes if exist
        if (scene.scene.subscenes) {
          scene.scene.subscenes.forEach((subscene) => {
            if(subscene.post) subscene.post.rt.onResize()
            subscene.onResize()
          })
        }
      }
    }, 200);
  }

  desactivate(id) {
    this.scenes[id].active = false
    if (this.scenes[id].scene.disable) this.scenes[id].scene.disable()

    this.gl.clearColor(0, 0, 0, 0)
    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT)
    this.gl.colorMask(true, true, true, true)
  }

  catchContext() {
    if (location.pathname.includes("/benchmark")) initWebglMemory()

    this.renderer = new Renderer(performanceConfig.optimisedRenderer)
    this.gl = this.renderer.gl

    window.isWebgl2 = this.renderer.isWebgl2

    if (!this.renderer.isWebgl2) {
      // VAO Polyfill
      let vaoExt = this.gl.getExtension('OES_vertex_array_object')
      if (vaoExt) {
        this.gl['createVertexArray'] = function () {
          return vaoExt['createVertexArrayOES']()
        }
        this.gl['deleteVertexArray'] = function (vao) {
          vaoExt['deleteVertexArrayOES'](vao)
        }
        this.gl['bindVertexArray'] = function (vao) {
          vaoExt['bindVertexArrayOES'](vao)
        }
        this.gl['isVertexArray'] = function (vao) {
          return vaoExt['isVertexArrayOES'](vao)
        }
      }
      this.gl.getExtension('OES_standard_derivatives')
      this.gl.getExtension('EXT_shader_texture_lod')

      this.gl.getExtension('OES_texture_float')
      this.gl.getExtension('OES_texture_float_linear')
      this.gl.getExtension('OES_texture_half_float')
      this.gl.getExtension('OES_texture_half_float_linear')
      this.gl.getExtension('EXT_color_buffer_half_float')
    }

    this.canvas = this.gl.canvas
    this.container.appendChild(this.gl.canvas)

    this.resize()

    if(isIOS) {
      window.addEventListener('orientationchange', ()=>{
        this.hasSetIOSHeight = false
        this.resize()
      })
    }
  }

  async getTier() {
    const tier = await getGPUTier({
      glContext: this.gl,
      benchmarksURL: "/glxp/detect-gpu/benchmarks-min"
    });

    // console.log("%c --- GPU Tier : ---", "color: #ff0000; font-size: 16px;")
    // console.log(tier);
    // console.log("%c ------------------", "color: #ff0000; font-size: 16px;");

    return tier
  }

  /**
   * @param {import('./utils/detect-gpu/dist/types/src').TierResult} tier 
   */
  adaptToTier(tier) {
    const thresholds = {
      low: 56,
      normal: 60,
    }

    // Disable FXAA on mobile
    if (tier.isMobile) performanceConfig.adaptFxaa = true

    if (tier.type !== 'BENCHMARK') {
      // Fallback if no benchmark for this device
      this.performanceManager.state.renderQuality = 'low'
    } else {
      if (tier.fps >= thresholds.normal) this.performanceManager.setActive(false)

      if (tier.fps < thresholds.normal) {
        this.performanceManager.state.renderQuality = 'low'
      } else if (tier.fps < thresholds.low) {
        this.performanceManager.state.renderQuality = 'potato'
      }
    }
  }

  getMemoryInfo() {
    const ext = this.gl.getExtension('GMAN_webgl_memory');
    if (ext) {
      /**
       {
          memory: {
            buffer: <bytes used by buffers>
            texture: <bytes used by textures>
            renderbuffer: <bytes used by renderbuffers>
            drawingbuffer: <bytes used by the canvas>
            total: <bytes used in total>
          },
          resources: {
            buffer: <count of buffers>,
            renderbuffer: <count of renderbuffers>
            program: <count of programs>
            query: <count of query objects, WebGL2 only>
            sampler: <count of samplers, WebGL2 only>
            shader: <count of shaders>
            sync: <count of sync objects, WebGL2 only>
            texture: <count of textures>
            transformFeedback: <count of transformfeedbacks, WebGL2 only>
            vertexArray: <count of vertexArrays, only if used or WebGL2>
          }
        }
       */
      const info = ext.getMemoryInfo();
      for (const [key, value] of Object.entries(info.memory)) {
        info.memory[key] = (value / 1_048_576).toFixed(0) + ' MB'
      }
      const contentElement = document.querySelector("#memory-results .content")
      if (contentElement) contentElement.innerHTML = JSON.stringify(info, null, 2)
    }
  }

  render() {
    let gl = this.gl
    gl.clearColor(
      this.clearColor[0],
      this.clearColor[1],
      this.clearColor[2],
      this.clearColor[3]
    )
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    gl.colorMask(true, true, true, true)

    this.renderer.drawCalls = 0

    // Important: FramerateManager should be updated
    // before render() calls, as doing otherwise might
    // cause black screens on dpr change
    // see: DIG Castles
    this.framerateManager.update()

    for (const scene in this.scenes) {
      if (this.scenes.hasOwnProperty(scene)) {
        const sc = this.scenes[scene]
        if (sc.active === true) {
          sc.scene.render()
        }
      }
    }
  }
}

const out = new Manager()
export default out
