import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Plane } from '~/glxp/ogl/extras/Plane.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Color } from '~/glxp/ogl/math/Color'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class ExperienceEarthGlowEntity {
  constructor(
    scene,
    {
      id = 0,
      parent = null,
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = false,
      depthTest = true,
      depthWrite = false,
      renderOrder = -1,
      name = 'Experience Earth Glow'
    } = {}
  ) {
    this.groupId = id
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name

    // Shader
    this.shaderGlow = new Shader(ShaderManifest['experienceGlow'])

    this.config = {
      GlowRotation: { value: 2.4, params: {  min: 0, max: Math.PI * 2, step: 0.01  }  },
      GlowRotationFactor: { value: 0.8, params: {  min: 0, max: 1, step: 0.01  }  },
      LowColor: { value: '#21367c', params: { }  },
      LowAlpha: { value: 0.75, params: {  min: 0, max: 1, step: 0.01  }  },
      RimColor: { value: '#2976ff', params: { }  },
      RimAlpha: { value: 1, params: {  min: 0, max: 1, step: 0.01  }  }
    }

    this.initGlow()
  }

  initGui(){
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
  }

  initGlow() {
    this.geometry = new Plane(this.gl, { width: 5, height: 5 })

    this.program = new Program(this.gl, {
      vertex: this.shaderGlow.vert,
      fragment: this.shaderGlow.frag,
      uniforms: {
        uTime: { value: this.scene.time }, 
        uCamera: { value: this.scene.camera.worldPosition },
        uGlowRotation: this.config.GlowRotation,
        uGlowRotationFactor: this.config.GlowRotationFactor,
        uColorLow: { value: new Color(this.config.LowColor.value) },
        uAlphaLow: this.config.LowAlpha,
        uColorRim: { value: new Color(this.config.RimColor.value) },
        uAlphaRim: this.config.RimAlpha,
      },
      transparent: true,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
    })

    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
    })

    this.mesh.scale.set(this.scale, this.scale, this.scale)
    this.mesh.lookAt(this.scene.camera.worldPosition)
    this.mesh.name = this.name

    this.meshWrapper = new Transform(this.gl)
    this.mesh.setParent(this.meshWrapper)
    this.meshWrapper.setParent(this.parent)
  }

  onLoaded() {
    this.initGui()

    setTimeout(() => {
      this.program.uniforms['uColorLow'].value = new Color(this.config.LowColor.value)
      this.program.uniforms['uColorRim'].value = new Color(this.config.RimColor.value)
    }, 1);
  }

  preRender() {
    this.program.uniforms["uTime"].value = this.scene.time

    this.mesh.lookAt(this.scene.camera.worldPosition)

    if (DebugController.active) {
      this.program.uniforms['uColorLow'].value = new Color(this.config.LowColor.value)
      this.program.uniforms['uColorRim'].value = new Color(this.config.RimColor.value)
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default ExperienceEarthGlowEntity
