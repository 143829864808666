import DebugController from '~/glxp/debug/debugController'
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

import { Vec3 } from '../ogl/math/Vec3'
import { Mesh } from '../ogl/core/Mesh'
import { Plane } from '../ogl/extras/Plane'
import { Program } from '../ogl/core/Program'

import Shader from '../utils/shader'
import shaderManifest from '../shaderManifest'

export default class Occluder {
  constructor(
    scene,
    { scaleFactor = 1, position = { x: 0, y: 0, z: 0 }, visible = false }
  ) {
    const { vert, frag } = new Shader(shaderManifest.debugUv)
    this.mesh = new Mesh(scene.gl, {
      geometry: new Plane(scene.gl, {

      }),
      program: new Program(scene.gl, {
        vertex: vert,
        fragment: frag,
      }),
    })

    this.config = {
      Scale: { value: scaleFactor, params: {} },
      Position: { value: new Vec3(position.x, position.y, position.z), params: {} },
      Visible: { value: visible, params: {} }
    }

    this.scene = scene

    this.init()
  }

  initGui = () => {
    const gui = DebugController.addBlade(this.config, `${this.scene.name} - Occluder`, GUI_PANEL_CUSTOM)
    gui && gui.params.Scale.on("change", (e) => {
      this.mesh.scale.set(e.value, e.value, e.value)
    })
    gui && gui.params.Position.on("change", (e) => {
      this.mesh.position.copy(e.value)
    })
    gui && gui.params.Visible.on("change", (e) => {
      this.mesh.visible = e.value
    })
  }

  init = () => {
    this.mesh.scale.set(this.config.Scale.value, this.config.Scale.value, this.config.Scale.value)
    this.mesh.position.copy(this.config.Position.value)
    this.mesh.visible = this.config.Visible.value
  }

  onLoaded = () => { }

  preRender = () => { }

  dispose = () => {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}
