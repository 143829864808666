export default {
    cleanConfig(entity){
        if (!entity.gui || entity.gui === {}) return
    
        // if (entity.defines["HAS_BASECOLORMAP"] == undefined && entity.gui.textureInputs['albedoMap']) {
        //     entity.gui.textureInputs['albedoMap'].hidden = true
        // }
        // if (entity.defines["HAS_ORM_MAP"] == undefined && entity.gui.textureInputs['ORMTexture']) {
        //     entity.gui.textureInputs['ORMTexture'].hidden = true
        // }
        // if (entity.defines["HAS_METALROUGHNESSMAP"] == undefined && entity.gui.textureInputs['metallicRoughnessTexture']) {
        //     entity.gui.textureInputs['metallicRoughnessTexture'].hidden = true
        // }
        // if (entity.defines["HAS_ALPHAMAP"] == undefined && entity.gui.textureInputs['alphaTexture']) {
        //     entity.gui.textureInputs['alphaTexture'].hidden = true
        // }
        // if (entity.defines["HAS_EMISSIVEMAP"] == undefined && entity.gui.textureInputs['emissiveTexture']) {
        //     entity.gui.textureInputs['emissiveTexture'].hidden = true
        // }
        // if (entity.defines["HAS_OCCLUSIONMAP"] == undefined && entity.gui.textureInputs['occlusionTexture']) {
        //     entity.gui.textureInputs['occlusionTexture'].hidden = true
        // }
    
        // if (entity.defines["HAS_NORMALMAP"] == undefined) {
        //     entity.gui.params["NormalScale"].hidden = true
        //     if (entity.gui.textureInputs['normalTexture']) {
        //         entity.gui.textureInputs['normalTexture'].hidden = true
        //     }
        // }
        if (entity.defines["HAS_OCCLUSIONMAP"] == undefined && entity.defines["HAS_ORM_MAP"] == undefined) {
            entity.gui.params["OcclusionFactor"].hidden = true
        }
        if (entity.defines["HAS_EMISSIVECOLOR"] == undefined) {
            entity.gui.params["EmissiveColor"].hidden = true
            entity.gui.params["EmissivePower"].hidden = true
        }
        if (entity.defines["HAS_SHEEN"] == undefined) {
            entity.gui.params["SheenColor"].hidden = true
            entity.gui.params["SheenOpacity"].hidden = true
            entity.gui.params["SheenDepth"].hidden = true
        }
    }
    
}