const out = {
    "PBR_CONFIG_HEADER":{
       "IBLDiffuseFactor":{
          "value":0.9,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLDiffuseFactor"
          }
       },
       "IBLSpecularFactor":{
          "value":1.4000000000000001,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLSpecularFactor"
          }
       },
       "Environment":{
          "value":"env_clouds",
          "params":{
             "options":{
                "env_clouds":"env_clouds"
             },
             "label":"Environment"
          }
       },
       "EnvironmentDiffuse":{
          "value":"env_clouds_blur",
          "params":{
             "options":{
                "env_clouds_blur":"env_clouds_blur"
             },
             "label":"EnvironmentDiffuse"
          }
       },
       "EnvRotationOffset":{
          "value":0.175,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"EnvRotationOffset"
          }
       },
       "lightColor":{
          "value":"#ffffff",
          "params":{
             "label":"lightColor"
          }
       },
       "lightPosition":{
          "value":{
             "x":0,
             "y":-100,
             "z":-10
          },
          "params":{
             "x":{
                "step":1
             },
             "y":{
                "step":1
             },
             "z":{
                "step":1
             },
             "label":"lightPosition"
          }
       },
       "emissiveColor":{
          "value":"#ffffff",
          "params":{
             "label":"emissiveColor"
          }
       },
       "FogColor":{
          "value":"#ffffff",
          "params":{
             "label":"FogColor"
          }
       },
       "FogNear":{
          "value":15,
          "params":{
             "min":0,
             "max":50,
             "step":0.01,
             "label":"FogNear"
          }
       },
       "FogFar":{
          "value":20,
          "params":{
             "min":0,
             "max":50,
             "step":0.01,
             "label":"FogFar"
          }
       },
       "lightPower":{
          "value":0.75,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"lightPower"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Debug":{
          "value":"disabled",
          "params":{
             "options":{
                "disabled":"disabled",
                "baseColor":"baseColor",
                "metallic":"metallic",
                "roughness":"roughness",
                "specRef":"specRef",
                "geomOcc":"geomOcc",
                "mcrfctDist":"mcrfctDist",
                "spec":"spec",
                "mathDiff":"mathDiff"
             },
             "label":"Debug"
          }
       },
       "OverwriteEnvIBL":{
          "value":false,
          "type":"bool"
       },
       "EnvIBL":{
          "value":{
             
          },
          "type":"input-image",
          "input":null
       }
    },
    "PBR_CONFIG_SPACEPORT":{
       "IBLDiffuseFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLDiffuseFactor"
          }
       },
       "IBLSpecularFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLSpecularFactor"
          }
       },
       "Environment":{
          "value":"env_spaceport",
          "params":{
             "options":{
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur"
             },
             "label":"Environment"
          }
       },
       "EnvironmentDiffuse":{
          "value":"env_spaceport_blur",
          "params":{
             "options":{
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur"
             },
             "label":"EnvironmentDiffuse"
          }
       },
       "EnvRotationOffset":{
          "value":0.75,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"EnvRotationOffset"
          }
       },
       "lightColor":{
          "value":"#ffffff",
          "params":{
             "label":"lightColor"
          }
       },
       "lightPosition":{
          "value":{
             "x":1,
             "y":50,
             "z":-1000
          },
          "params":{
             "x":{
                "step":1
             },
             "y":{
                "step":1
             },
             "z":{
                "step":1
             },
             "label":"lightPosition"
          }
       },
       "emissiveColor":{
          "value":"#ffffff",
          "params":{
             "label":"emissiveColor"
          }
       },
       "FogColor":{
          "value":"#4c4a82",
          "params":{
             "label":"FogColor"
          }
       },
       "FogNear":{
          "value":0,
          "params":{
             "min":0,
             "max":1000,
             "step":0.1,
             "label":"FogNear"
          }
       },
       "FogFar":{
          "value":550,
          "params":{
             "min":0,
             "max":1500,
             "step":1,
             "label":"FogFar"
          }
       },
       "lightPower":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"lightPower"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Debug":{
          "value":"disabled",
          "params":{
             "options":{
                "disabled":"disabled",
                "baseColor":"baseColor",
                "metallic":"metallic",
                "roughness":"roughness",
                "specRef":"specRef",
                "geomOcc":"geomOcc",
                "mcrfctDist":"mcrfctDist",
                "spec":"spec",
                "mathDiff":"mathDiff"
             },
             "label":"Debug"
          }
       },
       "OverwriteEnvIBL":{
          "value":false,
          "type":"bool"
       },
       "EnvIBL":{
          "value":{
             
          },
          "type":"input-image",
          "input":null
       }
    },
    "PBR_CONFIG_SPACEPORT_GLASS_SHIPS":{
       "IBLDiffuseFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLDiffuseFactor"
          }
       },
       "IBLSpecularFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLSpecularFactor"
          }
       },
       "Environment":{
          "value":"env_spaceport_glass_ships",
          "params":{
             "options":{
                "spaceportGlassShips":"env_spaceport_glass_ships",
                "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
                "spaceportGlassNoShips":"env_spaceport_glass_noships",
                "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur"
             },
             "label":"Environment"
          }
       },
       "EnvironmentDiffuse":{
          "value":"env_spaceport_glass_ships",
          "params":{
             "options":{
                "spaceportGlassShips":"env_spaceport_glass_ships",
                "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
                "spaceportGlassNoShips":"env_spaceport_glass_noships",
                "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur"
             },
             "label":"EnvironmentDiffuse"
          }
       },
       "EnvRotationOffset":{
          "value":0.75,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"EnvRotationOffset"
          }
       },
       "lightColor":{
          "value":"#ffffff",
          "params":{
             "label":"lightColor"
          }
       },
       "lightPosition":{
          "value":{
             "x":1,
             "y":50,
             "z":-1000
          },
          "params":{
             "x":{
                "step":1
             },
             "y":{
                "step":1
             },
             "z":{
                "step":1
             },
             "label":"lightPosition"
          }
       },
       "emissiveColor":{
          "value":"#ffffff",
          "params":{
             "label":"emissiveColor"
          }
       },
       "FogColor":{
          "value":"#4c4a82",
          "params":{
             "label":"FogColor"
          }
       },
       "FogNear":{
          "value":0,
          "params":{
             "min":0,
             "max":1000,
             "step":0.1,
             "label":"FogNear"
          }
       },
       "FogFar":{
          "value":450,
          "params":{
             "min":0,
             "max":1500,
             "step":1,
             "label":"FogFar"
          }
       },
       "lightPower":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"lightPower"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Debug":{
          "value":"disabled",
          "params":{
             "options":{
                "disabled":"disabled",
                "baseColor":"baseColor",
                "metallic":"metallic",
                "roughness":"roughness",
                "specRef":"specRef",
                "geomOcc":"geomOcc",
                "mcrfctDist":"mcrfctDist",
                "spec":"spec",
                "mathDiff":"mathDiff"
             },
             "label":"Debug"
          }
       },
       "OverwriteEnvIBL":{
          "value":false,
          "type":"bool"
       },
       "EnvIBL":{
          "value":{
             
          },
          "type":"input-image",
          "input":null
       }
    },
    "PBR_CONFIG_SPACEPORT_GLASS_NOSHIPS":{
       "IBLDiffuseFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLDiffuseFactor"
          }
       },
       "IBLSpecularFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLSpecularFactor"
          }
       },
       "Environment":{
          "value":"env_spaceport_glass_noships",
          "params":{
             "options":{
                "spaceportGlassShips":"env_spaceport_glass_ships",
                "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
                "spaceportGlassNoShips":"env_spaceport_glass_noships",
                "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur"
             },
             "label":"Environment"
          }
       },
       "EnvironmentDiffuse":{
          "value":"env_spaceport_glass_noships",
          "params":{
             "options":{
                "spaceportGlassShips":"env_spaceport_glass_ships",
                "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
                "spaceportGlassNoShips":"env_spaceport_glass_noships",
                "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur"
             },
             "label":"EnvironmentDiffuse"
          }
       },
       "EnvRotationOffset":{
          "value":0.75,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"EnvRotationOffset"
          }
       },
       "lightColor":{
          "value":"#ffffff",
          "params":{
             "label":"lightColor"
          }
       },
       "lightPosition":{
          "value":{
             "x":1,
             "y":50,
             "z":-1000
          },
          "params":{
             "x":{
                "step":1
             },
             "y":{
                "step":1
             },
             "z":{
                "step":1
             },
             "label":"lightPosition"
          }
       },
       "emissiveColor":{
          "value":"#000000",
          "params":{
             "label":"emissiveColor"
          }
       },
       "FogColor":{
          "value":"#4c4a82",
          "params":{
             "label":"FogColor"
          }
       },
       "FogNear":{
          "value":0,
          "params":{
             "min":0,
             "max":1000,
             "step":0.1,
             "label":"FogNear"
          }
       },
       "FogFar":{
          "value":450,
          "params":{
             "min":0,
             "max":1500,
             "step":1,
             "label":"FogFar"
          }
       },
       "lightPower":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"lightPower"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Debug":{
          "value":"disabled",
          "params":{
             "options":{
                "disabled":"disabled",
                "baseColor":"baseColor",
                "metallic":"metallic",
                "roughness":"roughness",
                "specRef":"specRef",
                "geomOcc":"geomOcc",
                "mcrfctDist":"mcrfctDist",
                "spec":"spec",
                "mathDiff":"mathDiff"
             },
             "label":"Debug"
          }
       },
       "OverwriteEnvIBL":{
          "value":false,
          "type":"bool"
       },
       "EnvIBL":{
          "value":{
             
          },
          "type":"input-image",
          "input":null
       }
    },
    "PBR_CONFIG_SPACECRAFT_FLEET_EVE":{
       "IBLDiffuseFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLDiffuseFactor"
          }
       },
       "IBLSpecularFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLSpecularFactor"
          }
       },
       "Environment":{
          "value":"env_spacecraft_fleet_eve",
          "params":{
             "options":{
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur",
                "spacecraft_fleet_eve":"env_spacecraft_fleet_eve",
                "spacecraft_fleet_eve_blur":"env_spacecraft_fleet_eve_blur"
             },
             "label":"Environment"
          }
       },
       "EnvironmentDiffuse":{
          "value":"env_spacecraft_fleet_eve_blur",
          "params":{
             "options":{
                "spaceport":"env_spaceport",
                "spaceport_blur":"env_spaceport_blur",
                "spacecraft_fleet_eve":"env_spacecraft_fleet_eve",
                "spacecraft_fleet_eve_blur":"env_spacecraft_fleet_eve_blur"
             },
             "label":"EnvironmentDiffuse"
          }
       },
       "EnvRotationOffset":{
          "value":0.75,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"EnvRotationOffset"
          }
       },
       "lightColor":{
          "value":"#ffffff",
          "params":{
             "label":"lightColor"
          }
       },
       "lightPosition":{
          "value":{
             "x":0,
             "y":-4,
             "z":0
          },
          "params":{
             "x":{
                "step":1
             },
             "y":{
                "step":1
             },
             "z":{
                "step":1
             },
             "label":"lightPosition"
          }
       },
       "emissiveColor":{
          "value":"#000000",
          "params":{
             "label":"emissiveColor"
          }
       },
       "FogColor":{
          "value":"#4c4a82",
          "params":{
             "label":"FogColor"
          }
       },
       "FogNear":{
          "value":0,
          "params":{
             "min":0,
             "max":1000,
             "step":0.1,
             "label":"FogNear"
          }
       },
       "FogFar":{
          "value":450,
          "params":{
             "min":0,
             "max":1500,
             "step":1,
             "label":"FogFar"
          }
       },
       "lightPower":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"lightPower"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Debug":{
          "value":"disabled",
          "params":{
             "options":{
                "disabled":"disabled",
                "baseColor":"baseColor",
                "metallic":"metallic",
                "roughness":"roughness",
                "specRef":"specRef",
                "geomOcc":"geomOcc",
                "mcrfctDist":"mcrfctDist",
                "spec":"spec",
                "mathDiff":"mathDiff"
             },
             "label":"Debug"
          }
       },
       "OverwriteEnvIBL":{
          "value":false,
          "type":"bool"
       },
       "EnvIBL":{
          "value":{
             
          },
          "type":"input-image",
          "input":null
       }
    },
    "PBR_CONFIG_SPACECRAFT_FLEET_UNITY":{
       "IBLDiffuseFactor":{
          "value":0.015,
          "params":{
             "min":0,
             "max":2,
             "step":0.001,
             "label":"IBLDiffuseFactor"
          }
       },
       "IBLSpecularFactor":{
          "value":2,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"IBLSpecularFactor"
          }
       },
       "Environment":{
          "value":"env_spacecraft_fleet_unity",
          "params":{
             "options":{
                "env_spacecraft_fleet_unity":"env_spacecraft_fleet_unity"
             },
             "label":"Environment"
          }
       },
       "EnvironmentDiffuse":{
          "value":"env_spacecraft_fleet_unity",
          "params":{
             "options":{
                "env_spacecraft_fleet_unity":"env_spacecraft_fleet_unity"
             },
             "label":"EnvironmentDiffuse"
          }
       },
       "EnvRotationOffset":{
          "value":0.175,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"EnvRotationOffset"
          }
       },
       "lightColor":{
          "value":"#5689cf",
          "params":{
             "label":"lightColor"
          }
       },
       "lightPosition":{
          "value":{
             "x":0,
             "y":-100,
             "z":0
          },
          "params":{
             "x":{
                "step":1
             },
             "y":{
                "step":1
             },
             "z":{
                "step":1
             },
             "label":"lightPosition"
          }
       },
       "emissiveColor":{
          "value":"#000000",
          "params":{
             "label":"emissiveColor"
          }
       },
       "FogColor":{
          "value":"#ffffff",
          "params":{
             "label":"FogColor"
          }
       },
       "FogNear":{
          "value":15,
          "params":{
             "min":0,
             "max":50,
             "step":0.01,
             "label":"FogNear"
          }
       },
       "FogFar":{
          "value":25,
          "params":{
             "min":0,
             "max":50,
             "step":0.01,
             "label":"FogFar"
          }
       },
       "lightPower":{
          "value":10,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"lightPower"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Debug":{
          "value":"disabled",
          "params":{
             "options":{
                "disabled":"disabled",
                "baseColor":"baseColor",
                "metallic":"metallic",
                "roughness":"roughness",
                "specRef":"specRef",
                "geomOcc":"geomOcc",
                "mcrfctDist":"mcrfctDist",
                "spec":"spec",
                "mathDiff":"mathDiff"
             },
             "label":"Debug"
          }
       },
       "OverwriteEnvIBL":{
          "value":false,
          "type":"bool"
       },
       "EnvIBL":{
          "value":{
             
          },
          "type":"input-image",
          "input":null
       }
    },
    "Global Settings - Spaceport America":{
       "Pause":{
          "value":false,
          "type":"bool"
       },
       "TimeScale":{
          "value":1,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"TimeScale"
          }
       },
       "ScrollDamping":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"ScrollDamping"
          }
       },
       "SunPosition":{
          "value":{
             "x":0,
             "y":29,
             "z":-225
          },
          "params":{
             "label":"SunPosition"
          }
       },
       "OverrideScroll":{
          "value":false,
          "params":{
             "label":"OverrideScroll"
          }
       },
       "Progress":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Progress"
          }
       },
       "DevScrollSpeed":{
          "value":30,
          "params":{
             "min":0,
             "max":60,
             "label":"DevScrollSpeed"
          }
       },
       "AnimCameraPosTargetDescendOffset":{
          "value":{
             "x":0,
             "y":11.77,
             "z":-0.5
          },
          "params":{
             "label":"AnimCameraPosTargetDescendOffset"
          }
       },
       "AnimCameraPosTargetPanOffset":{
          "value":{
             "x":-1.1,
             "y":-0.16,
             "z":-0.45
          },
          "params":{
             "label":"AnimCameraPosTargetPanOffset"
          }
       },
       "AnimCameraPosTargetParallaxOffset":{
          "value":{
             "x":0.235,
             "y":-0.225,
             "z":0.14
          },
          "params":{
             "label":"AnimCameraPosTargetParallaxOffset"
          }
       }
    },
    "Global Settings - Flight Experience":{
       "Pause":{
          "value":false,
          "type":"bool"
       },
       "TimeScale":{
          "value":1,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"TimeScale"
          }
       },
       "ScrollDamping":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"ScrollDamping"
          }
       },
       "SunPosition":{
          "value":{
             "x":-10.5,
             "y":0,
             "z":-10
          },
          "params":{
             "label":"SunPosition"
          }
       },
       "OverrideScroll":{
          "value":false,
          "params":{
             "label":"OverrideScroll"
          }
       },
       "Progress":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Progress"
          }
       },
       "DevScrollSpeed":{
          "value":30,
          "params":{
             "min":0,
             "max":60,
             "label":"DevScrollSpeed"
          }
       }
    },
    "Home Header - M_SpaceShip_Unity":{
       "albedoColor":{
          "value":"#ffffff",
          "params":{
             "label":"albedoColor"
          }
       },
       "IBL":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"IBL"
          }
       },
       "NormalScale":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"NormalScale"
          }
       },
       "MetalicFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"MetalicFactor"
          }
       },
       "RoughnessFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"RoughnessFactor"
          }
       },
       "EmissiveColor":{
          "value":"#ff2613",
          "params":{
             "label":"EmissiveColor"
          }
       },
       "EmissivePower":{
          "value":0,
          "params":{
             "min":0,
             "max":5,
             "step":0.01,
             "label":"EmissivePower"
          }
       },
       "OcclusionFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"OcclusionFactor"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":16,
             "step":0.1,
             "label":"SheenDepth"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":2,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       }
    },
    "Home Header - Mesh Earth":{
       "GroundResolution":{
          "value":{
             "x":10,
             "y":7
          },
          "params":{
             "x":{
                "min":0.1,
                "max":20,
                "step":0.1
             },
             "y":{
                "min":0.1,
                "max":20,
                "step":0.1
             },
             "label":"GroundResolution"
          }
       },
       "GroundSpeedFactor":{
          "value":-0.001,
          "params":{
             "min":-0.01,
             "max":0.01,
             "step":0.0001,
             "label":"GroundSpeedFactor"
          }
       },
       "CloudsResolution":{
          "value":{
             "x":1.75,
             "y":2
          },
          "params":{
             "x":{
                "min":0.1,
                "max":10,
                "step":0.1
             },
             "y":{
                "min":0.1,
                "max":10,
                "step":0.1
             },
             "label":"CloudsResolution"
          }
       },
       "CloudsDensityMin":{
          "value":0.15,
          "params":{
             "min":0.01,
             "max":1,
             "step":0.01,
             "label":"CloudsDensityMin"
          }
       },
       "CloudsDensityMax":{
          "value":0.85,
          "params":{
             "min":0.01,
             "max":1,
             "step":0.01,
             "label":"CloudsDensityMax"
          }
       },
       "CloudsColorVariance":{
          "value":0.4,
          "params":{
             "min":0.01,
             "max":1,
             "step":0.01,
             "label":"CloudsColorVariance"
          }
       },
       "CloudsSpeedFactor":{
          "value":-0.0005,
          "params":{
             "min":-0.01,
             "max":0.01,
             "step":0.0001,
             "label":"CloudsSpeedFactor"
          }
       },
       "CloudsShadowOffset":{
          "value":{
             "x":0.001,
             "y":0.004
          },
          "params":{
             "x":{
                "min":0.001,
                "max":0.1,
                "step":0.001
             },
             "y":{
                "min":0.001,
                "max":0.1,
                "step":0.001
             },
             "label":"CloudsShadowOffset"
          }
       },
       "CloudsShadowIntensity":{
          "value":0.5,
          "params":{
             "min":0.01,
             "max":1,
             "step":0.01,
             "label":"CloudsShadowIntensity"
          }
       },
       "FresnelColor":{
          "value":"#5e8cef",
          "params":{
             "label":"FresnelColor"
          }
       },
       "FresnelRimColor":{
          "value":"#a2daff",
          "params":{
             "label":"FresnelRimColor"
          }
       },
       "FresnelIntensity":{
          "value":1.05,
          "params":{
             "min":0,
             "max":1.4,
             "label":"FresnelIntensity"
          }
       }
    },
    "Outer Atmosphere":{
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0.5,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":-0.02,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":0.9600000000000001,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       },
       "GradientColor0":{
          "value":"#32dfff",
          "params":{
             "label":"GradientColor0"
          }
       },
       "GradientOpacity0":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"GradientOpacity0"
          }
       },
       "GradientColor1":{
          "value":"#a2daff",
          "params":{
             "label":"GradientColor1"
          }
       },
       "GradientOpacity1":{
          "value":0.98,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"GradientOpacity1"
          }
       },
       "UvScale":{
          "value":1.15,
          "params":{
             "min":0,
             "max":2,
             "label":"UvScale"
          }
       },
       "UvOffset":{
          "value":-0.1,
          "params":{
             "min":-1,
             "max":1,
             "label":"UvOffset"
          }
       }
    },
    "Inner Atmosphere":{
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":-0.7000000000000001,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       },
       "GradientColor0":{
          "value":"#104f9f",
          "params":{
             "label":"GradientColor0"
          }
       },
       "GradientOpacity0":{
          "value":0.09,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"GradientOpacity0"
          }
       },
       "GradientColor1":{
          "value":"#a2daff",
          "params":{
             "label":"GradientColor1"
          }
       },
       "GradientOpacity1":{
          "value":0.9500000000000001,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"GradientOpacity1"
          }
       },
       "UvScale":{
          "value":2,
          "params":{
             "min":0,
             "max":2,
             "label":"UvScale"
          }
       },
       "UvOffset":{
          "value":-0.01,
          "params":{
             "min":-1,
             "max":1,
             "label":"UvOffset"
          }
       }
    },
    "Home Header - Background":{
       "GradientEdges":{
          "value":{
             "x":0,
             "y":0.7794117647058822
          },
          "params":{
             "x":{
                "min":0,
                "max":1
             },
             "y":{
                "min":0,
                "max":1
             },
             "label":"GradientEdges"
          }
       },
       "Opacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "label":"Opacity"
          }
       },
       "GradientAngle":{
          "value":0.8878152173913043,
          "params":{
             "min":-3.1415,
             "max":3.1415,
             "label":"GradientAngle"
          }
       },
       "Color":{
          "value":"#23073f",
          "params":{
             "label":"Color"
          }
       },
       "GradientColor":{
          "value":"#150623",
          "params":{
             "label":"GradientColor"
          }
       }
    },
    "Bloom - Home Header":{
       "BloomTint":{
          "value":"#ffffff",
          "params":{
             "label":"BloomTint"
          }
       },
       "Threshold":{
          "value":0,
          "params":{
             "min":0,
             "max":1.25,
             "step":0.01,
             "label":"Threshold"
          }
       },
       "Blur":{
          "value":0.39999999999999997,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Blur"
          }
       },
       "Overdrive":{
          "value":0,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"Overdrive"
          }
       },
       "Similarity":{
          "value":0.09,
          "params":{
             "min":0,
             "max":0.2,
             "step":0.001,
             "label":"Similarity"
          }
       }
    },
    "Global Settings - Home Header":{
       "Pause":{
          "value":false,
          "type":"bool"
       },
       "TimeScale":{
          "value":1,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"TimeScale"
          }
       },
       "SunPosition":{
          "value":{
             "x":-13.5,
             "y":-1.3,
             "z":-50
          },
          "params":{
             "label":"SunPosition"
          }
       },
       "ScrollDamping":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"ScrollDamping"
          }
       },
       "OverrideScroll":{
          "value":false,
          "params":{
             "label":"OverrideScroll"
          }
       },
       "Progress":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Progress"
          }
       },
       "DevScrollSpeed":{
          "value":30,
          "params":{
             "min":0,
             "max":60,
             "label":"DevScrollSpeed"
          }
       }
    },
    "Home Spaceport - Flare - Glow_00":{
       "Opacity":{
          "value":1.3043478260869565,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":5.5,
             "y":1.2
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#f8af5e",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Home Spaceport - Flare - Glow_01":{
       "Opacity":{
          "value":0.9782608695652174,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":3.6,
             "y":1.5
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#c985e8",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Home Spaceport - Flare - Hexa_00":{
       "Opacity":{
          "value":1.3,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":0.039,
             "y":0.039
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#c562bc",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Home Spaceport - Flare - Hexa_01":{
       "Opacity":{
          "value":1,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":0.0925,
             "y":0.0925
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#c555c4",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Home Spaceport - Flare - Hexa_02":{
       "Opacity":{
          "value":1,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":0.092,
             "y":0.092
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#ff6161",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Home Spaceport - Flare - Arc_01":{
       "Opacity":{
          "value":3.260869565217391,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":0.78,
             "y":0.78
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#7c38af",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.48999999999999994,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Bloom - Home Spaceport":{
       "BloomTint":{
          "value":"#f9ffc0",
          "params":{
             "label":"BloomTint"
          }
       },
       "Threshold":{
          "value":0.7499999999999999,
          "params":{
             "min":0,
             "max":1.25,
             "step":0.01,
             "label":"Threshold"
          }
       },
       "Blur":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Blur"
          }
       },
       "Overdrive":{
          "value":0,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"Overdrive"
          }
       },
       "Similarity":{
          "value":0.1,
          "params":{
             "min":0,
             "max":0.2,
             "step":0.001,
             "label":"Similarity"
          }
       }
    },
    "Global Settings - Home Spaceport":{
       "ScrollDamping":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"ScrollDamping"
          }
       },
       "SunPosition":{
          "value":{
             "x":-1,
             "y":27,
             "z":-225
          },
          "params":{
             "label":"SunPosition"
          }
       },
       "OverrideScroll":{
          "value":false,
          "params":{
             "label":"OverrideScroll"
          }
       },
       "Progress":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Progress"
          }
       },
       "DevScrollSpeed":{
          "value":30,
          "params":{
             "min":0,
             "max":60,
             "label":"DevScrollSpeed"
          }
       }
    },
    "Post Processing - Spaceport America":{
       "BloomOpacity":{
          "value":0.25,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"BloomOpacity"
          }
       },
       "NoiseOpacity":{
          "value":0.025,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"NoiseOpacity"
          }
       },
       "Gamma":{
          "value":1.2,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Gamma"
          }
       },
       "Exposure":{
          "value":0.25,
          "params":{
             "min":-2,
             "max":2,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0.18,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Vignette":{
          "value":0.25,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Vignette"
          }
       },
       "ChromaticAberations":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"ChromaticAberations"
          }
       },
       "VignetteStrength":{
          "value":0.5,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"VignetteStrength"
          }
       },
       "FXAA":{
          "value":true,
          "params":{
             "label":"FXAA"
          }
       },
       "Bloom":{
          "value":true,
          "params":{
             "label":"Bloom"
          }
       },
       "DirtDistanceFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.1,
             "label":"DirtDistanceFactor"
          }
       },
       "DirtOpacityFactor":{
          "value":0.01301633047112499,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"DirtOpacityFactor"
          }
       },
       "DirtResolutionScale":{
          "value":{
             "x":1,
             "y":1
          },
          "params":{
             "x":{
                "min":0,
                "max":3,
                "step":0.01
             },
             "y":{
                "min":0,
                "max":3,
                "step":0.01
             },
             "label":"DirtResolutionScale"
          }
       },
       "DirtResolutionOffset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-3,
                "max":3,
                "step":0.01
             },
             "y":{
                "min":-3,
                "max":3,
                "step":0.01
             },
             "label":"DirtResolutionOffset"
          }
       }
    },
    "Spaceport America - M_Spaceport_02 ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#b75050",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Spaceport_01 ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#b75050",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Pavement_Sandroof ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Sandwings ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Pavement_L ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0.08,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Pavement_R ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0.08,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_01_R ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_01_L ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_02_R ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_02_L ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_03 ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_04 ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Terrain_05 ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.4,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_TerrainTowardsMountains ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.2,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Mountains ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#361212",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0.3,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0.02,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Fence ":{
       "TextureScale":{
          "value":100,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Props ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Skybox ":{
       "TextureScale":{
          "value":1,
          "params":{
             "min":0.1,
             "max":200,
             "step":0.1,
             "label":"TextureScale"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":10,
             "step":0.1,
             "label":"SheenDepth"
          }
       }
    },
    "Spaceport America - M_Glass_PBR":{
       "albedoColor":{
          "value":"#ffe0e0",
          "params":{
             "label":"albedoColor"
          }
       },
       "IBL":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "step":0.01,
             "label":"IBL"
          }
       },
       "NormalScale":{
          "value":0.25999999999999995,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"NormalScale"
          }
       },
       "MetalicFactor":{
          "value":0.8200000000000001,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"MetalicFactor"
          }
       },
       "RoughnessFactor":{
          "value":0,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"RoughnessFactor"
          }
       },
       "EmissiveColor":{
          "value":"#ff2613",
          "params":{
             "label":"EmissiveColor"
          }
       },
       "EmissivePower":{
          "value":0,
          "params":{
             "min":0,
             "max":5,
             "step":0.01,
             "label":"EmissivePower"
          }
       },
       "OcclusionFactor":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"OcclusionFactor"
          }
       },
       "SheenColor":{
          "value":"#ffffff",
          "params":{
             "label":"SheenColor"
          }
       },
       "SheenOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"SheenOpacity"
          }
       },
       "SheenDepth":{
          "value":2,
          "params":{
             "min":1,
             "max":16,
             "step":0.1,
             "label":"SheenDepth"
          }
       },
       "Alpha":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Alpha"
          }
       },
       "Tint":{
          "value":"#ffffff",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":0,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":2,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Contrast":{
          "value":0,
          "params":{
             "min":-1,
             "max":1,
             "step":0.01,
             "label":"Contrast"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       }
    },
    "Spaceport America - Night Sky":{
       "ColorTop":{
          "value":"#00000c",
          "params":{
             "label":"ColorTop"
          }
       },
       "ColorBottom":{
          "value":"#2a3e93",
          "params":{
             "label":"ColorBottom"
          }
       },
       "StarsRes":{
          "value":1.5,
          "params":{
             "min":0.1,
             "max":10,
             "step":0.01,
             "label":"StarsRes"
          }
       },
       "StarsMaskOffset":{
          "value":0.75,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"StarsMaskOffset"
          }
       },
       "StarsOpacity":{
          "value":0.8,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"StarsOpacity"
          }
       },
       "BlobsColor1":{
          "value":"#43125d",
          "params":{
             "label":"BlobsColor1"
          }
       },
       "BlobsColor2":{
          "value":"#1c1e7c",
          "params":{
             "label":"BlobsColor2"
          }
       },
       "BlobsOpacity":{
          "value":0.35,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"BlobsOpacity"
          }
       },
       "BlobsSpeed":{
          "value":0.05,
          "params":{
             "min":0,
             "max":1,
             "step":0.001,
             "label":"BlobsSpeed"
          }
       },
       "MaskLength":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"MaskLength"
          }
       },
       "DitheringFactor":{
          "value":0.1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"DitheringFactor"
          }
       }
    },
    "Spaceport America - Lens Flares":{
       "Spacing":{
          "value":0.083,
          "params":{
             "min":0,
             "max":0.3,
             "step":0.001,
             "label":"Spacing"
          }
       },
       "CenterFadePower":{
          "value":0.04,
          "params":{
             "min":-.3,
             "max":.3,
             "label":"CenterFadePower"
          }
       },
       "GlobalOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":10,
             "label":"GlobalOpacity"
          }
       }
    },
    "Spaceport America - Flare - Glow_00":{
       "Opacity":{
          "value":9.456521739130435,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":10.8,
             "y":1.2
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#f08e31",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Spaceport America - Flare - Glow_01":{
       "Opacity":{
          "value":3.39,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":3.6,
             "y":1.5
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#b01d43",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "RotationOffset":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"RotationOffset"
          }
       }
    },
    "Spaceport America - Flare - Circular_Partial_00":{
       "Opacity":{
          "value":4.565217391304348,
          "params":{
             "min":0,
             "max":30,
             "label":"Opacity"
          }
       },
       "Scale":{
          "value":{
             "x":1,
             "y":1
          },
          "params":{
             "min":0,
             "max":2,
             "label":"Scale"
          }
       },
       "Offset":{
          "value":{
             "x":0,
             "y":0
          },
          "params":{
             "x":{
                "min":-10,
                "max":10
             },
             "y":{
                "min":-10,
                "max":10
             },
             "label":"Offset"
          }
       },
       "Tint":{
          "value":"#d32599",
          "params":{
             "label":"Tint"
          }
       },
       "TintOpacity":{
          "value":1,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"TintOpacity"
          }
       },
       "Exposure":{
          "value":0,
          "params":{
             "min":-2,
             "max":3,
             "step":0.01,
             "label":"Exposure"
          }
       },
       "Saturation":{
          "value":1,
          "params":{
             "min":0,
             "max":2,
             "step":0.01,
             "label":"Saturation"
          }
       },
       "Rotation":{
          "value":0,
          "params":{
             "min":-3.141592653589793,
             "max":3.141592653589793,
             "label":"Rotation"
          }
       }
    },
    "Bloom - Spaceport America":{
       "BloomTint":{
          "value":"#ffffff",
          "params":{
             "label":"BloomTint"
          }
       },
       "Threshold":{
          "value":0.7,
          "params":{
             "min":0,
             "max":1.25,
             "step":0.01,
             "label":"Threshold"
          }
       },
       "Blur":{
          "value":0.7000000000000001,
          "params":{
             "min":0,
             "max":1,
             "step":0.01,
             "label":"Blur"
          }
       },
       "Overdrive":{
          "value":0.55,
          "params":{
             "min":0,
             "max":3,
             "step":0.01,
             "label":"Overdrive"
          }
       },
       "Similarity":{
          "value":0.07,
          "params":{
             "min":0,
             "max":0.2,
             "step":0.001,
             "label":"Similarity"
          }
       }
    },
    "Spaceport America - Occluder":{
       "Scale":{
          "value":0.575,
          "params":{
             "label":"Scale"
          }
       },
       "Position":{
          "value":[
             0,
             0,
             0
          ],
          "params":{
             "label":"Position"
          }
       },
       "Visible":{
          "value":false,
          "params":{
             "label":"Visible"
          }
       }
    }
 }
export default out