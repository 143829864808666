export function getGPUVersion(model) {
    var _a;
    model = model.replace(/\([^)]+\)/, '');
    const matches = 
    // First set of digits
    model.match(/\d+/) ||
        // If the renderer did not contain any numbers, match letters
        model.match(/(\W|^)([A-Za-z]{1,3})(\W|$)/g);
    // Remove any non-word characters and also remove 'amd' which could be matched
    // in the clause above
    return (_a = matches === null || matches === void 0 ? void 0 : matches.join('').replace(/\W|amd/g, '')) !== null && _a !== void 0 ? _a : '';
}
