import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'
import DebugController from '~/glxp/debug/debugController'
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import PBRUtils from '~/glxp/utils/PBRUtils'

import { vec3, mat4 } from 'gl-matrix'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Color } from '~/glxp/ogl/math/Color'

import Manifest from '~/glxp/manifest'
import GUIMaterialManifest from '~/glxp/guiMaterialManifest'

class MeshEntity {
  constructor(
    scene,
    {
      parent = null,
      id = 0,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      gltf = null,
      data = null, // for gltf: meshData - for collada: geometry
      node = null,
      name = '',
      materialName = '',
      material = undefined,
      transparent = false,
      depthTest = true,
      depthWrite = true,
      renderOrder = 0,
      globalConfig = null,
      fog = false,
      alpha = 1,
      forceRenderOrder = false,
      shaderId = 'standard',
      windowsTextureId = null
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.gltf = gltf
    this.data = data
    this.node = node
    this.name = name
    this.parent = parent ? parent : scene.root
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.videoTexture = null
    this.video = null
    this.fog = fog
    this.renderOrder = renderOrder
    this.forceRenderOrder = forceRenderOrder
    this.shaderId = shaderId
    this.groupId = id
    this.geom = {}
    this.materialName = materialName
    this.material = material
    this.globalConfig = globalConfig
    this.alpha = alpha
    this.defines = {}
    this.seed = Math.random()
    this.transform = new Transform()
    this.lastEnv = null
    this.lastEnvDiffuse = null
    this.windowsTextureId = windowsTextureId

    this.localState = { uniforms: {} }
    this.normalMatrix = mat4.create()
    this.MVPMatrix = mat4.create()

    this.maxTextureIndex = 0
    this.defines = {}
    this.defines['USE_IBL'] = 1
    this.defines['MANUAL_SRGB'] = 1


    if (node) {
      if (node.rotation) {
        this.transform.quaternion.x = node.rotation[0]
        this.transform.quaternion.y = node.rotation[1]
        this.transform.quaternion.z = node.rotation[2]
        this.transform.quaternion.w = node.rotation[3]
      }

      if (node.scale) {
        vec3.copy(this.transform.scale, node.scale)
      }

      if (node.translation) {
        vec3.copy(this.transform.position, node.translation)
      }
    }

    this.geom = this.gltf
      ? GLTFUtils.getGeometryDataFromGltf(this.data, this.defines, this.gltf)
      : this.data

    this.guiName = `${this.scene.name || '?'} - ${this.materialName}`

    this.initConfig()
    this.initTextures()
    PBRUtils.cleanConfig(this)
    this.createUniforms()
    this.init()
  }

  initConfig() {
    this.config = GUIMaterialManifest.createPBRMaterial(this.guiName)

    if (DebugController.guiIsReady) {
      this.initGUI()
    } else {
      DebugController.on('gui-lazyloaded', this.initGUI.bind(this))
    }
  }

  initGUI() {
    GUIMaterialManifest.addPBRMaterialToGUI(this.guiName, this.groupId)
    this.gui = GUIMaterialManifest.getGui(this.guiName)
    if(this.gui){
      // DebugController.addTextureUpload(this)
    }
  }

  init() {
    this.environment = this.globalConfig.Environment.value
    this.diffuseenvironment = this.globalConfig.EnvironmentDiffuse.value
    this.lightColor = new Color(this.globalConfig.lightColor.value)

    let attribs = {
      position: { size: 3, data: this.geom.vertices },
      index: { data: this.geom.indices },
    }

    if (this.geom.normals) {
      this.defines['HAS_NORMALS'] = 1
      attribs = Object.assign(attribs, {
        normal: { size: 3, data: this.geom.normals },
      })
    }
    if (this.geom.uvs) {
      this.defines['HAS_UV'] = 1
      attribs = Object.assign(attribs, {
        uv: { size: 2, data: this.geom.uvs },
      })
    }

    this.shader = new Shader(ShaderManifest[this.shaderId], 1, this.defines)
    this.geometry = new Geometry(this.gl, attribs)
    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      cullFace: this.gl.BACK,
      uniforms: this.localState.uniforms,
    })

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      transform: this.transform,
      frustumCulled: false,
      renderOrder: this.renderOrder,
      forceRenderOrder: this.forceRenderOrder
    })
    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  getImageInfo(uri, funcName, uniformName) {
    this.localState.uniforms[uniformName] = {
      type: funcName,
      uri: uri,
      textIndx: this.maxTextureIndex,
    }
    this.maxTextureIndex++
  }

  initTextures() {
    const gl = this.gl

    // material definition:
    // 1. default material values are taken from the default config defined in initConfig
    let pbrMat = null

    // 2. if material exists in manifest, take its values
    if (Manifest.materials[this.materialName]) {
      pbrMat = Manifest.materials[this.materialName]
    }

    // 3. if 'material' is defined in entity's parameters, take its values. Useful when colors are defined in gltf (meebits)
    if (this.material !== undefined && this.material.pbrMetallicRoughness) {
      pbrMat = this.material.pbrMetallicRoughness
      this.isGenericPBRMaterial = true
    }

    // 4. if a config is saved in config.js, it will override material values anyway

    // uniforms definition
    // IBL
    if (pbrMat && pbrMat.IBL) {
      this.config.IBL.value = pbrMat.IBL

      // this.config.IBLAlbedoMix.value = pbrMat.IBLAlbedoMix
      this.config.IBLAlbedoMix.value = pbrMat.IBLAlbedoMix ? pbrMat.IBLAlbedoMix : this.config.IBLAlbedoMix.value
      this.localState.uniforms['uIBLAlbedoMix'] = {
        type: 'float1',
        value: this.config.IBLAlbedoMix.value,
      }

      // this.config.IBLRoughnessImpact.value = pbrMat.IBLRoughnessImpact
      this.config.IBLRoughnessImpact.value = pbrMat.IBLRoughnessImpact ? pbrMat.IBLRoughnessImpact : this.config.IBLRoughnessImpact.value
      this.localState.uniforms['uIBLRoughnessImpact'] = {
        type: 'float1',
        value: this.config.IBLRoughnessImpact.value,
      }
    }

    // EnvMaps
    this.localState.uniforms['uDiffuseEnvSampler'] = {
      type: 'texture',
      uri: 'env_diffuse',
      textIndx: this.maxTextureIndex,
    }
    this.maxTextureIndex++

    this.localState.uniforms['uSpecularEnvSampler'] = {
      type: 'texture',
      uri: 'env_default',
      textIndx: this.maxTextureIndex,
    }
    this.maxTextureIndex++

    if (pbrMat && pbrMat.Alpha) {
      this.config.Alpha.value = pbrMat.Alpha
    }

    // uvScale
    this.localState.uniforms['uUvScale'] = {
      type: 'float1',
      value: pbrMat && pbrMat.UvScale ? pbrMat.UvScale : 1,
    }

    // Base Color
    var baseColorFactor =
      pbrMat && pbrMat.albedoColor
        ? new Color(pbrMat.albedoColor).getRGBA()
        : [1.0, 1.0, 1.0, 1.0]
    if (this.isGenericPBRMaterial && pbrMat.baseColorFactor) {
      baseColorFactor = pbrMat.baseColorFactor
    }
    this.config.albedoColor.value =
      pbrMat && pbrMat.albedoColor
        ? pbrMat.albedoColor
        : new Color(baseColorFactor).getHex()
    this.localState.uniforms['uBaseColorFactor'] = {
      type: 'float4',
      value: baseColorFactor,
    }
    if (pbrMat && pbrMat.albedoMap) {
      this.getImageInfo(pbrMat.albedoMap, 'texture', 'uBaseColorSampler')
      this.defines.HAS_BASECOLORMAP = 1
    } else if (this.localState.uniforms['uBaseColorSampler']) {
      delete this.localState.uniforms['uBaseColorSampler']
    }

    if (pbrMat && pbrMat.alphaTexture) {
      this.getImageInfo(pbrMat.alphaTexture, 'texture', 'uAlphaMapSampler')
      this.defines.HAS_ALPHAMAP = 1
    }

    // Metallic-Roughness
    this.config.MetalicFactor.value =
      pbrMat && pbrMat.MetalicFactor !== undefined
        ? pbrMat.MetalicFactor
        : this.config.MetalicFactor.value
    if (this.isGenericPBRMaterial && pbrMat.metallicFactor !== undefined) {
      this.config.MetalicFactor.value = pbrMat.metallicFactor
    }

    this.config.RoughnessFactor.value =
      pbrMat && pbrMat.RoughnessFactor !== undefined
        ? pbrMat.RoughnessFactor
        : this.config.RoughnessFactor.value
    if (this.isGenericPBRMaterial && pbrMat.roughnessFactor !== undefined) {
      this.config.RoughnessFactor.value = pbrMat.roughnessFactor
    }

    this.localState.uniforms['uMetallicRoughnessValues'] = {
      type: 'float2',
      value: [
        this.config.MetalicFactor.value,
        this.config.RoughnessFactor.value,
      ],
    }
    if (pbrMat && pbrMat.metallicRoughnessTexture) {
      this.getImageInfo(
        pbrMat.metallicRoughnessTexture,
        'texture',
        'uMetallicRoughnessSampler',
        gl.RGBA
      )
      this.defines.HAS_METALROUGHNESSMAP = 1
    } else if (this.localState.uniforms['uMetallicRoughnessSampler']) {
      delete this.localState.uniforms['uMetallicRoughnessSampler']
    }

    // ORM (AO - Roughness - Metallic)
    if (pbrMat && pbrMat.ORMTexture) {
      this.getImageInfo(
        pbrMat.ORMTexture,
        'texture',
        'uOcclusionRoughnessMetallicSampler',
        gl.RGBA
      )
      this.defines.HAS_ORM_MAP = 1
      this.config.OcclusionFactor.value =
        pbrMat && typeof pbrMat.OcclusionFactor === 'number'
          ? pbrMat.OcclusionFactor
          : this.config.OcclusionFactor.value
      this.localState.uniforms['uOcclusionStrength'] = {
        type: 'float1',
        value: this.config.OcclusionFactor.value,
      }
    } else if (this.localState.uniforms['uOcclusionRoughnessMetallicSampler']) {
      delete this.localState.uniforms['uOcclusionRoughnessMetallicSampler']
    }

    // Normals
    if (pbrMat && pbrMat.normalTexture) {
      this.getImageInfo(
        pbrMat.normalTexture,
        'texture',
        'uNormalSampler',
        gl.RGBA
      )
      this.config.NormalScale.value = pbrMat.NormalScale
        ? pbrMat.NormalScale
        : this.config.NormalScale.value
      this.localState.uniforms['uNormalScale'] = {
        type: 'float1',
        value: this.config.NormalScale.value,
      }
      this.defines.HAS_NORMALMAP = 1
    } else if (this.localState.uniforms['uNormalSampler']) {
      delete this.localState.uniforms['uNormalSampler']
    }

    // brdfLUT
    this.localState.uniforms['uBrdfLUT'] = {
      type: 'texture',
      uri: 'brdfLUT',
      textIndx: this.maxTextureIndex,
    }
    this.maxTextureIndex++

    // Emissive
    if (pbrMat && pbrMat.emissiveTexture) {
      this.getImageInfo(pbrMat.emissiveTexture, 'texture', 'uEmissiveSampler')
      this.defines.HAS_EMISSIVEMAP = 1

      var emissiveFactor =
        pbrMat.emissiveFactor !== null ? pbrMat.emissiveFactor : [0.0, 0.0, 0.0]

      this.globalConfig.emissiveColor.value = new Color(emissiveFactor).getHex()

      this.localState.uniforms['uEmissiveFactor'] = {
        type: 'float3',
        value: emissiveFactor,
      }
    } else if (this.localState.uniforms['uEmissiveSampler']) {
      delete this.localState.uniforms['uEmissiveSampler']
    }
    if (pbrMat && pbrMat.emissiveColor) {
      this.defines.HAS_EMISSIVECOLOR = 1
      var emissiveColor =
        pbrMat.emissiveColor !== null ? pbrMat.emissiveColor : [0.0, 0.0, 0.0]

      this.config.EmissiveColor.value = new Color(emissiveColor).getHex()
      this.config.EmissivePower.value =
        pbrMat.emissivePower !== null
          ? pbrMat.emissivePower
          : this.config.EmissivePower.value

      this.localState.uniforms['uEmissiveColor'] = {
        type: 'float3',
        value: this.config.EmissiveColor.value,
      }
      this.localState.uniforms['uEmissivePower'] = {
        type: 'float1',
        value: this.config.EmissivePower.value,
      }
    }

    // AO
    if (pbrMat && pbrMat.occlusionTexture) {
      this.getImageInfo(pbrMat.occlusionTexture, 'texture', 'uOcclusionSampler')
      this.config.OcclusionFactor.value =
        pbrMat.OcclusionFactor !== null
          ? pbrMat.OcclusionFactor
          : this.config.OcclusionFactor.value
      this.localState.uniforms['uOcclusionStrength'] = {
        type: 'float1',
        value: this.config.OcclusionFactor.value,
      }
      this.defines.HAS_OCCLUSIONMAP = 1
    } else if (this.localState.uniforms['uOcclusionSampler']) {
      delete this.localState.uniforms['uOcclusionSampler']
    }

    // Sheen
    if (pbrMat && pbrMat.sheenColor) {
      var sheenColor = pbrMat.sheenColor
        ? new Color(pbrMat.sheenColor)
        : [1.0, 1.0, 1.0]
      var sheenOpacity = pbrMat.sheenOpacity !== null ? pbrMat.sheenOpacity : 1
      var sheenDepth = pbrMat.sheenDepth !== null ? pbrMat.sheenDepth : 2
      this.localState.uniforms['uSheenColor'] = {
        type: 'float3',
        value: sheenColor,
      }
      this.localState.uniforms['uSheenOpacity'] = {
        type: 'float1',
        value: sheenOpacity,
      }
      this.localState.uniforms['uSheenDepth'] = {
        type: 'float1',
        value: sheenDepth,
      }
      this.config.SheenColor.value = pbrMat.sheenColor
      this.config.SheenOpacity.value = sheenOpacity
      this.config.SheenDepth.value = sheenDepth
      this.defines.HAS_SHEEN = 1
    }

    // Fog
    if (this.globalConfig.FogColor && this.fog) {
      this.defines['HAS_FOG'] = 1
      this.localState.uniforms['uFogColor'] = {
        type: 'float3',
        value: new Color(this.globalConfig.FogColor.value),
      }
      this.localState.uniforms['uFogNear'] = {
        type: 'float1',
        value: this.globalConfig.FogNear.value,
      }
      this.localState.uniforms['uFogFar'] = {
        type: 'float1',
        value: this.globalConfig.FogFar.value,
      }
    }
  }

  createUniforms() {
    // General
    this.localState.uniforms['uTime'] = { type: 'float1', value: 0 }
    this.localState.uniforms['uAlpha'] = { type: 'float1', value: 1 }

    // Light
    this.localState.uniforms['uLightDirection'] = {
      type: 'float3',
      value: [0.0, 0.5, 0.5],
    }
    this.localState.uniforms['uLightColor'] = {
      type: 'float3',
      value: [1.0, 1.0, 1.0],
    }

    // get scaling stuff
    this.localState.uniforms['uScaleDiffBaseMR'] = {
      type: 'float4',
      value: [0.0, 0.0, 0.0, 0.0],
    }
    this.localState.uniforms['uScaleFGDSpec'] = {
      type: 'float4',
      value: [0.0, 0.0, 0.0, 0.0],
    }
    this.localState.uniforms['uScaleIBLAmbient'] = {
      type: 'float4',
      value: [1.5, 1.5, 0, 0],
    }
    this.localState.uniforms['uEnvOffset'] = { type: 'float4', value: 0 }

    this.localState.uniforms['uCamera'] = { type: 'float3', value: [0, 0, 0] }

    // Color Correction
    this.defines['HAS_COLOR_CORRECTION'] = 1
    this.localState.uniforms['uTint'] = { type: 'float3', value: [1, 1, 1] }
    this.localState.uniforms['uTintOpacity'] = { type: 'float1', value: 0 }
    this.localState.uniforms['uExposure'] = { type: 'float1', value: 0 }
    this.localState.uniforms['uContrast'] = { type: 'float1', value: 0 }
    this.localState.uniforms['uSaturation'] = { type: 'float1', value: 0 }

    for (const key in this.localState.uniforms) {
      if (Object.hasOwnProperty.call(this.localState.uniforms, key)) {
        if (this.localState.uniforms[key].type == 'texture') {
          this.localState.uniforms[key].value = new Texture(this.gl)
        }
      }
    }
  }

  onLoaded() {
    for (const key in this.localState.uniforms) {
      if (Object.hasOwnProperty.call(this.localState.uniforms, key)) {
        if (this.localState.uniforms[key].type == 'texture') {
          this.localState.uniforms[key].value =
            this.scene.textureLoader.getTexture(
              this.localState.uniforms[key].uri
            )
          this.localState.uniforms[key].value.needsUpdate = true
        }
      }
    }

    PBRUtils.cleanConfig(this)
  }

  // Render
  getFirstDrawPromise() {
    this.didFirstDraw = false
    return new Promise((resolve) => {
      this.firstDrawResolve = resolve
    })
  }

  firstDraw() {
    if (this.didFirstDraw) return

    // this.gl.finish()

    const storedVisibility = this.mesh.visible
    this.mesh.visible = true

    this.scene.renderer.render({
      scene: this.mesh,
      camera: this.scene.camera,
      clear: false,
      frustumCull: true,
      sort: false,
    })

    this.mesh.visible = storedVisibility

    // this.gl.finish()

    if (!this.didFirstDraw) {
      this.didFirstDraw = true
      requestAnimationFrame(() => {
        this.firstDrawResolve(this)
      })
    }
  }

  // TODO: remove new Color() on each frame by using this.gui.on()
  preRender() {
    this.program.uniforms.uTime.value = this.scene.time
    this.program.uniforms.needsUpdate = true

    this.program.uniforms['uLightDirection'].value = [
      this.globalConfig.lightPosition.value.x * this.scene.root.scale.x -
        this.mesh.worldMatrix[12],
      this.globalConfig.lightPosition.value.y * this.scene.root.scale.y -
        this.mesh.worldMatrix[13],
      this.globalConfig.lightPosition.value.z * this.scene.root.scale.z -
        this.mesh.worldMatrix[14],
    ]
    // this.program.uniforms['uLightDirection'].value = [
    //     CONFIG.lightPosition.value.x - this.mesh.worldMatrix[12],
    //     CONFIG.lightPosition.value.y - this.mesh.worldMatrix[13],
    //     CONFIG.lightPosition.value.z - this.mesh.worldMatrix[14]
    // ]

    this.lightColor.set(this.globalConfig.lightColor.value)
    this.localState.uniforms['uLightColor'].value = [
      this.lightColor[0] * this.globalConfig.lightPower.value,
      this.lightColor[1] * this.globalConfig.lightPower.value,
      this.lightColor[2] * this.globalConfig.lightPower.value,
    ]
    this.localState.uniforms['uScaleIBLAmbient'].value = [
      this.config.IBL.value * this.globalConfig.IBLDiffuseFactor.value,
      this.config.IBL.value * this.globalConfig.IBLSpecularFactor.value,
      0.0,
      0.0,
    ]

    const debugState = this.globalConfig.Debug.value
    this.localState.uniforms['uScaleDiffBaseMR'].value = [
      debugState == 'mathDiff' ? 1 : 0,
      debugState == 'baseColor' ? 1 : 0,
      debugState == 'metallic' ? 1 : 0,
      debugState == 'roughness' ? 1 : 0,
    ]
    this.localState.uniforms['uScaleFGDSpec'].value = [
      debugState == 'specRef' ? 1 : 0,
      debugState == 'geomOcc' ? 1 : 0,
      debugState == 'mcrfctDist' ? 1 : 0,
      debugState == 'spec' ? 1 : 0,
    ]
    this.localState.uniforms['uEnvOffset'].value =
      this.globalConfig.EnvRotationOffset.value


    this.localState.uniforms['uIBLAlbedoMix'].value = this.config.IBLAlbedoMix.value
    this.localState.uniforms['uIBLRoughnessImpact'].value = this.config.IBLRoughnessImpact.value

    this.localState.uniforms['uMetallicRoughnessValues'].value = [
      this.config.MetalicFactor.value,
      this.config.RoughnessFactor.value,
    ]
    if (this.localState.uniforms['uNormalScale']) {
      this.localState.uniforms['uNormalScale'].value =
        this.config.NormalScale.value
    }
    if (this.localState.uniforms['uOcclusionStrength']) {
      this.localState.uniforms['uOcclusionStrength'].value =
        this.config.OcclusionFactor.value
    }

    const bc = new Color(this.config.albedoColor.value)
    this.localState.uniforms['uBaseColorFactor'].value = [
      bc[0],
      bc[1],
      bc[2],
      this.config.Alpha.value * this.globalConfig.Alpha.value,
    ]

    if (this.localState.uniforms['uEmissiveFactor']) {
      const ec = new Color(this.globalConfig.emissiveColor.value)
      this.localState.uniforms['uEmissiveFactor'].value = [ec[0], ec[1], ec[2]]
    }

    if (this.localState.uniforms['uEmissiveColor']) {
      this.localState.uniforms['uEmissiveColor'].value = new Color(
        this.config.EmissiveColor.value
      )
    }
    if (this.localState.uniforms['uEmissivePower']) {
      this.localState.uniforms['uEmissivePower'].value =
        this.config.EmissivePower.value
    }

    if (this.localState.uniforms['uSheenColor']) {
      this.localState.uniforms['uSheenOpacity'].value =
        this.config.SheenOpacity.value
      this.localState.uniforms['uSheenDepth'].value =
        this.config.SheenDepth.value
      this.localState.uniforms['uSheenColor'].value = new Color(
        this.config.SheenColor.value
      )
    }

    // Fog
    if (this.globalConfig.FogColor && this.fog) {
      this.localState.uniforms['uFogNear'].value =
        this.globalConfig.FogNear.value
      this.localState.uniforms['uFogFar'].value = this.globalConfig.FogFar.value
      this.localState.uniforms['uFogColor'].value = new Color(
        this.globalConfig.FogColor.value
      )
    }

    this.localState.uniforms['uCamera'].value = this.scene.camera.position

    this.environment = this.globalConfig.Environment.value
    if (this.environment !== this.lastEnv) {
      this.lastEnv = this.environment
      this.localState.uniforms['uSpecularEnvSampler'].value =
        this.scene.textureLoader.getTexture(this.environment)
      this.localState.uniforms['uSpecularEnvSampler'].value.needsUpdate = true
    }
    this.diffuseenvironment = this.globalConfig.EnvironmentDiffuse.value
    if (this.diffuseenvironment !== this.lastEnvDiffuse) {
      this.lastEnvDiffuse = this.diffuseenvironment
      this.localState.uniforms['uDiffuseEnvSampler'].value =
        this.scene.textureLoader.getTexture(this.diffuseenvironment)
      this.localState.uniforms['uDiffuseEnvSampler'].value.needsUpdate = true
    }

    // Color Correction
    this.localState.uniforms['uTint'].value = new Color(this.config.Tint.value)
    this.localState.uniforms['uTintOpacity'].value =
      this.config.TintOpacity.value
    this.localState.uniforms['uExposure'].value = this.config.Exposure.value
    this.localState.uniforms['uContrast'].value = this.config.Contrast.value
    this.localState.uniforms['uSaturation'].value = this.config.Saturation.value
  }

  dispose() {
    this.mesh.setParent(null)
    this.meshTransparent.setParent(null)

    this.geometry.remove()
    this.program.remove()
    this.transparentProgram.remove()
  }
}

export default MeshEntity
