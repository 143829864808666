import {load} from '@loaders.gl/core'
import {GLBLoader, GLTFLoader} from '@loaders.gl/gltf'
import {DracoLoader} from '@loaders.gl/draco';

const _appendBuffer = function(buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
}

class Loader {
    constructor(url, hasDracoCompression = true ){
        this.url = url
        this.hasDracoCompression = hasDracoCompression
        this.promise = new Promise((resolve, reject)=>{
            this.resolve = resolve
        })
        this.load()
        return this.promise
    }

    async load(){
        if (this.hasDracoCompression) {
            const gltf = await load(this.url, GLTFLoader, {DracoLoader})
            gltf.isDracoCompressed = true;
            this.resolve(gltf)
        } else {
            const gltf = await load(this.url, GLBLoader)
            gltf.isDracoCompressed = false;

            let buff = new ArrayBuffer()
            for (let i = 0; i < gltf.binChunks.length; i++) {
                const chunk = gltf.binChunks[i]
                buff = _appendBuffer(buff, chunk.arrayBuffer.slice(chunk.byteOffset, chunk.byteOffset + chunk.byteLength))
            }

            gltf.json.bin = buff
            gltf.json.isDracoCompressed = gltf.isDracoCompressed
            this.resolve(gltf.json)
        }
        

    }
}

export default Loader