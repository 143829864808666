const out = {
   "PBR_CONFIG_SPACECRAFT_FLEET_EVE": {
      "IBLDiffuseFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "IBLSpecularFactor"
         }
      },
      "Environment": {
         "value": "env_spacecraft_fleet_eve",
         "params": {
            "options": {
               "spaceport": "env_spaceport",
               "spaceport_blur": "env_spaceport_blur",
               "spacecraft_fleet_eve": "env_spacecraft_fleet_eve",
               "spacecraft_fleet_eve_blur": "env_spacecraft_fleet_eve_blur"
            },
            "label": "Environment"
         }
      },
      "EnvironmentDiffuse": {
         "value": "env_spacecraft_fleet_eve_blur",
         "params": {
            "options": {
               "spaceport": "env_spaceport",
               "spaceport_blur": "env_spaceport_blur",
               "spacecraft_fleet_eve": "env_spacecraft_fleet_eve",
               "spacecraft_fleet_eve_blur": "env_spacecraft_fleet_eve_blur"
            },
            "label": "EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset": {
         "value": 0.75,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.001,
            "label": "EnvRotationOffset"
         }
      },
      "lightColor": {
         "value": "#ffffff",
         "params": {
            "label": "lightColor"
         }
      },
      "lightPosition": {
         "value": {
            "x": 0,
            "y": -4,
            "z": 0
         },
         "params": {
            "x": {
               "step": 1
            },
            "y": {
               "step": 1
            },
            "z": {
               "step": 1
            },
            "label": "lightPosition"
         }
      },
      "emissiveColor": {
         "value": "#ffffff",
         "params": {
            "label": "emissiveColor"
         }
      },
      "FogColor": {
         "value": "#4c4a82",
         "params": {
            "label": "FogColor"
         }
      },
      "FogNear": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 1000,
            "step": 0.1,
            "label": "FogNear"
         }
      },
      "FogFar": {
         "value": 450,
         "params": {
            "min": 0,
            "max": 1500,
            "step": 1,
            "label": "FogFar"
         }
      },
      "lightPower": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "lightPower"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Debug": {
         "value": "disabled",
         "params": {
            "options": {
               "disabled": "disabled",
               "baseColor": "baseColor",
               "metallic": "metallic",
               "roughness": "roughness",
               "specRef": "specRef",
               "geomOcc": "geomOcc",
               "mcrfctDist": "mcrfctDist",
               "spec": "spec",
               "mathDiff": "mathDiff"
            },
            "label": "Debug"
         }
      },
      "OverwriteEnvIBL": {
         "value": false,
         "type": "bool"
      },
      "EnvIBL": {
         "value": {

         },
         "type": "input-image",
         "input": null
      }
   },
   "PBR_CONFIG_SPACECRAFT_FLEET_UNITY": {
      "IBLDiffuseFactor": {
         "value": 0.43,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor": {
         "value": 1.0445847299813784,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "IBLSpecularFactor"
         }
      },
      "Environment": {
         "value": 'env_clouds_bottom_blur_x1',
         "params": { "options": {                
           "env_spacecraft_fleet_unity": "env_spacecraft_fleet_unity",
           "env_spacecraft_fleet_unity2": "env_spacecraft_fleet_unity2",
           "env_spacecraft_fleet_unity3": "env_spacecraft_fleet_unity3",
           "env_spacecraft_fleet_unity4": "env_spacecraft_fleet_unity4",
           "env_clouds_blur": "env_clouds_blur",
           "env_clouds": "env_clouds",
           "env_clouds_flipped": "env_clouds_flipped",
           "env_clouds_bottom_blur_x1": "env_clouds_bottom_blur_x1_flip",
           }
         },
         "label": "Environment"
       },
       "EnvironmentDiffuse": {
         "value": 'env_clouds_blur',
         "params": { "options": {                
           "env_spacecraft_fleet_unity": "env_spacecraft_fleet_unity",
           "env_spacecraft_fleet_unity2": "env_spacecraft_fleet_unity2",
           "env_spacecraft_fleet_unity3": "env_spacecraft_fleet_unity3",
           "env_spacecraft_fleet_unity4": "env_spacecraft_fleet_unity4",
           "env_clouds_blur": "env_clouds_blur",
           "env_clouds": "env_clouds",
           "env_clouds_flipped": "env_clouds_flipped",
           "env_clouds_bottom_blur_x1": "env_clouds_bottom_blur_x1",
           }
         },
         "label": "EnvironmentDiffuse"
       },
      "EnvRotationOffset": {
         "value": 0.175,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.001,
            "label": "EnvRotationOffset"
         }
      },
      "lightColor": {
         "value": "#5689cf",
         "params": {
            "label": "lightColor"
         }
      },
      "lightPosition": {
         "value": {
            "x": -1206.83,
            "y": -515,
            "z": -2300
         },
         "params": {
            "x": {
               "step": 1
            },
            "y": {
               "step": 1
            },
            "z": {
               "step": 1
            },
            "label": "lightPosition"
         }
      },
      "lightPower": {
         "value": 3.3897235754189996,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "lightPower"
         }
      },
      "emissiveColor": {
         "value": "#ffffff",
         "params": {
            "label": "emissiveColor"
         }
      },
      "FogColor": {
         "value": "#ffffff",
         "params": {
            "label": "FogColor"
         }
      },
      "FogNear": {
         "value": 15,
         "params": {
            "min": 0,
            "max": 50,
            "step": 0.01,
            "label": "FogNear"
         }
      },
      "FogFar": {
         "value": 25,
         "params": {
            "min": 0,
            "max": 50,
            "step": 0.01,
            "label": "FogFar"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Debug": {
         "value": "disabled",
         "params": {
            "options": {
               "disabled": "disabled",
               "baseColor": "baseColor",
               "metallic": "metallic",
               "roughness": "roughness",
               "specRef": "specRef",
               "geomOcc": "geomOcc",
               "mcrfctDist": "mcrfctDist",
               "spec": "spec",
               "mathDiff": "mathDiff"
            },
            "label": "Debug"
         }
      },
      "OverwriteEnvIBL": {
         "value": false,
         "type": "bool"
      },
      "EnvIBL": {
         "value": {

         },
         "type": "input-image",
         "input": null
      }
   },
   "Post Processing - Spacecraft Fleet - Eve": {
      "BloomOpacity": {
         "value": 0.25,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "BloomOpacity"
         }
      },
      "NoiseOpacity": {
         "value": 0.007,
         "params": {
            "min": 0,
            "max": .08,
            "step": 0.001,
            "label": "NoiseOpacity"
         }
      },
      "Gamma": {
         "value": 0.9500000000000002,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Gamma"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0.2,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Vignette": {
         "value": 0.08,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Vignette"
         }
      },
      "ChromaticAberations": {
         "value": 0.05,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "ChromaticAberations"
         }
      },
      "VignetteStrength": {
         "value": 0.5,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "VignetteStrength"
         }
      },
      "FXAA": {
         "value": true,
         "params": {
            "label": "FXAA"
         }
      },
      "Bloom": {
         "value": true,
         "params": {
            "label": "Bloom"
         }
      },
      "NoiseScale": {
         "value": 0.0001,
         "params": {
            "min": 0,
            "max": 0.0003,
            "label": "NoiseScale"
         }
      }
   },
   "Post Processing - Spacecraft Fleet - Unity": {
      "BloomOpacity": {
         "value": 0.7100000000000001,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "BloomOpacity"
         }
      },
      "NoiseOpacity": {
         "value": 0.02,
         "params": {
            "min": 0,
            "max": 0.04,
            "label": "NoiseOpacity"
         },
         "previousValue": 0.05
      },
      "Gamma": {
         "value": 1.1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Gamma"
         }
      },
      "Exposure": {
         "value": 0.15,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0.2,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Vignette": {
         "value": 0.6,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Vignette"
         }
      },
      "ChromaticAberations": {
         "value": 0.05,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "ChromaticAberations"
         }
      },
      "VignetteStrength": {
         "value": 0.15,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "VignetteStrength"
         }
      },
      "FXAA": {
         "value": true,
         "params": {
            "label": "FXAA"
         }
      },
      "Bloom": {
         "value": true,
         "params": {
            "label": "Bloom"
         }
      },
      "NoiseScale": {
         "value": 0.0001,
         "params": {
            "min": 0,
            "max": 0.003,
            "label": "NoiseScale"
         }
      },
      "DirtDistanceFactor": {
         "value": 2.1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.1,
            "label": "DirtDistanceFactor"
         }
      },
      "DirtOpacityFactor": {
         "value": 0.9801226974385465,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "DirtOpacityFactor"
         },
         "previousValue": 0.9
      },
      "DirtResolutionScale": {
         "value": {
            "x": 1,
            "y": 1
         },
         "params": {
            "x": {
               "min": 0,
               "max": 3,
               "step": 0.01
            },
            "y": {
               "min": 0,
               "max": 3,
               "step": 0.01
            },
            "label": "DirtResolutionScale"
         }
      },
      "DirtResolutionOffset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -3,
               "max": 3,
               "step": 0.01
            },
            "y": {
               "min": -3,
               "max": 3,
               "step": 0.01
            },
            "label": "DirtResolutionOffset"
         }
      }
   },
   "Spacecraft Fleet - Unity - M_SpaceShip_Decal_Unity": {
      "albedoColor": {
         "value": "#ffffff",
         "params": {
            "label": "albedoColor"
         }
      },
      "IBL": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "IBL"
         }
      },
      "IBLAlbedoMix": {
        "value": 0.7,
        "params": {
          "min": 0,
          "max": 1,
          "step": 0.01,
          "label": "IBLAlbedoMix"
        }
      },
      "IBLRoughnessImpact": {
        "value": 0.5,
        "params": {
          "min": 0,
          "max": 1,
          "step": 0.01,
          "label": "IBLRoughnessImpact"
        }
      },
      "NormalScale": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "NormalScale"
         }
      },
      "MetalicFactor": {
         "value": 3,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "MetalicFactor"
         }
      },
      "RoughnessFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "RoughnessFactor"
         }
      },
      "EmissiveColor": {
         "value": "#ff2613",
         "params": {
            "label": "EmissiveColor"
         }
      },
      "EmissivePower": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 5,
            "step": 0.01,
            "label": "EmissivePower"
         }
      },
      "OcclusionFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "OcclusionFactor"
         }
      },
      "SheenColor": {
         "value": "#ffffff",
         "params": {
            "label": "SheenColor"
         }
      },
      "SheenOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "SheenOpacity"
         }
      },
      "SheenDepth": {
         "value": 2,
         "params": {
            "min": 1,
            "max": 16,
            "step": 0.1,
            "label": "SheenDepth"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Tint": {
         "value": "#06050f",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0.03,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0.9,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      }
   },
   "Spacecraft Fleet - Unity - M_Spaceship_Unity_Text": {
      "albedoColor": {
         "value": "#ffffff",
         "params": {
            "label": "albedoColor"
         }
      },
      "IBL": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "IBL"
         }
      },
      "NormalScale": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "NormalScale"
         }
      },
      "MetalicFactor": {
         "value": 2.4499999999999997,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "MetalicFactor"
         }
      },
      "RoughnessFactor": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "RoughnessFactor"
         }
      },
      "EmissiveColor": {
         "value": "#ff2613",
         "params": {
            "label": "EmissiveColor"
         }
      },
      "EmissivePower": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 5,
            "step": 0.01,
            "label": "EmissivePower"
         }
      },
      "OcclusionFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "OcclusionFactor"
         }
      },
      "SheenColor": {
         "value": "#ffffff",
         "params": {
            "label": "SheenColor"
         }
      },
      "SheenOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "SheenOpacity"
         }
      },
      "SheenDepth": {
         "value": 2,
         "params": {
            "min": 1,
            "max": 16,
            "step": 0.1,
            "label": "SheenDepth"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Tint": {
         "value": "#ffffff",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      }
   },
   "Spacecraft Fleet - Unity - M_Spaceship_Unity_Windows": {
      "albedoColor": {
         "value": "#ffffff",
         "params": {
            "label": "albedoColor"
         }
      },
      "IBL": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "IBL"
         }
      },
      "NormalScale": {
         "value": 3,
         "params": {
            "min": 0,
            "max": 5,
            "step": 0.01,
            "label": "NormalScale"
         }
      },
      "MetalicFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "MetalicFactor"
         }
      },
      "RoughnessFactor": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "RoughnessFactor"
         }
      },
      "EmissiveColor": {
         "value": "#ff2613",
         "params": {
            "label": "EmissiveColor"
         }
      },
      "EmissivePower": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 5,
            "step": 0.01,
            "label": "EmissivePower"
         }
      },
      "OcclusionFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "OcclusionFactor"
         }
      },
      "SheenColor": {
         "value": "#ffffff",
         "params": {
            "label": "SheenColor"
         }
      },
      "SheenOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "SheenOpacity"
         }
      },
      "SheenDepth": {
         "value": 1.3,
         "params": {
            "min": 1,
            "max": 16,
            "step": 0.1,
            "label": "SheenDepth"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Tint": {
         "value": "#ffffff",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      }
   },
   "Spacecraft Fleet - Eve - M_SpaceShip_Unity_Sheen": {
      "albedoColor": {
         "value": "#ffffff",
         "params": {
            "label": "albedoColor"
         }
      },
      "IBL": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "IBL"
         }
      },
      "NormalScale": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "NormalScale"
         }
      },
      "MetalicFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "MetalicFactor"
         }
      },
      "RoughnessFactor": {
         "value": 0.5,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "RoughnessFactor"
         }
      },
      "EmissiveColor": {
         "value": "#ff2613",
         "params": {
            "label": "EmissiveColor"
         }
      },
      "EmissivePower": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 5,
            "step": 0.01,
            "label": "EmissivePower"
         }
      },
      "OcclusionFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "OcclusionFactor"
         }
      },
      "SheenColor": {
         "value": "#ffffff",
         "params": {
            "label": "SheenColor"
         }
      },
      "SheenOpacity": {
         "value": 0.7000000000000001,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "SheenOpacity"
         }
      },
      "SheenDepth": {
         "value": 10,
         "params": {
            "min": 1,
            "max": 16,
            "step": 0.1,
            "label": "SheenDepth"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Tint": {
         "value": "#ffffff",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      }
   },
   "Spacecraft Fleet - Eve - M_SpaceShip_Eve_Sheen": {
      "albedoColor": {
         "value": "#ffffff",
         "params": {
            "label": "albedoColor"
         }
      },
      "IBL": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "IBL"
         }
      },
      "NormalScale": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "NormalScale"
         }
      },
      "MetalicFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "MetalicFactor"
         }
      },
      "RoughnessFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "RoughnessFactor"
         }
      },
      "EmissiveColor": {
         "value": "#ff2613",
         "params": {
            "label": "EmissiveColor"
         }
      },
      "EmissivePower": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 5,
            "step": 0.01,
            "label": "EmissivePower"
         }
      },
      "OcclusionFactor": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "OcclusionFactor"
         }
      },
      "SheenColor": {
         "value": "#ffffff",
         "params": {
            "label": "SheenColor"
         }
      },
      "SheenOpacity": {
         "value": 0.35,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "SheenOpacity"
         }
      },
      "SheenDepth": {
         "value": 10,
         "params": {
            "min": 1,
            "max": 16,
            "step": 0.1,
            "label": "SheenDepth"
         }
      },
      "Alpha": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Alpha"
         }
      },
      "Tint": {
         "value": "#ffffff",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 2,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Contrast": {
         "value": 0,
         "params": {
            "min": -1,
            "max": 1,
            "step": 0.01,
            "label": "Contrast"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      }
   },
   "Bloom - Spacecraft Fleet - Eve": {
      "BloomTint": {
         "value": "#ffffff",
         "params": {
            "label": "BloomTint"
         }
      },
      "Threshold": {
         "value": 0.6499999999999999,
         "params": {
            "min": 0,
            "max": 1.25,
            "step": 0.01,
            "label": "Threshold"
         }
      },
      "Blur": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Blur"
         }
      },
      "Overdrive": {
         "value": 0.75,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "Overdrive"
         }
      },
      "Similarity": {
         "value": 0.115,
         "params": {
            "min": 0,
            "max": 0.2,
            "step": 0.001,
            "label": "Similarity"
         }
      }
   },
   "Spacecraft Fleet - Eve - Background": {
      "GradientEdges": {
         "value": {
            "x": 0,
            "y": 0.66
         },
         "params": {
            "x": {
               "min": 0,
               "max": 1
            },
            "y": {
               "min": 0,
               "max": 1
            },
            "label": "GradientEdges"
         }
      },
      "TextureTranslation": {
         "value": {
            "x": 0.165,
            "y": 0.1
         },
         "params": {
            "x": {
               "min": -1,
               "max": 1,
               "step": 0.001
            },
            "y": {
               "min": -1,
               "max": 1,
               "step": 0.001
            },
            "label": "TextureTranslation"
         }
      },
      "TextureScale": {
         "value": {
            "x": 0.475,
            "y": 0.475
         },
         "params": {
            "x": {
               "min": 0,
               "max": 1,
               "step": 0.001
            },
            "y": {
               "min": 0,
               "max": 1,
               "step": 0.001
            },
            "label": "TextureScale"
         }
      },
      "Opacity": {
         "value": 0.9,
         "params": {
            "min": 0,
            "max": 1,
            "label": "Opacity"
         }
      },
      "GradientAngle": {
         "value": -3.1415,
         "params": {
            "min": -3.1415,
            "max": 3.1415,
            "label": "GradientAngle"
         }
      }
   },
   "Spacecraft Fleet - Eve - Lens Flares": {
      "Spacing": {
         "value": 0.08,
         "params": {
            "min": 0,
            "max": 0.3,
            "step": 0.001,
            "label": "Spacing"
         }
      },
      "CenterFadePower": {
         "value": 0.01,
         "params": {
            "min": -2,
            "max": 2,
            "label": "CenterFadePower"
         }
      },
      "GlobalOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 10,
            "label": "GlobalOpacity"
         }
      }
   },
   "Spacecraft Fleet - Eve - Flare - Simon_Glow": {
      "Opacity": {
         "value": 12,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 2.5,
            "y": 0.23
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#eba73d",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0.3,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "Rotation": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "Rotation"
         }
      }
   },
   "Spacecraft Fleet - Eve - Flare - Circular_Partial_00": {
      "Opacity": {
         "value": 30,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 1,
            "y": 1
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#ff07dd",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0.39,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "RotationOffset": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "RotationOffset"
         }
      }
   },
   "Global Settings - Spacecraft Fleet - Eve": {
      "ScrollSpeed": {
         "value": 15,
         "params": {
            "min": 0,
            "max": 60,
            "label": "ScrollSpeed"
         }
      },
      "InitialY": {
         "value": 0.51,
         "params": {
            "min": 0,
            "max": 1,
            "label": "InitialY"
         }
      },
      "DropHeight": {
         "value": 7,
         "params": {
            "min": 0,
            "max": 10,
            "label": "DropHeight"
         }
      },
      "InitialFov": {
         "value": 35,
         "params": {
            "min": 1,
            "max": 50,
            "label": "InitialFov"
         }
      },
      "FovOffset": {
         "value": 24,
         "params": {
            "min": 1,
            "max": 50,
            "label": "FovOffset"
         }
      },
      "InitialLookY": {
         "value": 1.3,
         "params": {
            "min": 0,
            "max": 2,
            "label": "InitialLookY"
         }
      },
      "LookYOffset": {
         "value": 1.3,
         "params": {
            "min": 0,
            "max": 10,
            "label": "LookYOffset"
         }
      },
      "SunPosition": {
         "value": {
            "x": 0,
            "y": -326.9230661749139,
            "z": -400
         },
         "params": {
            "label": "SunPosition"
         }
      },
      "GroundSpeed": {
         "value": 0.02,
         "params": {
            "min": 0,
            "max": 0.1,
            "label": "GroundSpeed"
         }
      }
   },
   "Spacecraft Fleet - Unity - Background": {
      "GradientEdges": {
         "value": {
            "x": 0.4,
            "y": 0.5
         },
         "params": {
            "x": {
               "min": 0,
               "max": 1
            },
            "y": {
               "min": 0,
               "max": 1
            },
            "label": "GradientEdges"
         }
      },
      "GradientEdges1": {
         "value": {
            "x": 0.1,
            "y": 91
         },
         "params": {
            "label": "GradientEdges1"
         }
      },
      "Opacity": {
         "value": 0.9,
         "params": {
            "min": 0,
            "max": 1,
            "label": "Opacity"
         }
      },
      "Opacity1": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "label": "Opacity1"
         }
      },
      "GradientAngle": {
         "value": -3.1415,
         "params": {
            "min": -3.1415,
            "max": 3.1415,
            "label": "GradientAngle"
         }
      },
      "Color": {
         "value": "#000000",
         "params": {
            "label": "Color"
         }
      },
      "GradientColor": {
         "value": "#6aa6ff",
         "params": {
            "label": "GradientColor"
         }
      },
      "Color1": {
         "value": "#75a1ff",
         "params": {
            "label": "Color1"
         }
      },
      "GradientColor1": {
         "value": "#0e0516",
         "params": {
            "label": "GradientColor1"
         }
      }
   },
   "Spacecraft Fleet - Unity - Lens Flares": {
      "Spacing": {
         "value": 0.002,
         "params": {
            "min": 0,
            "max": 0.3,
            "step": 0.001,
            "label": "Spacing"
         }
      },
      "CenterFadePower": {
         "value": 0.26086956521739113,
         "params": {
            "min": -2,
            "max": 2,
            "label": "CenterFadePower"
         }
      },
      "GlobalOpacity": {
         "value": 5,
         "params": {
            "min": 0,
            "max": 10,
            "label": "GlobalOpacity"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Simon_Glow": {
      "Opacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 0.6,
            "y": 0.6
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#ffd834",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0.9,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0.4,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 0.9,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "Rotation": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "Rotation"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Spikes": {
      "Opacity": {
         "value": 0.35,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 2.85,
            "y": 2.08
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#ff03aa",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 1.1500000000000001,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "Rotation": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "Rotation"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Orb": {
      "Opacity": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 1,
            "y": 1
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#b26f3a",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "RotationOffset": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "RotationOffset"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Stripe_02": {
      "Opacity": {
         "value": 0.9782608695652174,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 1.4,
            "y": 1
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#31b1f8",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "Rotation": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "Rotation"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Stripe_01": {
      "Opacity": {
         "value": 0.6521739130434783,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 0.4,
            "y": 0.4
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": -0.32,
            "y": -0.07
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#e52da0",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "Rotation": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "Rotation"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Chromatic_Stripe": {
      "Opacity": {
         "value": 1.6304347826086956,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 1,
            "y": 1
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0.4,
            "y": -0.1
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#6ab2c8",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "Rotation": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "Rotation"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Simon_Arc_01_Blob": {
      "Opacity": {
         "value": 0.28,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 1.5,
            "y": 1
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 0,
            "y": 0
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#ff3ce4",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0.75,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "RotationOffset": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "RotationOffset"
         }
      }
   },
   "Spacecraft Fleet - Unity - Flare - Simon_Arc_00": {
      "Opacity": {
         "value": 0.32608695652173914,
         "params": {
            "min": 0,
            "max": 30,
            "label": "Opacity"
         }
      },
      "Scale": {
         "value": {
            "x": 0.75,
            "y": 0.75
         },
         "params": {
            "min": 0,
            "max": 2,
            "label": "Scale"
         }
      },
      "Offset": {
         "value": {
            "x": 4.264705882352942,
            "y": -2.0588235294117654
         },
         "params": {
            "x": {
               "min": -10,
               "max": 10
            },
            "y": {
               "min": -10,
               "max": 10
            },
            "label": "Offset"
         }
      },
      "Tint": {
         "value": "#d29f82",
         "params": {
            "label": "Tint"
         }
      },
      "TintOpacity": {
         "value": 0,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "TintOpacity"
         }
      },
      "Exposure": {
         "value": 0,
         "params": {
            "min": -2,
            "max": 3,
            "step": 0.01,
            "label": "Exposure"
         }
      },
      "Saturation": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 2,
            "step": 0.01,
            "label": "Saturation"
         }
      },
      "RotationOffset": {
         "value": 0,
         "params": {
            "min": -3.141592653589793,
            "max": 3.141592653589793,
            "label": "RotationOffset"
         }
      }
   },
   "Bloom - Spacecraft Fleet - Unity": {
      "BloomTint": {
         "value": "#f2bb44",
         "params": {
            "label": "BloomTint"
         }
      },
      "Threshold": {
         "value": 0.95,
         "params": {
            "min": 0,
            "max": 1.25,
            "step": 0.01,
            "label": "Threshold"
         }
      },
      "Blur": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "Blur"
         }
      },
      "Overdrive": {
         "value": 1,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "Overdrive"
         }
      },
      "Similarity": {
         "value": 0.2,
         "params": {
            "min": 0,
            "max": 0.2,
            "step": 0.001,
            "label": "Similarity"
         }
      }
   },
   "Global Settings - Spacecraft Fleet - Unity": {
      "InitialCameraRotation": {
         "value": {
            "x": -0.19,
            "y": 0.33,
            "z": -0.12
         },
         "params": {
            "label": "InitialCameraRotation"
         }
      },
      "InitialUnityPosition": {
         "value": {
            "x": 0,
            "y": 0,
            "z": 0.48
         },
         "params": {
            "label": "InitialUnityPosition"
         }
      },
      "InitialIBLSpecularFactor": {
         "value": 1.35,
         "params": {
            "min": 0,
            "max": 4,
            "step": 0.01,
            "label": "InitialIBLSpecularFactor"
         }
      },
      "InitialLightPower": {
         "value": 7.61,
         "params": {
            "min": 0,
            "max": 20,
            "step": 0.01,
            "label": "InitialLightPower"
         }
      },
      "IBLSpecularFactorAttenuation": {
         "value": 1.1400000000000003,
         "params": {
            "min": 0,
            "max": 3,
            "step": 0.01,
            "label": "IBLSpecularFactorAttenuation"
         }
      },
      "LightPowerAttenuation": {
         "value": 2.07,
         "params": {
            "min": 0,
            "max": 10,
            "step": 0.01,
            "label": "LightPowerAttenuation"
         }
      },
      "IBLSpecularFactorMinimum": {
         "value": 0.13,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "IBLSpecularFactorMinimum"
         }
      },
      "LightPowerAttenuationMinimum": {
         "value": 0.24,
         "params": {
            "min": 0,
            "max": 1,
            "step": 0.01,
            "label": "LightPowerAttenuationMinimum"
         }
      },
      "ScrollSpeed": {
         "value": 5,
         "params": {
            "min": 0,
            "max": 60,
            "label": "ScrollSpeed"
         }
      },
      "SunPosition": {
         "value": {
            "x": -1267.9081315952822,
            "y": -515,
            "z": -2300
         },
         "params": {
            "label": "SunPosition"
         }
      },
      "InitialFov": {
         "value": 27,
         "params": {
            "min": 1,
            "max": 50,
            "label": "InitialFov"
         }
      },
      "FovOffset": {
         "value": 1,
         "params": {
            "min": 1,
            "max": 50,
            "label": "FovOffset"
         }
      }
   },
   "Camera - Spacecraft Fleet - Unity": {
      "rotationOffset": {
         "value": {
            "x": -0.01900305223588661,
            "y": 0.03300530125180301,
            "z": -0.01200192772792838
         },
         "params": {
            "x": {
               "min": -3.1415,
               "max": 3.1415
            },
            "y": {
               "min": -3.1415,
               "max": 3.1415
            },
            "z": {
               "min": -3.1415,
               "max": 3.1415
            },
            "label": "rotationOffset"
         }
      }
   },
   "VSS Unity": {
      "positionOffset": {
         "value": {
            "x": 0,
            "y": 0,
            "z": 0.22280819366852916
         },
         "params": {
            "x": {
               "min": -3.1415,
               "max": 3.1415
            },
            "y": {
               "min": -3.1415,
               "max": 3.1415
            },
            "z": {
               "min": -3.1415,
               "max": 3.1415
            },
            "label": "positionOffset"
         }
      }
   },
   "Spacecraft Fleet - Unity - Occluder": {
      "Scale": {
         "value": 0.7,
         "params": {
            "label": "Scale"
         }
      },
      "Position": {
         "value": [
            0.2,
            0.16,
            0.52
         ],
         "params": {
            "label": "Position"
         }
      },
      "Visible": {
         "value": false,
         "params": {
            "label": "Visible"
         }
      }
   }
}
export default out