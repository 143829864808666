// Libs
import { gsap } from 'gsap'

// Structural
import SceneAbs from '~/glxp/abstract/scene'
import TextureLoader from '~/glxp/utils/textureLoaderObject'
import ShaderManifest from '~/glxp/shaderManifest'
import Shader from '~/glxp/utils/shader'

// Subscenes
import SceneSpacecraftEve from './spacecraftFleet/spacecraftFleetEve'
import SceneSpacecraftUnity from './spacecraftFleet/spacecraftFleetUnity'

// Utils
import RAF from '~/glxp/utils/raf'
import Mouse from '~/glxp/utils/mouse'
import GlobalEmitter from '~/glxp/utils/emitter'

// OGL
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Triangle } from '~/glxp/ogl/extras/Triangle.js'

// Managers
class SpacecraftFleetParent extends SceneAbs {
  constructor(container, manager = null) {
    super(container, manager)

    this.name = 'Home Parent'

    this.container = container
    this.manager = manager
    this.renderer = manager.renderer

    this.time = 0
    this.dt = 0
    this.drawcalls = 0
    this.progress = 0
    this.progressDelta = 0
    this.progressTarget = 0.00001
    this.timescale = 1
    this.forceAllDraw = true

    this.clearColor = this.manager.clearColor
    this.loaded = false
    this.active = false
    this.isTransition = false
    this.timelinesTo = {}
    this.hasLeftUnity = false

    // EXEMPLE
    this.currentSubsceneId = 0

    if (manager && manager.textureLoader) {
      this.textureLoader = manager.textureLoader
    } else {
      this.textureLoader = new TextureLoader(this)
    }

    this.initScenes()
    this.initComposition()

    this.config = {
      Pause: { value: false, type: 'bool' },
      time_scale: { value: 1.5, params: { min: 0, max: 3, step: 0.01 } },
      debug: { value: 0, params: { min: 0, max: 0.1, step: 0.0001 } },
      scroll_damping: { value: 0.05, params: { min: 0, max: 1, step: 0.01 } },
      scroll_speed: { value: 5, params: { min: 0, max: 10, step: 0.1 } },
      threshold: { value: 0.003, params: {} }
    }
  }

  // Inits
  initScenes() {
    // TODO: Refactor subscenes parameters
    this.subsceneEve = new SceneSpacecraftEve(
      this.container,
      this.manager,
      this.textureLoader,
      true
    )
    this.subsceneUnity = new SceneSpacecraftUnity(
      this.container,
      this.manager,
      this.textureLoader,
      true
    )

    this.subscenes = [this.subsceneEve, this.subsceneUnity]

    this.activeSubsceneFirst = this.subsceneEve
    this.activeSubsceneSecond = this.subsceneUnity

    this.rtFirst = this.activeSubsceneFirst.rt
    this.rtSecond = this.activeSubsceneSecond.rt
  }

  initComposition() {
    const geometry = new Triangle(this.gl)

    this.compositionShader = new Shader(ShaderManifest['subscenes'])

    this.compositionProgram = new Program(this.gl, {
      vertex: this.compositionShader.vert,
      fragment: this.compositionShader.frag,
      uniforms: {
        // uTime: { value: 0 },
        // uMouse: { value: Mouse.cursor },
        uSceneFirst: { value: this.rtFirst.textures[0] },
        uSceneSecond: { value: this.rtSecond.textures[0] },
        uTransitionFactor: { value: 0 },
        // CUSTOM
        uTransitionSkew: { value: 0.19 },
        // uTempOverlay: { value: 0.0 },
      },
    })

    this.compositionMesh = new Mesh(this.gl, {
      geometry,
      program: this.compositionProgram,
    })
  }

  // Events
  addEvents() {
    GlobalEmitter.on('webgl_fleet_switch_subscene', this.onSceneSwitch)
    GlobalEmitter.on("webgl_fleet_set_subscene", this.onScenePick)
    GlobalEmitter.on("webgl_fleet_progress_to_subscene", this.onSubsceneProgress)

    GlobalEmitter.on('webgl_fleet_eve_progress', this.onEveProgressRequest)
    GlobalEmitter.on('webgl_fleet_unity_progress', this.onUnityProgressRequest)

    GlobalEmitter.on('webgl_unity_leave', this.onUnityLeave)
    GlobalEmitter.on('webgl_unity_enter', this.onUnityEnter)
  }

  removeEvents() {
    GlobalEmitter.off('webgl_fleet_switch_subscene', this.onSceneSwitch)
    GlobalEmitter.off("webgl_fleet_set_subscene", this.onScenePick)
    GlobalEmitter.off("webgl_fleet_progress_to_subscene", this.onSubsceneProgress)

    GlobalEmitter.off('webgl_fleet_eve_progress', this.onEveProgressRequest)
    GlobalEmitter.off('webgl_fleet_unity_progress', this.onUnityProgressRequest)

    GlobalEmitter.off('webgl_unity_leave', this.onUnityLeave)
    GlobalEmitter.off('webgl_unity_enter', this.onUnityEnter)
  }

  onUnityLeave = () => {
    this.hasLeftUnity = true
  }

  onUnityEnter = () => {
    this.hasLeftUnity = false
  }

  onEveProgressRequest = ({ progress }) => {
    this.subsceneEve.setProgress(progress)
  }

  onUnityProgressRequest = ({ progress }) => {
    this.subsceneUnity.setProgress(progress)
  }

  onSceneSwitch = ({ isInstant } = { isInstant: false }) => {
    if (isInstant) {
      this.setSubscenes(this.activeSubsceneSecond, this.activeSubsceneFirst)
      this.activeSubsceneFirst.activate()

      // TODO: Refactor
      // Delay to make sure the previous scene has time to finish some animations.
      setTimeout(() => {
        this.activeSubsceneSecond.disable()
      }, 1000)
    } else {
      this.initTransitionToSecond()
    }
  }

  onScenePick = ({ id, isInstant }) => {
    switch (id) {
      case 'unity':
        if (isInstant) {
          this.setSubscenes(this.subsceneUnity, this.subsceneEve)
          this.subsceneUnity.activate()
        } else {
          this.transitionToSubscene(this.subsceneUnity)
        }
        break

      case 'eve':
        if (isInstant) {
          this.setSubscenes(this.subsceneEve, this.subsceneUnity)
          this.subsceneEve.activate()
        } else {
          this.transitionToSubscene(this.subsceneEve)
        }
        break
      default:
        break
    }
  }

  onSubsceneProgress = ({ id, progress }) => {
    if (!this.timelinesTo[id]) {
      this.timelinesTo[id] = this.getTimelineTransition()
    }

    this.timelinesTo[id].progress(progress)
  }

  // Lifecycle
  activate() {
    return new Promise((resolve) => {
      this.active = true
      this.activationResolve = resolve
      this.postFirstDraw()

      this.addEvents()
    })
  }

  disable() {
    this.active = false

    this.removeEvents()
  }

  async load() {
    const loadableTextures = []

    // Create global loadables array from subscenes loadable textures
    this.subscenes.forEach((subscene) => {
      const subsceneLoadableTextures = subscene.fetchLoadableTextures()

      const compareLoadables = (l1, l2) =>
        l1.groupKey === l2.groupKey && l1.key === l2.key

      if (subsceneLoadableTextures) {
        subsceneLoadableTextures.forEach((subLoadable) => {
          if (
            loadableTextures.findIndex((loadable) =>
              compareLoadables(loadable, subLoadable)
            ) < 0
          ) {
            loadableTextures.push(subLoadable)
          }
        })
      }
    })

    // Load all loadable textures and wait for promises
    const loadablePromises = loadableTextures.map((loadable) =>
      this.textureLoader.load(loadable.element, loadable.key, loadable.options)
    )

    // Add model load promises
    this.subscenes.forEach((subscene) => {
      const subsceneModelsPromises = subscene.loadModels()

      if (subsceneModelsPromises) {
        loadablePromises.push(...subsceneModelsPromises)
      }
    })

    Promise.all(loadablePromises).then(() => {
      this.toggleNoiseAndFlares(false)
      this.onLoaded()
    })

    // Handle loading progress
    let percent = 0
    for (let i = 0; i < loadablePromises.length; i++) {
      loadablePromises[i].then(() => {
        percent++
        const formatedVal = Math.round(
          (percent / loadablePromises.length) * 100
        )
        this._emitter.emit('progress', formatedVal)
        GlobalEmitter.emit('webgl_loading_progress', { progress: formatedVal })
      })
    }
  }

  // Scenes switch
  setSubscenes(
    subsceneFirst = this.activeSubsceneFirst,
    subsceneSecond = this.activeSubsceneSecond
  ) {
    this.activeSubsceneFirst = subsceneFirst
    this.activeSubsceneSecond = subsceneSecond

    this.rtFirst = this.activeSubsceneFirst.rt
    this.rtSecond = this.activeSubsceneSecond.rt

    this.compositionProgram.uniforms.uSceneFirst.value =
      this.rtFirst.textures[0]
    this.compositionProgram.uniforms.uSceneSecond.value =
      this.rtSecond.textures[0]
  }

  getTimelineTransition() {
    // Hotfix: render a single frame to avoid flare occlusion issue when transitioning
    this.subsceneUnity.activate()
    setTimeout(() => {
      this.subsceneUnity.disable()
    }, 200);

    const tl = gsap.timeline({ paused: true })
    tl.call(() => {
      this.activeSubsceneSecond.active = false
    })
    tl.to(
      this.compositionProgram.uniforms.uTransitionFactor,
      { value: 1, duration: 2, ease: 'power3.none' },
      0.0
    )
    tl.call(() => {
      this.toggleNoiseAndFlares(true)

      this.setSubscenes(this.activeSubsceneSecond, this.activeSubsceneFirst)
      this.compositionProgram.uniforms.uTransitionFactor.value = 0

      this.activeSubsceneSecond.active = false
    })

    return tl
  }

  // TRASH CODE
  toggleNoiseAndFlares(toggle) {
    if (!toggle) {
      // Hotfix: render a single frame to avoid performance issues when transitioning
      this.subsceneUnity.post.program.uniforms.uDirtOpacityFactor.previousValue = this.subsceneUnity.post.program.uniforms.uDirtOpacityFactor.value
      this.subsceneUnity.post.program.uniforms.uDirtOpacityFactor.value = 0
      this.subsceneUnity.post.program.uniforms.uNoiseOpacity.previousValue = this.subsceneUnity.post.program.uniforms.uNoiseOpacity.value
      this.subsceneUnity.post.program.uniforms.uNoiseOpacity.value = 0
      for (const flare of this.subsceneUnity.lensFlares.flares) {
        flare.previousOpacity = flare.config.Opacity.value
        flare.config.Opacity.value = 0
      }
    } else {
      // Hotfix: reset noise and flare values
      this.subsceneUnity.post.program.uniforms.uDirtOpacityFactor.value = this.subsceneUnity.post.program.uniforms.uDirtOpacityFactor.previousValue
      this.subsceneUnity.post.program.uniforms.uNoiseOpacity.value = this.subsceneUnity.post.program.uniforms.uNoiseOpacity.previousValue

      for (const flare of this.subsceneUnity.lensFlares.flares) {
        flare.config.Opacity.value = flare.previousOpacity
      }
    }
  }

  initTransitionToSecond() {
    if (this.isTransition) return

    this.isTransition = true

    this.activeSubsceneSecond.activate()

    const tl = gsap.timeline()

    return new Promise((resolve, reject) => {
      tl.to(
        this.compositionProgram.uniforms.uTransitionFactor,
        { value: 1, duration: 1.5, ease: 'power3.inOut' },
        0.0
      )

      tl.fromTo(
        this.compositionProgram.uniforms.uTransitionSkew,
        { value: 0.0 },
        { value: 0.35, duration: 0.75, ease: 'power3.in' },
        0
      )
      tl.fromTo(
        this.compositionProgram.uniforms.uTransitionSkew,
        { value: 0.35 },
        { value: 0.0, duration: 0.75, ease: 'power3.out' },
        0.75
      )

      tl.call(() => {
        this.setSubscenes(this.activeSubsceneSecond, this.activeSubsceneFirst)
        this.compositionProgram.uniforms.uTransitionFactor.value = 0
        this.activeSubsceneSecond.disable()

        this.isTransition = false

        resolve()
      })
    })
  }

  async transitionToSubscene(subscene) {
    if (this.isTransition) return

    this.setSubscenes(this.activeSubsceneFirst, subscene)
    await this.initTransitionToSecond()
  }

  // Utils
  setProgress(p, shouldForce = false) {
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  applyDefaultState() {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  reset() {
    // Progress
    this.setProgress(0, true)

    this.subscenes.forEach((subscene) => {
      if (subscene.setProgress) subscene.setProgress(0, true)
    })

    // Transition
    this.setSubscenes(this.subsceneEve, this.subsceneUnity)
    this.compositionProgram.uniforms.uTransitionFactor.value = 0
    this.activeSubsceneFirst.activate()
    this.activeSubsceneSecond.disable()
  }

  // Events
  onLoaded() {
    this.active = true
    this.loaded = true
    this._emitter.emit('loaded')

    // Subscenes onLoaded & First Draw
    const firstDrawPromises = []
    this.subscenes.forEach((subscene) => {
      subscene.onLoaded()

      if (subscene.shouldFirstDraw && subscene.firstDrawAllPromise) {
        firstDrawPromises.push(subscene.firstDrawAllPromise)
      }
    })

    // Will go through this Promise even if no scene has their first draw enabled
    Promise.all(firstDrawPromises).then(() => {
      // HOTFIX TO FIRST DRAW
      this.activeSubsceneSecond.activate()
      setTimeout(() => {
        this.activeSubsceneSecond.disable()
      }, 120);

      // console.log(`Subscenes finished first draw. Activate ${this.name} scene.`)

      GlobalEmitter.emit('webgl_loaded')
    })

    // Activate
    this.activeSubsceneFirst.activate()
  }

  postFirstDraw() { }

  resize() { }

  // Rendering
  preRender() {
    // TODO: Check if we need global scene parent progress
    let tmp
    tmp = this.progressTarget - this.progress
    this.progressDelta = tmp
    tmp *= this.config.scroll_damping.value

    this.timescale = this.config.time_scale.value

    this.progress += tmp
  }

  render() {
    if (!this.active) {
      return
    }
    let gl = this.gl

    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    this.preRender()

    // Subscenes
    // TODO: filter might be too obscure at this point
    // Test is also done in subscene, this is just to prevent useless calls.
    this.subscenes
      .filter(
        (subscene) =>
          subscene.active ||
          (subscene.shouldFirstDraw && !subscene.didFirstDraw)
      )
      .forEach((subscene, i) => {
        // Clear color between each subscene renders
        if (i >= 1) {
          gl.clearColor(
            this.clearColor[0],
            this.clearColor[1],
            this.clearColor[2],
            this.clearColor[3]
          )
          gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
          gl.colorMask(true, true, true, true)
        }

        subscene.render()
      })

    // Compositing
    gl.viewport(0, 0, this.width, this.height)
    this.renderer.setViewport(this.width, this.height)

    // Render Time
    this.renderer.render({ scene: this.compositionMesh })

    this.postRender()
  }

  postRender() {
    if(this.subsceneEve.active){
      for (const hotspot of this.subsceneUnity.hotspotManager.hotspots) {
        hotspot.visibility = 0
      }
      this.subsceneUnity.hotspotManager.update()
    }

    if(this.hasLeftUnity){
      // console.log("%c has left unity", 'background: #222; color: #bada55');

      for (const hotspot of this.subsceneUnity.hotspotManager.hotspots) {
        hotspot.visibility = 0
      }
      this.subsceneUnity.hotspotManager.update()
      
      for (const hotspot of this.subsceneEve.hotspotManager.hotspots) {
        hotspot.visibility = 0
      }
      this.subsceneEve.hotspotManager.update()
    }

    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }
  }
}

export default SpacecraftFleetParent
