import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Sphere } from '~/glxp/ogl/extras/Sphere.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Color } from '~/glxp/ogl/math/Color'
import { Texture } from '~/glxp/ogl/core/Texture.js'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class ExperienceEarthCloudsEntity {
  constructor(
    scene,
    {
      id = 0,
      parent = null,
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = true,
      depthTest = false,
      depthWrite = true,
      renderOrder = 1,
      name = 'Experience Earth Clouds'
    } = {}
  ) {
    this.groupId = id
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name

    // Shader
    this.shaderEarth = new Shader(ShaderManifest['experienceClouds'])

    // Textures
    this.texture = new Texture(this.gl)
    this.textureCloseup = new Texture(this.gl)
    this.textureClouds = new Texture(this.gl)
    this.textureNight = new Texture(this.gl)

    this.config = {
      Progress: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      CloudsResBig: {
        value: { x: 6, y: 4 },
        params: { x: { min: 0.1, max: 30, step: 0.01 }, y: { min: 0.1, max: 30, step: 0.01 } },
      },
      CloudsResSmall: {
        value: { x: 10, y: 8 },
        params: { x: { min: 0.1, max: 30, step: 0.01 }, y: { min: 0.1, max: 30, step: 0.01 } },
      },
      SunPosition: {
        value: { x: 0.445, y: 0.5 },
        params: { x: { min: 0.1, max: 1, step: 0.01 }, y: { min: 0.1, max: 1, step: 0.01 } },
      },
      GroundColor: { value: "#993573", params: {} },
      CloudsColor: { value: "#ffffff", params: {} },
      FresnelColor: { value: "#6c8ccf", params: {} },
      FresnelRimColor: { value: "#acdaff", params: {} },
    }

    this.initEarth()
  }

  initGui(){
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
  }

  initEarth() {
    this.geometry = new Sphere(this.gl, { radius: 1.3525, widthSegments: 128, phiStart: Math.PI * 1.25, phiLength: Math.PI * 0.5, thetaStart: Math.PI * 0.25, thetaLength: Math.PI * 0.5 })

    this.program = new Program(this.gl, {
      vertex: this.shaderEarth.vert,
      fragment: this.shaderEarth.frag,
      uniforms: {
        uTime: { value: this.scene.time }, 
        uAlpha: { value: 1 },
        uCamera: { value: this.scene.camera.worldPosition },

        uTexture: { value: this.texture },

        uProgress: this.config.Progress,
        uCloudsResBig: { value: [this.config.CloudsResBig.value.x, this.config.CloudsResBig.value.y]},
        uCloudsResSmall: { value: [this.config.CloudsResSmall.value.x, this.config.CloudsResSmall.value.y]},
        uSunPosition: { value: [this.config.SunPosition.value.x, this.config.SunPosition.value.y]},
        uGroundColor: { value: new Color(this.config.GroundColor.value) },
        uCloudsColor: { value: new Color(this.config.CloudsColor.value) },
        uFresnelColor: { value: new Color(this.config.FresnelColor.value) },
        uFresnelRimColor: { value: new Color(this.config.FresnelRimColor.value) },
      },
      transparent: this.transparent,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
    })

    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
    })

    this.mesh.scale.set(this.scale, this.scale, this.scale)
    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  onLoaded() {
    if (this.texture) {
      this.texture = this.scene.textureLoader.getTexture('flightExperienceEarthCloudsSeamless')
      this.texture.needsUpdate = true
      this.program.uniforms['uTexture'].value = this.texture
    }

    this.initGui()

    setTimeout(() => {
      this.program.uniforms["uCloudsResBig"].value = [this.config.CloudsResBig.value.x, this.config.CloudsResBig.value.y]
      this.program.uniforms["uCloudsResSmall"].value = [this.config.CloudsResSmall.value.x, this.config.CloudsResSmall.value.y]
      this.program.uniforms["uSunPosition"].value = [this.config.SunPosition.value.x, this.config.SunPosition.value.y]
      this.program.uniforms['uGroundColor'].value = new Color(this.config.GroundColor.value)
      this.program.uniforms['uCloudsColor'].value = new Color(this.config.CloudsColor.value)
      this.program.uniforms['uFresnelColor'].value = new Color(this.config.FresnelColor.value)
      this.program.uniforms['uFresnelRimColor'].value = new Color(this.config.FresnelRimColor.value)
    }, 1);
  }

  preRender() {
    this.program.uniforms["uTime"].value = this.scene.time

    if (DebugController.active) {
      this.program.uniforms["uCloudsResBig"].value = [this.config.CloudsResBig.value.x, this.config.CloudsResBig.value.y]
      this.program.uniforms["uCloudsResSmall"].value = [this.config.CloudsResSmall.value.x, this.config.CloudsResSmall.value.y]
      this.program.uniforms["uSunPosition"].value = [this.config.SunPosition.value.x, this.config.SunPosition.value.y]
      this.program.uniforms['uGroundColor'].value = new Color(this.config.GroundColor.value)
      this.program.uniforms['uCloudsColor'].value = new Color(this.config.CloudsColor.value)
      this.program.uniforms['uFresnelColor'].value = new Color(this.config.FresnelColor.value)
      this.program.uniforms['uFresnelRimColor'].value = new Color(this.config.FresnelRimColor.value)
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default ExperienceEarthCloudsEntity
