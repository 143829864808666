export default {
  main: {
    // 'test': { url: `/glxp/textures/UV_Grid.ktx2`, options: ['compressed'] },
    // 'testPng': { url: `/glxp/textures/UV_Grid.png`, options: [] },

    // Envs
    'brdfLUT': { url: `/glxp/brdfLUT.png`, options: [""] },
    'env_diffuse': { url: `/glxp/environment/diffuse.png`, options: ["repeat", "lodTarget=256", "compressed"] },
    'env_default': { url: `/glxp/environment/env_default.jpg`, options: ["repeat", "lodTarget=256", "compressed"] },
    'env_clouds': { url: `/glxp/environment/env_clouds_reversed.jpg`, options: ["repeat", "lodTarget=256", "compressed"] },
    'env_clouds_blur': { url: `/glxp/environment/env_clouds_reversed_blurred.jpg`, options: ["repeat", "compressed", "lodTarget=256"] },
    'env_clouds_flipped': { url: `/glxp/environment/env_clouds_reversed.jpg`, options: ["repeat", "lodTarget=1024", "compressed", "flipY"] },
    'env_clouds_bottom_blur_x1': { url: `/glxp/environment/env_clouds_reversed_blur_x1.png`, options: ["repeat", "lodTarget=1024", "compressed", "flipY"] },

    // Noises
    'fbm_1': { url: `/glxp/textures/noises/fbm-1.png`, options: ["repeat", "lodTarget=1024", "transparent"] },
    'fbm_2': { url: `/glxp/textures/noises/fbm-2.png`, options: ["repeat", "lodTarget=1024", "transparent"] },
    'lightning_1': { url: `/glxp/textures/noises/lightning-2.png`, options: ["repeat"] },
    'absFbm_1': { url: `/glxp/textures/noises/absFbmSeamless.png`, options: ["repeat", "lodTarget=1024", "transparent"] },
    'perlin_1': { url: `/glxp/textures/noises/perlin-1.png`, options: ["repeat", "lodTarget=1024", "transparent"] },
    'blue_1': { url: `/glxp/textures/noises/blue-1.png`, options: ["repeat", "lodTarget=1024"] },

    // Stars
    'stars': { url: `/glxp/textures/space/unlit/stars-seamless.png`, options: ["repeat", "lodTarget=512", "compressed"] },

    // Utils
    'white': { url: `/glxp/textures/white.jpg`, options: [] },
    'black': { url: `/glxp/textures/black.jpg`, options: [] },
    'default_normals': { url: `/glxp/textures/default_normals.jpg`, options: [] },
  },

  // Space
  spacePBR: {
  },
  spaceUnlit: {
    'M_SpaceEarth': { url: `/glxp/textures/space/unlit/earth-7-2.png`, options: ["repeat", "lodTarget=512", "compressed"] },
  },

  // Flight Experience
  flightExperiencePBR: {
  },
  flightExperienceUnlit: {
    'flightExperienceEarthDay': { url: `/glxp/textures/experience/unlit/earth-day-resized.jpg`, options: ["repeat", "flipY", "lodTarget=2048"] },
    // 'flightExperienceEarthCloseup': { url: `/glxp/textures/experience/unlit/earth-day-closeup-colors.jpg`, options: ["repeat", "flipY"] },
    // 'flightExperienceEarthCloseup': { url: `/glxp/textures/space/unlit/earth-7-2.png`, options: ["repeat", "flipY", "lodTarget=1024"] },
    'flightExperienceEarthNight': { url: `/glxp/textures/experience/unlit/earth-night-resized.jpg`, options: ["repeat", "flipY", "lodTarget=2048"] },
    'flightExperienceEarthClouds': { url: `/glxp/textures/experience/unlit/earth-clouds-resized.jpg`, options: ["repeat", "flipY", "lodTarget=1024"] },
    'flightExperienceEarthCloudsSeamless': { url: `/glxp/textures/experience/unlit/clouds-seamless.png`, options: ["repeat", "lodTarget=1024"] },
  },

  // Spacecraft Fleet
  spacecraftFleetEve: {
    'env_spacecraft_fleet_eve_flipped': { url: `/glxp/environment/env_spacecraft_fleet_eve_v2.png`, options: ["repeat", "flipY", "lodTarget=2048"] },
    'env_spacecraft_fleet_eve': { url: `/glxp/environment/env_spacecraft_fleet_eve_v2.png`, options: ["repeat", "lodTarget=1024"] },
    'env_spacecraft_fleet_eve_blur': { url: `/glxp/environment/env_spacecraft_fleet_eve_v2_10pxblur.png`, options: ["repeat", "lodTarget=1024"] },
    'env_spacecraft_fleet_unity': { url: `/glxp/environment/env_spacecraft_fleet_imagine_11_10_49_30px_blur.png`, options: ["repeat", "lodTarget=1024"] },
    'env_spacecraft_fleet_unity2': { url: `/glxp/environment/env_spacecraft_fleet_imagine_11_11_02.png`, options: ["repeat", "lodTarget=1024"] },
    'env_spacecraft_fleet_unity3': { url: `/glxp/environment/env_spacecraft_fleet_imagine_11_10_49_30px_blur_accent_v2.png`, options: ["repeat", "lodTarget=1024"] },
    'env_spacecraft_fleet_unity4': { url: `/glxp/environment/env_spacecraft_fleet_imagine_11_10_49_30px_blur.png`, options: ["repeat", "lodTarget=1024"] },
    'eveGround': { url: `/glxp/textures/eve_background.png`, options: ["flipY"] },
  },

  // Eve
  evePBR: {
    'eveDiffuse': { url: `/glxp/textures/eve/pbr/T_SpaceShip_Eve_NewLivery_D.png`, options: ["repeat", "lodTarget=1024"] },
    'eveNormals': { url: `/glxp/textures/eve/pbr/T_SpaceShip_Eve_N.png`, options: ["repeat", "lodTarget=1024"] },
    'eveORM': { url: `/glxp/textures/eve/pbr/T_SpaceShip_Eve_NewLivery_ORM.png`, options: ["repeat", "lodTarget=1024"] },
    'eveWindows': { url: `/glxp/textures/eve/pbr/T_SpaceShip_Eve_Windows.png`, options: ["repeat", "lodTarget=256", "compressed"] },
  },
  eveUnlit: {},

  // Imagine
  imaginePBR: {
    'imagineDiffuse': { url: `/glxp/textures/imagine/pbr/T_SpaceShip_Imagine_D.png`, options: ["repeat", "lodTarget=1024"] },
    'imagineNormals': { url: `/glxp/textures/imagine/pbr/T_SpaceShip_Imagine_N.png`, options: ["repeat", "lodTarget=1024"] },
    'imagineORM': { url: `/glxp/textures/imagine/pbr/T_SpaceShip_Imagine_ORM.png`, options: ["repeat", "lodTarget=1024"] },
  },
  imagineUnlit: {},

  // Unity
  unityDecalPBR: {
    'unityDecalDiffuse': { url: `/glxp/textures/unity/pbr/v2/T_SpaceShip_Unity_D_v4.png`, options: ["repeat", "lodTarget=2048", "compressed"] },
    'unityDecalNormals': { url: `/glxp/textures/unity/pbr/v2/T_SpaceShip_Unity_N.png`, options: ["repeat", "lodTarget=2048", "transparent"] },
    'unityDecalORM': { url: `/glxp/textures/unity/pbr/v2/T_SpaceShip_Unity_NoLandingGear_ORM.png`, options: ["repeat"] },
    'unityDecalTextDiffuse': { url: `/glxp/textures/unity/pbr/v2/text/T_SpaceShip_Imagine_Text_D.png`, options: ["repeat", "lodTarget=256", "compressed"] },
    'unityDecalTextAlpha': { url: `/glxp/textures/unity/pbr/v2/text/T_SpaceShip_Unity_Text_Alpha.png`, options: ["repeat", "lodTarget=2048", "compressed"] },
    'unityDecalTextNormal': { url: `/glxp/textures/unity/pbr/v2/text/T_SpaceShip_Imagine_Text_N.png`, options: ["repeat", "lodTarget=1024", "transparent"] },
    'unityDecalTextORM': { url: `/glxp/textures/unity/pbr/v2/text/T_SpaceShip_Imagine_Text_ORM_v2.png`, options: ["repeat", "lodTarget=256", "compressed"] }
  },
  unityPBR: {
    'unityDiffuse': { url: `/glxp/textures/unity/pbr/v1/T_SpaceShip_Unity_D_noholes.png`, options: ["repeat", "lodTarget=2048", "compressed"] },
    'unityNormals': { url: `/glxp/textures/unity/pbr/v1/T_SpaceShip_Unity_N.png`, options: ["repeat", "lodTarget=512", "transparent"] },
    'unityORM': { url: `/glxp/textures/unity/pbr/v1/T_SpaceShip_Unity_NoLandingGear_ORM.png`, options: ["repeat", "lodTarget=1024", "transparent"] },
    'unityWindows': { url: `/glxp/textures/unity/pbr/v1/T_SpaceShip_Unity_D_windows.png`, options: ["repeat", "lodTarget=256", "compressed"] },
  },
  unityUnlit: {},

  // Spaceport
  spaceportEnv: {
    'env_spaceport': { url: `/glxp/environment/env_spaceport.jpg`, options: ["repeat", "lodTarget=1024"] },
    'env_spaceport_blur': { url: `/glxp/environment/env_spaceport_blur.jpg`, options: ["repeat", "lodTarget=1024"] },
    'env_spaceport_glass_ships': { url: `/glxp/environment/env_spaceport_glass_ships_up.jpg`, options: ["repeat", "lodTarget=1024"] },
    'env_spaceport_glass_ships_blur': { url: `/glxp/environment/env_spaceport_glass_ships_up_blur.jpg`, options: ["repeat", "lodTarget=1024"] },
    'env_spaceport_glass_noships': { url: `/glxp/environment/env_spaceport_glass_noships_up.jpg`, options: ["repeat", "lodTarget=1024"] },
    'env_spaceport_glass_noships_blur': { url: `/glxp/environment/env_spaceport_glass_noships_up_blur.jpg`, options: ["repeat", "lodTarget=1024"] },
  },
  spaceportPBR: {
    'spaceportGlassORM': { url: `/glxp/textures/spaceport_v2/pbr/T_Glass_ORM.png`, options: ["repeat", "compressed", "lodTarget=1024"] },
  },
  spaceportUnlit: {
    // V4
    'M_Skybox': { url: `/glxp/textures/spaceport_v2/unlit/T_Skybox_D.png`, options: ["repeat", "lodTarget=2048"] },
    'M_Mountains': { url: `/glxp/textures/spaceport_v2/unlit/T_Mountains_D.png`, options: ["repeat", "lodTarget=1024"] },
    'M_Mountains_Opacity': { url: `/glxp/textures/spaceport_v2/unlit/T_Mountains_Alpha_Blur.png`, options: ["repeat", "lodTarget=1024"] },
    'M_Pavement_L': { url: `/glxp/textures/spaceport_v2/unlit/T_Pavement_L.png`, options: ["repeat", "compressed", "lodTarget=2048"] },
    'M_Pavement_R': { url: `/glxp/textures/spaceport_v2/unlit/T_Pavement_R.png`, options: ["repeat", "compressed", "lodTarget=2048"] },
    'M_Pavement_Sandroof': { url: `/glxp/textures/spaceport_v2/unlit/T_Pavement_SandRoof_D.png`, options: ["repeat", "compressed", "lodTarget=1024"] },
    'M_Sandwings': { url: `/glxp/textures/spaceport_v2/unlit/T_Sandwings_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Spaceport_01': { url: `/glxp/textures/spaceport_v2/unlit/T_Spaceport_01_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Spaceport_02': { url: `/glxp/textures/spaceport_v2/unlit/T_Spaceport_02_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Terrain_01_L': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_01_L_D.png`, options: ["repeat", "compressed", "lodTarget=2048"] },
    'M_Terrain_01_R': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_01_R_D.png`, options: ["repeat", "compressed", "lodTarget=2048"] },
    'M_Terrain_02_L': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_02_L_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Terrain_02_R': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_02_R_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Terrain_03': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_03_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Terrain_04': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_04_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Terrain_05': { url: `/glxp/textures/spaceport_v2/unlit/T_Terrain_05_D.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_TerrainTowardsMountains': { url: `/glxp/textures/spaceport_v2/unlit/T_TerrainTowardsMountains_D.png`, options: ["repeat", "compressed", "lodTarget=1024"] },
    'M_SpaceShips_Shadow': { url: `/glxp/textures/spaceport_v2/unlit/SpaceShips_Shadow_D.png`, options: ["repeat", "compressed", "lodTarget=256"], material: { renderOrder: 2, depthWrite: false } },
    'M_SpaceShips_Shadow_Opacity': { url: `/glxp/textures/spaceport_v2/unlit/SpaceShips_Shadow_A.png`, options: ["repeat", "compressed", "lodTarget=512"] },
    'M_Fence': { url: `/glxp/textures/spaceport_v2/unlit/T_Fence_D.png`, options: ["repeat", "lodTarget=512"], material: { cullFace: false, textureScale: 100, renderOrder: 2, depthWrite: false } },
    'M_Fence_Opacity': { url: `/glxp/textures/spaceport_v2/unlit/T_Fence_A.png`, options: ["repeat", "lodTarget=512"] },
    'M_Props': { url: `/glxp/textures/spaceport_v2/unlit/T_Props_D.png`, options: ["repeat", "compressed", "lodTarget=1024"] },
    // 'M_UsaFlag': { url: `/glxp/textures/spaceport_v2/unlit/usa_flag.png`, options: ["repeat", "lodTarget=256"] },
    // 'M_FranceFlag': { url: `/glxp/textures/spaceport_v2/unlit/fra_flag.png`, options: ["repeat", "lodTarget=256"] },
    // 'M_BelgiumFlag': { url: `/glxp/textures/spaceport_v2/unlit/bel_flag.png`, options: ["repeat", "lodTarget=256"] },
    'M_VgFlag': { url: `/glxp/textures/spaceport_v2/unlit/vg_flag.png`, options: ["repeat"] },
    'M_MetalMatcap': { url: `/glxp/textures/matcap.png`, options: [] },
  },
  // Lens flares
  defaultLensFlares: {
    'Glow_Stripes': { url: `/glxp/textures/flares/default/glow-stripes.png`, options: ["transparent", "lodTarget=256"] },
    'Glow_00': { url: `/glxp/textures/flares/default/glow.png`, options: ["transparent", "lodTarget=256"] },
    'Glow_01': { url: `/glxp/textures/flares/default/glow1.png`, options: ["transparent", "lodTarget=256"] },
    'Hexa_00': { url: `/glxp/textures/flares/default/hexa.png`, options: ["transparent", "lodTarget=256"] },
    'Hexa_01': { url: `/glxp/textures/flares/default/hexa1.png`, options: ["transparent", "lodTarget=256"] },
    'Hexa_02': { url: `/glxp/textures/flares/default/hexa2.png`, options: ["transparent", "lodTarget=256"] },
    'Arc_00': { url: `/glxp/textures/flares/default/arc.png`, options: ["transparent", "lodTarget=256"] },
    'Arc_01': { url: `/glxp/textures/flares/default/arc1.png`, options: ["transparent", "lodTarget=256"] },

    'postprocessing_dirt': { url: `/glxp/textures/flares/dirt/dirt.png`, options: ["lodTarget=512"] },
  },
  circularLensFlares: {
    'Circular_Partial_00': { url: `/glxp/textures/flares/circular/partial.png`, options: ["transparent", "lodTarget=256"] },
    'Circular_Full_00': { url: `/glxp/textures/flares/circular/full.png`, options: ["transparent", "lodTarget=256"] },
  },
  JJAbramsLensFlares: {
    'Purple_Glow': { url: `/glxp/textures/flares/jjabrams/glow.png`,                  options: ["transparent", "lodTarget=256"] },
    'Spikes': { url: `/glxp/textures/flares/jjabrams/spikes.png`,                     options: ["transparent", "lodTarget=256"] },
    'Orb': { url: `/glxp/textures/flares/jjabrams/orb.png`,                           options: ["transparent", "lodTarget=256"] },
    'Stripe_01': { url: `/glxp/textures/flares/jjabrams/stripe_1_centered.png`,       options: ["transparent", "lodTarget=256"] },
    'Stripe_02': { url: `/glxp/textures/flares/jjabrams/stripe_3.png`,                options: ["transparent", "lodTarget=256"] },
    'Stripe_03': { url: `/glxp/textures/flares/jjabrams/stripe_4.png`,                options: ["transparent", "lodTarget=256"] },
    'Chromatic_Stripe': { url: `/glxp/textures/flares/jjabrams/chromatic_stripe.png`, options: ["transparent", "lodTarget=256"] },

    'postprocessing_dirt': { url: `/glxp/textures/flares/dirt/dirt.png`, options: ["lodTarget=512"] },
  },
  simonDhaenensLensFlares: {
    'Simon_Glow': { url: `/glxp/textures/flares/simonDhaenens/glow_v2.png`, options: ["transparent", "lodTarget=256"] },
    'Simon_Arc_00': { url: `/glxp/textures/flares/simonDhaenens/arc.png`, options: ["transparent", "lodTarget=256"] },
    'Simon_Arc_01': { url: `/glxp/textures/flares/simonDhaenens/arc1.png`, options: ["transparent", "lodTarget=256"] },
    'Simon_Arc_01_Blob': { url: `/glxp/textures/flares/simonDhaenens/arc1_blobbed.png`, options: ["transparent", "lodTarget=256"] },
    'Blob': { url: `/glxp/textures/flares/simonDhaenens/blob.png`, options: ["transparent", "lodTarget=256"] },

    'postprocessing_dirt': { url: `/glxp/textures/flares/dirt/dirt.png`, options: ["lodTarget=512"] },
  },

  // Misc
  audio: {
    // 'audio_intro': `/assets/audios/01.ogg`,
  },
  mp3: {
    // 'audio_intro': `/assets/audios/01.mp3`,
  },

  materials: {
    "Exemple": {
      albedoColor: "#ffffff",
      albedoMap: "foo",
      ORMTexture: "foo",
      // metallicRoughnessTexture: "foo",
      emissiveTexture: "foo",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 2.5,
      occlusionTexture: "foo",
      normalTexture: "foo",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 0,
      RoughnessFactor: 1,
      OcclusionFactor: 1,
      sheenColor: "#ffffff",
      sheenOpacity: 1,
      sheenDepth: 2
    },

    // Imagine
    "M_SpaceShip_Imagine": {
      albedoColor: "#ffffff",
      albedoMap: "imagineDiffuse",
      ORMTexture: "imagineORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "imagineNormals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 0.2,
      OcclusionFactor: 1,
    },

    // Spaceport
    "M_Glass_PBR": {
      albedoColor: "#828282",
      albedoMap: "white",
      ORMTexture: "spaceportGlassORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "default_normals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 0,
      OcclusionFactor: 1,
    },
    // Unity
    "M_SpaceShip_Unity": {
      albedoColor: "#ffffff",
      albedoMap: "unityDiffuse",
      ORMTexture: "unityORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "unityNormals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 1,
      OcclusionFactor: 1,
    },
    "M_SpaceShip_Unity_Sheen": {
      albedoColor: "#ffffff",
      albedoMap: "unityDiffuse",
      ORMTexture: "unityORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "unityNormals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: .5,
      OcclusionFactor: 1,

      sheenColor: "#ffffff",
      sheenOpacity: .7,
      sheenDepth: 10
    },
    "M_SpaceShip_Decal_Unity": {
      albedoColor: "#ffffff",
      albedoMap: "unityDecalDiffuse",
      ORMTexture: "unityDecalORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "unityDecalNormals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1.8,
      RoughnessFactor: .9,
      OcclusionFactor: 1,
    },
    "M_Spaceship_Unity_Text": {
      albedoColor: "#ffffff",
      albedoMap: "unityDecalTextDiffuse",
      alphaTexture: "unityDecalTextAlpha",
      // ORMTexture: "unityTextORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "unityDecalTextNormal",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 0,
      OcclusionFactor: 1,
      // sheenColor: "#ffffff",
      // sheenOpacity: .7,
      // sheenDepth: 10
    },
    "M_Spaceship_Unity_Windows": {
      albedoColor: "#ffffff",
      albedoMap: "unityDecalTextDiffuse",
      alphaTexture: "unityDecalTextAlpha",
      // ORMTexture: "unityTextORM",
      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "unityDecalTextNormal",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 0,
      OcclusionFactor: 1,
      // sheenColor: "#ffffff",
      // sheenOpacity: .6,
      // sheenDepth: 3.3
    },
    // Eve
    "M_SpaceShip_Eve": {
      albedoColor: "#ffffff",
      albedoMap: "eveDiffuse",
      ORMTexture: "eveORM",
      // metallicRoughnessTexture: "eveRM",

      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "eveNormals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 1,
      OcclusionFactor: 1,
    },
    "M_SpaceShip_Eve_Sheen": {
      albedoColor: "#ffffff",
      albedoMap: "eveDiffuse",
      ORMTexture: "eveORM",
      // metallicRoughnessTexture: "eveMR",

      emissiveTexture: "black",
      emissiveFactor: "#FFFFFF",
      emissiveColor: "#ff2613",
      emissivePower: 0,
      normalTexture: "eveNormals",
      IBL: 1,
      NormalScale: 1,
      MetalicFactor: 1,
      RoughnessFactor: 1,
      OcclusionFactor: 1,

      sheenColor: "#ffffff",
      sheenOpacity: .7,
      sheenDepth: 10
    },
  }
}
