import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import { Sphere } from '~/glxp/ogl/extras/Sphere.js'
import { Plane } from '~/glxp/ogl/extras/Plane.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { vec3 } from 'gl-matrix'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import DebugController from '~/glxp/debug/debugController'
import { Color } from '../ogl/math/Color'
import { GUI_PANEL_CUSTOM } from '../data/dataGUIPanels'
import { map } from '../utils/math'

class BackgroundTextureEntity {
  constructor(
    scene,
    shaderId,
    textureId,
    {
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = false,
      depthTest = true,
      depthWrite = true,
      renderOrder = 0,
      name = 'Background',
      color = "#0b0415",
      gradientColor = "#6866BB",
      gradientEdges = { x: 0, y: 0.1 },
      opacity = .28
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name
    this.color = color
    this.gradientColor = gradientColor
    this.textureId = textureId
    this.texture = new Texture(this.gl)

    this.shader = new Shader(ShaderManifest[shaderId])

    this.config = {
      GradientEdges: { value: gradientEdges, params: { x: { min: 0, max: 1 }, y: { min: 0, max: 1 } } },
      TextureTranslation: { value: { x: 0.165, y: 0.12 }, params: { x: { min: -1, max: 1, step: .001 }, y: { min: -1, max: 1, step: .001 } } },
      TextureScale: { value: { x: 0.475, y: 0.475 }, params: { x: { min: 0, max: 1 , step: .001}, y: { min: 0, max: 1 , step: .001} } },
      Opacity: { value: opacity, params: { min: 0, max: 1 } },
      GradientAngle: { value: -3.1415, params: { min: -3.1415, max: 3.1415 } }
    }

    this.init()
  }

  initGui() {
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
    this.gui && this.gui.params.GradientEdges.on("change", (e) => {
      this.program.uniforms.uGradientEdges.value[0] = e.value.x
      this.program.uniforms.uGradientEdges.value[1] = e.value.y
    })
    this.gui && this.gui.params.TextureTranslation.on("change", (e) => {
      this.program.uniforms.uTextureTranslation.value[0] = e.value.x
      this.program.uniforms.uTextureTranslation.value[1] = e.value.y
    })
    this.gui && this.gui.params.TextureScale.on("change", (e) => {
      this.program.uniforms.uTextureScale.value[0] = e.value.x
      this.program.uniforms.uTextureScale.value[1] = e.value.y
    })
  }

  init() {
    this.geometry = new Sphere(this.gl, { radius: 500 })
    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      uniforms: {
        uColor: { value: new Color(this.color) },
        uGradientColor: { value: new Color(this.gradientColor) },
        uGradientEdges: { value: [this.config.GradientEdges.value.x, this.config.GradientEdges.value.y] },
        uGradientOpacity: this.config.Opacity,
        uAlpha: { value: 1 },
        uResolution: { value: [this.scene.width, this.scene.height] },
        uGradientAngle: this.config.GradientAngle,
        uTexture: { value: this.texture },
        uTextureTranslation: { value: [this.config.TextureTranslation.value.x, this.config.TextureTranslation.value.y] },
        uTextureScale: { value: [this.config.TextureScale.value.x, this.config.TextureScale.value.y] }
      },
      cullFace: this.gl.FRONT,
      transparent: this.transparent,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
    })

    this.program.cullFace = false
    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)
    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
    })
    this.mesh.scale.set(this.scale, this.scale, this.scale)
    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  onLoaded() {
    this.initGui()

    this.texture = this.scene.textureLoader.getTexture(this.textureId)
    this.texture.needsUpdate = true
    this.program.uniforms['uTexture'].value = this.texture
  }

  updateTranslation(progress){
    this.program.uniforms.uTextureTranslation.value[0] = map(progress, 0, 1, .54, -.54)
  }

  preRender() {
    this.program.uniforms['uResolution'].value = [this.scene.width, this.scene.height]
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default BackgroundTextureEntity
