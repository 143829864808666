import { GUI_PANEL_UNLIT } from '../data/dataGUIPanels'
import DebugController from '~/glxp/debug/debugController'
import MeshUnlit from '~/glxp/entities/MeshUnlit'
import GUIMaterialManifest from '~/glxp/guiMaterialManifest'
import { Color } from '~/glxp/ogl/math/Color'

class MeshFlag extends MeshUnlit {
  constructor(scene, data, texture, opts = {}) {
    super(scene, data, texture, opts)

    this.program.uniforms['uTime'] = { value: 0 }
    this.program.uniforms['uMeshScale'] = this.config.MeshScale
    this.program.uniforms['uClothWaveAmplitude'] = this.config.ClothWaveAmplitude
    this.program.uniforms['uClothWaveFrequency'] = this.config.ClothWaveFrequency
    this.program.uniforms['uUvScaleX'] = this.config.UvScaleX
    this.program.uniforms['uUvScaleY'] = this.config.UvScaleY
    this.program.uniforms['uUvOffsetX'] = this.config.UvOffsetX
    this.program.uniforms['uUvOffsetY'] = this.config.UvOffsetY
    this.program.uniforms['uDirectionFactor'] = { value: opts.directionFactor || 1 }
  }

  initConfig() {
    const guiName = `${this.scene.name} - Flag`

    this.config = {
      ...GUIMaterialManifest.createUnlitMaterial(guiName),
      MeshScale: { value: 0.005, params: {max: 1, min: 0} }, 
      ClothWaveAmplitude: { value: 0.2, params: {} },
      ClothWaveFrequency: { value: 3.5, params: {} },
      UvScaleX: { value: 22, params: {} },
      UvScaleY: { value: 42.1, params: {} },
      UvOffsetX: { value: 0.001, params: {} },
      UvOffsetY: { value: 0.535, params: {} },
    }

    this.config['Exposure'] = { value: -.1, params: {max: 2, min: -2} }
    this.config['Tint'] = {value: "#4c0764", params: {}}

    delete this.config['TextureScale']
    delete this.config['Contrast']

    this.gui = DebugController.addBlade(this.config, guiName, GUI_PANEL_UNLIT)

    this.gui && this.gui.on('change', () => {
      this.program.uniforms['uTint'].value = new Color(this.config.Tint.value)
    })
  }

  preRender() {
    // console.log(this.scene.time);
    this.program.uniforms['uTime'].value = this.scene.time
  }
}

export default MeshFlag
