const VERTICES = [0,-0.15,-1.45,0.15,0,-1.45,0,0,-1.45,0,0,-1.75,0,0,-1.75,0.15,0,-1.45,0,-0.15,-1.45,-0.15,0,-1.45,0,0,-1.75,0,0,-1.75,0,-0.15,-1.45,-0.15,0,-1.45,0,0.15,-1.45,0,0,-1.75,0,0,-1.75,-0.15,0,-1.45,0,0.15,-1.45,0,0,-1.75,0,0,-1.75,0,0.15,-1.45,0.15,0,-1.45,0.05,0.05,0,-0.05,0.05,0,-0.05,-0.05,0,0.05,-0.05,0,0.05,0.05,-1.5,0.05,0.05,0,0.05,-0.05,0,0.05,-0.05,-1.5,-0.05,0.05,-1.5,0.05,0.05,-1.5,0.05,-0.05,-1.5,-0.05,-0.05,-1.5,-0.05,0.05,0,-0.05,0.05,-1.5,-0.05,-0.05,-1.5,-0.05,-0.05,0,0.05,0.05,-1.5,-0.05,0.05,-1.5,-0.05,0.05,0,0.05,0.05,0,0.05,-0.05,0,-0.05,-0.05,0,-0.05,-0.05,-1.5,0.05,-0.05,-1.5]
const INDICES = [0,1,2,3,4,5,6,3,5,7,0,2,8,9,10,11,8,10,12,7,2,13,14,15,16,13,15,1,12,2,17,18,19,20,17,19,21,22,23,24,21,23,25,26,27,28,25,27,29,30,31,32,29,31,33,34,35,36,33,35,37,38,39,40,37,39,41,42,43,44,41,43]
const UVS = [ 0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1]
const NORMAL = new Array(135)

export default {
  vertices: VERTICES,
  uvs: UVS,
  indices: INDICES,
  normal: NORMAL
}