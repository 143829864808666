import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'
import DebugController from '~/glxp/debug/debugController'
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import PBRUtils from '~/glxp/utils/PBRUtils'

import { vec3, mat4 } from 'gl-matrix'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Color } from '~/glxp/ogl/math/Color'

import Manifest from '~/glxp/manifest'
import GUIMaterialManifest from '~/glxp/guiMaterialManifest'

import MeshPBR from './MeshPBR'

class MeshPBRWindows extends MeshPBR {
  constructor() {
    super(...arguments)

    if (this.windowsTextureId) {
      this.windowsTexture = new Texture(this.gl)
    }
  }

  init() {
    this.environment = this.globalConfig.Environment.value
    this.diffuseenvironment = this.globalConfig.EnvironmentDiffuse.value
    this.altEnvironment = new Texture(this.gl)
    this.lightColor = new Color(this.globalConfig.lightColor.value)

    this.localState.uniforms.uAltEnvironment = {value: this.altEnvironment}

    let attribs = {
      position: { size: 3, data: this.geom.vertices },
      index: { data: this.geom.indices },
    }

    if (this.geom.normals) {
      this.defines['HAS_NORMALS'] = 1
      attribs = Object.assign(attribs, {
        normal: { size: 3, data: this.geom.normals },
      })
    }
    if (this.geom.uvs) {
      this.defines['HAS_UV'] = 1
      attribs = Object.assign(attribs, {
        uv: { size: 2, data: this.geom.uvs },
      })
    }

    // NEW
    if (this.windowsTextureId) this.defines.HAS_WINDOW_MAP = 1

    this.shader = new Shader(ShaderManifest[this.shaderId], 1, this.defines)
    this.geometry = new Geometry(this.gl, attribs)
    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      cullFace: this.gl.BACK,
      uniforms: this.localState.uniforms,
    })

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      transform: this.transform,
      frustumCulled: false,
      renderOrder: this.renderOrder,
      forceRenderOrder: this.forceRenderOrder
    })
    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  initConfig() {
    this.config = GUIMaterialManifest.createPBRMaterial(this.guiName)

    // NEW
    this.config['WindowSheenOpacity'] = { value: 1.3, params: { min: 0, max: 3 } }
    this.config['WindowSheenDepth'] = { value: 3, params: { min: 0, max: 20 } }

    if (DebugController.guiIsReady) {
      this.initGUI()
    } else {
      DebugController.on('gui-lazyloaded', this.initGUI.bind(this))
    }
  }

  onLoaded() {
    // NEW
    this.localState.uniforms.uWindowSheenOpacity = this.config['WindowSheenOpacity']
    this.localState.uniforms.uWindowSheenDepth = this.config['WindowSheenDepth']

    for (const key in this.localState.uniforms) {
      if (Object.hasOwnProperty.call(this.localState.uniforms, key)) {
        if (this.localState.uniforms[key].type == 'texture') {
          this.localState.uniforms[key].value =
            this.scene.textureLoader.getTexture(
              this.localState.uniforms[key].uri
            )
          this.localState.uniforms[key].value.needsUpdate = true
        }
      }
    }

    PBRUtils.cleanConfig(this)

    // NEW
    if (this.windowsTextureId) {
      this.localState.uniforms.uWindowsTexture = { value: this.windowsTexture }
      this.windowsTexture = this.scene.textureLoader.getTexture(this.windowsTextureId)
      this.windowsTexture.needsUpdate = true
      this.localState.uniforms['uWindowsTexture'].value = this.windowsTexture
    }

    this.altEnvironment = this.scene.textureLoader.getTexture("env_spacecraft_fleet_unity3")
    this.altEnvironment.needsUpdate = true
    this.localState.uniforms['uAltEnvironment'].value = this.altEnvironment
  }
}

export default MeshPBRWindows
