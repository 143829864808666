const out = {
   "PBR_CONFIG_HEADER":{
      "IBLDiffuseFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLSpecularFactor"
         }
      },
      "Environment":{
         "value":"env_clouds",
         "params":{
            "options":{
               "env_clouds":"env_clouds"
            },
            "label":"Environment"
         }
      },
      "EnvironmentDiffuse":{
         "value":"env_clouds_blur",
         "params":{
            "options":{
               "env_clouds_blur":"env_clouds_blur"
            },
            "label":"EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset":{
         "value":0.175,
         "params":{
            "min":0,
            "max":1,
            "step":0.001,
            "label":"EnvRotationOffset"
         }
      },
      "lightColor":{
         "value":"#ffffff",
         "params":{
            "label":"lightColor"
         }
      },
      "lightPosition":{
         "value":{
            "x":0,
            "y":-100,
            "z":-10
         },
         "params":{
            "x":{
               "step":1
            },
            "y":{
               "step":1
            },
            "z":{
               "step":1
            },
            "label":"lightPosition"
         }
      },
      "emissiveColor":{
         "value":"#000000",
         "params":{
            "label":"emissiveColor"
         }
      },
      "FogColor":{
         "value":"#ffffff",
         "params":{
            "label":"FogColor"
         }
      },
      "FogNear":{
         "value":15,
         "params":{
            "min":0,
            "max":50,
            "step":0.01,
            "label":"FogNear"
         }
      },
      "FogFar":{
         "value":25,
         "params":{
            "min":0,
            "max":50,
            "step":0.01,
            "label":"FogFar"
         }
      },
      "lightPower":{
         "value":1,
         "params":{
            "min":0,
            "max":10,
            "step":0.01,
            "label":"lightPower"
         }
      },
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "Debug":{
         "value":"disabled",
         "params":{
            "options":{
               "disabled":"disabled",
               "baseColor":"baseColor",
               "metallic":"metallic",
               "roughness":"roughness",
               "specRef":"specRef",
               "geomOcc":"geomOcc",
               "mcrfctDist":"mcrfctDist",
               "spec":"spec",
               "mathDiff":"mathDiff"
            },
            "label":"Debug"
         }
      },
      "OverwriteEnvIBL":{
         "value":false,
         "type":"bool"
      },
      "EnvIBL":{
         "value":{
            
         },
         "type":"input-image",
         "input":null
      }
   },
   "PBR_CONFIG_SPACEPORT":{
      "IBLDiffuseFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLSpecularFactor"
         }
      },
      "Environment":{
         "value":"env_spaceport",
         "params":{
            "options":{
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur"
            },
            "label":"Environment"
         }
      },
      "EnvironmentDiffuse":{
         "value":"env_spaceport_blur",
         "params":{
            "options":{
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur"
            },
            "label":"EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset":{
         "value":0.75,
         "params":{
            "min":0,
            "max":1,
            "step":0.001,
            "label":"EnvRotationOffset"
         }
      },
      "lightColor":{
         "value":"#ffffff",
         "params":{
            "label":"lightColor"
         }
      },
      "lightPosition":{
         "value":{
            "x":1,
            "y":50,
            "z":-1000
         },
         "params":{
            "x":{
               "step":1
            },
            "y":{
               "step":1
            },
            "z":{
               "step":1
            },
            "label":"lightPosition"
         }
      },
      "emissiveColor":{
         "value":"#000000",
         "params":{
            "label":"emissiveColor"
         }
      },
      "FogColor":{
         "value":"#4c4a82",
         "params":{
            "label":"FogColor"
         }
      },
      "FogNear":{
         "value":0,
         "params":{
            "min":0,
            "max":1000,
            "step":0.1,
            "label":"FogNear"
         }
      },
      "FogFar":{
         "value":450,
         "params":{
            "min":0,
            "max":1500,
            "step":1,
            "label":"FogFar"
         }
      },
      "lightPower":{
         "value":1,
         "params":{
            "min":0,
            "max":10,
            "step":0.01,
            "label":"lightPower"
         }
      },
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "Debug":{
         "value":"disabled",
         "params":{
            "options":{
               "disabled":"disabled",
               "baseColor":"baseColor",
               "metallic":"metallic",
               "roughness":"roughness",
               "specRef":"specRef",
               "geomOcc":"geomOcc",
               "mcrfctDist":"mcrfctDist",
               "spec":"spec",
               "mathDiff":"mathDiff"
            },
            "label":"Debug"
         }
      },
      "OverwriteEnvIBL":{
         "value":false,
         "type":"bool"
      },
      "EnvIBL":{
         "value":{
            
         },
         "type":"input-image",
         "input":null
      }
   },
   "PBR_CONFIG_SPACEPORT_GLASS_SHIPS":{
      "IBLDiffuseFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLSpecularFactor"
         }
      },
      "Environment":{
         "value":"env_spaceport_glass_ships",
         "params":{
            "options":{
               "spaceportGlassShips":"env_spaceport_glass_ships",
               "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
               "spaceportGlassNoShips":"env_spaceport_glass_noships",
               "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur"
            },
            "label":"Environment"
         }
      },
      "EnvironmentDiffuse":{
         "value":"env_spaceport_glass_ships_blur",
         "params":{
            "options":{
               "spaceportGlassShips":"env_spaceport_glass_ships",
               "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
               "spaceportGlassNoShips":"env_spaceport_glass_noships",
               "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur"
            },
            "label":"EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset":{
         "value":0.75,
         "params":{
            "min":0,
            "max":1,
            "step":0.001,
            "label":"EnvRotationOffset"
         }
      },
      "lightColor":{
         "value":"#ffffff",
         "params":{
            "label":"lightColor"
         }
      },
      "lightPosition":{
         "value":{
            "x":1,
            "y":50,
            "z":-1000
         },
         "params":{
            "x":{
               "step":1
            },
            "y":{
               "step":1
            },
            "z":{
               "step":1
            },
            "label":"lightPosition"
         }
      },
      "emissiveColor":{
         "value":"#000000",
         "params":{
            "label":"emissiveColor"
         }
      },
      "FogColor":{
         "value":"#4c4a82",
         "params":{
            "label":"FogColor"
         }
      },
      "FogNear":{
         "value":0,
         "params":{
            "min":0,
            "max":1000,
            "step":0.1,
            "label":"FogNear"
         }
      },
      "FogFar":{
         "value":450,
         "params":{
            "min":0,
            "max":1500,
            "step":1,
            "label":"FogFar"
         }
      },
      "lightPower":{
         "value":1,
         "params":{
            "min":0,
            "max":10,
            "step":0.01,
            "label":"lightPower"
         }
      },
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "Debug":{
         "value":"disabled",
         "params":{
            "options":{
               "disabled":"disabled",
               "baseColor":"baseColor",
               "metallic":"metallic",
               "roughness":"roughness",
               "specRef":"specRef",
               "geomOcc":"geomOcc",
               "mcrfctDist":"mcrfctDist",
               "spec":"spec",
               "mathDiff":"mathDiff"
            },
            "label":"Debug"
         }
      },
      "OverwriteEnvIBL":{
         "value":false,
         "type":"bool"
      },
      "EnvIBL":{
         "value":{
            
         },
         "type":"input-image",
         "input":null
      }
   },
   "PBR_CONFIG_SPACEPORT_GLASS_NOSHIPS":{
      "IBLDiffuseFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLSpecularFactor"
         }
      },
      "Environment":{
         "value":"env_spaceport_glass_noships",
         "params":{
            "options":{
               "spaceportGlassShips":"env_spaceport_glass_ships",
               "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
               "spaceportGlassNoShips":"env_spaceport_glass_noships",
               "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur"
            },
            "label":"Environment"
         }
      },
      "EnvironmentDiffuse":{
         "value":"env_spaceport_glass_noships_blur",
         "params":{
            "options":{
               "spaceportGlassShips":"env_spaceport_glass_ships",
               "spaceportGlassShipsBlur":"env_spaceport_glass_ships_blur",
               "spaceportGlassNoShips":"env_spaceport_glass_noships",
               "spaceportGlassNoShipsBlur":"env_spaceport_glass_noships_blur",
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur"
            },
            "label":"EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset":{
         "value":0.75,
         "params":{
            "min":0,
            "max":1,
            "step":0.001,
            "label":"EnvRotationOffset"
         }
      },
      "lightColor":{
         "value":"#ffffff",
         "params":{
            "label":"lightColor"
         }
      },
      "lightPosition":{
         "value":{
            "x":1,
            "y":50,
            "z":-1000
         },
         "params":{
            "x":{
               "step":1
            },
            "y":{
               "step":1
            },
            "z":{
               "step":1
            },
            "label":"lightPosition"
         }
      },
      "emissiveColor":{
         "value":"#000000",
         "params":{
            "label":"emissiveColor"
         }
      },
      "FogColor":{
         "value":"#4c4a82",
         "params":{
            "label":"FogColor"
         }
      },
      "FogNear":{
         "value":0,
         "params":{
            "min":0,
            "max":1000,
            "step":0.1,
            "label":"FogNear"
         }
      },
      "FogFar":{
         "value":450,
         "params":{
            "min":0,
            "max":1500,
            "step":1,
            "label":"FogFar"
         }
      },
      "lightPower":{
         "value":1,
         "params":{
            "min":0,
            "max":10,
            "step":0.01,
            "label":"lightPower"
         }
      },
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "Debug":{
         "value":"disabled",
         "params":{
            "options":{
               "disabled":"disabled",
               "baseColor":"baseColor",
               "metallic":"metallic",
               "roughness":"roughness",
               "specRef":"specRef",
               "geomOcc":"geomOcc",
               "mcrfctDist":"mcrfctDist",
               "spec":"spec",
               "mathDiff":"mathDiff"
            },
            "label":"Debug"
         }
      },
      "OverwriteEnvIBL":{
         "value":false,
         "type":"bool"
      },
      "EnvIBL":{
         "value":{
            
         },
         "type":"input-image",
         "input":null
      }
   },
   "PBR_CONFIG_SPACECRAFT_FLEET_EVE":{
      "IBLDiffuseFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLSpecularFactor"
         }
      },
      "Environment":{
         "value":"env_spacecraft_fleet_eve",
         "params":{
            "options":{
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur",
               "spacecraft_fleet_eve":"env_spacecraft_fleet_eve",
               "spacecraft_fleet_eve_blur":"env_spacecraft_fleet_eve_blur"
            },
            "label":"Environment"
         }
      },
      "EnvironmentDiffuse":{
         "value":"env_spacecraft_fleet_eve_blur",
         "params":{
            "options":{
               "spaceport":"env_spaceport",
               "spaceport_blur":"env_spaceport_blur",
               "spacecraft_fleet_eve":"env_spacecraft_fleet_eve",
               "spacecraft_fleet_eve_blur":"env_spacecraft_fleet_eve_blur"
            },
            "label":"EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset":{
         "value":0.75,
         "params":{
            "min":0,
            "max":1,
            "step":0.001,
            "label":"EnvRotationOffset"
         }
      },
      "lightColor":{
         "value":"#ffffff",
         "params":{
            "label":"lightColor"
         }
      },
      "lightPosition":{
         "value":{
            "x":0,
            "y":-4,
            "z":0
         },
         "params":{
            "x":{
               "step":1
            },
            "y":{
               "step":1
            },
            "z":{
               "step":1
            },
            "label":"lightPosition"
         }
      },
      "emissiveColor":{
         "value":"#000000",
         "params":{
            "label":"emissiveColor"
         }
      },
      "FogColor":{
         "value":"#4c4a82",
         "params":{
            "label":"FogColor"
         }
      },
      "FogNear":{
         "value":0,
         "params":{
            "min":0,
            "max":1000,
            "step":0.1,
            "label":"FogNear"
         }
      },
      "FogFar":{
         "value":450,
         "params":{
            "min":0,
            "max":1500,
            "step":1,
            "label":"FogFar"
         }
      },
      "lightPower":{
         "value":1,
         "params":{
            "min":0,
            "max":10,
            "step":0.01,
            "label":"lightPower"
         }
      },
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "Debug":{
         "value":"disabled",
         "params":{
            "options":{
               "disabled":"disabled",
               "baseColor":"baseColor",
               "metallic":"metallic",
               "roughness":"roughness",
               "specRef":"specRef",
               "geomOcc":"geomOcc",
               "mcrfctDist":"mcrfctDist",
               "spec":"spec",
               "mathDiff":"mathDiff"
            },
            "label":"Debug"
         }
      },
      "OverwriteEnvIBL":{
         "value":false,
         "type":"bool"
      },
      "EnvIBL":{
         "value":{
            
         },
         "type":"input-image",
         "input":null
      }
   },
   "PBR_CONFIG_SPACECRAFT_FLEET_UNITY":{
      "IBLDiffuseFactor":{
         "value":0.01999999999999998,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLDiffuseFactor"
         }
      },
      "IBLSpecularFactor":{
         "value":2,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"IBLSpecularFactor"
         }
      },
      "Environment":{
         "value":"env_spacecraft_fleet_unity",
         "params":{
            "options":{
               "env_spacecraft_fleet_unity":"env_spacecraft_fleet_unity",
               "env_spacecraft_fleet_unity2":"env_spacecraft_fleet_unity2",
               "env_spacecraft_fleet_unity3":"env_spacecraft_fleet_unity3",
               "env_spacecraft_fleet_unity4":"env_spacecraft_fleet_unity4",
               "env_clouds_blur":"env_clouds_blur",
               "env_clouds":"env_clouds",
               "env_clouds_flipped":"env_clouds_flipped",
               "env_clouds_bottom_blur_x1":"env_clouds_bottom_blur_x1",
            },
            "label":"Environment"
         }
      },
      "EnvironmentDiffuse":{
         "value":"env_spacecraft_fleet_unity",
         "params":{
            "options":{
               "env_spacecraft_fleet_unity":"env_spacecraft_fleet_unity",
               "env_spacecraft_fleet_unity2":"env_spacecraft_fleet_unity2",
               "env_spacecraft_fleet_unity3":"env_spacecraft_fleet_unity3",
               "env_spacecraft_fleet_unity4":"env_spacecraft_fleet_unity4",
               "env_clouds_blur":"env_clouds_blur",
               "env_clouds":"env_clouds",
               "env_clouds_flipped":"env_clouds_flipped",
               "env_clouds_bottom_blur_x1":"env_clouds_bottom_blur_x1",
            },
            "label":"EnvironmentDiffuse"
         }
      },
      "EnvRotationOffset":{
         "value":0.175,
         "params":{
            "min":0,
            "max":1,
            "step":0.001,
            "label":"EnvRotationOffset"
         }
      },
      "lightColor":{
         "value":"#5689cf",
         "params":{
            "label":"lightColor"
         }
      },
      "lightPosition":{
         "value":{
            "x":0.17000000000007276,
            "y":-100,
            "z":0
         },
         "params":{
            "x":{
               "step":1
            },
            "y":{
               "step":1
            },
            "z":{
               "step":1
            },
            "label":"lightPosition"
         }
      },
      "lightPower":{
         "value":10,
         "params":{
            "min":0,
            "max":10,
            "step":0.01,
            "label":"lightPower"
         }
      },
      "emissiveColor":{
         "value":"#000000",
         "params":{
            "label":"emissiveColor"
         }
      },
      "FogColor":{
         "value":"#ffffff",
         "params":{
            "label":"FogColor"
         }
      },
      "FogNear":{
         "value":15,
         "params":{
            "min":0,
            "max":50,
            "step":0.01,
            "label":"FogNear"
         }
      },
      "FogFar":{
         "value":25,
         "params":{
            "min":0,
            "max":50,
            "step":0.01,
            "label":"FogFar"
         }
      },
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "Debug":{
         "value":"disabled",
         "params":{
            "options":{
               "disabled":"disabled",
               "baseColor":"baseColor",
               "metallic":"metallic",
               "roughness":"roughness",
               "specRef":"specRef",
               "geomOcc":"geomOcc",
               "mcrfctDist":"mcrfctDist",
               "spec":"spec",
               "mathDiff":"mathDiff"
            },
            "label":"Debug"
         }
      },
      "OverwriteEnvIBL":{
         "value":false,
         "type":"bool"
      },
      "EnvIBL":{
         "value":{
            
         },
         "type":"input-image",
         "input":null
      }
   },
   "Global Settings - Flight Experience":{
      "Pause":{
         "value":false,
         "type":"bool"
      },
      "TimeScale":{
         "value":1,
         "params":{
            "min":0,
            "max":3,
            "step":0.01,
            "label":"TimeScale"
         }
      },
      "ScrollDamping":{
         "value":0.05,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"ScrollDamping"
         }
      },
      "SunPosition":{
         "value":{
            "x":-10.5,
            "y":0,
            "z":-10.15
         },
         "params":{
            "label":"SunPosition"
         }
      },
      "OverrideScroll":{
         "value":false,
         "params":{
            "label":"OverrideScroll"
         }
      },
      "Progress":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Progress"
         }
      },
      "DevScrollSpeed":{
         "value":30,
         "params":{
            "min":0,
            "max":60,
            "label":"DevScrollSpeed"
         }
      }
   },
   "Post Processing - Flight Experience":{
      "BloomOpacity":{
         "value":0.25,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"BloomOpacity"
         }
      },
      "NoiseOpacity":{
         "value":0.03,
         "params":{
            "min":0,
            "max":0.2,
            "label":"NoiseOpacity"
         }
      },
      "Gamma":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Gamma"
         }
      },
      "Exposure":{
         "value":0.05,
         "params":{
            "min":-2,
            "max":2,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Contrast":{
         "value":0.15,
         "params":{
            "min":-1,
            "max":1,
            "step":0.01,
            "label":"Contrast"
         }
      },
      "Vignette":{
         "value":0.25,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Vignette"
         }
      },
      "ChromaticAberations":{
         "value":0.05,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"ChromaticAberations"
         }
      },
      "VignetteStrength":{
         "value":0.5,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"VignetteStrength"
         }
      },
      "FXAA":{
         "value":true,
         "params":{
            "label":"FXAA"
         }
      },
      "Bloom":{
         "value":true,
         "params":{
            "label":"Bloom"
         }
      },
      "NoiseScale":{
         "value":0.0001,
         "params":{
            "min":1e-7,
            "max":0.0003,
            "label":"NoiseScale"
         }
      },
      "DirtDistanceFactor":{
         "value":5.5,
         "params":{
            "min":0,
            "max":10,
            "step":0.1,
            "label":"DirtDistanceFactor"
         }
      },
      "DirtOpacityFactor":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"DirtOpacityFactor"
         }
      },
      "DirtResolutionScale":{
         "value":{
            "x":2.03,
            "y":2
         },
         "params":{
            "x":{
               "min":0,
               "max":3,
               "step":0.01
            },
            "y":{
               "min":0,
               "max":3,
               "step":0.01
            },
            "label":"DirtResolutionScale"
         }
      },
      "DirtResolutionOffset":{
         "value":{
            "x":0.03,
            "y":0.32
         },
         "params":{
            "x":{
               "min":-3,
               "max":3,
               "step":0.01
            },
            "y":{
               "min":-3,
               "max":3,
               "step":0.01
            },
            "label":"DirtResolutionOffset"
         }
      }
   },
   "Flight Experience - Experience Background":{
      "ColorBackground":{
         "value":"#11021f",
         "params":{
            "label":"ColorBackground"
         }
      },
      "StarsRes":{
         "value":{
            "x":12,
            "y":8
         },
         "params":{
            "x":{
               "min":0,
               "max":20,
               "step":0.01
            },
            "y":{
               "min":0,
               "max":20,
               "step":0.01
            },
            "label":"StarsRes"
         }
      },
      "NoiseRes":{
         "value":{
            "x":10,
            "y":10
         },
         "params":{
            "x":{
               "min":0,
               "max":20,
               "step":0.01
            },
            "y":{
               "min":0,
               "max":20,
               "step":0.01
            },
            "label":"NoiseRes"
         }
      },
      "StarsRampStart":{
         "value":0.3,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"StarsRampStart"
         }
      },
      "StarsRampEnd":{
         "value":0.7,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"StarsRampEnd"
         }
      },
      "NoiseSpeed":{
         "value":-0.01,
         "params":{
            "min":-0.5,
            "max":0.5,
            "step":0.001,
            "label":"NoiseSpeed"
         }
      }
   },
   "Flight Experience - Experience Earth":{
      "TexturesOffset":{
         "value":{
            "x":-0.375,
            "y":0
         },
         "params":{
            "x":{
               "min":-1,
               "max":1,
               "step":0.01
            },
            "y":{
               "min":-1,
               "max":1,
               "step":0.01
            },
            "label":"TexturesOffset"
         }
      },
      "CycleStart":{
         "value":0,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"CycleStart"
         }
      },
      "CycleProgress":{
         "value":0.15,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"CycleProgress"
         }
      },
      "CycleLength":{
         "value":0.1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"CycleLength"
         }
      },
      "CloudsColorVariance":{
         "value":0.5,
         "params":{
            "min":0.01,
            "max":1,
            "step":0.01,
            "label":"CloudsColorVariance"
         }
      },
      "CloudsSpeedFactor":{
         "value":0.0015,
         "params":{
            "min":0,
            "max":0.1,
            "step":0.0001,
            "label":"CloudsSpeedFactor"
         }
      },
      "CloudsShadowOffset":{
         "value":{
            "x":-0.002,
            "y":0.002
         },
         "params":{
            "x":{
               "min":-0.1,
               "max":0.1,
               "step":0.001
            },
            "y":{
               "min":-0.1,
               "max":0.1,
               "step":0.001
            },
            "label":"CloudsShadowOffset"
         }
      },
      "CloudsShadowIntensity":{
         "value":0.2,
         "params":{
            "min":0.01,
            "max":1,
            "step":0.01,
            "label":"CloudsShadowIntensity"
         }
      },
      "FresnelShadowColor":{
         "value":"#030523",
         "params":{
            "label":"FresnelShadowColor"
         }
      },
      "FresnelGlowColor":{
         "value":"#1a6dff",
         "params":{
            "label":"FresnelGlowColor"
         }
      },
      "FresnelGlowRimColor":{
         "value":"#1a6dff",
         "params":{
            "label":"FresnelGlowRimColor"
         }
      },
      "FresnelGlowIntensity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "label":"FresnelGlowIntensity"
         }
      },
      "GlowRotation":{
         "value":2,
         "params":{
            "min":0,
            "max":6.283185307179586,
            "step":0.01,
            "label":"GlowRotation"
         }
      },
      "GlowRotationFactor":{
         "value":0.5,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"GlowRotationFactor"
         }
      }
   },
   "Flight Experience - Experience Earth Clouds":{
      "Progress":{
         "value":0,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Progress"
         }
      },
      "CloudsResBig":{
         "value":{
            "x":6,
            "y":4
         },
         "params":{
            "x":{
               "min":0.1,
               "max":30,
               "step":0.01
            },
            "y":{
               "min":0.1,
               "max":30,
               "step":0.01
            },
            "label":"CloudsResBig"
         }
      },
      "CloudsResSmall":{
         "value":{
            "x":10,
            "y":8
         },
         "params":{
            "x":{
               "min":0.1,
               "max":30,
               "step":0.01
            },
            "y":{
               "min":0.1,
               "max":30,
               "step":0.01
            },
            "label":"CloudsResSmall"
         }
      },
      "SunPosition":{
         "value":{
            "x":0.445,
            "y":0.5
         },
         "params":{
            "x":{
               "min":0.1,
               "max":1,
               "step":0.01
            },
            "y":{
               "min":0.1,
               "max":1,
               "step":0.01
            },
            "label":"SunPosition"
         }
      },
      "GroundColor":{
         "value":"#b53f88",
         "params":{
            "label":"GroundColor"
         }
      },
      "CloudsColor":{
         "value":"#ffffff",
         "params":{
            "label":"CloudsColor"
         }
      },
      "FresnelColor":{
         "value":"#2f68cc",
         "params":{
            "label":"FresnelColor"
         }
      },
      "FresnelRimColor":{
         "value":"#9cc0ff",
         "params":{
            "label":"FresnelRimColor"
         }
      }
   },
   "Flight Experience - Experience Earth Glow":{
      "GlowRotation":{
         "value":2.4,
         "params":{
            "min":0,
            "max":6.283185307179586,
            "step":0.01,
            "label":"GlowRotation"
         }
      },
      "GlowRotationFactor":{
         "value":0,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"GlowRotationFactor"
         }
      },
      "LowColor":{
         "value":"#1a6dff",
         "params":{
            "label":"LowColor"
         }
      },
      "LowAlpha":{
         "value":0.5,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"LowAlpha"
         }
      },
      "RimColor":{
         "value":"#1a6dff",
         "params":{
            "label":"RimColor"
         }
      },
      "RimAlpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"RimAlpha"
         }
      }
   },
   "Flight Experience - Experience Earth Glow Top":{
      "RimColor":{
         "value":"#4497e2",
         "params":{
            "label":"RimColor"
         }
      },
      "RimAlpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"RimAlpha"
         }
      },
      "Position":{
         "value":0.539,
         "params":{
            "min":0.5,
            "max":0.6,
            "step":0.0001,
            "label":"Position"
         }
      },
      "LengthInner":{
         "value":0.003,
         "params":{
            "min":0,
            "max":0.2,
            "step":0.001,
            "label":"LengthInner"
         }
      },
      "LengthOuter":{
         "value":0.029500000000000002,
         "params":{
            "min":0,
            "max":0.2,
            "step":0.001,
            "label":"LengthOuter"
         }
      }
   },
   "Flight Experience - Experience Lines":{
      "LineWidth":{
         "value":0.03,
         "params":{
            "min":0.01,
            "max":1,
            "step":0.01,
            "label":"LineWidth"
         }
      },
      "DotsSmooth":{
         "value":0.01,
         "params":{
            "min":0.001,
            "max":0.5,
            "step":0.001,
            "label":"DotsSmooth"
         }
      },
      "DotsFrequency":{
         "value":200,
         "params":{
            "min":1,
            "max":300,
            "step":1,
            "label":"DotsFrequency"
         }
      },
      "ColorDots":{
         "value":"#ffffff",
         "params":{
            "label":"ColorDots"
         }
      },
      "ColorFull":{
         "value":"#a836ff",
         "params":{
            "label":"ColorFull"
         }
      },
      "ColorFullFresnel":{
         "value":"#265eac",
         "params":{
            "label":"ColorFullFresnel"
         }
      },
      "FresnelLength":{
         "value":0.5,
         "params":{
            "min":0.01,
            "max":1,
            "step":0.01,
            "label":"FresnelLength"
         }
      },
      "GlowOpacity":{
         "value":0.1,
         "params":{
            "min":0.01,
            "max":1,
            "step":0.01,
            "label":"GlowOpacity"
         }
      },
      "DottedLineProgress":{
         "value":0,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"DottedLineProgress"
         }
      },
      "FullLineProgress":{
         "value":0,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"FullLineProgress"
         }
      },
      "LineRot1":{
         "value":{
            "x":0.63,
            "y":-0.28,
            "z":-0.27
         },
         "params":{
            "x":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "y":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "z":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "label":"LineRot1"
         }
      },
      "LineRot2":{
         "value":{
            "x":1.55,
            "y":0.14,
            "z":-0.34
         },
         "params":{
            "x":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "y":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "z":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "label":"LineRot2"
         }
      },
      "LineRot3":{
         "value":{
            "x":0.23,
            "y":-0.72,
            "z":0.64
         },
         "params":{
            "x":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "y":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "z":{
               "min":-6.283185307179586,
               "max":6.283185307179586,
               "step":0.01
            },
            "label":"LineRot3"
         }
      }
   },
   "Flight Experience - Experience Sun":{
      "Alpha":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Alpha"
         }
      },
      "SunSize":{
         "value":0.19999999999999998,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"SunSize"
         }
      },
      "SunScaling":{
         "value":{
            "x":0.6,
            "y":1
         },
         "params":{
            "x":{
               "min":0,
               "max":5,
               "step":0.01
            },
            "y":{
               "min":0,
               "max":4,
               "step":0.001
            },
            "label":"SunScaling"
         }
      },
      "SunColorTaper":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"SunColorTaper"
         }
      },
      "ColorSunCenter":{
         "value":"#ffcf07",
         "params":{
            "label":"ColorSunCenter"
         }
      },
      "ColorSunOutside":{
         "value":"#e84e82",
         "params":{
            "label":"ColorSunOutside"
         }
      },
      "ColorFresnelCenter":{
         "value":"#2461e2",
         "params":{
            "label":"ColorFresnelCenter"
         }
      },
      "ColorFresnelOutside":{
         "value":"#462baf",
         "params":{
            "label":"ColorFresnelOutside"
         }
      },
      "ColorMinimumFactor":{
         "value":0.9999999999999999,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"ColorMinimumFactor"
         }
      }
   },
   "Flight Experience - Lens Flares":{
      "Spacing":{
         "value":0.114,
         "params":{
            "min":0,
            "max":0.3,
            "step":0.001,
            "label":"Spacing"
         }
      },
      "CenterFadePower":{
         "value":1,
         "params":{
            "min":-2,
            "max":2,
            "label":"CenterFadePower"
         }
      },
      "GlobalOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":10,
            "label":"GlobalOpacity"
         }
      }
   },
   "Flight Experience - Flare - Spikes":{
      "Opacity":{
         "value":10,
         "params":{
            "min":0,
            "max":30,
            "label":"Opacity"
         }
      },
      "Scale":{
         "value":{
            "x":2.0500000000000003,
            "y":1.7800000000000002
         },
         "params":{
            "min":0,
            "max":2,
            "label":"Scale"
         }
      },
      "Offset":{
         "value":{
            "x":0,
            "y":0
         },
         "params":{
            "x":{
               "min":-10,
               "max":10
            },
            "y":{
               "min":-10,
               "max":10
            },
            "label":"Offset"
         }
      },
      "Tint":{
         "value":"#6e14b2",
         "params":{
            "label":"Tint"
         }
      },
      "TintOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"TintOpacity"
         }
      },
      "Exposure":{
         "value":0,
         "params":{
            "min":-2,
            "max":3,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Saturation":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Saturation"
         }
      },
      "Rotation":{
         "value":0,
         "params":{
            "min":-3.141592653589793,
            "max":3.141592653589793,
            "label":"Rotation"
         }
      }
   },
   "Flight Experience - Flare - Orb":{
      "Opacity":{
         "value":5.25,
         "params":{
            "min":0,
            "max":30,
            "label":"Opacity"
         }
      },
      "Scale":{
         "value":{
            "x":2,
            "y":0.5
         },
         "params":{
            "min":0,
            "max":2,
            "label":"Scale"
         }
      },
      "Offset":{
         "value":{
            "x":0,
            "y":0.1
         },
         "params":{
            "x":{
               "min":-10,
               "max":10
            },
            "y":{
               "min":-10,
               "max":10
            },
            "label":"Offset"
         }
      },
      "Tint":{
         "value":"#0642ff",
         "params":{
            "label":"Tint"
         }
      },
      "TintOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"TintOpacity"
         }
      },
      "Exposure":{
         "value":0.6,
         "params":{
            "min":-2,
            "max":3,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Saturation":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Saturation"
         }
      },
      "RotationOffset":{
         "value":0,
         "params":{
            "min":-3.141592653589793,
            "max":3.141592653589793,
            "label":"RotationOffset"
         }
      }
   },
   "Flight Experience - Flare - Stripe_02":{
      "Opacity":{
         "value":1,
         "params":{
            "min":0,
            "max":30,
            "label":"Opacity"
         }
      },
      "Scale":{
         "value":{
            "x":1.4,
            "y":1
         },
         "params":{
            "min":0,
            "max":2,
            "label":"Scale"
         }
      },
      "Offset":{
         "value":{
            "x":0,
            "y":0
         },
         "params":{
            "x":{
               "min":-10,
               "max":10
            },
            "y":{
               "min":-10,
               "max":10
            },
            "label":"Offset"
         }
      },
      "Tint":{
         "value":"#ef1414",
         "params":{
            "label":"Tint"
         }
      },
      "TintOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"TintOpacity"
         }
      },
      "Exposure":{
         "value":0,
         "params":{
            "min":-2,
            "max":3,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Saturation":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Saturation"
         }
      },
      "Rotation":{
         "value":0,
         "params":{
            "min":-3.141592653589793,
            "max":3.141592653589793,
            "label":"Rotation"
         }
      }
   },
   "Flight Experience - Flare - Stripe_01":{
      "Opacity":{
         "value":2.5,
         "params":{
            "min":0,
            "max":30,
            "label":"Opacity"
         }
      },
      "Scale":{
         "value":{
            "x":0.4,
            "y":0.4
         },
         "params":{
            "min":0,
            "max":2,
            "label":"Scale"
         }
      },
      "Offset":{
         "value":{
            "x":-0.32,
            "y":-0.2
         },
         "params":{
            "x":{
               "min":-10,
               "max":10
            },
            "y":{
               "min":-10,
               "max":10
            },
            "label":"Offset"
         }
      },
      "Tint":{
         "value":"#00f6ff",
         "params":{
            "label":"Tint"
         }
      },
      "TintOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"TintOpacity"
         }
      },
      "Exposure":{
         "value":0,
         "params":{
            "min":-2,
            "max":3,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Saturation":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Saturation"
         }
      },
      "Rotation":{
         "value":0,
         "params":{
            "min":-3.141592653589793,
            "max":3.141592653589793,
            "label":"Rotation"
         }
      }
   },
   "Flight Experience - Flare - Stripe_03":{
      "Opacity":{
         "value":8,
         "params":{
            "min":0,
            "max":30,
            "label":"Opacity"
         }
      },
      "Scale":{
         "value":{
            "x":0.58,
            "y":0.6
         },
         "params":{
            "min":0,
            "max":2,
            "label":"Scale"
         }
      },
      "Offset":{
         "value":{
            "x":0.37,
            "y":-0.24
         },
         "params":{
            "x":{
               "min":-10,
               "max":10
            },
            "y":{
               "min":-10,
               "max":10
            },
            "label":"Offset"
         }
      },
      "Tint":{
         "value":"#00317c",
         "params":{
            "label":"Tint"
         }
      },
      "TintOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"TintOpacity"
         }
      },
      "Exposure":{
         "value":0,
         "params":{
            "min":-2,
            "max":3,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Saturation":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Saturation"
         }
      },
      "Rotation":{
         "value":0,
         "params":{
            "min":-3.141592653589793,
            "max":3.141592653589793,
            "label":"Rotation"
         }
      }
   },
   "Flight Experience - Flare - Chromatic_Stripe":{
      "Opacity":{
         "value":1,
         "params":{
            "min":0,
            "max":30,
            "label":"Opacity"
         }
      },
      "Scale":{
         "value":{
            "x":1,
            "y":1
         },
         "params":{
            "min":0,
            "max":2,
            "label":"Scale"
         }
      },
      "Offset":{
         "value":{
            "x":0.4,
            "y":-0.38
         },
         "params":{
            "x":{
               "min":-10,
               "max":10
            },
            "y":{
               "min":-10,
               "max":10
            },
            "label":"Offset"
         }
      },
      "Tint":{
         "value":"#ffffff",
         "params":{
            "label":"Tint"
         }
      },
      "TintOpacity":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"TintOpacity"
         }
      },
      "Exposure":{
         "value":0,
         "params":{
            "min":-2,
            "max":3,
            "step":0.01,
            "label":"Exposure"
         }
      },
      "Saturation":{
         "value":1,
         "params":{
            "min":0,
            "max":2,
            "step":0.01,
            "label":"Saturation"
         }
      },
      "Rotation":{
         "value":0,
         "params":{
            "min":-3.141592653589793,
            "max":3.141592653589793,
            "label":"Rotation"
         }
      }
   },
   "Bloom - Flight Experience":{
      "BloomTint":{
         "value":"#ffffff",
         "params":{
            "label":"BloomTint"
         }
      },
      "Threshold":{
         "value":0.5999999999999999,
         "params":{
            "min":0,
            "max":1.25,
            "step":0.01,
            "label":"Threshold"
         }
      },
      "Blur":{
         "value":1,
         "params":{
            "min":0,
            "max":1,
            "step":0.01,
            "label":"Blur"
         }
      },
      "Overdrive":{
         "value":0,
         "params":{
            "min":0,
            "max":3,
            "step":0.01,
            "label":"Overdrive"
         }
      },
      "Similarity":{
         "value":0.1,
         "params":{
            "min":0,
            "max":0.2,
            "step":0.001,
            "label":"Similarity"
         }
      }
   }
}
export default out