import { BREAKPOINTS } from '~~/utils/constants'

export default defineNuxtPlugin((nuxtApp) => {
  function preventOrphans(text) {
    if (window.innerWidth < BREAKPOINTS.L) {
      return text
    }

    if (text && typeof text === 'string') {
      const splitText = text.split(' ')

      if (splitText.length === 1) {
        return text
      }

      const lastWord = splitText.pop()

      /*
        it's ok if the orphan is long, for example:

        Experience a cognitive
        transformation
      */

      if (lastWord.length < 14) {
        return `${splitText.join(' ')}&nbsp;${lastWord}`
      }
    }

    return text
  }

  nuxtApp.provide('preventOrphans', preventOrphans)
})
