import { getProxyState } from '../utils/getProxyState'
import GlobalEmitter from '~/glxp/utils/emitter'
import { Texture } from '~/glxp/ogl/core/Texture.js'

/**
 * Modulates render quality settings according to performance targets
 */
export default class PerformanceManager {
  webglManager
  thresholds = {
    minimal: 0,
    low: 0,
    normal: 0
  }

  active = true
  state = getProxyState({ renderQuality: 'normal' })
  windowIsBlurred = false
  perfCheckHistory = []
  enableLogs = false

  constructor(webglManager, thresholds) {
    this.webglManager = webglManager
    this.thresholds = thresholds
    this.init()
  }

  init = () => {
    this.setEvents()
  }

  setEvents = () => {
    window.addEventListener('blur', () => {
      this.windowIsBlurred = true
    })

    window.addEventListener('visibilitychange', (e) => {
      if (document.visibilityState === 'visible') {
        this.windowIsBlurred = false
      } else {
        this.windowIsBlurred = true
      }
    })

    window.addEventListener('focus', () => {
      this.windowIsBlurred = false
    })

    GlobalEmitter.on('webgl_loaded', ()=>{
      setTimeout(() => {
        this.onRenderQualityChange(this.state.renderQuality)
        if(this.bloomHasBeenDisabled) this.disableBloom()
        // if(this.fxaaHasBeenDisabled) this.disableFXAA()
      }, 1000);
    })

    this.state.onChange('renderQuality', this.onRenderQualityChange)
  }

  onRenderQualityChange = (renderQuality) => {
    if (this.enableLogs) console.warn(`Set WebGL to ${renderQuality} quality`)

    switch (renderQuality) {
      case 'normal':
        this.webglManager.dpr = Math.min(window.devicePixelRatio, 2)
        break;

      case 'low':
        this.webglManager.dpr = 1
        this.disableBloom()
        break;

      case 'minimal':
        this.webglManager.dpr = 0.9
        this.disableBloom()
        // this.disableFXAA()
        this.setActive(false)
        break;

      case 'potato':
        this.webglManager.dpr = 0.8
        this.disableBloom()
        this.disableFXAA()
        this.setActive(false)
        break;

      default:
        break;
    }

    this.webglManager.resize()
    this.webglManager._emitter.emit('resize')

    // this.handlePerfHistory()
  }

  setDebugEvents = () => {
    if (this.enableLogs) console.log("set debug events");

    window.addEventListener('keydown', (e) => {
      if (e.code === 'Space') this.active = !this.active

      const shouldDowngrade = e.code === 'ArrowDown'
      const shouldUpgrade = e.code === 'ArrowUp'

      if (shouldDowngrade) {
        this.downgradeQuality()
      } else if (shouldUpgrade) {
        this.upgradeQuality()
      }
    })
  }

  // Do not launch on first frames drawn as it will not be accurate
  activate() {
    if (this.enableLogs) console.log("call activate perf check");

    for (const scene of Object.values(this.webglManager.scenes)) {
      if (!scene.scene) {
        console.error(
          "attempted to launch PerformanceManager when scenes weren't constructed yet"
        )
      } else if (
        this.webglManager.forceAllDraw ||
        this.webglManager.drawcalls < 40
      ) {
        console.error('attempted to launch PerformanceManager on first frames')
      }
    }

    if (this.enableLogs) console.log("activate perf check");
    this.active = true
  }

  onPerfCheck = ({ fps }) => {
    // if(this.enableLogs) console.log('perf-check', fps);
    // if(this.enableLogs) console.log('is perf check active ?', this.active)

    if (!this.active || document.hidden || this.windowIsBlurred) return

    if (fps > this.thresholds.minimal && fps <= this.thresholds.low) {
      this.state.renderQuality = 'low'
    } else if (fps <= this.thresholds.minimal) {
      this.state.renderQuality = 'minimal'
    }
  }

  upgradeQuality = () => {
    if (this.state.renderQuality == 'low') {
      this.state.renderQuality = 'normal'
    } else if (this.state.renderQuality == 'minimal') {
      this.state.renderQuality = 'low'
    }
  }

  downgradeQuality = () => {
    if (this.state.renderQuality == 'normal') {
      this.state.renderQuality = 'low'
    } else if (this.state.renderQuality == 'low') {
      this.state.renderQuality = 'minimal'
    }
  }
  
  // Limit frequency of changes by using lowest settings when changing too quickly
  handlePerfHistory = () => {
    this.perfCheckHistory.push(this.state.renderQuality)

    if (this.perfCheckHistory.length === 4) {
      const distinctValues = [...new Set(this.perfCheckHistory)]

      if (distinctValues.length === 2) {
        const nbOfOccurences1 = this.perfCheckHistory.filter(
          (value) => value === distinctValues[0]
        ).length
        const nbOfOccurences2 = this.perfCheckHistory.filter(
          (value) => value === distinctValues[1]
        ).length

        if (nbOfOccurences1 === nbOfOccurences2) {
          if (this.enableLogs) console.warn(
            'perf changes are too repetitive and frequent, disabling perf check'
          )
          this.setActive(false)
        }
      }
      this.perfCheckHistory = []
    }
  }

  disableFXAA() {
    this.fxaaHasBeenDisabled = true
    for (const { scene } of Object.values(this.webglManager.scenes)) {
      if(!scene) return

      if (scene.subscenes) {
        for (const subscene of scene.subscenes) {
          if (subscene.post) subscene.post.config.FXAA.value = false
          subscene.postOptions["fxaa"] = false
        }
      } else {
        if (scene.post) scene.post.config.FXAA.value = false
        scene.postOptions["fxaa"] = false
      }
    }
  }

  disableBloom() {
    this.bloomHasBeenDisabled = true
    for (const { scene } of Object.values(this.webglManager.scenes)) {
      if(!scene) return
      if (scene.subscenes) {
        for (const subscene of scene.subscenes) {
          if (subscene.bloomPass) {
            subscene.bloomPass = null
          }
          if (subscene.post){
            subscene.post.bloomTexture = new Texture(this.webglManager.gl)
            subscene.post.config.Bloom.value = false
            subscene.post.program.uniforms.uBloomEnabled.value = false
          }
          subscene.hasBloom = false
          scene.postOptions["bloom"] = false

        }
      } else {
        if (scene.bloomPass) {
          scene.bloomPass = null
        }
        scene.hasBloom = false
        if (scene.post){
          scene.post.bloomTexture = new Texture(this.webglManager.gl)
          scene.post.config.Bloom.value = false
          scene.post.program.uniforms.uBloomEnabled.value = false
        }
        scene.postOptions["bloom"] = false

      }
    }
  }

  setActive = (toggle) => {
    this.active = toggle
  }
}
