const ua = window.navigator.userAgent

export const isFirefox = navigator.userAgent.indexOf('Firefox') > -1
export const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
export const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
export const isIE = /* @cc_on!@ */ false || !!document.documentMode
export const isEdge = !isIE && !!window.StyleMedia
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
export const isBlink = (isChrome || isOpera) && !!window.CSS

export const isMobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
export const isTablet = (ua.match(/(iPad)/) /* iOS pre 13 */ || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */)
export const isDesktop = !isMobile && !isTablet

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

export const isIOS = iOS()
