// HOME - SPACEPORT
export const HOME_SPACEPORT_CAMERA_POSITION_POINTS_DESKTOP = [
  [-1.8461958169937134, -1.102885127067566, 0.4009128212928772], [-1.2064299583435059, -0.9891525506973267, 0.21833482384681702], [-0.043751925230026245, -1.0075199604034424, 0.12841029465198517], [0.3970847725868225, -1.0075199604034424, 0.15393003821372986],
]

export const HOME_SPACEPORT_CAMERA_POSITION_POINTS_MOBILE = [
  [-1.75, -1.6, 0.4], [-1.2, -1.3, 0.2], [0, -1.2, 0.13], [0.75, -1.5, 0.25],
]

// SPACEPORT AMERICA
// (Might be unused if gsap animation)
export const SPACEPORT_CAMERA_POSITION_POINTS_DESKTOP = [
  [0, -0.35, 8], [0, -0.4, 2], [0, -0.5, 0.4], [0, -0.5, 0.25], [0, -0.5, 0.15], [0.7, -0.7, 0.15],
]

export const SPACEPORT_CAMERA_POSITION_POINTS_MOBILE = [
  [0, -1.1, 8], [0, -1.2, 2], [0, -1.3, 0.4], [0, -1.3, 0.25], [0, -1.3, 0.15], [0.7, -1.6, 0.15],
]

export const SPACEPORT_CAMERA_LOOKAT_POINTS = [
  [0, 15, 20], [0, 3.5, 8], [0, 3.5, 1.5], [0, 3.5, 0.35], [0, 3.5, 0.15], [0, 0.5, 0.15],
]

// SPACECRAFT FLEET
export const FLEET_INTRO_CAMERA_POSITION_POINTS_VERTICAL = [
  [0.0, -6.434587478637695, 0.5790531635284424],
  [2.9082374572753906, -3.456935167312622, 1.4981367588043213],
  [3.8545920848846436, -1.0842111110687256, 1.5526213645935059],
  [3.3558614253997803, 0.9850302934646606, 1.5526213645935059],
  [1.6654527187347412, 2.5739238262176514, 1.54935884475708],
  [0.5586516857147217, 2.9021973609924316, 1.5134702920913696],
  [-0.473972350358963, 2.6463699340820312, 1.3275885581970215],
  [-1.242581844329834, 2.240351676940918, -0.32559895515441895],
]

export const FLEET_INTRO_CAMERA_POSITION_POINTS_HORIZONTAL = [
  [0.0, -6.434587478637695, 0.5790531635284424],
  [2.9082374572753906, -3.456935167312622, 1.4981367588043213],
  [3.8545920848846436, -1.0842111110687256, 1.5526213645935059],
  [3.3558614253997803, 0.9850302934646606, 1.5526213645935059],
  [1.6654527187347412, 2.5739238262176514, 1.54935884475708],
  [0.5586516857147217, 2.9021973609924316, 1.5134702920913696],
  [-0.48961949348449707, 2.9021973609924316, 1.5134702920913696],
  [-1.4505391120910645, 2.5415303707122803, 1.5259497165679932],
]

export const FLEET_UNITY_CAMERA_POSITION_POINTS_HORIZONTAL = [
  [0.5142241716384888,-1.3102236986160278,0.17465278506278992],
  [1.3791533708572388,-2.629861831665039,0.3081424832344055],
  [2.7238857746124268,-4.060478687286377,0.264264851808548],
]

export const FLEET_UNITY_CAMERA_POSITION_POINTS = [
  [0.5142241716384888,-1.3102236986160278,0.17465278506278992],[1.325343370437622,-2.693920135498047,0.21886053681373596],[2.912742853164673,-3.150292158126831,0.04126894474029541],[4.414483070373535,-1.8752344846725464,-1.0581825971603394],[5.008798122406006,0.14085827767848969,-0.8657393455505371],[4.509812355041504,2.15859055519104,-0.21135351061820984],[3.5834460258483887,3.981508731842041,0.264264851808548],
]