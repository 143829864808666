import { Vec2 } from '~/glxp/ogl/math/Vec2.js'
import { Vec3 } from '~/glxp/ogl/math/Vec3.js'

import { isMobile } from '@/glxp/utils/device'

export const HOTSPOTS_SPACEPORT = [
  {
    name: 'spaceport-hotspot-1',
    worldPosition:  isMobile ? new Vec3(-0.15, 0.15, -0.025) : new Vec3(-0.2, 0.15, -0.025),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: 1
  },
  {
    name: 'spaceport-hotspot-2',
    worldPosition: new Vec3(0.1, 0.08, -0.05),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: 1
  },
  {
    name: 'spaceport-hotspot-3',
    worldPosition: new Vec3(0.585, 0.015, 0.09),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: 0
  },
  // {
  //   name: 'spaceport-hotspot-4',
  //   worldPosition: new Vec3(-10, 1, -20),
  //   screenPosition: new Vec3(),
  //   screenPositionPx: new Vec2(),
  //   distanceToCamera: 1,
  //   inView: false,
  //   visibility: 0
  // },
  {
    name: 'spaceport-hotspot-5',
    worldPosition: new Vec3(0, -100, 0),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: 0
  },
]

// Generated in ExperienceLines
// export const HOTSPOTS_FLIGHT_EXPERIENCE = []

export const HOTSPOTS_FLEET_EVE = [
  {
    name: 'airlift-assist',
    worldPosition: new Vec3(.7, .8, -.3),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: .9
  },
  {
    name: 'hotspot-two',
    worldPosition: new Vec3(0, .8, 0),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: .9
  },
]

export const HOTSPOTS_FLEET_UNITY = [
  {
    name: 'unity-exterior-hotspot-1',
    worldPosition: new Vec3(0, 0, 0),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: .95
  },
  {
    name: 'unity-exterior-hotspot-2',
    worldPosition: new Vec3(0, -0.4, 0),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: .95
  },
  {
    name: 'unity-exterior-hotspot-3',
    worldPosition: new Vec3(0, 0.2, 0),
    screenPosition: new Vec3(),
    screenPositionPx: new Vec2(),
    distanceToCamera: 1,
    inView: false,
    visibility: .95
  },
  // {
  //   name: 'unity-interior-hotspot-1',
  //   worldPosition: new Vec3(-.3, 0, 0),
  //   screenPosition: new Vec3(),
  //   screenPositionPx: new Vec2(),
  //   distanceToCamera: 1,
  //   inView: false,
  //   visibility: .95
  // },
]
