const out = {
  "PBR_CONFIG_HEADER": {
    "IBLDiffuseFactor": {
      "value": 0.9,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLDiffuseFactor"
      }
    },
    "IBLSpecularFactor": {
      "value": 1.4000000000000001,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLSpecularFactor"
      }
    },
    "Environment": {
      "value": "env_clouds",
      "params": {
        "options": {
          "env_clouds": "env_clouds"
        },
        "label": "Environment"
      }
    },
    "EnvironmentDiffuse": {
      "value": "env_clouds_blur",
      "params": {
        "options": {
          "env_clouds_blur": "env_clouds_blur"
        },
        "label": "EnvironmentDiffuse"
      }
    },
    "EnvRotationOffset": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.001,
        "label": "EnvRotationOffset"
      }
    },
    "lightColor": {
      "value": "#ffffff",
      "params": {
        "label": "lightColor"
      }
    },
    "lightPosition": {
      "value": {
        "x": 0,
        "y": -21,
        "z": -5
      },
      "params": {
        "x": {
          "step": 1
        },
        "y": {
          "step": 1
        },
        "z": {
          "step": 1
        },
        "label": "lightPosition"
      }
    },
    "emissiveColor": {
      "value": "#ffffff",
      "params": {
        "label": "emissiveColor"
      }
    },
    "FogColor": {
      "value": "#ffffff",
      "params": {
        "label": "FogColor"
      }
    },
    "FogNear": {
      "value": 15,
      "params": {
        "min": 0,
        "max": 50,
        "step": 0.01,
        "label": "FogNear"
      }
    },
    "FogFar": {
      "value": 20,
      "params": {
        "min": 0,
        "max": 50,
        "step": 0.01,
        "label": "FogFar"
      }
    },
    "lightPower": {
      "value": 0.75,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "lightPower"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Debug": {
      "value": "disabled",
      "params": {
        "options": {
          "disabled": "disabled",
          "baseColor": "baseColor",
          "metallic": "metallic",
          "roughness": "roughness",
          "specRef": "specRef",
          "geomOcc": "geomOcc",
          "mcrfctDist": "mcrfctDist",
          "spec": "spec",
          "mathDiff": "mathDiff"
        },
        "label": "Debug"
      }
    },
    "OverwriteEnvIBL": {
      "value": false,
      "type": "bool"
    },
    "EnvIBL": {
      "value": {

      },
      "type": "input-image",
      "input": null
    }
  },
  "PBR_CONFIG_SPACEPORT": {
    "IBLDiffuseFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLDiffuseFactor"
      }
    },
    "IBLSpecularFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLSpecularFactor"
      }
    },
    "Environment": {
      "value": "env_spaceport",
      "params": {
        "options": {
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur"
        },
        "label": "Environment"
      }
    },
    "EnvironmentDiffuse": {
      "value": "env_spaceport_blur",
      "params": {
        "options": {
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur"
        },
        "label": "EnvironmentDiffuse"
      }
    },
    "EnvRotationOffset": {
      "value": 0.75,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.001,
        "label": "EnvRotationOffset"
      }
    },
    "lightColor": {
      "value": "#ffffff",
      "params": {
        "label": "lightColor"
      }
    },
    "lightPosition": {
      "value": {
        "x": 1,
        "y": 50,
        "z": -1000
      },
      "params": {
        "x": {
          "step": 1
        },
        "y": {
          "step": 1
        },
        "z": {
          "step": 1
        },
        "label": "lightPosition"
      }
    },
    "emissiveColor": {
      "value": "#ffffff",
      "params": {
        "label": "emissiveColor"
      }
    },
    "FogColor": {
      "value": "#4c4a82",
      "params": {
        "label": "FogColor"
      }
    },
    "FogNear": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1000,
        "step": 0.1,
        "label": "FogNear"
      }
    },
    "FogFar": {
      "value": 550,
      "params": {
        "min": 0,
        "max": 1500,
        "step": 1,
        "label": "FogFar"
      }
    },
    "lightPower": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "lightPower"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Debug": {
      "value": "disabled",
      "params": {
        "options": {
          "disabled": "disabled",
          "baseColor": "baseColor",
          "metallic": "metallic",
          "roughness": "roughness",
          "specRef": "specRef",
          "geomOcc": "geomOcc",
          "mcrfctDist": "mcrfctDist",
          "spec": "spec",
          "mathDiff": "mathDiff"
        },
        "label": "Debug"
      }
    },
    "OverwriteEnvIBL": {
      "value": false,
      "type": "bool"
    },
    "EnvIBL": {
      "value": {

      },
      "type": "input-image",
      "input": null
    }
  },
  "PBR_CONFIG_SPACEPORT_GLASS_SHIPS": {
    "IBLDiffuseFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLDiffuseFactor"
      }
    },
    "IBLSpecularFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLSpecularFactor"
      }
    },
    "Environment": {
      "value": "env_spaceport_glass_ships",
      "params": {
        "options": {
          "spaceportGlassShips": "env_spaceport_glass_ships",
          "spaceportGlassShipsBlur": "env_spaceport_glass_ships_blur",
          "spaceportGlassNoShips": "env_spaceport_glass_noships",
          "spaceportGlassNoShipsBlur": "env_spaceport_glass_noships_blur",
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur"
        },
        "label": "Environment"
      }
    },
    "EnvironmentDiffuse": {
      "value": "env_spaceport_glass_ships",
      "params": {
        "options": {
          "spaceportGlassShips": "env_spaceport_glass_ships",
          "spaceportGlassShipsBlur": "env_spaceport_glass_ships_blur",
          "spaceportGlassNoShips": "env_spaceport_glass_noships",
          "spaceportGlassNoShipsBlur": "env_spaceport_glass_noships_blur",
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur"
        },
        "label": "EnvironmentDiffuse"
      }
    },
    "EnvRotationOffset": {
      "value": 0.75,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.001,
        "label": "EnvRotationOffset"
      }
    },
    "lightColor": {
      "value": "#ffffff",
      "params": {
        "label": "lightColor"
      }
    },
    "lightPosition": {
      "value": {
        "x": 1,
        "y": 50,
        "z": -1000
      },
      "params": {
        "x": {
          "step": 1
        },
        "y": {
          "step": 1
        },
        "z": {
          "step": 1
        },
        "label": "lightPosition"
      }
    },
    "emissiveColor": {
      "value": "#ffffff",
      "params": {
        "label": "emissiveColor"
      }
    },
    "FogColor": {
      "value": "#4c4a82",
      "params": {
        "label": "FogColor"
      }
    },
    "FogNear": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1000,
        "step": 0.1,
        "label": "FogNear"
      }
    },
    "FogFar": {
      "value": 450,
      "params": {
        "min": 0,
        "max": 1500,
        "step": 1,
        "label": "FogFar"
      }
    },
    "lightPower": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "lightPower"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Debug": {
      "value": "disabled",
      "params": {
        "options": {
          "disabled": "disabled",
          "baseColor": "baseColor",
          "metallic": "metallic",
          "roughness": "roughness",
          "specRef": "specRef",
          "geomOcc": "geomOcc",
          "mcrfctDist": "mcrfctDist",
          "spec": "spec",
          "mathDiff": "mathDiff"
        },
        "label": "Debug"
      }
    },
    "OverwriteEnvIBL": {
      "value": false,
      "type": "bool"
    },
    "EnvIBL": {
      "value": {

      },
      "type": "input-image",
      "input": null
    }
  },
  "PBR_CONFIG_SPACEPORT_GLASS_NOSHIPS": {
    "IBLDiffuseFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLDiffuseFactor"
      }
    },
    "IBLSpecularFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLSpecularFactor"
      }
    },
    "Environment": {
      "value": "env_spaceport_glass_noships",
      "params": {
        "options": {
          "spaceportGlassShips": "env_spaceport_glass_ships",
          "spaceportGlassShipsBlur": "env_spaceport_glass_ships_blur",
          "spaceportGlassNoShips": "env_spaceport_glass_noships",
          "spaceportGlassNoShipsBlur": "env_spaceport_glass_noships_blur",
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur"
        },
        "label": "Environment"
      }
    },
    "EnvironmentDiffuse": {
      "value": "env_spaceport_glass_noships",
      "params": {
        "options": {
          "spaceportGlassShips": "env_spaceport_glass_ships",
          "spaceportGlassShipsBlur": "env_spaceport_glass_ships_blur",
          "spaceportGlassNoShips": "env_spaceport_glass_noships",
          "spaceportGlassNoShipsBlur": "env_spaceport_glass_noships_blur",
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur"
        },
        "label": "EnvironmentDiffuse"
      }
    },
    "EnvRotationOffset": {
      "value": 0.75,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.001,
        "label": "EnvRotationOffset"
      }
    },
    "lightColor": {
      "value": "#ffffff",
      "params": {
        "label": "lightColor"
      }
    },
    "lightPosition": {
      "value": {
        "x": 1,
        "y": 50,
        "z": -1000
      },
      "params": {
        "x": {
          "step": 1
        },
        "y": {
          "step": 1
        },
        "z": {
          "step": 1
        },
        "label": "lightPosition"
      }
    },
    "emissiveColor": {
      "value": "#000000",
      "params": {
        "label": "emissiveColor"
      }
    },
    "FogColor": {
      "value": "#4c4a82",
      "params": {
        "label": "FogColor"
      }
    },
    "FogNear": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1000,
        "step": 0.1,
        "label": "FogNear"
      }
    },
    "FogFar": {
      "value": 450,
      "params": {
        "min": 0,
        "max": 1500,
        "step": 1,
        "label": "FogFar"
      }
    },
    "lightPower": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "lightPower"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Debug": {
      "value": "disabled",
      "params": {
        "options": {
          "disabled": "disabled",
          "baseColor": "baseColor",
          "metallic": "metallic",
          "roughness": "roughness",
          "specRef": "specRef",
          "geomOcc": "geomOcc",
          "mcrfctDist": "mcrfctDist",
          "spec": "spec",
          "mathDiff": "mathDiff"
        },
        "label": "Debug"
      }
    },
    "OverwriteEnvIBL": {
      "value": false,
      "type": "bool"
    },
    "EnvIBL": {
      "value": {

      },
      "type": "input-image",
      "input": null
    }
  },
  "PBR_CONFIG_SPACECRAFT_FLEET_EVE": {
    "IBLDiffuseFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLDiffuseFactor"
      }
    },
    "IBLSpecularFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLSpecularFactor"
      }
    },
    "Environment": {
      "value": "env_spacecraft_fleet_eve",
      "params": {
        "options": {
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur",
          "spacecraft_fleet_eve": "env_spacecraft_fleet_eve",
          "spacecraft_fleet_eve_blur": "env_spacecraft_fleet_eve_blur"
        },
        "label": "Environment"
      }
    },
    "EnvironmentDiffuse": {
      "value": "env_spacecraft_fleet_eve_blur",
      "params": {
        "options": {
          "spaceport": "env_spaceport",
          "spaceport_blur": "env_spaceport_blur",
          "spacecraft_fleet_eve": "env_spacecraft_fleet_eve",
          "spacecraft_fleet_eve_blur": "env_spacecraft_fleet_eve_blur"
        },
        "label": "EnvironmentDiffuse"
      }
    },
    "EnvRotationOffset": {
      "value": 0.75,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.001,
        "label": "EnvRotationOffset"
      }
    },
    "lightColor": {
      "value": "#ffffff",
      "params": {
        "label": "lightColor"
      }
    },
    "lightPosition": {
      "value": {
        "x": 0,
        "y": -4,
        "z": 0
      },
      "params": {
        "x": {
          "step": 1
        },
        "y": {
          "step": 1
        },
        "z": {
          "step": 1
        },
        "label": "lightPosition"
      }
    },
    "emissiveColor": {
      "value": "#000000",
      "params": {
        "label": "emissiveColor"
      }
    },
    "FogColor": {
      "value": "#4c4a82",
      "params": {
        "label": "FogColor"
      }
    },
    "FogNear": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1000,
        "step": 0.1,
        "label": "FogNear"
      }
    },
    "FogFar": {
      "value": 450,
      "params": {
        "min": 0,
        "max": 1500,
        "step": 1,
        "label": "FogFar"
      }
    },
    "lightPower": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "lightPower"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Debug": {
      "value": "disabled",
      "params": {
        "options": {
          "disabled": "disabled",
          "baseColor": "baseColor",
          "metallic": "metallic",
          "roughness": "roughness",
          "specRef": "specRef",
          "geomOcc": "geomOcc",
          "mcrfctDist": "mcrfctDist",
          "spec": "spec",
          "mathDiff": "mathDiff"
        },
        "label": "Debug"
      }
    },
    "OverwriteEnvIBL": {
      "value": false,
      "type": "bool"
    },
    "EnvIBL": {
      "value": {

      },
      "type": "input-image",
      "input": null
    }
  },
  "PBR_CONFIG_SPACECRAFT_FLEET_UNITY": {
    "IBLDiffuseFactor": {
      "value": 0.015,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLDiffuseFactor"
      }
    },
    "IBLSpecularFactor": {
      "value": 2,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "IBLSpecularFactor"
      }
    },
    "Environment": {
      "value": "env_spacecraft_fleet_unity",
      "params": {
        "options": {
          "env_spacecraft_fleet_unity": "env_spacecraft_fleet_unity",
        },
        "label": "Environment"
      }
    },
    "EnvironmentDiffuse": {
      "value": "env_spacecraft_fleet_unity",
      "params": {
        "options": {
          "env_spacecraft_fleet_unity": "env_spacecraft_fleet_unity",
        },
        "label": "EnvironmentDiffuse"
      }
    },
    "EnvRotationOffset": {
      "value": 0.175,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.001,
        "label": "EnvRotationOffset"
      }
    },
    "lightColor": {
      "value": "#5689cf",
      "params": {
        "label": "lightColor"
      }
    },
    "lightPosition": {
      "value": {
        "x": 0.17000000000007276,
        "y": -100,
        "z": 0
      },
      "params": {
        "x": {
          "step": 1
        },
        "y": {
          "step": 1
        },
        "z": {
          "step": 1
        },
        "label": "lightPosition"
      }
    },
    "lightPower": {
      "value": 10,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "lightPower"
      }
    },
    "emissiveColor": {
      "value": "#000000",
      "params": {
        "label": "emissiveColor"
      }
    },
    "FogColor": {
      "value": "#ffffff",
      "params": {
        "label": "FogColor"
      }
    },
    "FogNear": {
      "value": 15,
      "params": {
        "min": 0,
        "max": 50,
        "step": 0.01,
        "label": "FogNear"
      }
    },
    "FogFar": {
      "value": 25,
      "params": {
        "min": 0,
        "max": 50,
        "step": 0.01,
        "label": "FogFar"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Debug": {
      "value": "disabled",
      "params": {
        "options": {
          "disabled": "disabled",
          "baseColor": "baseColor",
          "metallic": "metallic",
          "roughness": "roughness",
          "specRef": "specRef",
          "geomOcc": "geomOcc",
          "mcrfctDist": "mcrfctDist",
          "spec": "spec",
          "mathDiff": "mathDiff"
        },
        "label": "Debug"
      }
    },
    "OverwriteEnvIBL": {
      "value": false,
      "type": "bool"
    },
    "EnvIBL": {
      "value": {

      },
      "type": "input-image",
      "input": null
    }
  },
  "Post Processing - Home Header": {
    "BloomOpacity": {
      "value": 0.3,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "BloomOpacity"
      }
    },
    "NoiseOpacity": {
      "value": 0.02,
      "params": {
        "min": 0,
        "max": 0.04,
        "label": "NoiseOpacity"
      }
    },
    "Gamma": {
      "value": 0.9,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Gamma"
      }
    },
    "Exposure": {
      "value": 0.25,
      "params": {
        "min": -2,
        "max": 2,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0.16,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Vignette": {
      "value": 0.25,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Vignette"
      }
    },
    "ChromaticAberations": {
      "value": 0.05,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "ChromaticAberations"
      }
    },
    "VignetteStrength": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "VignetteStrength"
      }
    },
    "FXAA": {
      "value": true,
      "params": {
        "label": "FXAA"
      }
    },
    "Bloom": {
      "value": true,
      "params": {
        "label": "Bloom"
      }
    },
    "NoiseScale": {
      "value": 0.0001,
      "params": {
        "min": 1e-7,
        "max": 0.0003,
        "label": "NoiseScale"
      }
    },
    "DirtDistanceFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.1,
        "label": "DirtDistanceFactor"
      }
    },
    "DirtOpacityFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "DirtOpacityFactor"
      }
    },
    "DirtResolutionScale": {
      "value": {
        "x": 1,
        "y": 1
      },
      "params": {
        "x": {
          "min": 0,
          "max": 3,
          "step": 0.01
        },
        "y": {
          "min": 0,
          "max": 3,
          "step": 0.01
        },
        "label": "DirtResolutionScale"
      }
    },
    "DirtResolutionOffset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -3,
          "max": 3,
          "step": 0.01
        },
        "y": {
          "min": -3,
          "max": 3,
          "step": 0.01
        },
        "label": "DirtResolutionOffset"
      }
    }
  },
  "Post Processing - Home Spaceport": {
    "BloomOpacity": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "BloomOpacity"
      }
    },
    "NoiseOpacity": {
      "value": 0.01,
      "params": {
        "min": 0,
        "max": 0.04,
        "label": "NoiseOpacity"
      }
    },
    "Gamma": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Gamma"
      }
    },
    "Exposure": {
      "value": 0.3,
      "params": {
        "min": -2,
        "max": 2,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0.16,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Vignette": {
      "value": 0.3,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Vignette"
      }
    },
    "ChromaticAberations": {
      "value": 1.734723475976807e-18,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "ChromaticAberations"
      }
    },
    "VignetteStrength": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "VignetteStrength"
      }
    },
    "FXAA": {
      "value": true,
      "params": {
        "label": "FXAA"
      }
    },
    "Bloom": {
      "value": true,
      "params": {
        "label": "Bloom"
      }
    },
    "NoiseScale": {
      "value": 0.0001,
      "params": {
        "min": 1e-7,
        "max": 0.0003,
        "label": "NoiseScale"
      }
    },
    "DirtDistanceFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.1,
        "label": "DirtDistanceFactor"
      }
    },
    "DirtOpacityFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "DirtOpacityFactor"
      }
    },
    "DirtResolutionScale": {
      "value": {
        "x": 1,
        "y": 1
      },
      "params": {
        "x": {
          "min": 0,
          "max": 3,
          "step": 0.01
        },
        "y": {
          "min": 0,
          "max": 3,
          "step": 0.01
        },
        "label": "DirtResolutionScale"
      }
    },
    "DirtResolutionOffset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -3,
          "max": 3,
          "step": 0.01
        },
        "y": {
          "min": -3,
          "max": 3,
          "step": 0.01
        },
        "label": "DirtResolutionOffset"
      }
    }
  },
  "Home Header - M_SpaceShip_Unity": {
    "albedoColor": {
      "value": "#ffffff",
      "params": {
        "label": "albedoColor"
      }
    },
    "IBL": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "IBL"
      }
    },
    "IBLAlbedoMix": {
      "value": 0.9,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLAlbedoMix"
      }
    },
    "IBLRoughnessImpact": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLRoughnessImpact"
      }
    },
    "NormalScale": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "NormalScale"
      }
    },
    "MetalicFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "MetalicFactor"
      }
    },
    "RoughnessFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "RoughnessFactor"
      }
    },
    "EmissiveColor": {
      "value": "#ff2613",
      "params": {
        "label": "EmissiveColor"
      }
    },
    "EmissivePower": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 5,
        "step": 0.01,
        "label": "EmissivePower"
      }
    },
    "OcclusionFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "OcclusionFactor"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 16,
        "step": 0.1,
        "label": "SheenDepth"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 2,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    }
  },
  "Home Header - Mesh Earth": {
    "GroundResolution": {
      "value": {
        "x": 10,
        "y": 7
      },
      "params": {
        "x": {
          "min": 0.1,
          "max": 20,
          "step": 0.1
        },
        "y": {
          "min": 0.1,
          "max": 20,
          "step": 0.1
        },
        "label": "GroundResolution"
      }
    },
    "GroundSpeedFactor": {
      "value": -0.001,
      "params": {
        "min": -0.01,
        "max": 0.01,
        "step": 0.0001,
        "label": "GroundSpeedFactor"
      }
    },
    "CloudsResolution": {
      "value": {
        "x": 1.75,
        "y": 2
      },
      "params": {
        "x": {
          "min": 0.1,
          "max": 10,
          "step": 0.1
        },
        "y": {
          "min": 0.1,
          "max": 10,
          "step": 0.1
        },
        "label": "CloudsResolution"
      }
    },
    "CloudsDensityMin": {
      "value": 0.01,
      "params": {
        "min": 0.01,
        "max": 1,
        "step": 0.01,
        "label": "CloudsDensityMin"
      }
    },
    "CloudsDensityMax": {
      "value": 0.85,
      "params": {
        "min": 0.01,
        "max": 1,
        "step": 0.01,
        "label": "CloudsDensityMax"
      }
    },
    "CloudsColorVariance": {
      "value": 0.35,
      "params": {
        "min": 0.01,
        "max": 1,
        "step": 0.01,
        "label": "CloudsColorVariance"
      }
    },
    "CloudsSpeedFactor": {
      "value": -0.002,
      "params": {
        "min": -0.01,
        "max": 0.01,
        "step": 0.0001,
        "label": "CloudsSpeedFactor"
      }
    },
    "CloudsShadowOffset": {
      "value": {
        "x": 0.001,
        "y": 0.003
      },
      "params": {
        "x": {
          "min": 0.001,
          "max": 0.1,
          "step": 0.001
        },
        "y": {
          "min": 0.001,
          "max": 0.1,
          "step": 0.001
        },
        "label": "CloudsShadowOffset"
      }
    },
    "CloudsShadowIntensity": {
      "value": 0.7,
      "params": {
        "min": 0.01,
        "max": 1,
        "step": 0.01,
        "label": "CloudsShadowIntensity"
      }
    },
    "FresnelColor": {
      "value": "#5e8cef",
      "params": {
        "label": "FresnelColor"
      }
    },
    "FresnelRimColor": {
      "value": "#a2daff",
      "params": {
        "label": "FresnelRimColor"
      }
    },
    "FresnelIntensity": {
      "value": 1.05,
      "params": {
        "min": 0,
        "max": 1.4,
        "label": "FresnelIntensity"
      }
    }
  },
  "Outer Atmosphere": {
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0.5,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": -0.02,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 0.9600000000000001,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    },
    "GradientColor0": {
      "value": "#32dfff",
      "params": {
        "label": "GradientColor0"
      }
    },
    "GradientOpacity0": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "GradientOpacity0"
      }
    },
    "GradientColor1": {
      "value": "#a2daff",
      "params": {
        "label": "GradientColor1"
      }
    },
    "GradientOpacity1": {
      "value": 0.98,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "GradientOpacity1"
      }
    },
    "UvScale": {
      "value": 1.12,
      "params": {
        "min": 0,
        "max": 2,
        "label": "UvScale"
      }
    },
    "UvOffset": {
      "value": -0.1,
      "params": {
        "min": -1,
        "max": 1,
        "label": "UvOffset"
      }
    }
  },
  "Inner Atmosphere": {
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": -0.7000000000000001,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    },
    "GradientColor0": {
      "value": "#104f9f",
      "params": {
        "label": "GradientColor0"
      }
    },
    "GradientOpacity0": {
      "value": 0.09,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "GradientOpacity0"
      }
    },
    "GradientColor1": {
      "value": "#a2daff",
      "params": {
        "label": "GradientColor1"
      }
    },
    "GradientOpacity1": {
      "value": 0.9500000000000001,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "GradientOpacity1"
      }
    },
    "UvScale": {
      "value": 2,
      "params": {
        "min": 0,
        "max": 2,
        "label": "UvScale"
      }
    },
    "UvOffset": {
      "value": -0.01,
      "params": {
        "min": -1,
        "max": 1,
        "label": "UvOffset"
      }
    }
  },
  "Home Spaceport - M_SpaceShip_Unity": {
    "albedoColor": {
      "value": "#ffffff",
      "params": {
        "label": "albedoColor"
      }
    },
    "IBL": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "IBL"
      }
    },
    "IBLAlbedoMix": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLAlbedoMix"
      }
    },
    "IBLRoughnessImpact": {
      "value": 0.8,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLRoughnessImpact"
      }
    },
    "NormalScale": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "NormalScale"
      }
    },
    "MetalicFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "MetalicFactor"
      }
    },
    "RoughnessFactor": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "RoughnessFactor"
      }
    },
    "EmissiveColor": {
      "value": "#ffffff",
      "params": {
        "label": "EmissiveColor"
      }
    },
    "EmissivePower": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 5,
        "step": 0.01,
        "label": "EmissivePower"
      }
    },
    "OcclusionFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "OcclusionFactor"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 16,
        "step": 0.1,
        "label": "SheenDepth"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 2,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    }
  },
  "Home Spaceport - M_SpaceShip_Eve": {
    "albedoColor": {
      "value": "#ffffff",
      "params": {
        "label": "albedoColor"
      }
    },
    "IBL": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "IBL"
      }
    },
    "IBLAlbedoMix": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLAlbedoMix"
      }
    },
    "IBLRoughnessImpact": {
      "value": 0.8,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLRoughnessImpact"
      }
    },
    "NormalScale": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "NormalScale"
      }
    },
    "MetalicFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "MetalicFactor"
      }
    },
    "RoughnessFactor": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "RoughnessFactor"
      }
    },
    "EmissiveColor": {
      "value": "#ff2613",
      "params": {
        "label": "EmissiveColor"
      }
    },
    "EmissivePower": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 5,
        "step": 0.01,
        "label": "EmissivePower"
      }
    },
    "OcclusionFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "OcclusionFactor"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 16,
        "step": 0.1,
        "label": "SheenDepth"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 2,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    }
  },
  "Home Spaceport - M_Spaceport_02 ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Spaceport_01 ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Pavement_Sandroof ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Sandwings ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Pavement_L ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0.05,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Pavement_R ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0.05,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_01_R ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.15,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": -0.15,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_01_L ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": -0.15,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_02_R ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_02_L ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_03 ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_04 ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Terrain_05 ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_TerrainTowardsMountains ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Mountains ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#361212",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.4,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0.05,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Fence ": {
    "TextureScale": {
      "value": 100,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Props ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_SpaceShips_Shadow ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Skybox ": {
    "TextureScale": {
      "value": 1,
      "params": {
        "min": 0.1,
        "max": 200,
        "step": 0.1,
        "label": "TextureScale"
      }
    },
    "Tint": {
      "value": "#d28d33",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 10,
        "step": 0.1,
        "label": "SheenDepth"
      }
    }
  },
  "Home Spaceport - M_Glass_PBR": {
    "albedoColor": {
      "value": "#ffffff",
      "params": {
        "label": "albedoColor"
      }
    },
    "IBL": {
      "value": 1.05,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "IBL"
      }
    },
    "IBLAlbedoMix": {
      "value": 0.5,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLAlbedoMix"
      }
    },
    "IBLRoughnessImpact": {
      "value": 0.8,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "IBLRoughnessImpact"
      }
    },
    "NormalScale": {
      "value": 0.09999999999999998,
      "params": {
        "min": 0,
        "max": 10,
        "step": 0.01,
        "label": "NormalScale"
      }
    },
    "MetalicFactor": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "MetalicFactor"
      }
    },
    "RoughnessFactor": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "RoughnessFactor"
      }
    },
    "EmissiveColor": {
      "value": "#ff2613",
      "params": {
        "label": "EmissiveColor"
      }
    },
    "EmissivePower": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 5,
        "step": 0.01,
        "label": "EmissivePower"
      }
    },
    "OcclusionFactor": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "OcclusionFactor"
      }
    },
    "SheenColor": {
      "value": "#ffffff",
      "params": {
        "label": "SheenColor"
      }
    },
    "SheenOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "SheenOpacity"
      }
    },
    "SheenDepth": {
      "value": 2,
      "params": {
        "min": 1,
        "max": 16,
        "step": 0.1,
        "label": "SheenDepth"
      }
    },
    "Alpha": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Alpha"
      }
    },
    "Tint": {
      "value": "#c26089",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.01,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 2,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Contrast": {
      "value": 0,
      "params": {
        "min": -1,
        "max": 1,
        "step": 0.01,
        "label": "Contrast"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    }
  },
  "Home Header - Background": {
    "GradientEdges": {
      "value": {
        "x": 0,
        "y": 0.7794117647058822
      },
      "params": {
        "x": {
          "min": 0,
          "max": 1
        },
        "y": {
          "min": 0,
          "max": 1
        },
        "label": "GradientEdges"
      }
    },
    "Opacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "label": "Opacity"
      }
    },
    "GradientAngle": {
      "value": 0.8878152173913043,
      "params": {
        "min": -3.1415,
        "max": 3.1415,
        "label": "GradientAngle"
      }
    },
    "Color": {
      "value": "#23073f",
      "params": {
        "label": "Color"
      }
    },
    "GradientColor": {
      "value": "#150623",
      "params": {
        "label": "GradientColor"
      }
    }
  },
  "Home Header - Lens Flares": {
    "Spacing": {
      "value": 0.115,
      "params": {
        "min": 0,
        "max": 0.3,
        "step": 0.001,
        "label": "Spacing"
      }
    },
    "CenterFadePower": {
      "value": 0.8,
      "params": {
        "min": -0.3,
        "max": 0.3,
        "label": "CenterFadePower"
      }
    },
    "GlobalOpacity": {
      "value": 2.5,
      "params": {
        "min": 0,
        "max": 10,
        "label": "GlobalOpacity"
      }
    }
  },
  "Home Header - Flare - Glow_00": {
    "Opacity": {
      "value": 2,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 2,
        "y": 2
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#e5b639",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "Rotation": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "Rotation"
      }
    }
  },
  "Home Header - Flare - Glow_01": {
    "Opacity": {
      "value": 1.3043478260869565,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 2,
        "y": 2
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#e83434",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "Rotation": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "Rotation"
      }
    }
  },
  "Home Header - Flare - Hexa_00": {
    "Opacity": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.039,
        "y": 0.039
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#c716cc",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Header - Flare - Hexa_01": {
    "Opacity": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.0925,
        "y": 0.0925
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#9b18e2",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Header - Flare - Hexa_02": {
    "Opacity": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.092,
        "y": 0.092
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#ffffff",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Header - Flare - Arc_01": {
    "Opacity": {
      "value": 2.9347826086956523,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 1,
        "y": 1
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#ab84d5",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Bloom - Home Header": {
    "BloomTint": {
      "value": "#ffffff",
      "params": {
        "label": "BloomTint"
      }
    },
    "Threshold": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1.25,
        "step": 0.01,
        "label": "Threshold"
      }
    },
    "Blur": {
      "value": 0.39999999999999997,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Blur"
      }
    },
    "Overdrive": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "Overdrive"
      }
    },
    "Similarity": {
      "value": 0.09,
      "params": {
        "min": 0,
        "max": 0.2,
        "step": 0.001,
        "label": "Similarity"
      }
    }
  },
  "Global Settings - Home Header": {
    "Pause": {
      "value": false,
      "type": "bool"
    },
    "TimeScale": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "TimeScale"
      }
    },
    "SunPosition": {
      "value": {
        "x": -13.5,
        "y": -1.3,
        "z": -50
      },
      "params": {
        "label": "SunPosition"
      }
    },
    "ScrollDamping": {
      "value": 0.05,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "ScrollDamping"
      }
    },
    "OverrideScroll": {
      "value": false,
      "params": {
        "label": "OverrideScroll"
      }
    },
    "Progress": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Progress"
      }
    },
    "DevScrollSpeed": {
      "value": 30,
      "params": {
        "min": 0,
        "max": 60,
        "label": "DevScrollSpeed"
      }
    }
  },
  "Home Spaceport - Lens Flares": {
    "Spacing": {
      "value": 0.114,
      "params": {
        "min": 0,
        "max": 0.3,
        "step": 0.001,
        "label": "Spacing"
      }
    },
    "CenterFadePower": {
      "value": 0.25,
      "params": {
        "min": -0.3,
        "max": 0.3,
        "label": "CenterFadePower"
      }
    },
    "GlobalOpacity": {
      "value": 3,
      "params": {
        "min": 0,
        "max": 10,
        "label": "GlobalOpacity"
      }
    }
  },
  "Home Spaceport - Flare - Glow_00": {
    "Opacity": {
      "value": 1.3043478260869565,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 5.5,
        "y": 1.2
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#f8af5e",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Spaceport - Flare - Glow_01": {
    "Opacity": {
      "value": 0.55,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 3.6,
        "y": 1.5
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0.01,
        "y": 0.043
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#c985e8",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Spaceport - Flare - Hexa_00": {
    "Opacity": {
      "value": 1.3,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.039,
        "y": 0.039
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#c562bc",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Spaceport - Flare - Hexa_01": {
    "Opacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.0925,
        "y": 0.0925
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#c555c4",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Spaceport - Flare - Hexa_02": {
    "Opacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.092,
        "y": 0.092
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#ff6161",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Home Spaceport - Flare - Arc_01": {
    "Opacity": {
      "value": 3.260869565217391,
      "params": {
        "min": 0,
        "max": 30,
        "label": "Opacity"
      }
    },
    "Scale": {
      "value": {
        "x": 0.78,
        "y": 0.78
      },
      "params": {
        "min": 0,
        "max": 2,
        "label": "Scale"
      }
    },
    "Offset": {
      "value": {
        "x": 0,
        "y": 0
      },
      "params": {
        "x": {
          "min": -10,
          "max": 10
        },
        "y": {
          "min": -10,
          "max": 10
        },
        "label": "Offset"
      }
    },
    "Tint": {
      "value": "#7c38af",
      "params": {
        "label": "Tint"
      }
    },
    "TintOpacity": {
      "value": 0.48999999999999994,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "TintOpacity"
      }
    },
    "Exposure": {
      "value": 0,
      "params": {
        "min": -2,
        "max": 3,
        "step": 0.01,
        "label": "Exposure"
      }
    },
    "Saturation": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 2,
        "step": 0.01,
        "label": "Saturation"
      }
    },
    "RotationOffset": {
      "value": 0,
      "params": {
        "min": -3.141592653589793,
        "max": 3.141592653589793,
        "label": "RotationOffset"
      }
    }
  },
  "Bloom - Home Spaceport": {
    "BloomTint": {
      "value": "#f9ffc0",
      "params": {
        "label": "BloomTint"
      }
    },
    "Threshold": {
      "value": 0.7499999999999999,
      "params": {
        "min": 0,
        "max": 1.25,
        "step": 0.01,
        "label": "Threshold"
      }
    },
    "Blur": {
      "value": 1,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Blur"
      }
    },
    "Overdrive": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 3,
        "step": 0.01,
        "label": "Overdrive"
      }
    },
    "Similarity": {
      "value": 0.1,
      "params": {
        "min": 0,
        "max": 0.2,
        "step": 0.001,
        "label": "Similarity"
      }
    }
  },
  "Global Settings - Home Spaceport": {
    "ScrollDamping": {
      "value": 0.05,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "ScrollDamping"
      }
    },
    "SunPosition": {
      "value": {
        "x": -1,
        "y": 27,
        "z": -225
      },
      "params": {
        "label": "SunPosition"
      }
    },
    "OverrideScroll": {
      "value": false,
      "params": {
        "label": "OverrideScroll"
      }
    },
    "Progress": {
      "value": 0,
      "params": {
        "min": 0,
        "max": 1,
        "step": 0.01,
        "label": "Progress"
      }
    },
    "DevScrollSpeed": {
      "value": 30,
      "params": {
        "min": 0,
        "max": 60,
        "label": "DevScrollSpeed"
      }
    }
  }
}

export default out