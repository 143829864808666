import Unlit from '~/glxp/shaders/unlit.glsl'
import MergedUnlit from '~/glxp/shaders/mergedUnlit.glsl'
import Standard from '~/glxp/shaders/pbr/standard.glsl'
// import MergedStandard from '~/glxp/shaders/pbr/mergedStandard.glsl'

import DebugUnlit from '~/glxp/shaders/debug/debugUnlit.glsl'
import DebugHotspot from '~/glxp/shaders/debug/debugHotspot.glsl'
import DebugUv from '~/glxp/shaders/debug/debugUv.glsl'
import Grid from '~/glxp/shaders/debug/grid.glsl'
import Whitebox from '~/glxp/shaders/debug/whitebox.glsl'

import Skybox from '~/glxp/shaders/skybox.glsl'
// import Ground from '~/glxp/shaders/reflectiveGround.glsl'
import HomePlanet from '~/glxp/shaders/homePlanet.glsl'
import ExperiencePlanet from '~/glxp/shaders/experience/experiencePlanet.glsl'
import ExperienceLine from '~/glxp/shaders/experience/experienceLine.glsl'
import ExperienceGlow from '~/glxp/shaders/experience/experienceGlow.glsl'
import ExperienceGlowTop from '~/glxp/shaders/experience/experienceGlowTop.glsl'
import ExperienceSun from '~/glxp/shaders/experience/experienceSun.glsl'
import ExperienceBackground from '~/glxp/shaders/experience/experienceBackground.glsl'
import ExperienceClouds from '~/glxp/shaders/experience/experienceClouds.glsl'
import SpaceportSky from '~/glxp/shaders/spaceport/spaceportSky.glsl'
import InnerAtmosphere from '~/glxp/shaders/innerAtmosphere.glsl'
import OuterAtmosphere from '~/glxp/shaders/outerAtmosphere.glsl'
import Background from '~/glxp/shaders/background.glsl'
import BackgroundSphereTexture from '~/glxp/shaders/backgroundSphereTexture.glsl'
import BackgroundSpacecraftFleetUnity from '~/glxp/shaders/backgroundSpacecraftFleetUnity.glsl'
// import Occluder from '~/glxp/shaders/occluder.glsl'
import Flare from "~/glxp/shaders/flare.glsl"

// Post
import Post from '~/glxp/shaders/post/mainPost.glsl'
import LensFlarePost from '~/glxp/shaders/post/lensFlarePost.glsl'
import BlurPost from '~/glxp/shaders/post/blurPost.glsl'
import BlurPass from '~/glxp/shaders/blurPass.glsl'
import Subscenes from '~/glxp/shaders/post/subscenes.glsl'

// Commons
// import Composite from '~/glxp/shaders/common/composite.glsl'
// import ColorBurn from '~/glxp/shaders/common/colorBurn.glsl'
import Rotation from '~/glxp/shaders/common/rotation.glsl'
// import NormalBlending from '~/glxp/shaders/common/normalBlending.glsl'
// import ScreenBlending from '~/glxp/shaders/common/screenBlending.glsl'
import Blur from '~/glxp/shaders/common/blur5.glsl'
// import Bright from '~/glxp/shaders/common/bright.glsl'
import Noise from '~/glxp/shaders/common/noise.glsl'
import AllBlendModes from '~/glxp/shaders/common/allBlendModes.glsl'
import ColorCorrection from '~/glxp/shaders/common/colorCorrection.glsl'
import Fxaa from '~/glxp/shaders/common/fxaa.glsl'
import Circle from "~/glxp/shaders/common/circle.glsl"
import SpacecraftFleetGround from "~/glxp/shaders/spacecraftFleetGround.glsl"
import StandardWindows from "~/glxp/shaders/pbr/standardWindows.glsl"
import Flag from "~/glxp/shaders/flag.glsl"
import Matcap from "~/glxp/shaders/matcap.glsl"

export default {
  'unlit': Unlit,
  'mergedUnlit': MergedUnlit,
  'standard': Standard,
  // 'mergedStandard': MergedStandard,
  'post': Post,
  'postBlur': BlurPass,
  'debugUnlit': DebugUnlit,
  'debugHotspot': DebugHotspot,
  'skybox': Skybox,
  'grid': Grid,
  'subscenes': Subscenes,
  'homePlanet': HomePlanet,
  'experiencePlanet': ExperiencePlanet,
  'experienceLine': ExperienceLine,
  'experienceGlow': ExperienceGlow,
  'experienceGlowTop': ExperienceGlowTop,
  'experienceSun': ExperienceSun,
  'experienceBackground': ExperienceBackground,
  'experienceClouds': ExperienceClouds,
  'whitebox': Whitebox,
  'flare': Flare,
  'lensFlarePost': LensFlarePost,
  'background': Background,
  'spaceportSky': SpaceportSky,
  'innerAtmosphere': InnerAtmosphere,
  'outerAtmosphere': OuterAtmosphere,
  'backgroundSphereTexture': BackgroundSphereTexture,
  'backgroundSpacecraftFleetUnity': BackgroundSpacecraftFleetUnity,
  'debugUv': DebugUv,
  // 'occluder': Occluder,
  'spacecraftFleetGround': SpacecraftFleetGround,
  'standardWindows': StandardWindows,
  'flag': Flag,
  'matcap': Matcap,
  
  'commons': {
    // 'colorBurn': ColorBurn,
    'rotation': Rotation,
    'blur': Blur,
    'noise': Noise,
    'blurPost': BlurPost,
    // 'composite': Composite,
    // 'bright': Bright,
    'allBlendModes': AllBlendModes,
    // 'normalBlending': NormalBlending,
    // 'screenBlending': ScreenBlending,
    'colorCorrection': ColorCorrection,
    'fxaa': Fxaa,
    'circle': Circle
  }
}
