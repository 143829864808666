import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Vec2 } from '~/glxp/ogl/math/Vec2.js'
import { Vec3 } from '~/glxp/ogl/math/Vec3.js'
import { Cylinder } from '~/glxp/ogl/extras/Cylinder.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Color } from '~/glxp/ogl/math/Color'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'
import { Transform } from '~~/glxp/ogl/core/Transform'

class ExperienceLineEntity {
  constructor(
    scene,
    {
      id = 0,
      parent = null,
      config = {},
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = true,
      depthTest = true,
      depthWrite = false,
      renderOrder = 10,
      name = 'Experience Line'
    } = {}
  ) {
    this.groupId = id
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name

    // Shader
    this.shader = new Shader(ShaderManifest['experienceLine'])

    // Config
    this.config = config

    this.init()
  }

  init() {
    this.radius = 1.355
    this.geometry = new Cylinder(this.gl, { radiusTop : this.radius, radiusBottom : this.radius, height: 0.15, radialSegments: 48, heightSegments: 1, openEnded: true })

    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      uniforms: {
        uTime: { value: this.scene.time }, 
        uAlpha: { value: 1 },
        uCamera: { value: this.scene.camera.worldPosition },
        uColorDots: { value: new Color(this.config.ColorDots.value) },
        uColorFull: { value: new Color(this.config.ColorFull.value) },
        uColorFullFresnel: { value: new Color(this.config.ColorFullFresnel.value) },
        uLineWidth: this.config.LineWidth,
        uLineWidth: this.config.LineWidth,
        uDotsFrequency: this.config.DotsFrequency,
        uDotsSmooth: this.config.DotsSmooth,
        uFresnelLength: this.config.FresnelLength,
        uGlowOpacity: this.config.GlowOpacity,
        uDottedLineProgress: this.config.DottedLineProgress,
        uFullLineProgress: this.config.FullLineProgress,
      },
      transparent: this.transparent,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
    })

    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
    })

    this.mesh.scale.set(this.scale, this.scale, this.scale)
    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  initHotspots({ namePrefix, qty, length, offset, } = {namePrefix: 'hotspot_line', qty: 4, length: Math.PI * 2, offset: 0}) {
    this.hotspots = []

    const radInterval = length / qty

    for (let i = 0; i < qty; i += 1) {
      const x = Math.cos(i * radInterval + offset) * this.radius
      const z = Math.sin(i * radInterval + offset) * this.radius

      const hotspot = new Transform(this.gl)
      hotspot.position.set(x, 0, z)
      hotspot.setParent(this.mesh)
      
      this.hotspots.push({
        name: `${namePrefix}_${i}`,
        worldPosition: hotspot.worldPosition,
        screenPosition: new Vec3(),
        screenPositionPx: new Vec2(),
        distanceToCamera: 1,
        inView: false,
        visibility: 1
      })
    }

    return this.hotspots
  }

  onLoaded() {
    setTimeout(() => {
      this.program.uniforms['uColorDots'].value = new Color(this.config.ColorDots.value)
      this.program.uniforms['uColorFull'].value = new Color(this.config.ColorFull.value)
      this.program.uniforms['uColorFullFresnel'].value = new Color(this.config.ColorFullFresnel.value)
    }, 1);
  }

  preRender() {
    this.program.uniforms["uTime"].value = this.scene.time

    if (DebugController.active) {
      this.program.uniforms['uColorDots'].value = new Color(this.config.ColorDots.value)
      this.program.uniforms['uColorFull'].value = new Color(this.config.ColorFull.value)
      this.program.uniforms['uColorFullFresnel'].value = new Color(this.config.ColorFullFresnel.value)
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default ExperienceLineEntity
