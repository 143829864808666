// Libs
import gsap from 'gsap'

// Structural
import GLBLoader from '~/glxp/loader/glbLoader'
import Scene from '~/glxp/abstract/scene'
import DebugController from '~/glxp/debug/debugController'

// Entities
import MeshPBR from '~/glxp/entities/MeshPBR'
import MeshUnlit from '~/glxp/entities/MeshUnlit'
import MeshPBRWindows from './MeshPBRWindows'

// Utils
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import Manifest from '~/glxp/manifest'
import PBRConfigs from '~/glxp/debug/pbrConfigs'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'

// Constants
import { GUI_PANEL_PBR, GUI_PANEL_UNLIT } from '~/glxp/data/dataGUIPanels'

export default class MeshVSSUnity {
    scene
    meshesExcludeList
    PBRConfig
    body
    wings
    windows
    text
    leftSkirt
    rightSkirt
    hotspots = []

    root = new Transform()
    rootParent = new Transform()
    meshes = []
    featherTimeline = gsap.timeline({ paused: true })
    animations = {
        root: {
            position: { x: 0, y: 0, z: 0 },
            rotation: { x: 0, y: 0, z: 0 }
        },
        body: {
            rotation: { x: 0, y: 0, z: 0 }
        },
        wings: {
            rotation: { x: 0, y: 0, z: 0 }
        },
    }
    config = {
        positionOffset: {
            value: { x: 0, y: 0, z: 0 },
            params: { x: { min: -3.1415, max: 3.1415 }, y: { min: -3.1415, max: 3.1415 }, z: { min: -3.1415, max: 3.1415 } }
        }
    }

    /**
     * Wrapper for loading and animating VSS Unity
     * @param {Scene} scene 
     * @param {{ meshesExcludeList : string[] }} options
     */
    constructor(scene, { meshesExcludeList, PBRConfig }) {
        this.scene = scene
        this.meshesExcludeList = meshesExcludeList
        this.PBRConfig = PBRConfig
    }

    load(url = `${"/"}glxp/models/spaceship_unity_decal_v5_draco_v2`) {
        return new Promise((resolve, reject) => {
            new GLBLoader(url + '.glb', true).then((glb) => {
                // console.log(glb);

                // Root for PBR model
                this.root.scale.set(1, 1, 1)

                // Build node Tree
                let modelTree = GLTFUtils.buildNodeTree(glb, this.root)

                // Mesh List
                let meshList = GLTFUtils.buildMeshList(glb, modelTree)

                // Mesh Instanciation
                let entity

                for (const mesh of meshList) {
                    // console.log(mesh.node.name);
                    if (this.meshesExcludeList.includes(mesh.node.name)) {
                        continue
                    }

                    if (mesh.node.name.includes('Hotspot')) {
                      entity = new MeshUnlit(this.scene, mesh.meshData, "black", {
                        id: GUI_PANEL_UNLIT,
                        gltf: glb,
                        name: mesh.meshName,
                        node: mesh.node,
                        materialName: "Hotspot",
                        shaderId: "debugUv",
                        parent: this.root,
                        visible: false
                      })
                      this.hotspots.push(entity)
                    }

                    switch (mesh.node.name) {
                        case 'SpaceShip_Unity_Body':
                            entity = new MeshPBR(this.scene, {
                                id: GUI_PANEL_PBR,
                                parent: mesh.parent || this.root,
                                gltf: glb,
                                data: mesh.meshData,
                                name: mesh.meshName,
                                node: mesh.node,
                                fog: false,
                                transparent: false,
                                materialName: mesh.materialName,
                                material: mesh.material,
                                globalConfig: this.PBRConfig,
                            })
                            this.body = entity.mesh
                            break

                        case 'SpaceShip_Unity_Wings':
                            entity = new MeshPBR(this.scene, {
                                id: GUI_PANEL_PBR,
                                parent: mesh.parent || this.root,
                                gltf: glb,
                                data: mesh.meshData,
                                name: mesh.meshName,
                                node: mesh.node,
                                fog: false,
                                transparent: false,
                                materialName: mesh.materialName,
                                material: mesh.material,
                                globalConfig: this.PBRConfig,
                            })
                            this.wings = entity.mesh
                            break
                        
                        case 'SpaceShip_Unity_Skirt_L':
                            entity = new MeshPBR(this.scene, {
                                id: GUI_PANEL_PBR,
                                parent: mesh.parent || this.root,
                                gltf: glb,
                                data: mesh.meshData,
                                name: mesh.meshName,
                                node: mesh.node,
                                fog: false,
                                transparent: false,
                                materialName: mesh.materialName,
                                material: mesh.material,
                                globalConfig: this.PBRConfig,
                            })
                            this.leftSkirt = entity.mesh
                            break

                        case 'SpaceShip_Unity_Skirt_R':
                            entity = new MeshPBR(this.scene, {
                                id: GUI_PANEL_PBR,
                                parent: mesh.parent || this.root,
                                gltf: glb,
                                data: mesh.meshData,
                                name: mesh.meshName,
                                node: mesh.node,
                                fog: false,
                                transparent: false,
                                materialName: mesh.materialName,
                                material: mesh.material,
                                globalConfig: this.PBRConfig,
                            })
                            this.rightSkirt = entity.mesh
                            break

                        case 'SpaceShip_Unity_Windows':
                            entity = new MeshPBRWindows(this.scene, {
                                id: GUI_PANEL_PBR,
                                parent: mesh.parent || this.root,
                                gltf: glb,
                                data: mesh.meshData,
                                name: mesh.meshName,
                                node: mesh.node,
                                fog: false,
                                transparent: true,
                                materialName: 'M_Spaceship_Unity_Windows',
                                material: mesh.material,
                                globalConfig: this.PBRConfig,
                                shaderId: 'standardWindows'
                            })
                            entity.config.WindowSheenOpacity.value = 1
                            entity.config.WindowSheenDepth.value = 1
                            this.windows = entity.mesh
                            break

                        case 'SpaceShip_Unity_Text':
                            entity = new MeshPBR(this.scene, {
                                id: GUI_PANEL_PBR,
                                parent: mesh.parent || this.root,
                                gltf: glb,
                                data: mesh.meshData,
                                name: mesh.meshName,
                                node: mesh.node,
                                fog: false,
                                transparent: true,
                                materialName: mesh.materialName,
                                material: mesh.material,
                                globalConfig: this.PBRConfig,
                            })
                            this.text = entity.mesh
                            break;
                    }

                    this.meshes.push(entity)
                }

                this.root.setParent(this.rootParent)
                resolve()
            })
        })
    }

    initGui(){
        DebugController.addBlade(this.config, `VSS Unity`, 0)
    }

    setFeatherTimeline() {
        this.featherTimeline.to(this.animations.root.rotation, { x: Math.PI * -0.12, duration: 100, ease: 'power1.inOut' }, 0)
        // Must rotate to 60 degrees exactly
        this.featherTimeline.to(this.animations.wings.rotation, { x: 1.0472, duration: 100, ease: 'power1.inOut' }, 0)
    }

    /**
     * Remember to update feathering timeline: featherTimeline.progress(someNumber)
     */
    updateFeather(progress) {
        this.featherTimeline.progress(progress)

        // this.root.rotation.set(this.animations.root.rotation.x, this.animations.root.rotation.y, this.animations.root.rotation.z)

        this.wings && this.wings.rotation.set(this.animations.wings.rotation.x, this.animations.wings.rotation.y, this.animations.wings.rotation.z)

        this.leftSkirt && this.leftSkirt.rotation.set(this.animations.wings.rotation.x, this.animations.wings.rotation.y, this.animations.wings.rotation.z)

        this.rightSkirt && this.rightSkirt.rotation.set(this.animations.wings.rotation.x, this.animations.wings.rotation.y, this.animations.wings.rotation.z)
    }
}
