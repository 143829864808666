// import {Pane} from 'tweakpane';
// import * as EssentialsPlugin from '@tweakpane/plugin-essentials';
// import * as TweakpaneImagePlugin from 'tweakpane-image-plugin';

import Emitter from 'event-emitter'

import RAF from '~/glxp/utils/raf'

// Configs
import ConfigHome from './configs/configHome'
import ConfigSpacecraftFleet from './configs/configSpacecraftFleet'
import ConfigSpaceportAmerica from './configs/configSpaceportAmerica'
import ConfigFlightExperience from './configs/configFlightExperience'

// uncomment Firebase stuff during dev and QA, it's not needed in production
// import { initializeApp } from 'firebase/app';
// import { getDatabase, ref, set, onValue } from "firebase/database";

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function(str, newStr){

    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);

  };
}

// IMPORTANT : (database"URL" IN ALL CAPS)
// const firebaseConfig = {
//   apiKey: "AIzaSyC95bPJfdTNeQhm5O66oKivpUyviDV3rZQ",
//   authDomain: "virgin-galactic-gui.firebaseapp.com",
//   databaseURL: "https://virgin-galactic-gui-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "virgin-galactic-gui",
//   storageBucket: "virgin-galactic-gui.appspot.com",
//   messagingSenderId: "301453363579",
//   appId: "1:301453363579:web:419e62fe2632486e5555de"
// };
const firebaseConfig = null

let EssentialsPlugin
let TweakpaneImagePlugin
let Pane

class DebugController {
  constructor() {
    this.active = this.queryDebug()
    // this.active = true

    this.tweakpaneIsLoaded = false
    this.guiIsReady = this.active ? false : true
    this.bladesQueue = []
    this.configs = {}
    this.inputs = {}
    this.savedConfigs = {}
    this.folders = {}
    this.sceneConfig = {}

    this.currentSceneId = null

    this._emitter = {}
    Emitter(this._emitter)
    this.on = this._emitter.on.bind(this._emitter)

    if (this.active) {
      this.lazyloadTweakpaneThenInit()
    }
  }

  lazyloadTweakpaneThenInit() {
    const promiseTweakpane = import('tweakpane')
    const promiseEssentials = import('@tweakpane/plugin-essentials')
    const promiseImage = import('tweakpane-image-plugin')

    Promise.all([promiseTweakpane, promiseEssentials, promiseImage]).then(
      (packages) => {
        Pane = packages[0].Pane
        EssentialsPlugin = packages[1]
        TweakpaneImagePlugin = packages[2]
        this.tweakpaneIsLoaded = true

        this.init()
        if (firebaseConfig) {
          this.initNetwork()
        }

        // create the blades stored in the bladesQueue
        for (let i = 0; i < this.bladesQueue.length; i++) {
          const params = this.bladesQueue[i]
          this.addBladeConfigActive(params.config, params.id, params.tabId)
        }
        this.bladesQueue = []
        this._emitter.emit('gui-lazyloaded')
        this.guiIsReady = true
      }
    )
  }

  init() {
    this.pane = new Pane({
      title: 'Debug UI',
      expanded: true,
    })
    this.pane.hidden = !this.active || this.queryDebug('demo')
    const positionPane = this.pane.addBlade({
      view: 'list',
      label: 'position',
      options: [
        { text: 'top right', value: "top right" },
        { text: 'top left', value: "top left" },
        { text: 'bottom right', value: "bottom right" },
        { text: 'bottom left', value: "bottom left" },
      ],
      value: 'top right',
    });
    positionPane.on('change', (ev) => {
      switch (ev.value) {
        case "top right":
          this.pane.element.parentNode.style.top = "0px";
          this.pane.element.parentNode.style.right = "0px";
          this.pane.element.parentNode.style.bottom = "auto";
          this.pane.element.parentNode.style.left = "auto";
          break;
        case "top left":
          this.pane.element.parentNode.style.top = "0px";
          this.pane.element.parentNode.style.right = "auto";
          this.pane.element.parentNode.style.bottom = "auto";
          this.pane.element.parentNode.style.left = "0px";
          break;
        case "bottom right":
          this.pane.element.parentNode.style.top = "auto";
          this.pane.element.parentNode.style.right = "0px";
          this.pane.element.parentNode.style.bottom = "0px";
          this.pane.element.parentNode.style.left = "auto";
          break;
        case "bottom left":
          this.pane.element.parentNode.style.top = "auto";
          this.pane.element.parentNode.style.right = "auto";
          this.pane.element.parentNode.style.bottom = "0px";
          this.pane.element.parentNode.style.left = "0px";
          break;
      }
    })
    this.pane.registerPlugin(EssentialsPlugin)
    this.pane.registerPlugin(TweakpaneImagePlugin)
    this.pane.add
    this.fpsGraph = this.pane.addBlade({
      view: 'fpsgraph',
      label: 'FPS',
      lineCount: 2,
    })
    RAF.onBefore = this.fpsGraph.begin.bind(this.fpsGraph)
    RAF.onAfter = this.fpsGraph.end.bind(this.fpsGraph)

    this.tab = this.pane.addTab({
      pages: [{ title: 'General' }, { title: 'Materials - PBR' }, { title: 'Materials - Unlit' }, { title: 'Materials - Custom' }],
    })

    document.head.insertAdjacentHTML(
      'beforeend',
      `<style>
    :root {
      --tp-base-background-color: hsla(0, 0%, 10%, 1);
      --tp-base-shadow-color: hsla(0, 0%, 0%, 0.2);
      --tp-button-background-color: hsla(0, 0%, 80%, 1);
      --tp-button-background-color-active: hsla(0, 0%, 100%, 1);
      --tp-button-background-color-focus: hsla(0, 0%, 95%, 1);
      --tp-button-background-color-hover: hsla(0, 0%, 85%, 1);
      --tp-button-foreground-color: hsla(0, 0%, 0%, 0.8);
      --tp-container-background-color: hsla(0, 0%, 0%, 0.3);
      --tp-container-background-color-active: hsla(0, 0%, 0%, 0.6);
      --tp-container-background-color-focus: hsla(0, 0%, 0%, 0.5);
      --tp-container-background-color-hover: hsla(0, 0%, 0%, 0.4);
      --tp-container-foreground-color: hsla(0, 0%, 100%, 0.5);
      --tp-groove-foreground-color: hsla(0, 0%, 0%, 0.2);
      --tp-input-background-color: hsla(0, 0%, 0%, 0.3);
      --tp-input-background-color-active: hsla(0, 0%, 0%, 0.6);
      --tp-input-background-color-focus: hsla(0, 0%, 0%, 0.5);
      --tp-input-background-color-hover: hsla(0, 0%, 0%, 0.4);
      --tp-input-foreground-color: hsla(0, 0%, 100%, 0.5);
      --tp-label-foreground-color: hsla(0, 0%, 100%, 0.5);
      --tp-monitor-background-color: hsla(0, 0%, 0%, 0.3);
      --tp-monitor-foreground-color: hsla(0, 0%, 100%, 0.3);
    }
    .tp-dfwv {
      position: fixed;
      width: 600px !important;
      z-index: 999999;
      max-height: calc(100vh - 8px);
      overflow-y: auto;
    }
    </style>`
    )

    this.pane.containerElem_.addEventListener('mouseover', () => {
      this._emitter.emit('prevent')
    })
    this.pane.containerElem_.addEventListener('mouseleave', () => {
      this._emitter.emit('unprevent')
    })
  }

  queryDebug(name = 'dev') {
    let url = window.location.href
    name = name.replace(/[[]]/g, '\\$&')

    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    var results = regex.exec(url)
    if (results) {
      return decodeURIComponent(results[2].replace(/\+/g, ' ')) === 'true'
    }
    return false
  }

  initNetwork() {
    this.networkIsOnline = false
    this.networkInputs = {}
    this.networkParams = {
      status: '🔴 offline',
      name: localStorage.getItem('firebase-gui-name') || '',
      'config-list': [],
    }

    this.networkPane = new Pane({
      title: 'Network',
      expanded: true,
    })
    this.networkPane.hidden = !this.active || this.queryDebug('demo')
    this.networkPane.containerElem_.style.right = 'auto'
    this.networkPane.containerElem_.style.left = '8px'
    this.networkPane.containerElem_.style.top = 'auto'
    this.networkPane.containerElem_.style.bottom = '8px'
    this.networkPane.containerElem_.style.maxWidth = '400px'

    const positionPane = this.networkPane.addBlade({
      view: 'list',
      label: 'position',
      options: [
        { text: 'top right', value: "top right" },
        { text: 'top left', value: "top left" },
        { text: 'bottom right', value: "bottom right" },
        { text: 'bottom left', value: "bottom left" },
      ],
      value: 'bottom left',
    });
    positionPane.on('change', (ev) => {
      switch (ev.value) {
        case "top right":
          this.networkPane.element.parentNode.style.top = "0px";
          this.networkPane.element.parentNode.style.right = "0px";
          this.networkPane.element.parentNode.style.bottom = "auto";
          this.networkPane.element.parentNode.style.left = "auto";
          break;
        case "top left":
          this.networkPane.element.parentNode.style.top = "0px";
          this.networkPane.element.parentNode.style.right = "auto";
          this.networkPane.element.parentNode.style.bottom = "auto";
          this.networkPane.element.parentNode.style.left = "0px";
          break;
        case "bottom right":
          this.networkPane.element.parentNode.style.top = "auto";
          this.networkPane.element.parentNode.style.right = "0px";
          this.networkPane.element.parentNode.style.bottom = "0px";
          this.networkPane.element.parentNode.style.left = "auto";
          break;
        case "bottom left":
          this.networkPane.element.parentNode.style.top = "auto";
          this.networkPane.element.parentNode.style.right = "auto";
          this.networkPane.element.parentNode.style.bottom = "0px";
          this.networkPane.element.parentNode.style.left = "0px";
          break;
      }
    })

    this.networkPane.addMonitor(this.networkParams, 'status', {
      multiline: false,
      lineCount: 1,
    })

    this.networkPane.addSeparator()

    this.networkInputs['list'] = this.networkPane.addBlade({
      view: 'list',
      label: 'presets',
      options: this.networkParams['config-list'],
      value: 'none',
    })
    this.networkPane.addSeparator()
    this.networkInputs['sync'] = this.networkPane.addButton({ title: 'Sync' })
    this.networkInputs['dump'] = this.networkPane.addButton({ title: 'Dump' })
    this.networkPane.addSeparator()
    this.networkInputs['name'] = this.networkPane.addInput(
      this.networkParams,
      'name'
    )
    this.networkInputs['save'] = this.networkPane.addButton({ title: 'Save' })

    // Initialize Firebase

    this.firebase = initializeApp(firebaseConfig)
    this.initNetworkEvents()
    this.updateNetworkStatus()
  }

  // TODO: For now pretty hacky way to remove gsap objects while avoiding tweakpan dom nodes
  // Needed to remove cyclical objects before json stringify
  removeSpecificKey = (obj, key = '') => {
    if (obj[key] !== undefined) {
      delete obj[key]
    }

    for (let subObj in obj) {
      if (obj[subObj] !== null && typeof obj[subObj] === "object" && obj[subObj].nodeType !== 1) {
        this.removeSpecificKey(obj[subObj], key)
      }
    }
  }

  dump = () => {
    this.removeSpecificKey(this.configs, '_gsap')

    console.log(this.configs)
    console.log(JSON.stringify(this.configs))

  }

  initNetworkEvents = () => {
    this.networkInputs['name'].on('change', (evt) => {
      localStorage.setItem('firebase-gui-name', evt.value)
    })

    this.networkInputs['dump'].on('click', () => {
      this._emitter.emit('beforeSave')
      this.dump()
    })
    this.networkInputs['save'].on('click', () => {
      this._emitter.emit('beforeSave')

      if (this.networkParams.name == '') {
        alert('Please fill a name in Network GUI')
        return
      }

      this.removeSpecificKey(this.configs, '_gsap')

      for (const key in this.configs) {
        if (Object.hasOwnProperty.call(this.configs, key)) {
          const folder = this.configs[key]
          if (this.configs[key]) {
            const configFolder = this.configs[key]
            for (const key in folder) {
              if (Object.hasOwnProperty.call(folder, key)) {
                const param = folder[key]
                if (configFolder[key]) {
                  const configParam = configFolder[key]
                  if (configParam.type == 'input-image') {
                    configParam.value = 'placeholder'
                  }
                }
              }
            }
          }
        }
      }

      // Sanitize name and append date
      const configName = `${this.networkParams.name.replace(/[.\/#$\[\]]+/g, '_')}___${new Date()
        .toUTCString()
        .replaceAll(',', '')
        .replaceAll(' ', '-')}`
      console.log('saving', configName)
      const db = getDatabase()
      console.log(this.configs)
      set(ref(db, 'configs/' + configName), this.configs).then(() => {
        this.refreshNetwork()
      })
    })
    this.networkInputs['sync'].on('click', () => {
      this.refreshNetwork()
    })
  }

  refreshNetwork() {
    const db = getDatabase()
    const starCountRef = ref(db, 'configs/')
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val()
      this.savedConfigs = data
      this.networkParams['config-list'].length = 0
      this.networkParams['config-list'].push({ text: 'current', value: null })
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this.networkParams['config-list'].push({ text: key, value: key })
        }
      }

      this.networkInputs['list'].dispose()
      this.networkInputs['list'] = this.networkPane.addBlade({
        view: 'list',
        label: 'presets',
        index: 1,
        options: this.networkParams['config-list'],
        value: 'none',
      })
      this.networkInputs['list'].on('change', (evt) => {
        this.applyConfig(evt.value)
      })
      setTimeout(() => {
        this.networkPane.refresh()
      }, 200)
    })
  }

  updateNetworkStatus() {
    const db = getDatabase()
    const connectedRef = ref(db, '.info/connected')
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        this.networkIsOnline = true
        console.log('GUI connected')
        this.refreshNetwork()
        this.networkParams['status'] = '🟢 online'
      } else {
        this.networkIsOnline = false
        console.log('GUI not connected')
      }
      this.networkInputs['sync'].disabled = !this.networkIsOnline
      this.networkInputs['dump'].disabled = !this.networkIsOnline
      this.networkInputs['save'].disabled = !this.networkIsOnline
      this.networkPane.refresh()
    })
  }

  applyConfig(id) {
    for (const key in this.savedConfigs[id]) {
      if (Object.hasOwnProperty.call(this.savedConfigs[id], key)) {
        const folder = this.savedConfigs[id][key]
        if (this.configs[key]) {
          const configFolder = this.configs[key]
          for (const key in folder) {
            if (Object.hasOwnProperty.call(folder, key)) {
              const param = folder[key]
              const configParam = configFolder[key]
              if (configParam) {
                configParam.value = param.value
              }
            }
          }
        }
      }
    }
    // if (config["_SplinesEditorData"]) {
    //   this.configs["_SplinesEditorData"] = this.savedConfigs[id]["_SplinesEditorData"]
    // }
    this.pane.refresh()
    this._emitter.emit('updateConfig')
  }

  applyConfigFile(config) {
    for (const key in config) {
      if (Object.hasOwnProperty.call(config, key)) {
        const folder = config[key]
        if (this.configs[key]) {
          const configFolder = this.configs[key]
          for (const key in folder) {
            if (Object.hasOwnProperty.call(folder, key)) {
              const param = folder[key]
              // console.log(param, param.params?.label);
              const configParam = configFolder[key]
              if (configParam) {
                configParam.value = param.value
              }
            }
          }
        }
      }
    }
    // if (config['_SplinesEditorData']) {
    //   this.configs['_SplinesEditorData'] = config['_SplinesEditorData']
    // }
    if (this.pane) {
      this.pane.refresh()
    }
  }

  setCurrentScene(id) {
    this.currentSceneId = id
  }

  onLoaded() {
    switch (this.currentSceneId) {
      case 'home':
        this.sceneConfig = ConfigHome
        break

      case 'spacecraftFleet':
        this.sceneConfig = ConfigSpacecraftFleet
        break

      case 'spaceportAmerica':
        this.sceneConfig = ConfigSpaceportAmerica
        break

      case 'flightExperience':
        this.sceneConfig = ConfigFlightExperience
        break

      default:
        this.sceneConfig = {}
    }

    this.applyConfigFile(this.sceneConfig)

    if (this.pane) {
      this.pane.refresh()
    }
  }

  addConfig() { }

  addBladeScene(config, id) {
    return this.addBlade(config, id, 'scene')
  }

  addBlade(config, id, tabId = 0, parentFolder = null, expanded = false) {
    // Search for config params but only generate gui element if dev mode is active
    if (this.active) {
      if (this.tweakpaneIsLoaded) {
        // if tweakpane is loaded, return the blade inputs
        return this.addBladeConfigActive(config, id, tabId, parentFolder, expanded)
      } else {
        // otherwise add it to the queue to create it later
        this.bladesQueue.push({ config, id, tabId })
        return null
      }
    } else {
      this.addBladeConfigInactive(config, id, tabId)
      return null
    }
  }

  addBladeConfigActive(config, id, tabId = 0, parentFolder = null, expanded = false) {
    // specific folder when using 'addBladeScene' instead of 'addBlade'
    const getParentFolder = parentFolder ? parentFolder : this.tab.pages[tabId]
    this.folders[id] = getParentFolder.addFolder({
      title: id,
      expanded,
    })

    let params = {}
    this.inputs[id] = {}
    for (const key in config) {
      if (Object.hasOwnProperty.call(config, key)) {
        const el = config[key]
        if (el.type == 'input-image') {
          const input = this.folders[id].addInput(el, 'value', {
            view: 'input-image',
            imageFit: 'contain',
            label: key,
          })
          this.inputs[id][key] = input
        } else if (el.type == 'bool') {
          this.folders[id].addInput(el, 'value', { label: key })
        } else if (el.view === "cubicbezier") {
          this.folders[id].addBlade(el)
        } else {
          this.configs[id] = config
          params[key] = this.folders[id].addInput(
            el,
            'value',
            Object.assign(el.params, { label: key })
          )
        }
      }
    }

    const folder = this.folders[`${id}`]
    folder.params = params
    return folder
  }

  addBladeConfigInactive(config, id) {
    for (const key in config) {
      if (Object.hasOwnProperty.call(config, key)) {
        this.configs[id] = config
      }
    }
  }

  getGui(id) {
    return this.folders[`${id}`]
  }

  // getPBRGui(id) {
  //   return this.folders[`${id} PBR`]
  // }

  addTextureUpload(entity, opts = {}) {
    // this function adds buttons in the GUI to upload entity's current textures
    const baseOptions = {
      maps: [
        'albedoMap',
        'normalTexture',
        'ORMTexture',
        'metallicRoughnessTexture',
        'alphaTexture',
        'emissiveTexture',
        'occlusionTexture',
      ], // optional
      callback: null, // optional
      textureOptions: ['repeat'], // optional
    }
    const options = { ...baseOptions, ...opts }

    if (entity.gui) {
      entity.gui.textureInputs = {}
    }

    for (let i = 0; i < options.maps.length; i++) {
      // create an file input for each texture
      const title = options.maps[i]
      const inputFile = document.createElement('input')
      inputFile.type = 'file'
      inputFile.classList.add('input-file')

      if (entity.gui) {
        // add the button in the folder
        const debugBtn = entity.gui.addButton({
          title,
          label: 'Upload texture',
          index: 1,
        })
        debugBtn.on('click', () => {
          inputFile.click()
        })
        entity.gui.textureInputs[title] = debugBtn
      } else {
        console.warn(
          'entity.gui is undefined, cannot add texture upload button',
          options
        )
        return
      }

      // when button is clicked, create image from imported file
      inputFile.addEventListener('change', (event) => {
        const file = inputFile.files[0]
        const image = new Image()
        image.onload = () => {
          // turn image into texture with optional params
          let texture
          if (entity.scene.textureLoader) {
            texture = entity.scene.textureLoader.initTexture(
              image,
              options.textureOptions
            )
          } else {
            console.warn(
              'entity.scene.textureLoader is undefined, cannot create texture from image',
              options
            )
            return
          }

          // detect uniform associated to texture's key in manifest
          let uniformName = null
          // pbr
          if (title === 'albedoMap') uniformName = 'uBaseColorSampler'
          if (title === 'ORMTexture')
            uniformName = 'uOcclusionRoughnessMetallicSampler'
          if (title === 'metallicRoughnessTexture')
            uniformName = 'uMetallicRoughnessSampler'
          if (title === 'normalTexture') uniformName = 'uNormalSampler'
          if (title === 'emissiveTexture') uniformName = 'uEmissiveSampler'
          if (title === 'occlusionTexture') uniformName = 'uOcclusionSampler'
          if (title === 'alphaTexture') uniformName = 'uAlphaMapSampler'

          // unlit
          if (title === 'texture') uniformName = 'uTexture'

          // replace uniform if it exists
          if (uniformName && entity.program.uniforms[uniformName]) {
            entity.program.uniforms[uniformName].value = texture
            entity.program.uniforms[uniformName].value.needsUpdate = true
          } else {
            console.warn(
              'no uniform matching that title:',
              title,
              ' - uniforms:',
              entity.program.uniforms
            )
          }

          if (options.callback) {
            options.callback(texture)
          }

          URL.revokeObjectURL(image.src)
        }
        if (file) image.src = URL.createObjectURL(file)
      })
    }
  }
}

const Export = new DebugController()
export default Export
