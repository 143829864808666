import DebugController from '~/glxp/debug/debugController'

import ExperienceLine from '~/glxp/entities/experience/ExperienceLine'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class ExperienceLinesEntity {
  constructor(
    scene,
    {
      id = 0,
      parent = null,
      scale = 1,
      name = 'Experience Lines'
    } = {}
  ) {
    this.groupId = id
    this.gl = scene.gl
    this.scene = scene
    this.parent = parent ? parent : scene.root
    this.scale = scale
    this.name = name

    this.config = {
      LineWidth: { value: 0.03, params: { min: 0.01, max: 1, step: 0.01 } },
      DotsSmooth: { value: 0.01, params: { min: 0.001, max: 0.5, step: 0.001 } },
      DotsFrequency: { value: 200, params: { min: 1, max: 300, step: 1 } },
      ColorDots: { value: '#ffffff', params: { } },
      ColorFull: { value: '#a836ff', params: { } },
      ColorFullFresnel: { value: '#265eac', params: { } },
      FresnelLength: { value: 0.5, params: { min: 0.01, max: 1, step: 0.01 } },
      GlowOpacity: { value: 0.1, params: { min: 0.01, max: 1, step: 0.01 } },
      DottedLineProgress: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      FullLineProgress: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      
      LineRot1: {
        value: { x: 0.63, y: -0.28, z: -0.27 },
        params: { x: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 }, y: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 }, z: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 } },
      },
      LineRot2: {
        value: { x: 1.55, y: 0.14, z: -0.34 },
        params: { x: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 }, y: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 }, z: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 } },
      },
      LineRot3: {
        value: { x: 0.23, y: -0.72, z: 0.64 },
        params: { x: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 }, y: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 }, z: { min: -Math.PI * 2, max: Math.PI * 2, step: 0.01 } },
      },
    }

    this.init()
  }

  initGui(){
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
  }

  init() {
    this.lines = []

    const line1 = new ExperienceLine(this.scene, { parent: this.parent, config: this.config })
    line1.mesh.rotation.set(this.config.LineRot1.value.x, this.config.LineRot1.value.y, this.config.LineRot1.value.z)
    this.lines.push(line1)

    const line2 = new ExperienceLine(this.scene, { parent: this.parent, config: this.config })
    line2.mesh.rotation.set(this.config.LineRot2.value.x, this.config.LineRot2.value.y, this.config.LineRot2.value.z)
    this.lines.push(line2)

    const line3 = new ExperienceLine(this.scene, { parent: this.parent, config: this.config })
    line3.mesh.rotation.set(this.config.LineRot3.value.x, this.config.LineRot3.value.y, this.config.LineRot3.value.z)
    this.lines.push(line3)

    this.initHotspots()
  }

  initHotspots() {
    this.hotspots = [
      ...this.lines[0].initHotspots({namePrefix: 'hotspot_experience_line1', qty: 3, length: Math.PI * 0.85, offset: Math.PI * 1.325}),
      ...this.lines[1].initHotspots({namePrefix: 'hotspot_experience_line2', qty: 3, length: Math.PI * 0.85, offset: -Math.PI * 0.4}),
      ...this.lines[2].initHotspots({namePrefix: 'hotspot_experience_line3', qty: 2, length: Math.PI * 0.55, offset: -Math.PI * 0.6}),
    ]
  }

  onLoaded() {
    this.initGui()

    setTimeout(() => {
      if (this.lines) {
        this.lines[0].mesh.rotation.set(this.config.LineRot1.value.x, this.config.LineRot1.value.y, this.config.LineRot1.value.z)
        this.lines[1].mesh.rotation.set(this.config.LineRot2.value.x, this.config.LineRot2.value.y, this.config.LineRot2.value.z)
        this.lines[2].mesh.rotation.set(this.config.LineRot3.value.x, this.config.LineRot3.value.y, this.config.LineRot3.value.z)
      }
    }, 1);
  }

  preRender() {
    if (DebugController.active) {
      if (this.lines) {
        this.lines[0].mesh.rotation.set(this.config.LineRot1.value.x, this.config.LineRot1.value.y, this.config.LineRot1.value.z)
        this.lines[1].mesh.rotation.set(this.config.LineRot2.value.x, this.config.LineRot2.value.y, this.config.LineRot2.value.z)
        this.lines[2].mesh.rotation.set(this.config.LineRot3.value.x, this.config.LineRot3.value.y, this.config.LineRot3.value.z)
      }
    }

    this.lines.forEach((line) => {
      line.preRender()
    })
  }

  dispose() {
    this.lines.forEach((line) => {
      line.dispose()
    })
  }
}

export default ExperienceLinesEntity
