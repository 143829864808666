// Libs
import gsap from 'gsap'

// Structural
import SubsceneAbs from '~/glxp/abstract/subscene'
import Manifest from '~/glxp/manifest'
import GLBLoader from '~/glxp/loader/glbLoader'

// Entities
import MeshPBR from '~~/glxp/entities/MeshPBR'
import MeshUnlit from '~/glxp/entities/MeshUnlit'
import MeshEarth from '~/glxp/entities/MeshEarth'
import MeshAtmosphere from '~/glxp/entities/MeshAtmosphere'
import LensFlares, { getHeaderFlares } from '~/glxp/entities/LensFlares'
import BackgroundEntity from '~~/glxp/entities/Background'
import MeshVSSUnity from '~~/glxp/entities/MeshVSSUnity'

// Post
import Bloom from '~/glxp/postProcess/bloomPass'
import LensFlarePost from '~/glxp/postProcess/LensFlarePost'

// Utils
import RAF from '~/glxp/utils/raf'
import DebugController from '~/glxp/debug/debugController'
import PBRConfigs from '~/glxp/debug/pbrConfigs'
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import GlobalEmitter from '~/glxp/utils/emitter'
import Mouse from '~/glxp/utils/mouse'
import { clamp, lerp } from '~/glxp/utils/math'
import { isMobile } from '@/glxp/utils/device'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { RenderTarget } from '~/glxp/ogl/core/RenderTarget.js'

// Managers
import CameraManager from '~/glxp/managers/CameraManager'
import DebugManager from '~/glxp/managers/DebugManager'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class SceneHomeHeader extends SubsceneAbs {
  constructor (container, manager = null, textureLoader, shouldFirstDraw = false) {
    super(container, manager, textureLoader, shouldFirstDraw)

    this.name = 'Home Header'

    this.manager = manager
    this.renderer = manager.renderer
    this.textureLoader = textureLoader

    this.time = 0
    this.dt = 0
    this.drawcalls = 0
    this.progress = 0
    this.progressDelta = 0
    this.progressTarget = 0.00001
    this.timescale = 1
    this.forceAllDraw = true
    this.lastWheel = 0
    this.wheelProgress = 0

    this.clearColor = this.manager.clearColor
    this.loaded = false
    this.active = false

    // Meshes & Entities
    this.root = new Transform()
    this.root.scale.set(1, 1, 1)
    this.meshes = []
    this.entities = {
      vssUnity: null,
      background: null
    }

    // Debug
    this.debugManager = new DebugManager(this)

    // Config
    this.config = {
      Pause: { value: false, type: "bool" },
      TimeScale: { value:  1, params: {min: 0, max: 3, step: 0.01} },
      SunPosition: { value: { x: -13.5, y: -1.3, z: -50 }, params: {} },
      ScrollDamping: { value: 0.05, params: { min: 0, max: 1, step: 0.01 } },

      // DEV
      OverrideScroll: { value: false, params: {} },
      Progress: { value: 0, params: {min: 0, max: 1, step: 0.01} },
      DevScrollSpeed: { value: 30, params: { min: 0, max: 60 } },
    }

    // Animations
    this.animCameraPos = isMobile ? { x: window.innerHeight > 666 ? 2.462 : 2.42 , y: -0.79, z: 8.5 } : { x: 1, y: 0, z: 6.5 }

    this.animCameraLookat = { x: -160, y: 0, z: -200 }

    this.animShipWrapperPos = { x: -1.6, y: 0.13, z: 2.5 }
    this.animShipWrapperRot = { x: Math.PI * -0.75, y: 0, z: Math.PI * 0.09 }

    // Inits
    this.initCameras()
    this.initRT()
    this.initBackground()
    this.initSun()

    if (!DebugController.queryDebug('notextures')) {
      this.initFlares()
    }

    // Animation
    this.isAnimatingIntro = false

    // Bindings
    this.boundPlayIntro = this.playIntro.bind(this)
    this.boundOnProgressRequest = this.onProgressRequest.bind(this)

    this.initialFov = isMobile ? 28.7 : 24
  }

  // Inits
  initRT () {
    this.rt = new RenderTarget(this.gl);
  }

  initPost() {
    this.post = new LensFlarePost(this, this.postOptions)
  }

  initCameras () {
    this.cameraManager = new CameraManager(this.gl, {
      width: this.width,
      height: this.height,
      scene: this,
      debug: false,
      hasMousePan: true,
      mousePanOffsets: [0.05, -0.025],
      frontFacing: true
    })
    this.camera = this.cameraManager.camera

    this.cameraManager.toGo.set(this.animCameraPos.x, this.animCameraPos.y, this.animCameraPos.z)
    this.cameraManager.toLook.set(this.animCameraLookat.x, this.animCameraLookat.y, this.animCameraLookat.z)
  }

  initBackground () {
    this.entities.background = new BackgroundEntity(this, "background")
    this.meshes.push(this.entities.background)
    this.entities.background.mesh.setParent(this.root)
  }

  initSun () {
    this.sun = new Transform()
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    this.sun.visible = false

    this.sun.setParent(this.root)
  }

  initFlares(){
    this.lensFlares = new LensFlares(this, {
      lightSource: this.sun,
      getFlares: (spacing) => getHeaderFlares(this, spacing),
      opacity: 1.4,
      spacing: 0.063,
      fadePower: 2,
    })
    this.meshes.push(this.lensFlares)
  }

  initShip () {
    this.meshes.push(...this.entities.vssUnity.meshes)
    
    this.shipWrapper = new Transform(this.gl)
    this.shipWrapper.setParent(this.root)

    if (this.entities.vssUnity.root) {
      this.entities.vssUnity.root.setParent(this.shipWrapper)

      // this.shipWrapper.position.set(this.animShipWrapperPos.x, this.animShipWrapperPos.y, this.animShipWrapperPos.z)
      // this.shipWrapper.rotation.set(this.animShipWrapperRot.x, this.animShipWrapperRot.y, this.animShipWrapperRot.z)
    }
  }

  setEarth() {
    // TODO: Refactor
    // Run setEarth after loadMesh

    if (this.earth) {
      this.earth.position.set(8, 15, -50)
      this.earth.rotation.y = .3
      this.earth.scale.set(85, 85, 85)
    }
  }

  // Animations
  playIntro () {
    if (this.tlIntro) {
      this.tlIntro.restart()
    }
  }

  initTimelineIntro () {
    this.isAnimatingIntro = true

    this.tlIntro = gsap.timeline({ paused: true, onComplete: () => { this.isAnimatingIntro = false } })

    this.tlIntro.fromTo(this.camera, { fov: this.initialFov }, { fov: this.initialFov + 3, duration: 1.5, ease: 'sine.inOut' }, 0)

    const ship = this.entities.vssUnity
    this.tlIntro.fromTo(ship.animations.root.position, { z: -15 }, { z: 0, duration: 3, ease: 'power4.out' }, 0)
    this.tlIntro.fromTo(this.animShipWrapperRot, { x: Math.PI * 0.05, y: 0, z: 0 }, { x: Math.PI * -0.15, y: Math.PI * -0.05, z: Math.PI * 0.09, duration: 2.75, ease: 'power4.out' }, 0)
  }

  initTimelineScroll () {
    this.tlScroll = gsap.timeline({ paused: true, duration: 100 })

    // Camera
    const ease = 'sine.out'
    if (isMobile) {
      this.tlScroll.to(this.animCameraLookat, { x: -100, duration: 80, ease }, 10)
      this.tlScroll.to(this.animCameraPos, { x: 1, duration: 80, ease }, 10)
    } else {
      this.tlScroll.to(this.animCameraLookat, { x: -120, duration: 100, ease }, 10)
      this.tlScroll.to(this.animCameraPos, { x: 0.4, duration: 100, ease }, 10)
    }
    this.tlScroll.to(this.camera, { fov: this.initialFov + 5, duration: 90, ease }, 10)

    this.entities.vssUnity.setFeatherTimeline()
  }

  setProgress(p, shouldForce = false){
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  // Lifecycle
  // Check subscene abstract for lifecycle events. They should start with super().
  fetchLoadableTextures () {
    let loadables = []

    this.initPost()

    const addTextureToLoadables = (groupKey) => {
      for (const key in Manifest[groupKey]) {
        if (Manifest[groupKey].hasOwnProperty(key)) {
          const element = Manifest[groupKey][key].url
          loadables.push({
            groupKey,
            element,
            key,
            options: Manifest[groupKey][key].options,
          })
        }
      }
    }

    // Common
    addTextureToLoadables('main')
    addTextureToLoadables('defaultLensFlares')

    // Space
    addTextureToLoadables('spaceUnlit')

    // Imagine
    // addTextureToLoadables('imaginePBR')
    // addTextureToLoadables('imagineUnlit')

    addTextureToLoadables('unityPBR')
    // addTextureToLoadables('unityDecalPBR')

    return loadables
  }

  loadModels () {
    const loadables = []
    const meshesExcludeList = ['SpaceShip_Unity_LandingGear']

    // Meshes
    this.PBRConfig = PBRConfigs["CONFIG_HEADER"]

    // Spaceship
    this.entities.vssUnity = new MeshVSSUnity(this, { meshesExcludeList, PBRConfig: this.PBRConfig })
    loadables.push(this.entities.vssUnity.load().then(()=>{
      this.initShip()
      this.initTimelineIntro()
      this.initTimelineScroll()
    }))

    // Earth
    let earthUrl = `${"/"}glxp/models/earth_atmosphere_draco_v5`;
    loadables.push(new GLBLoader(earthUrl + '.glb', true).then((glb) => {
      // Root for PBR model
      let modelRoot = new Transform()
      modelRoot.scale.set(1, 1, 1)
      modelRoot.setParent(this.root)

      // Build Node Tree and Mesh List
      let modelTree = GLTFUtils.buildNodeTree(glb, modelRoot)
      let meshList = GLTFUtils.buildMeshList(glb, modelTree)

      // Mesh Instanciation
      let entity

      for (const mesh of meshList) {
        // TODO: Check if we could use mesh name instead of node, or if node is more consistent
        if (meshesExcludeList.includes(mesh.node.name)) {
          continue
        }

        // TODO: add another gradient color to outer shader
        switch (mesh.node.name) {
          case "Atmosphere":
            {
              entity = new MeshAtmosphere(this,
                mesh.meshData,
                {
                  id: GUI_PANEL_CUSTOM,
                  parent: mesh.parent || modelRoot,
                  gltf: glb,
                  name: mesh.meshName,
                  node: mesh.node,
                  fog: false,
                  transparent: true,
                  materialName: mesh.materialName,
                  material: mesh.material,
                  globalConfig: this.PBRConfig,
                  shaderId: "outerAtmosphere",
                  
                  forceRenderOrder: true,
                  renderOrder: 2,

                  GradientOpacity0: 0,
                  GradientOpacity1: 1,
                  GradientColor0: "#0432a8",
                  GradientColor1: "#a2daff",
                  Bezier: [0, 0, 0, 0],
                  UvScale: 1.12,
                  UvOffset: -0.1,

                  name: "Outer Atmosphere"
                }
              )

              const innerAtmosphere = new MeshAtmosphere(this,
                mesh.meshData,
                {
                  id: GUI_PANEL_CUSTOM,
                  parent: mesh.parent || modelRoot,
                  gltf: glb,
                  name: mesh.meshName,
                  node: mesh.node,
                  fog: false,
                  transparent: true,
                  materialName: mesh.materialName,
                  material: mesh.material,
                  globalConfig: this.PBRConfig,
                  shaderId: "innerAtmosphere",
                  
                  depthTest: false,
                  forceRenderOrder: true,
                  renderOrder: 3,

                  GradientOpacity0: 0,
                  GradientOpacity1: 1,
                  GradientColor0: "#104f9f",
                  GradientColor1: "#a2daff",
                  Bezier: [0, 0, 1, -2],
                  UvScale: 1.94,
                  UvOffset: 0.015,

                  name: "Inner Atmosphere"
                }
              )
              this.meshes.push(innerAtmosphere)
            }
            break;
        
          default:
            entity = new MeshEarth(this,
              mesh.meshData,
              Manifest.spaceUnlit[mesh.materialName] !== undefined ? mesh.materialName : 'test',
              {
                id: GUI_PANEL_CUSTOM,
                parent: mesh.parent || modelRoot,
                gltf: glb,
                name: 'Mesh Earth',
                node: mesh.node,
                fog: false,
                transparent: true,
                materialName: mesh.materialName,
                material: mesh.material,
                globalConfig: this.PBRConfig,
                forceRenderOrder: true,
                renderOrder: 1
              }
            )
            break;
        }

        if(entity) this.meshes.push(entity)
      }

      this.earth = modelRoot
      
      this.setEarth()
    }))

    return loadables
  }

  // Utils
  applyDefaultState () {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  reset () {
    this.setProgress(0, true)
    this.tlIntro.pause()
    this.tlScroll.pause()
    this.tlIntro.progress(this.progress)
    this.tlScroll.progress(this.progress)
    this.camera.fov = this.initialFov
  }

  // Events
  addEvents() {
    GlobalEmitter.on('webgl_home_header_intro', this.boundPlayIntro)
    GlobalEmitter.on('webgl_home_header_progress', this.boundOnProgressRequest)
  }

  removeEvents() {
    GlobalEmitter.off('webgl_home_header_intro', this.boundPlayIntro)
    GlobalEmitter.off('webgl_home_header_progress', this.boundOnProgressRequest)
  }

  onActivate() {
    this.addEvents()
  }

  onDisable() {
    this.removeEvents()
  }

  onLoaded () {
    // this.active = true
    this._emitter.emit('loaded')

    // First Draw
    if (this.shouldFirstDraw) {
      const firstDrawPromises = this.getFirstDrawPromises()

      this.firstDrawAllPromise = Promise.all(firstDrawPromises).then(() => {
        this.didFirstDraw = true
        // GlobalEmitter.emit('webgl_loaded')
        // console.log(`Subscene ${this.name} finished first draw`)
      })
    }

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].onLoaded()
    }

    // Post
    if(this.hasBloom){
      this.bloomPass = new Bloom(this, this.post)
      this.bloomPass.config.Threshold.value = 0.7
      this.bloomPass.config.Blur.value = 1
    }
    this.post.onLoaded()

    // Debug
    this.gui = DebugController.addBlade(
      this.config,
      `Global Settings - ${this.name || 'Unknown'}`
    )
    DebugController.onLoaded()
    this.debugManager.onLoaded()
  }

  postFirstDraw(){}

  onProgressRequest({ progress }) {
    this.setProgress(progress)
  }

  resize() {
    // this.animCameraPos = isMobile ? { x: window.innerHeight > 666 ? 2.462 : 2.42 , y: -0.79, z: 8.5 } : { x: 1, y: 0, z: 6.5 }
    this.cameraManager.resize(this.width, this.height)
    this.rt.setSize(this.width, this.height)
  }

  // Rendering
  updateEntities () {
    this.cameraManager.toGo.set(this.animCameraPos.x, this.animCameraPos.y, this.animCameraPos.z)
    this.cameraManager.toLook.set(this.animCameraLookat.x, this.animCameraLookat.y, this.animCameraLookat.z)
    
    this.shipWrapper.position.set(this.animShipWrapperPos.x, this.animShipWrapperPos.y, this.animShipWrapperPos.z)
    this.shipWrapper.rotation.set(this.animShipWrapperRot.x, this.animShipWrapperRot.y, this.animShipWrapperRot.z)
    
    this.entities.vssUnity.updateFeather(this.progress * 1.025) // Slightly faster

    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)

    this.cameraManager.setProgress(this.progress)
    this.cameraManager.preRender()
  }

  preRender () {
    // Progress
    if (this.hasWheel) {
      // Listen to mouse wheel for progress on Sandbox

      if (!this.isAnimatingIntro) {
        this.wheelProgress += Mouse.normalizeWheel - this.lastWheel
        this.wheelProgress = clamp(this.wheelProgress, 0, 0.037)
      }

      // Can override it through the config value if needed
      this.progressTarget = this.config.OverrideScroll.value ? this.config.Progress.value : this.wheelProgress * this.config.DevScrollSpeed.value
      this.progress = lerp(this.progress, this.progressTarget, 0.075)
      this.progress = clamp(this.progress, 0, 1)
      this.lastWheel = Mouse.normalizeWheel
    } else if(!this.overrideProgress) {
      // Listen to FE events for progress
      let tmp = this.progressTarget - this.progress
      this.progressDelta = tmp
      tmp *= this.config.ScrollDamping.value

      this.progress += tmp
    }

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].preRender()
    }

    // TODO: Refactor
    this.tlScroll.progress(this.progress * 1.025) // Slightly faster
  
    // Update entitites based on progress
    this.updateEntities()
  }

  render () {
    // First Draw
    if (this.shouldFirstDraw && !this.didFirstDraw) {
      this.drawNextFDMesh()
      return
    }

    if (!this.active) { return }

    // Timings
    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    // Pre render
    this.preRender()

    // Aspect
    this.gl.viewport(0, 0, this.width, this.height)
    this.camera.perspective({ aspect: this.width/this.height })
    this.renderer.setViewport(this.width, this.height)



    // Post
    this.bloomPass && this.bloomPass.render()
    // Last link of post processing pipeline should render in this.rt, which is fetched from main scene wrapper
    this.post.render({ target: this.rt })

    // Render Time
    this.renderer.render({
      scene: this.root,
      camera: this.camera,
      clear: true,
      frustumCull: true,
      sort: true,
      post: this.post,
    })

    this.postRender()
  }

  postRender () {
    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls ++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }
  }
}

export default SceneHomeHeader