import throttle from 'lodash.throttle'

// Structural
import SubsceneAbs from '~/glxp/abstract/subscene'
import Manifest from '~/glxp/manifest'
import GLBLoader from '~/glxp/loader/glbLoader'
import DebugController from '~/glxp/debug/debugController'

// Entities
import BackgroundTextureEntity from '~~/glxp/entities/BackgroundTexture'
import MeshPBR from '~~/glxp/entities/MeshPBR'
import PlaneEntity from '~~/glxp/entities/Plane'
import MeshGround from '~~/glxp/entities/MeshGround'
import MeshPBRWindows from '~~/glxp/entities/MeshPBRWindows'
import LensFlares, { getSpacecraftFleetEveFlares } from '~~/glxp/entities/LensFlares'

// Post
import Bloom from '~/glxp/postProcess/bloomPass'
import PostEntity from '~/glxp/postProcess/Post'

// Utils
import Mouse from '@/glxp/utils/mouse'
import RAF from '~/glxp/utils/raf'
import PBRConfigs from '~/glxp/debug/pbrConfigs'
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import Catmull from '~/glxp/utils/catmull'
import { clamp, lerp, map } from '~/glxp/utils/math'
import easings from "~/glxp/utils/easing"

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { RenderTarget } from '~/glxp/ogl/core/RenderTarget.js'

// Managers
import CameraManager from '~/glxp/managers/CameraManager'
import DebugManager from '~/glxp/managers/DebugManager'
import HotspotManager from '~~/glxp/managers/HotspotManager'

// Constants
import { FLEET_INTRO_CAMERA_POSITION_POINTS_HORIZONTAL } from '~~/glxp/camera/paths'
import { HOTSPOTS_FLEET_EVE } from '~~/glxp/data/dataHotspots'
// import gsap, {Power1} from 'gsap'

class SceneSubSpacecraftFleetEve extends SubsceneAbs {
  constructor(
    container,
    manager = null,
    textureLoader,
    shouldFirstDraw = false
  ) {
    super(container, manager, textureLoader, shouldFirstDraw)

    this.name = 'Spacecraft Fleet - Eve'

    this.manager = manager
    this.renderer = manager.renderer
    this.textureLoader = textureLoader

    this.time = 0
    this.dt = 0
    this.drawcalls = 0
    this.progress = 0
    this.progressDelta = 0
    this.progressTarget = 0.00001
    this.timescale = 1
    this.forceAllDraw = true
    this.wheelProgress = 0
    this.lastWheel = 0

    this.clearColor = [49 / 255, 88 / 255, 169 / 255, 1]
    this.loaded = false
    this.active = false

    this.root = new Transform()
    this.root.scale.set(1, 1, 1)

    this.meshes = []
    this.entities = {
      eve: null,
      unity: null,
      background: null,
      ground: []
    }

    // this.debugManager = new DebugManager(this)

    this.config = {
      ScrollSpeed: { value: 15, params: { min: 0, max: 60 } },
      InitialY: { value: 0.51, params: { min: 0, max: 1 } },
      DropHeight: { value: 7, params: { min: 0, max: 10 } },
      InitialFov: { value: this.mobile ? 50 : 35, params: { min: 1, max: 50 } },
      FovOffset: { value: 24, params: { min: 1, max: 50 } },
      InitialLookY: { value: this.mobile ? -2 : 1.3, params: { min: 0, max: 2 } },
      LookYOffset: { value: this.mobile ? 5 : 1.3, params: { min: 0, max: 10 } },
      SunPosition: { value: { x: 0, y: 1, z: -400 }, params: {} },
      GroundSpeed: { value: .02, params: { min: 0, max: 0.1 } },
      Threshold: { value: 0.9250, params: { min: .9, max: 1 } },
    }
    this.PBRConfig = PBRConfigs['CONFIG_SPACECRAFT_FLEET_EVE']

    this.initCameras()
    this.initCameraTracks()
    this.initRT()
    this.initBackground()
    // this.initGround()
    this.initHotspots()
    if (!DebugController.queryDebug('notextures')) {
      this.initSun()
      this.initFlares()
    }
  }

  // Inits
  initCameras() {
    this.cameraManager = new CameraManager(this.gl, {
      width: this.width,
      height: this.height,
      scene: this,
      debug: false,
      hasMousePan: false,
    })
    this.camera = this.cameraManager.camera
  }

  initCameraTracks() {
    const positionTrack = new Catmull(
      this.cameraManager.parseCameraPoints(
        FLEET_INTRO_CAMERA_POSITION_POINTS_HORIZONTAL,
        1,
        false
      )
    )
    positionTrack.generate()
    this.cameraManager.addSpline(positionTrack, 'camera')
  }

  initRT() {
    this.rt = new RenderTarget(this.gl)
  }

  initBackground() {
    this.entities.background = new BackgroundTextureEntity(this, "backgroundSphereTexture", "env_spacecraft_fleet_eve_flipped", {
      color: "#999bee",
      gradientColor: "#5a94ec",
      opacity: .9,
      gradientEdges: { x: 0, y: .66 },
      transparent: true
    })
    this.meshes.push(this.entities.background)
    this.entities.background.mesh.setParent(this.root)
  }

  initGround() {
    this.groundDistance = 5
    this.groundScale = 3

    this.entities.ground[0] = new MeshGround(this, "spacecraftFleetGround", "fbm_1", { scale: this.groundScale, transparent: true, renderOrder: 997 })
    // this.entities.ground[0].mesh.position.set(0, -this.groundDistance, 0)
    // this.entities.ground[0].mesh.rotation.x = -Math.PI / 2

    this.entities.ground[0].mesh.position.set(0, 1.25, -2)

    for (const ground of this.entities.ground) {
      this.meshes.push(ground)
    }
  }

  initHotspots() {
    this.hotspotManager = new HotspotManager(this.gl, {
      scene: this,
      debug: false,
      hotspots: HOTSPOTS_FLEET_EVE,
      debugRadius: .08
    })
  }

  initSun() {
    this.sun = new PlaneEntity(this, "debugUv", "", {
      scale: 30
    })
    this.sun.mesh.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    this.sun.mesh.visible = false

    this.sun.mesh.setParent(this.root)
  }


  initFlares() {
    this.lensFlares = new LensFlares(this, {
      lightSource: this.sun.mesh,
      getFlares: (spacing) => getSpacecraftFleetEveFlares(this, spacing),
      spacing: 0.08,
      raycaster: this.raycaster,
      occluder: this.occluder,
      fadePower: .01
    })
    this.meshes.push(this.lensFlares)
  }

  setProgress(p, shouldForce = false) {
    // console.log(p);
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  // Lifecycle
  // Check subscene abstract for lifecycle events. They should start with super().
  fetchLoadableTextures() {
    let loadables = []

    this.post = new PostEntity(this, this.postOptions)
    this.post.config.Contrast.value = .19
    this.post.config.Exposure.value = .05

    const addTextureToLoadables = (groupKey) => {
      for (const key in Manifest[groupKey]) {
        if (Manifest[groupKey].hasOwnProperty(key)) {
          const element = Manifest[groupKey][key].url
          loadables.push({
            groupKey,
            element,
            key,
            options: Manifest[groupKey][key].options,
          })
        }
      }
    }

    addTextureToLoadables('main')

    addTextureToLoadables('spacecraftFleetEve')

    addTextureToLoadables('evePBR')
    addTextureToLoadables('unityPBR')

    addTextureToLoadables('circularLensFlares')
    addTextureToLoadables('defaultLensFlares')
    addTextureToLoadables('simonDhaenensLensFlares')

    for (const key in Manifest.main) {
      if (Manifest.main.hasOwnProperty(key)) {
        const element = Manifest.main[key].url
        loadables.push({
          groupKey: 'main',
          element,
          key,
          options: Manifest.main[key].options,
        })
      }
    }

    return loadables
  }

  loadModels() {
    const loadables = []

    /**
     * Eve and Unity
     */
    let url = `${'/'}glxp/models/spacecraft_fleet_eve_v3_draco`
    loadables.push(
      new GLBLoader(url + '.glb', true).then((glb) => {
        let modelRoot = new Transform()
        modelRoot.setParent(this.root)

        //TODO: find out why GLTFUtils.buildMeshList(glb, modelTree) doesn't attach Unity wings properly to parent
        //Build node Tree
        let modelTree = {}
        for (let i = 0; i < glb.nodes.length; i++) {
          const node = glb.nodes[i];
          if (node.children && node.children.length > 0) {
            modelTree[node.name] = {
              transform: new Transform(),
              childrenUIds: []
            }

            modelTree[node.name].transform.position.copy(node.translation)

            // Empties exported from Blender have no scale or rotation, it seems
            if (node.scale) modelTree[node.name].transform.scale.copy(node.scale)

            if (node.rotation) {
              modelTree[node.name].transform.quaternion.x = node.rotation[0]
              modelTree[node.name].transform.quaternion.y = node.rotation[1]
              modelTree[node.name].transform.quaternion.z = node.rotation[2]
              modelTree[node.name].transform.quaternion.w = node.rotation[3]
            }
            modelTree[node.name].transform.setParent(modelRoot)
            modelTree[node.name].transform.updateMatrixWorld(true)
            for (let j = 0; j < node.children.length; j++) {
              modelTree[node.name].childrenUIds.push(node.children[j].name)
            }
          }
        }

        let meshList = GLTFUtils.buildMeshList(glb, modelTree)

        //Mesh Instanciation
        for (const mesh of meshList) {
          const parent = GLTFUtils.getParentInNodeTree(modelTree, mesh.meshName)

          // Replace to sheen material
          // Get texture ID
          let materialName = ""
          let windowsTextureId = ""
          switch (mesh.meshName) {
            case "SpaceShip_Eve_Body":
              materialName = "M_SpaceShip_Eve_Sheen"
              windowsTextureId = "eveWindows"
              break;

            case "SpaceShip_Unity":
              materialName = "M_SpaceShip_Unity_Sheen"
              windowsTextureId = "unityWindows"
              break;

            default:
              materialName = mesh.materialName
              windowsTextureId = null
              break;
          }

          const entity = new MeshPBRWindows(this, {
            id: 1,
            parent: parent || modelRoot,
            gltf: glb,
            data: mesh.meshData,
            name: mesh.meshName,
            node: mesh.node,
            fog: false,
            transparent: false,
            materialName,
            globalConfig: this.PBRConfig,
            shaderId: 'standardWindows',
            windowsTextureId
          })
          this.meshes.push(entity)

          switch (mesh.meshName) {
            case "SpaceShip_Eve_Body":
              this.entities.eve = entity
              break;

            case "SpaceShip_Unity":
              this.entities.unity = entity
              break;

            default:
              break;
          }
        }
      })
    )

    return loadables
  }

  // Utils
  applyDefaultState() {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  // Events
  onLoaded() {
    // this.active = true
    this._emitter.emit('loaded')

    // First Draw
    if (this.shouldFirstDraw) {
      const firstDrawPromises = this.getFirstDrawPromises()

      this.firstDrawAllPromise = Promise.all(firstDrawPromises).then(() => {
        this.didFirstDraw = true
        // console.log(`Subscene ${this.name} finished first draw`)
      })
    }

    // Post
    if (this.hasBloom) {
      this.bloomPass = new Bloom(this, this.post)
      this.bloomPass.config.Threshold.value = .67
      this.bloomPass.config.Overdrive.value = .68
      this.bloomPass.config.Similarity.value = .124
    }

    this.post.onLoaded()

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].onLoaded()
    }

    // Debug
    this.gui = DebugController.addBlade(
      this.config,
      `Global Settings - ${this.name || 'Unknown'}`
    )
    DebugController.onLoaded()
    // this.debugManager.onLoaded()

    // For recording purposes
    // gsap.to(this, {
    //   progress: .58,
    //   ease: Power1.easeInOut,
    //   duration: 5,
    //   delay: 2
    // })

    // gsap.to(this, {
    //   progress: 1,
    //   ease: Power1.easeInOut,
    //   duration: 4,
    //   delay: 7.5
    // })
  }

  resize() {
    this.cameraManager.resize(this.width, this.height)
    this.rt.setSize(this.width, this.height)
    this.hotspotManager.resize(this.width, this.height)
    this.render(true)
  }

  // Rendering
  preRender() {
    // Meshes
    for (const mesh of this.meshes) {
      mesh.preRender()
    }

    this.cameraManager.setProgress(this.progress)
    this.cameraManager.preRender()
  }

  render(force = false) {
    if(force){
      this.renderThings()
      return
    }

    // First Draw
    if (this.shouldFirstDraw && !this.didFirstDraw) {
      this.drawNextFDMesh()
      return
    }

    if (!this.active) {
      return
    }

    if (this.progressTarget > this.config.Threshold.value && this.progress > this.config.Threshold.value) {
      return
    }

    this.renderThings()
  }

  renderThings() {
    // Timings
    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    // Wheel
    if (this.hasWheel) {
      this.wheelProgress += Mouse.normalizeWheel - this.lastWheel
      this.wheelProgress = clamp(this.wheelProgress, 0, 0.066)
      this.progressTarget = (this.wheelProgress * this.config.ScrollSpeed.value)
      this.lastWheel = Mouse.normalizeWheel
    }

    this.progress = lerp(this.progress, this.progressTarget, 0.075)
    this.progress = clamp(this.progress, 0, 1)

    // Animate ships
    if (this.entities.eve) {
      // Lift Eve
      const liftProgress = clamp(map(this.progress, 0.67, .9, 0, 1), 0, 1)
      this.entities.eve.mesh.position.y = liftProgress * .08

      // Drop Unity
      const dropProgress = clamp(map(this.progress, 0.67, .95, 0, 1), 0, 1)
      this.entities.unity.mesh.position.y = this.config.InitialY.value - easings.easeInCubic(dropProgress) * this.config.DropHeight.value
    }

    // Widen field of view
    const fovProgress = clamp(map(this.progress, 0, .75, 0, 1), 0, 1)
    this.camera.fov = this.config.InitialFov.value + fovProgress * this.config.FovOffset.value

    // Tilt up lookAt
    this.config.InitialLookY.value = this.mobile ? 0.75 : 1.3
    this.cameraManager.toLook[1] = this.config.InitialLookY.value - this.progress * this.config.LookYOffset.value

    // Hotspots visibility
    let hotspotProgress = clamp(map(this.progress, 0.2, .67, 0, 1), 0, 1) // 0 -> 1
    hotspotProgress = Math.sin(hotspotProgress * 3.1415) // 0 -> 1 -> 0

    for (const hotspot of this.hotspotManager.hotspots) {
      hotspot.visibility = hotspotProgress > 0.01 ? 1 : 0
    }

    this.entities.background && this.entities.background.updateTranslation(this.progress)

    // Tilt sun to make it move less on camera movement
    this.config.SunPosition.value.y = 0 - this.progress * 333
    this.sun && this.sun.mesh.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)

    // Pre render
    this.preRender()

    // Aspect
    this.gl.viewport(0, 0, this.width, this.height)
    this.camera.perspective({ aspect: this.width / this.height })
    this.renderer.setViewport(this.width, this.height)

    // Post
    this.bloomPass && this.bloomPass.render()
    // Last link of post processing pipeline should render in this.rt, which is fetched from main scene wrapper
    this.post.render({ target: this.rt })

    // Render Time
    this.renderer.render({
      scene: this.root,
      camera: this.camera,
      clear: true,
      frustumCull: true,
      sort: true,
      post: this.post,
    })

    // Debug
    // this.debugManager.render()

    // Post render
    this.postRender()
  }

  postRender() {
    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }

    this.hotspotManager.update()
  }
}

export default SceneSubSpacecraftFleetEve
