export class DrawableGeometry {
    gl
    locations     
    attributes      // [name: string]: Float32Array
    buffers = {}    // [name: string]: { value: Float32Array, size: number }
    vao

    /**
     * Sets buffers and corresponding VAO
     * @param {attributes: {[name: string]: WebGLUniformLocation | -1}, uniforms: {[name: string]: WebGLUniformLocation | null}} locations 
     * @param {[name: string]: { value: Float32Array, size: number }} attributes 
     */
    constructor(gl, locations, attributes) {
        this.gl = gl
        this.locations = locations
        this.attributes = attributes

        for (const [prop, data] of Object.entries(attributes)) {
            this.buffers[prop] = this.getBuffer(prop, data.value)
        }

        this.setVAO()
    }

    getBuffer = (prop, array) => {
        const buffer = this.gl.createBuffer();

        if(prop === "index"){
            this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, buffer);
            this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, array, this.gl.STATIC_DRAW);
        }else{
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
            this.gl.bufferData(this.gl.ARRAY_BUFFER, array, this.gl.STATIC_DRAW);
        }

        return buffer
    }

    setVAO = () => {
        this.vao = this.gl.createVertexArray()
        this.gl.bindVertexArray(this.vao)
        
        // Position
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffers.position)

        const positionLocation = this.locations.attributes.position
        const numComponents = this.attributes.position.size;
        this.gl.enableVertexAttribArray(positionLocation);
        this.gl.vertexAttribPointer(positionLocation, numComponents, this.gl.FLOAT, false, 0, 0);

        // Normal
        if(this.attributes.normal){
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffers.normal)

            const normalLocation = this.locations.attributes.normal
            const numComponents = this.attributes.normal.size
            this.gl.enableVertexAttribArray(normalLocation);
            this.gl.vertexAttribPointer(normalLocation, numComponents, this.gl.FLOAT, false, 0, 0);
        }

        // UV
        if(this.attributes.uv){
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffers.uv)

            const uvLocation = this.locations.attributes.uv
            const numComponents = this.attributes.uv.size;
            this.gl.enableVertexAttribArray(uvLocation);
            this.gl.vertexAttribPointer(uvLocation, numComponents, this.gl.FLOAT, false, 0, 0);
        }

        if(this.attributes.index){
            this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.buffers.index);
        }

        this.gl.bindVertexArray(null)
    }
}
