export default defineNuxtPlugin(() => {
  const onResize = () => {
    const realHeight = window.innerHeight
    const clientHeight = document.documentElement.clientHeight
    document.documentElement.setAttribute(
      'style',
      `--real-height: ${realHeight}px; --client-height: ${clientHeight}px`
    )
  }

  // Resize Event
  window.addEventListener('resize', onResize)

  // Initial Resize
  onResize()
})
