import Shader from '~/glxp/utils/shader'
import DebugController from '~/glxp/debug/debugController'
import ShaderManifest from '~/glxp/shaderManifest'
import GLTFUtils from '~/glxp/utils/GLTFUtils'

import { Program } from '~/glxp/ogl/core/Program.js'
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'
import { Color } from '~/glxp/ogl/math/Color'

import { vec3 } from 'gl-matrix'

class MeshEntity {
  constructor(
    scene,
    data,
    {
      parent = null,
      id = 0,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      gltf = null,
      node = null,
      name = '',
      materialName = '',
      transparent = false,
      depthTest = true,
      depthWrite = true,
      renderOrder = 0,
      globalConfig = null,
      fog = true,
      alpha = 1,
      forceRenderOrder = false,
      shaderId = "innerAtmosphere",
      Bezier = [0, 0.6, 0.2, 1],
      GradientOpacity0 = 1,
      GradientOpacity1 = 0,
      GradientColor0 = "#a3c9ff",
      GradientColor1 = "#a3c9ff",
      UvScale = 1,
      UvOffset = 0
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.gltf = gltf
    this.data = data
    this.node = node
    this.name = name
    this.parent = parent ? parent : scene.root
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.video = null
    this.fog = fog
    this.renderOrder = renderOrder
    this.groupId = id
    this.geom = {}
    this.materialInfos = materialName
    this.globalConfig = globalConfig
    this.alpha = alpha
    this.textureId = ''
    this.defines = {}
    this.seed = Math.random()
    this.transform = new Transform()
    this.shaderId = shaderId
    this.Bezier = Bezier
    this.GradientOpacity0 = GradientOpacity0
    this.GradientOpacity1 = GradientOpacity1
    this.GradientColor0 = GradientColor0
    this.GradientColor1 = GradientColor1
    this.UvScale = UvScale
    this.UvOffset = UvOffset
    this.forceRenderOrder = forceRenderOrder

    if (node) {
      if (node.rotation) {
        this.transform.quaternion.x = node.rotation[0]
        this.transform.quaternion.y = node.rotation[1]
        this.transform.quaternion.z = node.rotation[2]
        this.transform.quaternion.w = node.rotation[3]
      }

      if (node.scale) {
        vec3.copy(this.transform.scale, node.scale)
      }

      if (node.translation) {
        vec3.copy(this.transform.position, node.translation)
      }
    }

    this.geom = this.gltf
      ? GLTFUtils.getGeometryDataFromGltf(this.data, this.defines, this.gltf)
      : this.data

    this.initConfig()
    this.init()
    this.cleanConfig()
  }

  initConfig() {
    this.config = {
      Tint: { value: '#ffffff', params: {} },
      TintOpacity: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      Exposure: { value: 0, params: { min: -2, max: 3, step: 0.01 } },
      Contrast: { value: 0, params: { min: -1, max: 1, step: 0.01 } },
      Saturation: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
      SheenColor: { value: '#ffffff', params: {} },
      SheenOpacity: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      SheenDepth: { value: 2, params: { min: 1, max: 10, step: 0.1 } },

      GradientColor0: { value: this.GradientColor0, params: {} },
      GradientOpacity0: { value: this.GradientOpacity0, params: { min: 0, max: 1, step: 0.01 } },
      GradientColor1: { value: this.GradientColor1, params: {} },
      GradientOpacity1: { value: this.GradientOpacity1, params: { min: 0, max: 1, step: 0.01 } },
      UvScale: { value: this.UvScale, params: { min: 0, max: 2 } },
      UvOffset: { value: this.UvOffset, params: { min: -1, max: 1 } }
    }

    if (DebugController.guiIsReady) {
      this.initGUI()
    } else {
      DebugController.on('gui-lazyloaded', this.initGUI.bind(this))
    }
  }

  initGUI() {
    this.gui = DebugController.addBlade(this.config, this.name, this.groupId)

    this.gui && this.gui.addBlade({ view: 'cubicbezier', value: this.Bezier, label: "Gradient", expanded: true, picker: "inline" }).on("change", (e) => {
      this.program.uniforms["uBezier"].value = e.value.comps_
    })

    this.cleanConfig()
  }

  cleanConfig() {
    if (!this.gui || this.gui === {}) return

    if (this.defines['HAS_SHEEN'] == undefined) {
      this.gui.params['SheenColor'].hidden = true
      this.gui.params['SheenOpacity'].hidden = true
      this.gui.params['SheenDepth'].hidden = true
    }
  }

  initVideoTexture(url) {
    this.videoTexture = new Texture(this.gl, {
      generateMipmaps: false,
      width: 1024,
      height: 512,
    })
    let video = document.createElement('video')
    video.src = url
    video.loop = true
    video.muted = true
    this.video = video
  }

  init() {
    const attribs = {
      position: { size: 3, data: this.geom.vertices },
      uv: { size: 2, data: this.geom.uvs },
      // normal: { size: 3, data: this.geom.normals },
      index: { data: this.geom.indices },
    }
    this.geometry = new Geometry(this.gl, attribs)

    let uniforms = {
      uTime: { value: this.scene.time },
      uAlpha: { value: this.alpha },
      uSeed: { value: this.seed },
      uTint: { value: new Color(this.config.Tint.value) },
      uCamera: { value: this.scene.camera.position },
    }

    // Color Correction
    this.defines['HAS_COLOR_CORRECTION'] = 1

    uniforms = Object.assign(uniforms, {
      uTint: { value: new Color(this.config.Tint.value) },
      uTintOpacity: this.config.TintOpacity,
      uExposure: this.config.Exposure,
      uContrast: this.config.Contrast,
      uSaturation: this.config.Saturation,

      uBezier: { value: this.Bezier },
      uGradientColor0: { value: new Color(this.config.GradientColor0.value) },
      uGradientColor1: { value: new Color(this.config.GradientColor1.value) },
      uGradientOpacity0: this.config.GradientOpacity0,
      uGradientOpacity1: this.config.GradientOpacity1,
      uUvScale: this.config.UvScale,
      uUvOffset: this.config.UvOffset
    })

    if (this.scene.textureLoader.isTextureRegistered(`${this.textureId}`)) {
      this.defines['HAS_COLORMAP'] = 1
      this.texture = new Texture(this.gl)
      uniforms = Object.assign(uniforms, {
        uTexture: { value: this.texture },
      })
    } else {
      uniforms = Object.assign(uniforms, {
        uColor: { value: new Color(this.materialInfos.color) },
      })
    }
    if (
      this.scene.textureLoader.isTextureRegistered(`${this.textureId}_Opacity`)
    ) {
      this.defines['HAS_ALPHAMAP'] = 1
      this.alphaTexture = new Texture(this.gl)
      this.transparent = true
      this.renderOrder = 1

      if (this.materialInfos) {
        if (this.materialInfos['renderOrder'] !== undefined) {
          this.renderOrder = this.materialInfos.renderOrder
        }

        if (this.materialInfos['depthTest'] !== undefined) {
          this.depthTest = this.materialInfos.depthTest
        }

        if (this.materialInfos['depthWrite'] !== undefined) {
          this.depthWrite = this.materialInfos.depthWrite
        }
      }

      uniforms = Object.assign(uniforms, {
        uAlphaTexture: { value: this.alphaTexture },
      })
    }

    if (this.materialInfos && this.materialInfos['sheen'] !== undefined) {
      this.defines['HAS_SHEEN'] = 1
      uniforms = Object.assign(uniforms, {
        uSheenOpacity: this.config.SheenOpacity,
        uSheenDepth: this.config.SheenDepth,
        uSheenColor: { value: new Color(this.config.SheenColor.value) },
      })
    }

    if (this.globalConfig.FogColor && this.fog) {
      this.defines['HAS_FOG'] = 1
      uniforms = Object.assign(uniforms, {
        uFogColor: { value: new Color(this.globalConfig.FogColor.value) },
        // uFogDensity: { value: this.globalConfig.FogDensity.value },
        uFogNear: { value: this.globalConfig.FogNear.value },
        uFogFar: { value: this.globalConfig.FogFar.value },
      })
    }

    this.shader = new Shader(ShaderManifest[this.shaderId], 1, this.defines)

    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      uniforms,
    })

    if (this.materialInfos && this.materialInfos.cullface !== undefined) {
      if (this.materialInfos.cullface == false) {
        this.program.cullFace = false
      } else {
        this.program.cullFace = this.gl[this.materialInfos.cullface]
      }
    }

    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
      transform: this.transform,
      forceRenderOrder: this.forceRenderOrder
    })
    this.mesh.name = this.name

    this.mesh.setParent(this.parent)
    // this.mesh.updateMatrixWorld(true)
    // this.parent.updateMatrixWorld(true)
  }

  onLoaded() {
    if (this.texture) {
      this.texture = this.scene.textureLoader.getTexture(this.textureId)
      this.texture.needsUpdate = true
      this.program.uniforms['uTexture'].value = this.texture
    }

    if (this.alphaTexture) {
      this.alphaTexture = this.scene.textureLoader.getTexture(
        `${this.textureId}_Opacity`
      )
      this.alphaTexture.needsUpdate = true
      this.program.uniforms['uAlphaTexture'].value = this.alphaTexture
    }
  }

  // Render
  getFirstDrawPromise() {
    this.didFirstDraw = false
    return new Promise((resolve) => {
      this.firstDrawResolve = resolve
    })
  }

  firstDraw() {
    if (this.didFirstDraw) return

    // this.gl.finish()

    const storedVisibility = this.mesh.visible
    this.mesh.visible = true

    this.scene.renderer.render({
      scene: this.mesh,
      camera: this.scene.camera,
      clear: false,
      frustumCull: true,
      sort: false,
    })

    this.mesh.visible = storedVisibility

    // this.gl.finish()

    if (!this.didFirstDraw) {
      this.didFirstDraw = true
      requestAnimationFrame(() => {
        this.firstDrawResolve(this)
      })
    }
  }

  // TODO: remove new Color() on each frame by using this.gui.on()
  preRender() {
    this.program.uniforms['uTime'].value = this.scene.time
    this.program.uniforms['uAlpha'].value = this.alpha

    this.program.uniforms['uTint'].value = new Color(this.config.Tint.value)
    this.program.uniforms['uGradientColor0'].value = new Color(this.config.GradientColor0.value)
    this.program.uniforms['uGradientColor1'].value = new Color(this.config.GradientColor1.value)

    if (this.defines['HAS_FOG'] !== undefined) {
      this.program.uniforms['uFogColor'].value = new Color(
        this.globalConfig.FogColor.value
      )
      this.program.uniforms['uFogNear'].value = this.globalConfig.FogNear.value
      this.program.uniforms['uFogFar'].value = this.globalConfig.FogFar.value
    }
    this.program.uniforms['uCamera'].value = this.scene.camera.position

    if (
      this.video &&
      this.video.currentTime > 0 &&
      this.video.readyState >= this.video.HAVE_ENOUGH_DATA
    ) {
      if (!this.videoTexture.image) this.videoTexture.image = this.video
      this.videoTexture.needsUpdate = true
      this.program.uniforms['uTexture'].value = this.videoTexture
    }

    if (this.defines['HAS_SHEEN'] !== undefined) {
      this.program.uniforms['uSheenColor'].value = new Color(
        this.config.SheenColor.value
      )
    }

    this.program.uniforms['uUvOffset'].value = this.config.UvOffset.value

    // "Billboard"
    this.mesh.lookAt(this.scene.camera.position)
  }
}

export default MeshEntity
