import gsap from 'gsap'
import throttle from 'lodash.throttle'

// Structural
import SceneAbs from '~/glxp/abstract/scene'
import Manifest from '~/glxp/manifest'
import GLBLoader from '~/glxp/loader/glbLoader'

// Entities
import MeshPBR from '~/glxp/entities/MeshPBR'
import MeshUnlit from '~/glxp/entities/MeshUnlit'
import MeshFlag from '~/glxp/entities/MeshFlag'
import MeshPole from '../entities/MeshPole'
import SpaceportSky from '~/glxp/entities/spaceport/SpaceportSky'
import Occluder from '~/glxp/entities/Occluder'
import LensFlares, { getSpaceportAmericaFlares } from '~/glxp/entities/LensFlares'

// Post
import LensFlarePostEntity from '~/glxp/postProcess/LensFlarePost'
import Bloom from '~/glxp/postProcess/bloomPass'

// Utils
import RAF from '~/glxp/utils/raf'
import DebugController from '~/glxp/debug/debugController'
import PBRConfigs from '~/glxp/debug/pbrConfigs'
import GLTFUtils from '~/glxp/utils/GLTFUtils'
import GlobalEmitter from '~/glxp/utils/emitter'
import Mouse from '~/glxp/utils/mouse'
import { clamp, deg2rad, lerp, map } from '~/glxp/utils/math'
import { isMobile } from '@/glxp/utils/device'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'
import { Raycast } from '~~/glxp/ogl/extras/Raycast'

// Managers
import CameraManager from '~/glxp/managers/CameraManager'
import DebugManager from '~/glxp/managers/DebugManager'
import HotspotManager from '~/glxp/managers/HotspotManager'

// Data
import { HOTSPOTS_SPACEPORT } from '~/glxp/data/dataHotspots'

// Paths
// import { 
//   SPACEPORT_CAMERA_POSITION_POINTS_DESKTOP, 
//   SPACEPORT_CAMERA_POSITION_POINTS_MOBILE, 
//   SPACEPORT_CAMERA_LOOKAT_POINTS 
// } from '~/glxp/camera/paths'

class Scene extends SceneAbs {
  constructor(container, manager = null, shouldFirstDraw = false) {
    super(container, manager)

    this.name = 'Spaceport America'

    this.manager = manager
    this.renderer = manager.renderer

    this.time = 0
    this.dt = 0
    this.drawcalls = 0
    this.progress = 0
    this.progressDelta = 0
    this.progressTarget = 0.00001
    this.globalProgress = 0
    this.globalProgressDelta = 0
    this.globalProgressTarget = 0.00001
    this.timescale = 1
    this.forceAllDraw = true
    this.lastWheel = 0
    this.wheelProgress = 0
    this.occluder = null

    this.debugManager = new DebugManager(this)

    this.clearColor = this.manager.clearColor
    this.loaded = false
    this.active = false

    this.textureLoader = this.manager.textureLoader

    // Meshes
    this.root = new Transform()
    this.meshes = []

    // First Draw
    this.shouldFirstDraw = shouldFirstDraw
    this.didFirstDraw = false
    this.pendingFDMeshes = []
    this.pendingFDMeshesPassed = []

    this.root.scale.set(1, 1, 1)

    this.tweenProgress = { dropProgress: 0, panProgress: 0 }

    this.config = {
      Pause: { value: false, type: "bool" },
      TimeScale: { value: 1, params: { min: 0, max: 3, step: 0.01 } },
      ScrollDamping: { value: 0.05, params: { min: 0, max: 1, step: 0.01 } },
      SunPosition: { value: { x: 0, y: 29, z: -225 }, params: {} },
      // DEV
      OverrideScroll: { value: false, params: {} },
      Progress: { value: 0, params: { min: 0, max: 1, step: 0.01 } },
      DevScrollSpeed: { value: 30, params: { min: 0, max: 60 } },
      AnimCameraPosTargetDescendOffset: { value: {x: 0, y: 11.77, z: -.5}, params: {} },
      AnimCameraPosTargetPanOffset: { value: {x: -1.1, y: -0.16, z: -.45}, params: {} },
      AnimCameraPosTargetParallaxOffset: {  value: {x: 0.235, y: -.155, z: 0.14}, params: {} },
    }

    DebugController.addBlade(
      this.config,
      `Global Settings - ${this.name || 'Unknown'}`
    )

    // Animations
    this.animCameraPos = { x: 0, y: 12, z: 0.5 }
    this.animCameraLookat = { x: 0, y: 30, z: -20 }

    // Inits
    this.initCameras()
    // this.initCameraTracks()
    this.initHotspots()
    this.initSpaceportSky()
    this.initSun()
    
    if (!DebugController.queryDebug('notextures')) {
      this.initFlares()
    }

    this.initTimelineScroll()
  }

  // Inits
  initHotspots() {
    this.hotspotManager = new HotspotManager(this.gl, {
      scene: this,
      debug: DebugController.queryDebug('dev'),
      hotspots: HOTSPOTS_SPACEPORT,
      debugRadius: 0.03,
    })
  }

  initCameras() {
    this.cameraManager = new CameraManager(this.gl, {
      width: this.width,
      height: this.height,
      scene: this,
      debug: false,
      hasMousePan: true,
      mousePanOffsets: [0.01, -0.01],
    })
    this.camera = this.cameraManager.camera

    this.cameraManager.toGo.set(0, 1, 5)
    this.cameraManager.toLook.set(0, 0.1, 0)
  }

  // initCameraTracks() {
  //   // Position
  //   const cameraPositionTrack = new Catmull(
  //     this.cameraManager.parseCameraPoints(
  //       isMobile ? SPACEPORT_CAMERA_POSITION_POINTS_MOBILE : SPACEPORT_CAMERA_POSITION_POINTS_DESKTOP,
  //       1.5,
  //       false
  //     )
  //   )
  //   cameraPositionTrack.generate()

  //   this.cameraManager.addSpline(cameraPositionTrack, 'camera')

  //   // Lookat
  //   const cameraLookatTrack = new Catmull(
  //     this.cameraManager.parseCameraPoints(
  //       SPACEPORT_CAMERA_LOOKAT_POINTS,
  //       1.5,
  //       false
  //     )
  //   )
  //   cameraLookatTrack.generate()

  //   this.cameraManager.addSpline(cameraLookatTrack, 'lookat')
  // }
  
  initPost() {
    this.post = new LensFlarePostEntity(this, this.postOptions)
    
    // TODO: Check if there would be better way to set that before network config is enabled.
    this.post.config.Gamma.value = 1.2
    this.post.config.Exposure.value = 0.15
    this.post.config.Contrast.value = 0.2
  }

  initSpaceportSky() {
    this.nightSky = new SpaceportSky(this)

    this.meshes.push(this.nightSky)
  }

  initSun() {
    this.sun = new Transform()
    this.sun.visible = false
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    this.sun.setParent(this.root)
  }

  initFlares() {
    const raycaster = new Raycast(this.gl)

    this.occluder = new Occluder(this, { scaleFactor: .575, visible: false })
    this.occluder.mesh.setParent(this.root)
    this.meshes.push(this.occluder)

    this.lensFlares = new LensFlares(this, {
      occluder: this.occluder,
      raycaster,
      lightSource: this.sun,
      getFlares: (spacing) => getSpaceportAmericaFlares(this, spacing), 
      spacing: 0.083, 
      occlusionDirtMultiplier: 1.2,
      fadePower: .04,
    })

    this.meshes.push(this.lensFlares)
  }

  setProgress(p, shouldForce = false) {
    this.progressTarget = p

    if (shouldForce) this.progress = p
  }

  activate() {
    return new Promise((resolve) => {
      this.active = true
      this.activationResolve = resolve
      this.postFirstDraw()

      this.addEvents()
    })
  }

  disable() {
    this.active = false

    this.removeEvents()
  }

  async load() {
    let loadables = []

    // Post
    this.initPost()

    // Textures
    const addTextureToLoadables = (groupKey) => {
      for (const key in Manifest[groupKey]) {
        if (Manifest[groupKey].hasOwnProperty(key)) {
          const element = Manifest[groupKey][key]
          loadables.push(this.textureLoader.load(element.url, key, element.options))
        }
      }
    }

    // Textures - Common
    addTextureToLoadables('main')
    addTextureToLoadables('defaultLensFlares')
    addTextureToLoadables('circularLensFlares')

    // Textures - Spaceport scene
    addTextureToLoadables('spaceportPBR')
    addTextureToLoadables('spaceportUnlit')
    addTextureToLoadables('spaceportEnv')

    // Textures - Imagine
    addTextureToLoadables('imaginePBR')
    addTextureToLoadables('imagineUnlit')

    // Textures - Eve
    addTextureToLoadables('evePBR')
    addTextureToLoadables('eveUnlit')

    // Textures - Unity
    addTextureToLoadables('unityPBR')
    addTextureToLoadables('unityUnlit')

    // Models
    const unlitMaterialNames = Object.keys(Manifest.spaceportUnlit) || []
    const meshesExcludeList = [
      'SpaceShip_Unity_Wings',
      'SpaceShip_Unity_LandingGear',
      'SpaceShip_Unity_Body',
      'SpaceShip_Eve_LandingGear',
      'SpaceShip_Eve_Body',
      'Shadow_Unity',
      'Shadow_Eve'
    ]

    // SPACEPORT LANDSCAPE + BUILDINGS + SHIPS
    let url = `${"/"}glxp/models/spaceport_6_draco`;
    loadables.push(new GLBLoader(url + '.glb', true).then((glb) => {
      // Root for PBR model
      let modelRoot = new Transform()
      modelRoot.scale.set(.015, .015, .015)
      modelRoot.setParent(this.root)

      // Build node Tree
      let modelTree = GLTFUtils.buildNodeTree(glb, modelRoot)

      // Mesh List
      let meshList = GLTFUtils.buildMeshList(glb, modelTree)

      // Mesh Instanciation
      let entity

      for (const mesh of meshList) {
        if (meshesExcludeList.includes(mesh.node.name)) {
          continue
        }

        const isSkybox = mesh.materialName.includes("Skybox")
        const isMountain = mesh.materialName.includes("Mountains")
        const isPole = mesh.node.name.includes("_Pole")
        if(isPole) mesh.materialName = "Pole"
        const isFlag = mesh.node.name.includes("Tapestry")

        if (unlitMaterialNames.includes(mesh.materialName)) {
          entity = new MeshUnlit(this,
            mesh.meshData,
            Manifest.spaceportUnlit[mesh.materialName] !== undefined ? mesh.materialName : 'test',
            {
              id: 2,
              parent: mesh.parent || modelRoot,
              gltf: glb,
              name: mesh.meshName,
              node: mesh.node,
              fog: !isSkybox,
              transparent: false,
              materialName: mesh.materialName,
              material: Manifest.spaceportUnlit[mesh.materialName]?.material,
              globalConfig: this.getPBRConfig(mesh.materialName),
            }
          )

          if (isMountain) entity.mesh.rotation.y = -deg2rad(10)
          if (isSkybox) entity.mesh.rotation.y = -deg2rad(5)

        } else if(isFlag) {
          // const flags = ["M_UsaFlag", "M_FranceFlag", "M_BelgiumFlag"]
          // const flag = flags[Math.floor(Math.random() * flags.length)]
          const flag = "M_VgFlag"
          entity = new MeshFlag(this,
            mesh.meshData,
            flag,
            {
              id: 2,
              parent: mesh.parent || modelRoot,
              gltf: glb,
              name: mesh.meshName,
              node: mesh.node,
              fog: !isSkybox,
              transparent: false,
              materialName: "M_Props",
              material: Manifest.spaceportUnlit["M_Props"]?.material,
              globalConfig: this.getPBRConfig("M_Props"),
              shaderId: "flag",
            }
          )
        } else if(isPole) {
          entity = new MeshPole(this,
            mesh.meshData,
            "M_MetalMatcap",
          {
            id: 1,
            parent: mesh.parent || modelRoot,
            gltf: glb,
            data: mesh.meshData,
            name: mesh.meshName,
            node: mesh.node,
            fog: false,
            transparent: false,
            materialName: "M_Props",
            material: Manifest.spaceportUnlit["M_Props"]?.material,
            globalConfig: this.getPBRConfig("M_Props"),
            shaderId: "matcap",
          })
        } else {
          entity = new MeshPBR(this, {
            id: 1,
            parent: mesh.parent || modelRoot,
            gltf: glb,
            data: mesh.meshData,
            name: mesh.meshName,
            node: mesh.node,
            fog: false,
            transparent: false,
            materialName: mesh.materialName,
            material: mesh.material,
            globalConfig: this.getPBRConfig(mesh.materialName),
          })
        }
        this.meshes.push(entity)
      }
    }))

    // Load
    Promise.all(loadables).then(() => {
      this.onLoaded()
    })

    // Loading progression
    let percent = 0
    for (let i = 0; i < loadables.length; i++) {
      loadables[i].then(() => {
        percent++
        const formatedVal = Math.round(
          (percent / loadables.length) * 100
        )
        this._emitter.emit('progress', formatedVal)
        GlobalEmitter.emit('webgl_loading_progress', { progress: formatedVal })
      })
    }
  }

  // Animations
  initTimelineScroll() {
    this.tlScroll = gsap.timeline({ paused: true, duration: 100 })

    if (isMobile) {
      this.tlScroll.to(this.animCameraPos, { x: 0, y: 0.135, z: 1.85, duration: 70, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.animCameraLookat, { x: 0, y: 0.3, z: -0.625, duration: 70, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.camera, { fov: 40, duration: 60, ease: 'power3.inOut' }, 0)
      
      this.tlScroll.to(this.animCameraPos, { x: 1.35, y: 0.5, z: 2.25, duration: 30, ease: 'power3.inOut' }, 70)
      this.tlScroll.to(this.animCameraLookat, { x: -0.1, y: 0.3, z: -0.55, duration: 30, ease: 'power3.inOut' }, 70)
      this.tlScroll.to(this.camera, { fov: 50, duration: 30, ease: 'power3.inOut' }, 70)
    } else {
      // this.tlScroll.to(this.animCameraPos, { x: 0, y: 0.15, z: 1, duration: 70, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.animCameraLookat, { x: 0, y: 0.2, z: -0.85, duration: 70, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.tweenProgress, { dropProgress: 1, duration: 70, ease: 'power3.inOut' }, 0)
      this.tlScroll.to(this.tweenProgress, { panProgress: 1, duration: 70, ease: 'power3.inOut' }, 35.5)
      // this.tlScroll.to(this.animCameraPos, { x: 1.15, y: 0.25, z: 1.35, duration: 30, ease: 'power3.inOut' }, 70)
    }
  }

  // Utils
  getPBRConfig(materialName) {
    switch(materialName) {
      case 'M_Glass_PBR':
        return PBRConfigs["CONFIG_SPACEPORT_GLASS_NOSHIPS"]

      default:
        return PBRConfigs["CONFIG_SPACEPORT"]
    }
  }

  applyDefaultState() {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    gl.depthMask(true)
  }

  reset() {
    // Progress
    this.setProgress(0, true)
  }

  getFirstDrawPromises() {
    let promises = []

    this.meshes.forEach((mesh) => {
      if (mesh.getFirstDrawPromise) {
        promises.push(mesh.getFirstDrawPromise())
        this.pendingFDMeshes.push(mesh)
      }
    })

    return promises
  }

  drawNextFDMesh() {
    if (this.pendingFDMeshes.length > 0) {
      this.pendingFDMeshes[0].firstDraw()
      this.pendingFDMeshesPassed.push(this.pendingFDMeshes.shift())
    }
  }

  // Events
  addEvents() {
    GlobalEmitter.on('webgl_spaceport_progress', this.onProgressRequest.bind(this))
    GlobalEmitter.on('webgl_spaceport__global_page_progress', this.onGlobalProgressRequest.bind(this))
  }

  removeEvents() {
    GlobalEmitter.off('webgl_spaceport_progress', this.onProgressRequest.bind(this))
    GlobalEmitter.off('webgl_spaceport__global_page_progress', this.onGlobalProgressRequest.bind(this))
  }

  onLoaded() {
    this.active = true
    this.loaded = true

    this._emitter.emit('loaded')
    GlobalEmitter.emit('webgl_loaded')

    // First Draw
    if (this.shouldFirstDraw) {
      const firstDrawPromises = this.getFirstDrawPromises()

      this.firstDrawAllPromise = Promise.all(firstDrawPromises).then(() => {
        this.didFirstDraw = true
        // console.log(`Scene ${this.name} finished first draw`)
      })
    }

    // Meshes
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].onLoaded()
    }

    // Post
    if(this.hasBloom){
      this.bloomPass = new Bloom(this, this.post)
    }
    this.post.onLoaded()

    // Debug
    DebugController.onLoaded()
    this.debugManager.onLoaded()
  }

  postFirstDraw() { 
    if (DebugController.active) {
      this.occluder.initGui()
    }
  }

  onProgressRequest({ progress }) {
    this.setProgress(progress)
  }

  onGlobalProgressRequest({ progress }) {
    this.globalProgressTarget = progress
  }

  resize() {
    this.cameraManager.resize(this.width, this.height)
    this.hotspotManager.resize(this.width, this.height)
  }

  // Rendering
  preRender() {
    // Progress
    if (this.hasWheel) {
      // Listen to mouse wheel for progress on Sandbox
      if (!this.isAnimatingIntro) {
        this.wheelProgress += Mouse.normalizeWheel - this.lastWheel
        this.wheelProgress = clamp(this.wheelProgress, 0, 0.037)
      }

      // Can override it through the config value if needed
      this.progressTarget = this.config.OverrideScroll.value ? this.config.Progress.value : this.wheelProgress * this.config.DevScrollSpeed.value
      this.progressTarget = clamp(this.progressTarget, 0, 1)
      this.progress = lerp(this.progress, this.progressTarget, 0.075)
      this.lastWheel = Mouse.normalizeWheel
    } else {
      // Listen to FE events for progress
      let tmp = this.progressTarget - this.progress
      this.progressDelta = tmp
      tmp *= this.config.ScrollDamping.value

      this.progress += tmp

      let tmp2 = this.globalProgressTarget - this.globalProgress
      this.globalProgressDelta = tmp2
      tmp2 *= this.config.ScrollDamping.value

      this.globalProgress += tmp2
    }

    // TODO: move this to a .on() tweakpane event
    this.sun.position.set(this.config.SunPosition.value.x, this.config.SunPosition.value.y, this.config.SunPosition.value.z)
    
    // Animations
    this.tlScroll.progress(this.progress)

    // Descend towards ground
    this.cameraManager.toGo.x = this.animCameraPos.x - (this.config.AnimCameraPosTargetDescendOffset.value.x * this.tweenProgress.dropProgress)
    this.cameraManager.toGo.y = this.animCameraPos.y - (this.config.AnimCameraPosTargetDescendOffset.value.y * this.tweenProgress.dropProgress)
    this.cameraManager.toGo.z = this.animCameraPos.z - (this.config.AnimCameraPosTargetDescendOffset.value.z * this.tweenProgress.dropProgress)

    // Pan to side
    this.cameraManager.toGo.x += this.config.AnimCameraPosTargetDescendOffset.value.x - (this.config.AnimCameraPosTargetPanOffset.value.x * this.tweenProgress.panProgress)
    this.cameraManager.toGo.y += this.config.AnimCameraPosTargetDescendOffset.value.x - (this.config.AnimCameraPosTargetPanOffset.value.y * this.tweenProgress.panProgress)
    this.cameraManager.toGo.z += this.config.AnimCameraPosTargetDescendOffset.value.x - (this.config.AnimCameraPosTargetPanOffset.value.z * this.tweenProgress.panProgress)

    // Pan downwards to emulate parallax
    const parallaxProgress = clamp(map(this.globalProgress, 0.8, 1, 0, 1), 0, 1)
    this.cameraManager.toGo.x += (this.config.AnimCameraPosTargetParallaxOffset.value.x) * parallaxProgress
    this.cameraManager.toGo.y += (this.config.AnimCameraPosTargetParallaxOffset.value.y) * parallaxProgress
    this.cameraManager.toGo.z += (this.config.AnimCameraPosTargetParallaxOffset.value.z) * parallaxProgress

    this.cameraManager.toLook.set(this.animCameraLookat.x, this.animCameraLookat.y, this.animCameraLookat.z)

    // Sky
    const skyProgress = map(this.progress, 0, 0.4, 0, 1)
    this.nightSky.setProgress(skyProgress)

    // Meshes
    for (let i = 0; i < this.meshes.length; i += 1) {
      this.meshes[i].preRender()
    }

    // Hotspots
    const thresholds = isMobile ? [0.8, 0.9, 0.95] : [0.65, 0.7, 0.8]
    if(this.progress > thresholds[0]) this.hotspotManager.hotspots[2].visibility = 1
    else this.hotspotManager.hotspots[2].visibility = 0
    if(this.progress > thresholds[1]) this.hotspotManager.hotspots[3].visibility =  1
    else this.hotspotManager.hotspots[3].visibility = 0
    if(this.progress > thresholds[2]) {
      for (const hotspot of this.hotspotManager.hotspots) {
        hotspot.visibility = 0
      }
    }else if(this.progress > 0 && this.progress <= thresholds[2]) {
      this.hotspotManager.hotspots[0].visibility =  1
      this.hotspotManager.hotspots[1].visibility =  1
    }

    this.cameraManager.preRender()
  }

  render() {
    // First Draw
    if (this.shouldFirstDraw && !this.didFirstDraw) {
      this.drawNextFDMesh()
      return
    }

    if (!this.active) {
      return
    }

    // Timings
    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    // Pre render
    this.preRender()

    // Aspect
    this.gl.viewport(0, 0, this.width, this.height)
    this.camera.perspective({ aspect: this.width / this.height })
    this.renderer.setViewport(this.width, this.height)

    // Render Time
    this.renderer.render({
      scene: this.root,
      camera: this.camera,
      clear: true,
      frustumCull: true,
      sort: true,
      post: this.post,
    })

    // Post
    this.bloomPass && this.bloomPass.render()
    this.post.render()

    

    this.postRender()
  }

  throttledHotspotUpdate = throttle(() => {
    this.hotspotManager.update()
  }, 10)

  postRender() {
    this.gl.viewport(0, 0, this.width, this.height)
    this.drawcalls++

    if (this.forceAllDraw && this.drawcalls > 40) {
      this.forceAllDraw = false
      this.activationResolve()
    }

    this.throttledHotspotUpdate()
  }
}

export default Scene
