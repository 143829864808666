// In comments: isolated paramater FPS differences measured on the CPU
// Note: only tested on a macbook pro early 2015 13 inch intel iris 6100 1536 Mo
// Note: these measurements are rough estimations, they are just to get a general idea of the performance benefits of each setting
// Note: these might do not stack, so if you enable all of them, you do not get the sum of all of them
const out = {
    optimisedRenderer: {
        antialias: false,                       // +4FPS
        powerPreference: 'high-performance',    // +0FPS
        stencil: false,                         // +0FPS
        alpha: false,                           // +0FPS
        // webgl: 1
    },
    adaptDPR: true,                             // +17FPS 
    adaptFxaa: true,                            // +2FPS
    adaptBloom: true,                           // +12FPS
    // TODO :
    // adaptTextureSize: true,
}

export default out