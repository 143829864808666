// Libs
import gsap from 'gsap'

// Structural
import GLBLoader from '~/glxp/loader/glbLoader'
import Scene from '~/glxp/abstract/scene'
import DebugController from '~/glxp/debug/debugController'

// Entities
import MeshPBR from '~~/glxp/entities/MeshPBR'

// Utils
import GLTFUtils from '~/glxp/utils/GLTFUtils'

// OGL
import { Transform } from '~/glxp/ogl/core/Transform.js'

// Constants
import { GUI_PANEL_PBR } from '~/glxp/data/dataGUIPanels'

export default class MeshVSSUnity {
    scene
    meshesExcludeList
    PBRConfig
    body
    wings
    leftSkirt
    rightSkirt

    root = new Transform()
    rootParent = new Transform()
    meshes = []
    featherTimeline = gsap.timeline({ paused: true })
    animations = {
        root: {
            position: { x: 0, y: 0, z: 0 },
            rotation: { x: 0, y: 0, z: 0 }
        },
        body: {
            rotation: { x: 0, y: 0, z: 0 }
        },
        wings: {
            rotation: { x: 0, y: 0, z: 0 }
        }
    }
    config = {
        positionOffset: {
            value: { x: 0, y: 0, z: 0 },
            params: { x: { min: -3.1415, max: 3.1415 }, y: { min: -3.1415, max: 3.1415 }, z: { min: -3.1415, max: 3.1415 } }
        }
    }

    /**
     * Wrapper for loading and animating VSS Unity
     * @param {Scene} scene 
     * @param {{ meshesExcludeList : string[] }} options
     */
    constructor(scene, { meshesExcludeList, PBRConfig }) {
        this.scene = scene
        this.meshesExcludeList = meshesExcludeList
        this.PBRConfig = PBRConfig
    }

    load(url = `/glxp/models/spaceship_unity_skirts_draco`) {
        return new Promise((resolve, reject) => {
            new GLBLoader(url + '.glb', true).then((glb) => {
                // console.log(glb);

                // Root for PBR model
                this.root.scale.set(1, 1, 1)

                // Build node Tree
                let modelTree = GLTFUtils.buildNodeTree(glb, this.root)

                // Mesh List
                let meshList = GLTFUtils.buildMeshList(glb, modelTree)

                // Mesh Instanciation
                for (const mesh of meshList) {
                    // TODO: Check if we could use mesh name instead of node, or if node is more consistent
                    if (this.meshesExcludeList.includes(mesh.node.name)) {
                        continue
                    }

                    const entity = new MeshPBR(this.scene, {
                        id: GUI_PANEL_PBR,
                        parent: mesh.parent || this.root,
                        gltf: glb,
                        data: mesh.meshData,
                        name: mesh.meshName,
                        node: mesh.node,
                        fog: false,
                        materialName: mesh.materialName,
                        material: mesh.material,
                        globalConfig: this.PBRConfig,
                        transparent: true, // important for render order
                        forceRenderOrder: true,
                        renderOrder: 4
                    })

                    switch (mesh.node.name) {
                        case 'SpaceShip_Unity_Body':
                            this.body = entity.mesh
                            break

                        case 'SpaceShip_Unity_Wings':
                            this.wings = entity.mesh
                            break

                        case 'SpaceShip_Unity_Skirt_R':
                            this.rightSkirt = entity.mesh
                            break


                        case 'SpaceShip_Unity_Skirt_L':
                            this.leftSkirt = entity.mesh
                            break
                    }

                    this.meshes.push(entity)
                }

                this.root.setParent(this.rootParent)
                resolve()
            })
        })
    }

    initGui() {
        DebugController.addBlade(this.config, `VSS Unity`, 0)
    }

    setFeatherTimeline() {
        this.featherTimeline.to(this.animations.root.rotation, { x: Math.PI * -0.12, duration: 100, ease: 'power1.inOut' }, 0)
        // Must rotate to 60 degrees exactly
        this.featherTimeline.to(this.animations.wings.rotation, { x: 1.0472, duration: 100, ease: 'power1.inOut' }, 0)
    }

    /**
     * Remember to update feathering timeline: featherTimeline.progress(someNumber)
     */
    updateFeather(progress) {
        this.featherTimeline.progress(progress)

        this.root.position.set(this.animations.root.position.x, this.animations.root.position.y, this.animations.root.position.z)
        this.root.rotation.set(this.animations.root.rotation.x, this.animations.root.rotation.y, this.animations.root.rotation.z)

        this.wings && this.wings.rotation.set(this.animations.wings.rotation.x, this.animations.wings.rotation.y, this.animations.wings.rotation.z)

        // this.body.rotation.set(this.animations.body.rotation.x, this.animations.body.rotation.y, this.animations.body.rotation.z)

        this.leftSkirt && this.leftSkirt.rotation.set(this.animations.wings.rotation.x, this.animations.wings.rotation.y, this.animations.wings.rotation.z)

        this.rightSkirt && this.rightSkirt.rotation.set(this.animations.wings.rotation.x, this.animations.wings.rotation.y, this.animations.wings.rotation.z)
    }
}