import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'

import DebugController from '~/glxp/debug/debugController'

import { Sphere } from '~/glxp/ogl/extras/Sphere.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Color } from '~/glxp/ogl/math/Color'

// Data
import { GUI_PANEL_CUSTOM } from '~/glxp/data/dataGUIPanels'

class BackgroundEntity {
  constructor(
    scene,
    shaderId,
    {
      scale = 1,
      blendFunc = {
        src: scene.gl.SRC_ALPHA,
        dst: scene.gl.ONE_MINUS_SRC_ALPHA,
      },
      transparent = false,
      depthTest = true,
      depthWrite = true,
      renderOrder = 0,
      name = 'Background',
      color = "#0b0415",
      gradientColor = "#6866BB",
      gradientEdges = {x: 0, y: 0.1},
      opacity = .28
    } = {}
  ) {
    this.gl = scene.gl
    this.scene = scene
    this.scale = scale
    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.renderOrder = renderOrder
    this.name = name
    this.color = color
    this.gradientColor = gradientColor

    this.shader = new Shader(ShaderManifest[shaderId])

    this.config = {
      GradientEdges: { value: gradientEdges, params: { x: {min: 0, max: 1}, y: {min:0, max:1} } },
      Opacity: { value: opacity, params: { min: 0, max: 1 } },
      GradientAngle: { value: -3.1415, params: {min: -3.1415, max: 3.1415} },
      Color: { value: color, params: {} },
      GradientColor: { value: gradientColor, params: {} }
    }

    this.init()
  }

  initGui(){
    this.gui = DebugController.addBlade(this.config, `${this.scene.name} - ${this.name}`, GUI_PANEL_CUSTOM)
    this.gui && this.gui.params.GradientEdges.on("change", (e)=>{
      this.program.uniforms.uGradientEdges.value[0] = e.value.x
      this.program.uniforms.uGradientEdges.value[1] = e.value.y
    })
    this.gui && this.gui.params.Color.on("change", (e)=>{
      this.program.uniforms.uColor.value = new Color(e.value)
    })
    this.gui && this.gui.params.GradientColor.on("change", (e)=>{
      this.program.uniforms.uGradientColor.value = new Color(e.value)
    })
  }

  init() {
    this.geometry = new Sphere(this.gl, { radius: 500 })
    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      uniforms: {
        uColor: { value: new Color(this.config.Color.value)},
        uGradientColor: { value: new Color(this.config.GradientColor.value) },
        uGradientEdges: { value: [this.config.GradientEdges.value.x, this.config.GradientEdges.value.y] },
        uGradientOpacity: this.config.Opacity,
        uAlpha: { value: 1 },
        uResolution: { value: [this.scene.width, this.scene.height] },
        uGradientAngle: this.config.GradientAngle
      },
      cullFace: this.gl.FRONT,
      transparent: this.transparent,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
    })

    this.program.cullFace = false
    this.program.setBlendFunc(this.blendFunc.src, this.blendFunc.dst)
    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
      renderOrder: this.renderOrder,
    })
    this.mesh.scale.set(this.scale, this.scale, this.scale)
    this.mesh.name = this.name
    this.mesh.setParent(this.parent)
  }

  onLoaded() {
    this.initGui()
  }

  preRender() {
    this.program.uniforms['uResolution'].value = [this.scene.width, this.scene.height]

    this.program.uniforms.uGradientEdges.value[0] = this.config.GradientEdges.value.x
    this.program.uniforms.uGradientEdges.value[1] = this.config.GradientEdges.value.y

    this.program.uniforms.uColor.value = new Color(this.config.Color.value)

    this.program.uniforms.uGradientColor.value = new Color(this.config.GradientColor.value)
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default BackgroundEntity
