import Shader from '~/glxp/utils/shader'
import ShaderManifest from '~/glxp/shaderManifest'
import DebugController from '~/glxp/debug/debugController'
import Mouse from '~/glxp/utils/mouse'
import PostProcessRT from './postProcessRT'

import { Plane } from '~/glxp/ogl/extras/Plane.js'
import { Program } from '~/glxp/ogl/core/Program.js'
import { Mesh } from '~/glxp/ogl/core/Mesh.js'
import { Geometry } from '~/glxp/ogl/core/Geometry.js'
import { Texture } from '~/glxp/ogl/core/Texture.js'

const VERTICES = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0]
const INDICES = [0, 1, 2, 0, 2, 3]
const UVS = [0, 1, 0, 0, 1, 0, 1, 1]

class LensFlarePostEntity {
  constructor(scene, {
    blendFunc = {},
    transparent = false,
    depthTest = false,
    depthWrite = false,
    renderOrder = 0,
    alpha = 1,
    shaderId = 'lensFlarePost',
    fxaa = true
  } = {}) {
    this.gl = scene.gl
    this.scene = scene

    this.rt = new PostProcessRT(this.scene, 1, 'rgb', null, true)

    this.transparent = transparent
    this.blendFunc = blendFunc
    this.depthTest = depthTest
    this.depthWrite = depthWrite
    this.textureId = null
    this.renderOrder = renderOrder
    this.shader = new Shader(ShaderManifest[shaderId])
    this.texture = new Texture(this.gl, {
      rt: this.rt,
      width: this.rt.width,
      height: this.rt.height,
    })
    this.bloomTexture = new Texture(this.gl)
    this.dirtTexture = new Texture(this.gl)
    this.noiseTexture = new Texture(this.gl)
    this.alpha = alpha

    this.config = {
      //   GrainScale: { value: 1, params: {min: 0, max: 3, step: 0.01} },
      BloomOpacity: { value: .25, params: { min: 0, max: 1, step: 0.01 } },
      NoiseOpacity: { value: .02, params: { min: 0, max: 0.2 } },
      Gamma: { value: 1, params: { min: 0, max: 2, step: 0.01 } },
      Exposure: { value: 0.05, params: { min: -2, max: 2, step: 0.01 } },
      Contrast: { value: 0.1, params: { min: -1, max: 1, step: 0.01 } },
      Vignette: { value: .25, params: { min: 0, max: 1, step: 0.01 } },
      ChromaticAberations: { value: 0.05, params: { min: 0, max: 1, step: 0.01 } },
      VignetteStrength: { value: .5, params: { min: 0, max: 1, step: 0.01 } },
      FXAA: { value: fxaa, params: {} },
      Bloom: { value: true, params: {} },
      NoiseScale: { value: .0001, params: { min: 0.0000001, max: .0003 } },

      DirtDistanceFactor: { value: 1, params: { min: 0, max: 10, step: 0.1 } },
      DirtOpacityFactor: { value: 1, params: { min: 0, max: 1, step: 0.01 } },
      DirtResolutionScale: { value: { x: 1, y: 1 }, params: { x: { min: 0, max: 3, step: 0.01 }, y: { min: 0, max: 3, step: 0.01 } } },
      DirtResolutionOffset: { value: { x: 0, y: 0 }, params: { x: { min: -3, max: 3, step: 0.01 }, y: { min: -3, max: 3, step: 0.01 } } }
    }

    this.init()

    this.initGui()
  }

  initGui() {
    const gui = DebugController.addBlade(this.config, `Post Processing - ${this.scene.name || 'Unknown'}`, 0)

    gui && gui.params.DirtResolutionScale.on("change", (e) => {
      this.program.uniforms.uDirtResolutionScale.value[0] = e.value.x
      this.program.uniforms.uDirtResolutionScale.value[1] = e.value.y
    })

    gui && gui.params.DirtResolutionOffset.on("change", (e) => {
      this.program.uniforms.uDirtResolutionOffset.value[0] = e.value.x
      this.program.uniforms.uDirtResolutionOffset.value[1] = e.value.y
    })
  }

  init() {
    this.geometry = new Plane(this.gl)

    const attribs = {
      position: { size: 3, data: new Float32Array(VERTICES) },
      uv: { size: 2, data: new Float32Array(UVS) },
      index: { data: new Uint16Array(INDICES) }
    }
    this.geometry = new Geometry(this.gl, attribs)
    this.program = new Program(this.gl, {
      vertex: this.shader.vert,
      fragment: this.shader.frag,
      depthTest: this.depthTest,
      depthWrite: this.depthWrite,
      transparent: this.transparent,
      uniforms: {
        uTexture: { value: this.texture },
        uBloom: { value: this.bloomTexture },
        uTime: { value: this.scene.time },
        uRez: { value: [this.scene.width, this.scene.height] },
        uMouse: { value: Mouse.cursor },
        uVignette: this.config.Vignette,
        uVignetteStrength: this.config.VignetteStrength,
        uNoise: { value: this.noiseTexture },
        uNoiseOpacity: this.config.NoiseOpacity,
        uChromaticAberations: this.config.ChromaticAberations,
        uGamma: this.config.Gamma,
        uBloomOpacity: this.config.BloomOpacity,
        uExposure: this.config.Exposure,
        uContrast: this.config.Contrast,
        uFxaa: this.config.FXAA,
        uBloomEnabled: this.config.Bloom,
        uNoiseScale: { value: this.config.NoiseScale.value },

        uDirtTexture: { value: this.dirtTexture },
        uTextureAspectRatio: { value: 1 },
        uFlareOriginPosition: { value: [0, 0] },
        uDirtDistanceFactor: this.config.DirtDistanceFactor,
        uDirtOpacityFactor: this.config.DirtOpacityFactor,
        uDirtResolutionScale: { value: [this.config.DirtResolutionScale.value.x, this.config.DirtResolutionScale.value.y] },
        uDirtResolutionOffset: { value: [this.config.DirtResolutionOffset.value.x, this.config.DirtResolutionOffset.value.y] }
      }
    })

    this.program.cullFace = false
    this.mesh = new Mesh(this.gl, { geometry: this.geometry, program: this.program, renderOrder: this.renderOrder })

  }

  onLoaded() {
    if (this.scene.bloomPass) {
      this.bloomTexture = new Texture(this.gl, {
        rt: this.scene.bloomPass.rts[0],
        width: this.scene.bloomPass.rts[0].width,
        height: this.scene.bloomPass.rts[0].height,
      })
      this.program.uniforms['uBloom'].value = this.bloomTexture
    }

    // Dirt
    this.dirtTexture = this.scene.textureLoader.getTexture("postprocessing_dirt")
    this.dirtTexture.needsUpdate = true
    this.program.uniforms['uDirtTexture'].value = this.dirtTexture
    this.program.uniforms.uTextureAspectRatio.value = this.dirtTexture.image.width / this.dirtTexture.image.height

    // Noise
    this.noiseTexture = this.scene.textureLoader.getTexture("perlin_1")
    this.noiseTexture.needsUpdate = true
    this.program.uniforms['uNoise'].value = this.noiseTexture
  }

  preRender() {
    this.rt.preRender()
  }

  postRender() {
    this.rt.postRender()
  }

  render({ target = null } = {}) {
    this.program.uniforms['uTime'].value = this.scene.time
    this.program.uniforms['uRez'].value = [this.scene.width, this.scene.height]
    this.program.uniforms['uMouse'].value = Mouse.cursor

    this.program.uniforms['uNoiseScale'].value = this.config.NoiseScale.value * Math.max(this.scene.dpr * 2, 2)

    this.texture.needsUpdate = true

    if (target) {
      this.scene.renderer.render({
        scene: this.mesh,
        clear: true,
        frustumCull: false,
        sort: false,
        target: target
      })
    } else {
      this.scene.renderer.render({
        scene: this.mesh,
        clear: true,
        frustumCull: false,
        sort: false
      })
    }
  }

  dispose() {
    this.mesh.setParent(null)
    this.geometry.remove()
    this.program.remove()
  }
}

export default LensFlarePostEntity
