import GlobalEmitter from '~/glxp/utils/emitter'

const debounceMillis = 500

export default defineNuxtPlugin(() => {
  let timeoutID

  function onResize() {
    if (timeoutID) {
      clearTimeout(timeoutID)
    }
    timeoutID = setTimeout(() => {
      GlobalEmitter.emit('debouncedResize')
    }, debounceMillis)
  }

  // Resize Event
  window.addEventListener('resize', onResize)

  // Initial Resize
  onResize()
})
